import { createTheme } from '@mui/material'

export const colorTheme = {
  red: '#9F1E49',
  yellow: '#FECD51',
  white: '#F2F2F2',
  whitePaper: '#fbe3e9',
  black: '#151515',
  blackPaper: '#fff7e1'
}

const typography = {
  fontFamily: `'Oswald Variable', sans-serif`,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600
}

export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: colorTheme.red
    },
    secondary: {
      main: colorTheme.yellow
    },
    background: {
      default: colorTheme.white,
      paper: colorTheme.whitePaper
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: colorTheme.white,
          color: colorTheme.black,
          boxShadow: 'none'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          color: colorTheme.white
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: colorTheme.black
        }
      }
    }
  },
  typography
})

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: colorTheme.yellow
    },
    secondary: {
      main: colorTheme.red
    },
    background: {
      default: colorTheme.black,
      paper: colorTheme.blackPaper
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: colorTheme.black,
          color: colorTheme.white,
          boxShadow: 'none'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          color: colorTheme.black
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: colorTheme.white
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: colorTheme.black,
          boxShadow: 'none',
          backgroundImage: 'none'
        }
      }
    }
  },
  typography
})
