import { useState } from 'react'
import {
  Alert,
  Box,
  Grid2 as Grid,
  Snackbar,
  TextField,
  Typography,
  useTheme
} from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import emailjs from '@emailjs/browser'
import Link from '../../components/Link'
import { LoadingButton } from '@mui/lab'

function validateEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}

function trimText(text) {
  return text.replace(/\s+/g, '')
}

const ContactMe = () => {
  const theme = useTheme()
  const [dataForm, setDataForm] = useState({
    from_name: '',
    from_email: '',
    message: ''
  })
  const [errMsg, setErrMsg] = useState({
    from_name: '',
    from_email: '',
    message: ''
  })
  const [open, setOpen] = useState(false)
  const [severity, setSeverity] = useState('')
  const [alertMsg, setAlertMsg] = useState('')
  const [isSending, setIsSending] = useState(false)

  const hideSnackbar = () => setOpen(false)

  const handleChangeForm = (field, value) => {
    setDataForm((old) => ({ ...old, [field]: value }))
    setErrMsg((old) => ({ ...old, [field]: '' }))
  }

  const handleSendMessage = (e) => {
    e.preventDefault()

    let isError = false
    if (!trimText(dataForm.from_name)) {
      setErrMsg((old) => ({ ...old, from_name: 'please fill name' }))
      isError = true
    }

    if (!validateEmail(dataForm.from_email)) {
      setErrMsg((old) => ({ ...old, from_email: 'please fill valid email' }))
      isError = true
    }

    if (!trimText(dataForm.message)) {
      setErrMsg((old) => ({ ...old, message: 'message cannot be empty' }))
      isError = true
    }

    if (isError) {
      return
    }

    setIsSending(true)
    emailjs
      .sendForm('web_contact_form', 'web_contact_form_templat', e.target, {
        publicKey: process.env.REACT_APP_EMAILJS_KEY
      })
      .then(
        () => {
          setSeverity('success')
          setAlertMsg('Your message has been sent, Thank you!')
          setIsSending(false)
          setDataForm({ from_email: '', from_name: '', message: '' })
          setOpen(true)
        },
        (_) => {
          setSeverity('error')
          setAlertMsg('Something went wrong :(. Please contact me on other way')
          setIsSending(false)
          setOpen(true)
        }
      )
  }

  return (
    <Grid
      container
      mt={10}
      mx='auto'
      spacing={5}
      px={{ xs: 5, sm: 10, md: 20 }}>
      {/* CONTACT */}
      <Grid size={{ xs: 12, sm: 6 }}>
        <Typography
          variant='h3'
          fontWeight='fontWeightBold'
          color={theme.palette.primary.main}
          mb={2}>
          Let's Talk
        </Typography>
        <Typography mb={4}>
          Have any questions? Send me a message here! I respond as soon as
          posible.
        </Typography>
        <Typography>Additional ways to get in touch</Typography>
        <Typography>
          LinkedIn:{' '}
          <Link
            to='https://linkedin.com/in/nazudis'
            target='_blank'
            style={{ color: theme.palette.primary.main }}>
            /in/nazudis
          </Link>
        </Typography>
        <Typography>
          Email:{' '}
          <Link
            to='mailto:itsme@fauzanakmal.com'
            style={{ color: theme.palette.primary.main }}>
            itsme@fauzanakmal.com
          </Link>
        </Typography>
        <Typography>
          WhatsApp:{' '}
          <Link
            to='https://wa.me/+6285157578726'
            target='_blank'
            style={{ color: theme.palette.primary.main }}>
            Text Me
          </Link>
        </Typography>
      </Grid>

      {/* FORM */}
      <Grid
        size={{ xs: 12, sm: 6 }}
        display='flex'
        flexDirection='column'
        gap={4}
        component={'form'}
        onSubmit={handleSendMessage}
        noValidate>
        <TextField
          required
          id='from_name'
          name='from_name'
          label='Name'
          variant='outlined'
          placeholder='Your Name'
          value={dataForm.from_name}
          onChange={(e) => handleChangeForm('from_name', e.target.value)}
          error={!!errMsg.from_name}
          helperText={errMsg.from_name}
          disabled={isSending}
        />
        <TextField
          required
          id='from_email'
          name='from_email'
          label='Email'
          variant='outlined'
          type='email'
          placeholder='your@mail.com'
          value={dataForm.from_email}
          onChange={(e) => handleChangeForm('from_email', e.target.value)}
          error={!!errMsg.from_email}
          helperText={errMsg.from_email}
          disabled={isSending}
        />
        <TextField
          required
          multiline
          minRows={10}
          id='message'
          name='message'
          label='Message'
          variant='outlined'
          value={dataForm.message}
          onChange={(e) => handleChangeForm('message', e.target.value)}
          error={!!errMsg.message}
          helperText={errMsg.message}
          disabled={isSending}
        />
        <Box alignSelf='flex-end'>
          <LoadingButton
            type='submit'
            endIcon={<SendIcon />}
            loading={isSending}
            loadingPosition='end'
            variant='contained'>
            Send
          </LoadingButton>
        </Box>
      </Grid>

      {/* NOTIF SNACKBAR */}
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={hideSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert
          onClose={hideSnackbar}
          severity={severity}
          variant='filled'
          sx={{ width: '100%' }}>
          {alertMsg}
        </Alert>
      </Snackbar>
    </Grid>
  )
}

export default ContactMe
