import { Link as LinkReactRouter } from '@tanstack/react-router'

const Link = (props) => {
  return (
    <LinkReactRouter style={{ textDecoration: 'none' }} {...props}>
      {props.children}
    </LinkReactRouter>
  )
}

export default Link
