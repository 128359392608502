const LinuxIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='60'
      height='60'
      viewBox='0 0 192.756 192.756'>
      <g fillRule='evenodd' clipRule='evenodd'>
        <path d='M156.98 126.559c-2.275 9.376-13.82 28.953-19.957 37.569-6.135 8.652-5.377 16.441-16.717 13.408-11.305-3.033-14.477-2.481-26.161-1.792-11.615.689-9.099-.345-16.372 2.93-7.238 3.274-31.538-39.706-33.503-47.703-1.93-7.996-2.86-7.031 2.172-15.683S52.198 98.089 58.85 87.61c6.652-10.513 14.339-15.855 13.787-23.886-2.171-29.815-3.895-44.705 9.341-51.598 12.615-6.549 23.162-2.654 27.333-.414 1.791.965 5.445 2.827 8.168 6.101 2.723 3.206 5.17 8.065 6.549 14.201 2.826 12.305-1.172 8.238 2.033 22.335 3.172 14.063 9.617 20.957 17.477 32.09 7.858 11.132 16.062 29.503 13.442 40.12z' />
        <path
          d='M82.175 53.527c2.068-.827 1.72-.937 3.098-3.936 1.103-2.31 2.072-3.302 2.038-6.818 0-3.447-1.068-4.619-2.688-6.859-1.551-2.137-4.033-2.24-5.584-1.964-.896.138-2.068 1.275-2.86 2.964-.518 1.137-.931 2.585-.965 4.102-.104 4.067.241 5.619 1.172 8.375 1.102 3.24 3.79 4.928 5.789 4.136z'
          fill='#767778'
        />
        <path
          d='M82.175 53.514c2.055-.822 1.706-.936 3.076-3.915 1.096-2.295 2.064-3.285 2.045-6.781.013-3.424-1.035-4.645-2.628-6.798-1.577-2.081-4.009-2.159-5.549-1.893-.917.136-2.084 1.23-2.89 2.857-.556 1.105-.965 2.562-1 4.086-.101 4.039.263 5.597 1.178 8.338 1.086 3.223 3.782 4.894 5.768 4.106z'
          fill='#7d7f80'
        />
        <path
          d='M82.176 53.501c2.042-.816 1.692-.933 3.053-3.894 1.088-2.28 2.056-3.266 2.052-6.743.026-3.401-1.001-4.67-2.567-6.738-1.603-2.022-3.986-2.077-5.514-1.819-.939.133-2.102 1.184-2.92 2.75-.595 1.075-1 2.54-1.034 4.069-.099 4.012.283 5.576 1.184 8.301 1.067 3.204 3.772 4.857 5.746 4.074z'
          fill='#858686'
        />
        <path
          d='M82.176 53.488c2.028-.811 1.678-.931 3.03-3.872 1.082-2.265 2.049-3.249 2.06-6.705.038-3.379-.968-4.696-2.507-6.677-1.629-1.965-3.963-1.995-5.479-1.747-.96.13-2.118 1.139-2.949 2.642-.633 1.043-1.034 2.517-1.068 4.054-.097 3.984.304 5.555 1.19 8.263 1.048 3.185 3.762 4.82 5.723 4.042z'
          fill='#8e8f8f'
        />
        <path
          d='M82.176 53.476c2.015-.806 1.665-.93 3.008-3.851 1.074-2.25 2.041-3.231 2.068-6.668.051-3.355-.934-4.722-2.446-6.616-1.656-1.908-3.941-1.913-5.446-1.675-.981.128-2.134 1.094-2.979 2.535-.672 1.012-1.069 2.494-1.103 4.038-.095 3.957.325 5.534 1.196 8.226 1.032 3.167 3.754 4.783 5.702 4.011z'
          fill='#989797'
        />
        <path
          d='M82.176 53.463c2.001-.801 1.65-.928 2.985-3.83 1.067-2.234 2.034-3.212 2.075-6.63.064-3.333-.899-4.748-2.385-6.556-1.682-1.85-3.917-1.831-5.411-1.602-1.002.125-2.151 1.047-3.008 2.427-.71.981-1.104 2.472-1.137 4.022-.093 3.929.346 5.512 1.202 8.188 1.014 3.15 3.744 4.748 5.679 3.981z'
          fill='#9e9fa0'
        />
        <path
          d='M82.176 53.45c1.988-.795 1.637-.926 2.963-3.809 1.06-2.22 2.026-3.195 2.083-6.593.077-3.31-.866-4.774-2.325-6.495-1.708-1.793-3.894-1.75-5.375-1.53-1.024.124-2.168 1.002-3.038 2.32-.75.949-1.139 2.449-1.172 4.006-.09 3.902.367 5.492 1.208 8.151.996 3.132 3.734 4.712 5.656 3.95z'
          fill='#a6a6a7'
        />
        <path
          d='M82.176 53.438c1.975-.79 1.624-.924 2.94-3.787 1.053-2.206 2.019-3.177 2.09-6.556.09-3.287-.833-4.8-2.264-6.435-1.733-1.736-3.87-1.667-5.341-1.457-1.045.122-2.184.957-3.067 2.213-.788.918-1.173 2.426-1.206 3.99-.088 3.874.388 5.47 1.213 8.113.979 3.113 3.726 4.675 5.635 3.919z'
          fill='#b1b1b1'
        />
        <path
          d='M82.176 53.424c1.961-.785 1.609-.922 2.917-3.766 1.046-2.19 2.011-3.159 2.098-6.517.102-3.265-.799-4.826-2.204-6.374-1.759-1.678-3.848-1.586-5.307-1.384-1.066.118-2.2.911-3.097 2.104-.826.887-1.207 2.404-1.24 3.974-.086 3.847.409 5.449 1.219 8.076.963 3.095 3.718 4.64 5.614 3.887z'
          fill='#b8b8b9'
        />
        <path
          d='M82.176 53.412c1.948-.78 1.596-.92 2.895-3.745 1.039-2.176 2.003-3.142 2.104-6.48.116-3.243-.765-4.852-2.142-6.313-1.786-1.621-3.824-1.504-5.272-1.312-1.087.116-2.216.866-3.125 1.998-.865.856-1.242 2.381-1.274 3.958-.084 3.818.429 5.427 1.225 8.039.942 3.075 3.706 4.602 5.589 3.855z'
          fill='#bebdbe'
        />
        <path
          d='M82.177 53.399c1.935-.774 1.582-.918 2.872-3.724 1.032-2.16 1.996-3.123 2.113-6.442.128-3.219-.731-4.877-2.082-6.252-1.812-1.564-3.801-1.422-5.237-1.24-1.109.114-2.234.82-3.155 1.89-.904.824-1.277 2.358-1.31 3.942-.082 3.79.451 5.406 1.232 8.001.924 3.058 3.696 4.567 5.567 3.825z'
          fill='#c5c5c6'
        />
        <path
          d='M82.177 53.386c1.921-.769 1.568-.917 2.849-3.703 1.025-2.146 1.988-3.105 2.121-6.405.141-3.196-.698-4.903-2.022-6.192-1.837-1.507-3.777-1.341-5.202-1.167-1.13.112-2.25.775-3.185 1.783-.943.792-1.312 2.336-1.344 3.926-.08 3.763.472 5.385 1.238 7.963.907 3.041 3.687 4.532 5.545 3.795z'
          fill='#cccbcb'
        />
        <path
          d='M82.177 53.374c1.908-.764 1.554-.915 2.826-3.682 1.018-2.131 1.981-3.088 2.128-6.367.154-3.174-.664-4.929-1.961-6.132-1.863-1.449-3.754-1.258-5.167-1.095-1.151.109-2.267.73-3.214 1.676-.981.761-1.347 2.313-1.378 3.91-.078 3.735.493 5.364 1.244 7.926.888 3.023 3.677 4.495 5.522 3.764z'
          fill='#d2d1d1'
        />
        <path
          d='M82.177 53.361c1.895-.758 1.541-.913 2.804-3.661 1.011-2.116 1.973-3.069 2.135-6.329.167-3.151-.629-4.955-1.899-6.071-1.89-1.392-3.731-1.177-5.133-1.022-1.172.106-2.283.684-3.244 1.568-1.02.729-1.38 2.29-1.412 3.894-.075 3.708.514 5.343 1.25 7.889.87 3.004 3.667 4.458 5.499 3.732z'
          fill='#d7d7d7'
        />
        <path
          d='M82.177 53.348c1.882-.752 1.527-.91 2.781-3.639 1.004-2.101 1.966-3.052 2.143-6.292.18-3.128-.596-4.98-1.839-6.01-1.916-1.335-3.708-1.096-5.099-.95-1.193.104-2.299.639-3.273 1.461-1.059.698-1.416 2.267-1.447 3.878-.073 3.679.535 5.321 1.256 7.851.854 2.987 3.659 4.422 5.478 3.701z'
          fill='#dcdcdd'
        />
        <path
          d='M82.177 53.335c1.868-.748 1.513-.909 2.758-3.618.997-2.086 1.958-3.034 2.151-6.254.192-3.106-.563-5.007-1.779-5.95-1.942-1.277-3.685-1.013-5.064-.876-1.215.102-2.316.593-3.303 1.353-1.097.667-1.45 2.245-1.481 3.862-.071 3.652.555 5.301 1.262 7.814.836 2.968 3.65 4.386 5.456 3.669z'
          fill='#e4e3e3'
        />
        <path
          d='M82.177 53.322c1.854-.742 1.5-.906 2.736-3.597.99-2.071 1.951-3.016 2.158-6.216.205-3.083-.529-5.033-1.718-5.889-1.967-1.22-3.662-.932-5.029-.805-1.236.1-2.333.547-3.332 1.246-1.136.636-1.484 2.222-1.516 3.846-.069 3.625.576 5.279 1.268 7.776.818 2.951 3.64 4.35 5.433 3.639z'
          fill='#e9e9e9'
        />
        <path
          d='M82.177 53.31c1.842-.737 1.486-.905 2.713-3.575.982-2.056 1.943-2.998 2.166-6.179.218-3.061-.495-5.059-1.657-5.829-1.994-1.163-3.639-.85-4.995-.732-1.257.098-2.35.502-3.362 1.139-1.174.604-1.519 2.2-1.55 3.831-.067 3.597.598 5.258 1.274 7.739.801 2.93 3.631 4.312 5.411 3.606z'
          fill='#eeeded'
        />
        <path
          d='M82.178 53.297c1.828-.731 1.472-.903 2.69-3.554.975-2.042 1.936-2.98 2.173-6.142.23-3.038-.461-5.085-1.597-5.769-2.02-1.105-3.616-.768-4.96-.659a7.983 7.983 0 0 0-3.391 1.031c-1.213.573-1.554 2.177-1.584 3.814-.065 3.569.619 5.236 1.279 7.701.784 2.915 3.622 4.279 5.39 3.578z'
          fill='#f2f3f4'
        />
        <path
          d='M82.178 53.284c1.815-.726 1.458-.901 2.668-3.533.968-2.027 1.928-2.962 2.18-6.104.244-3.015-.427-5.11-1.536-5.707-2.046-1.048-3.593-.686-4.925-.587a8.905 8.905 0 0 0-3.42.924c-1.252.542-1.588 2.154-1.619 3.798-.062 3.542.639 5.215 1.285 7.664.765 2.896 3.611 4.24 5.367 3.545z'
          fill='#f7f8f8'
        />
        <path
          d='M82.178 53.271c1.801-.721 1.444-.899 2.646-3.511.961-2.012 1.92-2.944 2.188-6.066.257-2.992-.394-5.137-1.475-5.647-2.072-.991-3.57-.604-4.891-.514-1.321.09-2.399.365-3.45.816-1.291.51-1.623 2.131-1.653 3.782-.06 3.514.661 5.194 1.292 7.626.746 2.878 3.601 4.205 5.343 3.514z'
          fill='#fff'
        />
        <path d='M80.246 39.499c1.034 0 2.343.689 2.964 1.62.655.93 1.138 2.24 1.138 3.722 0 2.206-.242 4.653-1.551 5.412-.414.241-1.31.448-1.827.448-1.172 0-1.275-.758-2.378-1.896-.379-.414-1.517-2.412-1.517-4.067 0-1.034-.242-2.516.655-3.826.619-.966 1.412-1.413 2.516-1.413z' />
        <path d='M79.969 41.348c.404-.625 2.021-.33 2.609.956.588 1.287.478 4.08.074 4.227-1.066.331-.736-1.213-1.654-2.61-.918-1.323-1.433-1.948-1.029-2.573z' />
        <path
          d='M79.995 41.375c.399-.617 1.996-.327 2.576.943s.471 4.028.073 4.173c-1.053.326-.726-1.198-1.633-2.577-.907-1.306-1.415-1.923-1.016-2.539z'
          fill='#021010'
        />
        <path
          d='M80.021 41.4c.394-.609 1.97-.322 2.543.932.574 1.253.466 3.977.072 4.12-1.039.322-.716-1.183-1.612-2.543-.896-1.29-1.397-1.899-1.003-2.509z'
          fill='#131919'
        />
        <path
          d='M80.047 41.427c.389-.601 1.944-.317 2.51.919.566 1.237.459 3.924.071 4.066-1.024.318-.707-1.167-1.591-2.51-.884-1.273-1.379-1.874-.99-2.475z'
          fill='#1e2323'
        />
        <path
          d='M80.073 41.453c.384-.593 1.919-.313 2.477.907.558 1.221.453 3.873.07 4.013-1.012.313-.698-1.152-1.57-2.478-.873-1.255-1.361-1.848-.977-2.442z'
          fill='#282d2d'
        />
        <path
          d='M80.099 41.479c.378-.585 1.893-.309 2.443.896.551 1.205.447 3.821.069 3.958-.998.31-.689-1.136-1.549-2.444-.86-1.239-1.342-1.824-.963-2.41z'
          fill='#2f3534'
        />
        <path
          d='M80.125 41.506c.374-.577 1.868-.305 2.411.882.544 1.189.441 3.77.068 3.905-.984.306-.679-1.121-1.527-2.411-.85-1.221-1.327-1.799-.952-2.376z'
          fill='#383d3d'
        />
        <path
          d='M80.15 41.533c.369-.569 1.842-.301 2.378.871s.435 3.718.067 3.852c-.971.301-.67-1.105-1.507-2.378-.838-1.207-1.306-1.776-.938-2.345z'
          fill='#414747'
        />
        <path
          d='M80.176 41.559c.363-.562 1.816-.297 2.345.858.528 1.156.429 3.666.066 3.798-.958.297-.661-1.09-1.486-2.344-.826-1.189-1.288-1.75-.925-2.312z'
          fill='#4a4e4e'
        />
        <path
          d='M80.202 41.585c.358-.554 1.791-.293 2.312.846s.423 3.614.065 3.745c-.944.293-.651-1.075-1.466-2.312-.813-1.172-1.269-1.725-.911-2.279z'
          fill='#545859'
        />
        <path
          d='M80.228 41.611c.353-.545 1.765-.289 2.278.834.513 1.124.417 3.562.064 3.691-.931.289-.642-1.059-1.444-2.278-.802-1.155-1.251-1.701-.898-2.247z'
          fill='#5c6060'
        />
        <path
          d='M80.253 41.638c.348-.538 1.74-.285 2.246.822.506 1.106.411 3.511.063 3.637-.917.285-.634-1.044-1.424-2.246-.789-1.137-1.232-1.675-.885-2.213z'
          fill='#646768'
        />
        <path
          d='M80.28 41.664c.342-.529 1.714-.28 2.212.811.499 1.09.405 3.459.063 3.584-.904.28-.624-1.029-1.402-2.213-.78-1.122-1.216-1.652-.873-2.182z'
          fill='#6a6d6e'
        />
        <path
          d='M80.306 41.69c.337-.521 1.688-.276 2.179.798.492 1.075.399 3.408.062 3.531-.89.276-.614-1.014-1.382-2.18-.768-1.104-1.197-1.626-.859-2.149z'
          fill='#727575'
        />
        <path
          d='M80.332 41.717c.332-.514 1.662-.272 2.146.786.484 1.059.393 3.356.061 3.477-.876.272-.604-.998-1.36-2.147-.757-1.088-1.18-1.602-.847-2.116z'
          fill='#7b7c7d'
        />
        <path
          d='M80.357 41.743c.328-.505 1.637-.268 2.113.774.477 1.042.387 3.304.06 3.423-.863.268-.595-.982-1.34-2.113-.744-1.071-1.16-1.577-.833-2.084z'
          fill='#848586'
        />
        <path
          d='M80.383 41.77c.322-.499 1.612-.264 2.08.762.469 1.025.381 3.252.059 3.369-.85.264-.586-.967-1.318-2.08-.733-1.055-1.143-1.553-.821-2.051z'
          fill='#8c8d8d'
        />
        <path
          d='M80.409 41.796c.317-.49 1.586-.259 2.047.75.462 1.009.375 3.201.058 3.315-.836.261-.577-.951-1.297-2.047-.721-1.037-1.125-1.528-.808-2.018z'
          fill='#939394'
        />
        <path
          d='M80.435 41.823c.312-.482 1.561-.255 2.014.737.454.993.369 3.149.057 3.263-.822.256-.568-.936-1.277-2.014-.709-1.022-1.106-1.504-.794-1.986z'
          fill='#9c9d9e'
        />
        <path
          d='M80.46 41.849c.307-.474 1.535-.251 1.981.726.446.977.362 3.097.056 3.209-.809.251-.559-.921-1.256-1.981-.697-1.005-1.087-1.48-.781-1.954z'
          fill='#a4a4a5'
        />
        <path
          d='M80.486 41.875c.302-.466 1.509-.247 1.948.714.439.96.357 3.045.055 3.155-.795.247-.548-.906-1.234-1.948-.686-.987-1.07-1.454-.769-1.921z'
          fill='#adadae'
        />
        <path
          d='M103.545 53.975c5.17-.551 6.998-2.551 7.928-6.032.828-3.103.861-6.549-1.447-10.582-2.172-3.86-3.412-4.48-6.549-4.722-4.826-.414-7.101 2.964-8.169 5.377-1.138 2.619-.896 2.24-.827 5.549.069 3.481 2.032 4.452 3.238 6.623 1.207 2.138 5.24 3.822 5.826 3.787z'
          fill='#767778'
        />
        <path
          d='M103.549 53.956c5.148-.542 6.996-2.563 7.904-6.017.814-3.124.855-6.552-1.436-10.49-2.123-3.732-3.371-4.347-6.469-4.586-4.811-.412-7.153 2.811-8.226 5.206-1.103 2.527-.899 2.258-.826 5.524.076 3.479 2.042 4.415 3.249 6.587 1.206 2.141 5.218 3.81 5.804 3.776z'
          fill='#7d7f80'
        />
        <path
          d='M103.551 53.936c5.131-.531 6.998-2.575 7.883-6.003.803-3.145.848-6.554-1.426-10.397-2.074-3.605-3.326-4.213-6.389-4.45-4.795-.411-7.204 2.658-8.28 5.034-1.068 2.436-.903 2.277-.825 5.499.084 3.478 2.053 4.379 3.257 6.552 1.208 2.145 5.194 3.8 5.78 3.765z'
          fill='#858686'
        />
        <path
          d='M103.553 53.916c5.109-.522 7-2.588 7.861-5.988.791-3.167.84-6.558-1.416-10.306-2.023-3.477-3.281-4.08-6.307-4.315-4.781-.409-7.256 2.505-8.337 4.862-1.032 2.343-.905 2.295-.823 5.474.092 3.475 2.063 4.342 3.267 6.516 1.206 2.152 5.169 3.793 5.755 3.757z'
          fill='#8e8f8f'
        />
        <path
          d='M103.555 53.896c5.092-.512 7.002-2.6 7.84-5.972.777-3.189.834-6.56-1.404-10.215-1.977-3.349-3.24-3.946-6.227-4.179-4.766-.407-7.309 2.353-8.394 4.691-.997 2.252-.908 2.314-.821 5.449.1 3.473 2.074 4.305 3.275 6.479 1.207 2.157 5.145 3.783 5.731 3.747z'
          fill='#989797'
        />
        <path
          d='M103.557 53.876c5.072-.502 7.002-2.612 7.82-5.958.764-3.21.824-6.562-1.396-10.122-1.926-3.222-3.195-3.812-6.146-4.044-4.75-.406-7.36 2.2-8.448 4.521-.962 2.159-.911 2.332-.82 5.422.108 3.471 2.083 4.269 3.283 6.444 1.207 2.162 5.121 3.774 5.707 3.737z'
          fill='#9e9fa0'
        />
        <path
          d='M103.561 53.856c5.051-.492 7.002-2.625 7.795-5.943.752-3.231.82-6.565-1.383-10.031-1.877-3.093-3.154-3.678-6.066-3.908-4.736-.404-7.413 2.047-8.504 4.349-.927 2.066-.914 2.351-.818 5.397.116 3.47 2.095 4.232 3.293 6.409 1.204 2.167 5.097 3.764 5.683 3.727z'
          fill='#a6a6a7'
        />
        <path
          d='M103.562 53.836c5.031-.482 7.004-2.636 7.775-5.927.738-3.254.811-6.569-1.375-9.939-1.828-2.966-3.109-3.544-5.984-3.772-4.721-.403-7.465 1.894-8.561 4.177-.893 1.976-.917 2.369-.817 5.373.123 3.467 2.104 4.196 3.301 6.373 1.208 2.17 5.076 3.753 5.661 3.715z'
          fill='#b1b1b1'
        />
        <path
          d='M103.564 53.816c5.012-.472 7.004-2.649 7.754-5.913.725-3.275.805-6.571-1.363-9.847-1.779-2.838-3.068-3.411-5.906-3.636-4.705-.402-7.516 1.741-8.615 4.005-.858 1.883-.92 2.387-.815 5.347.131 3.465 2.116 4.16 3.312 6.337 1.204 2.177 5.049 3.745 5.633 3.707z'
          fill='#b8b8b9'
        />
        <path
          d='M103.566 53.796c4.992-.462 7.006-2.662 7.732-5.898.713-3.297.797-6.574-1.354-9.756-1.73-2.71-3.023-3.276-5.824-3.5-4.691-.4-7.568 1.588-8.672 3.834-.822 1.791-.923 2.405-.814 5.322.139 3.463 2.126 4.123 3.32 6.301 1.208 2.182 5.028 3.735 5.612 3.697z'
          fill='#bebdbe'
        />
        <path
          d='M103.57 53.777c4.973-.453 7.006-2.674 7.709-5.883.701-3.318.791-6.577-1.342-9.664-1.682-2.582-2.98-3.143-5.746-3.365-4.676-.398-7.619 1.435-8.727 3.663-.787 1.699-.926 2.424-.812 5.297.146 3.461 2.136 4.086 3.33 6.265 1.206 2.186 5.002 3.725 5.588 3.687z'
          fill='#c5c5c6'
        />
        <path
          d='M103.572 53.757c4.953-.442 7.008-2.687 7.688-5.868.688-3.34.783-6.58-1.332-9.572-1.633-2.454-2.936-3.009-5.664-3.229-4.66-.396-7.671 1.283-8.784 3.492-.751 1.606-.928 2.443-.81 5.271.154 3.46 2.146 4.05 3.338 6.23 1.207 2.19 4.978 3.715 5.564 3.676z'
          fill='#cccbcb'
        />
        <path
          d='M103.574 53.737c4.934-.433 7.01-2.699 7.666-5.854.674-3.362.775-6.582-1.322-9.48-1.582-2.327-2.893-2.875-5.582-3.094-4.646-.395-7.724 1.13-8.84 3.32-.717 1.514-.932 2.461-.809 5.246.162 3.458 2.156 4.014 3.346 6.194 1.207 2.198 4.957 3.708 5.541 3.668z'
          fill='#d2d1d1'
        />
        <path
          d='M103.578 53.717c4.912-.422 7.008-2.711 7.643-5.838.662-3.383.77-6.586-1.311-9.389-1.535-2.199-2.852-2.742-5.504-2.958-4.631-.394-7.775.977-8.894 3.148-.683 1.422-.936 2.479-.808 5.221.169 3.456 2.167 3.978 3.356 6.158 1.206 2.202 4.932 3.699 5.518 3.658z'
          fill='#d7d7d7'
        />
        <path
          d='M103.58 53.697c4.893-.413 7.01-2.724 7.621-5.824.65-3.405.762-6.588-1.299-9.296-1.486-2.071-2.809-2.608-5.424-2.822-4.615-.393-7.828.824-8.951 2.978-.647 1.33-.938 2.497-.806 5.194.177 3.455 2.178 3.941 3.364 6.123 1.208 2.206 4.909 3.688 5.495 3.647z'
          fill='#dcdcdd'
        />
        <path
          d='M103.582 53.677c4.873-.402 7.012-2.735 7.6-5.808.637-3.427.756-6.591-1.289-9.206-1.436-1.943-2.764-2.474-5.342-2.686-4.602-.391-7.88.671-9.007 2.806-.612 1.238-.94 2.516-.805 5.17.185 3.452 2.188 3.904 3.375 6.087 1.204 2.211 4.882 3.678 5.468 3.637z'
          fill='#e4e3e3'
        />
        <path
          d='M103.584 53.657c4.854-.392 7.012-2.747 7.58-5.794.623-3.448.746-6.593-1.281-9.112-1.387-1.815-2.721-2.341-5.262-2.551-4.586-.39-7.931.518-9.062 2.634-.577 1.146-.944 2.534-.803 5.145.193 3.45 2.199 3.868 3.383 6.051 1.207 2.216 4.861 3.669 5.445 3.627z'
          fill='#e9e9e9'
        />
        <path
          d='M103.588 53.637c4.832-.382 7.012-2.76 7.557-5.778.609-3.47.74-6.596-1.27-9.021-1.34-1.688-2.678-2.207-5.182-2.415-4.57-.388-7.983.365-9.119 2.463-.542 1.054-.946 2.553-.801 5.12.201 3.447 2.209 3.831 3.391 6.016 1.207 2.22 4.838 3.658 5.424 3.615z'
          fill='#eeeded'
        />
        <path
          d='M103.59 53.618c4.812-.373 7.014-2.773 7.535-5.764.598-3.492.732-6.6-1.26-8.93-1.289-1.56-2.633-2.073-5.1-2.279-4.557-.386-8.036.212-9.175 2.292-.507.961-.949 2.571-.8 5.094.208 3.446 2.219 3.795 3.401 5.98 1.207 2.226 4.815 3.649 5.399 3.607z'
          fill='#f2f3f4'
        />
        <path
          d='M103.592 53.598c4.795-.362 7.016-2.785 7.514-5.749.586-3.513.725-6.602-1.248-8.837-1.24-1.433-2.592-1.939-5.021-2.144-4.541-.385-8.086.06-9.229 2.12-.472.87-.953 2.59-.798 5.069.215 3.444 2.229 3.758 3.409 5.944 1.205 2.23 4.789 3.64 5.373 3.597z'
          fill='#f7f8f8'
        />
        <path
          d='M103.594 53.578c4.775-.353 7.016-2.797 7.492-5.734.572-3.535.719-6.604-1.238-8.746-1.191-1.304-2.547-1.805-4.939-2.008-4.525-.383-8.139-.094-9.286 1.948-.437.778-.956 2.608-.797 5.044.224 3.442 2.24 3.722 3.419 5.909 1.206 2.236 4.765 3.631 5.349 3.587z'
          fill='#fff'
        />
        <path d='M103.131 39.36c2.586 0 4.104 2.31 4.619 5.274.207 1.344-.104 2.895-.93 3.964-.932 1.241-2.586 1.999-3.93 1.999-1.275 0-2.723.207-3.482-.828-.758-1.068-.93-3.446-.93-5.135 0-1.896.553-3.24 1.518-4.309.723-.792 2.033-.965 3.135-.965z' />
        <path d='M103.297 40.425c.469-.313 1.25 0 2.148 1.016.977 1.094 1.406 1.953.234 2.578-.898.469-1.172-.938-1.719-1.523-.858-.938-1.601-1.446-.663-2.071z' />
        <path
          d='M103.316 40.453c.459-.311 1.23 0 2.117 1 .961 1.078 1.371 1.924.23 2.54-.881.468-1.15-.923-1.688-1.5-.846-.923-1.577-1.421-.659-2.04z'
          fill='#021010'
        />
        <path
          d='M103.336 40.48c.449-.309 1.213 0 2.084.986.947 1.062 1.338 1.895.227 2.501-.859.466-1.127-.91-1.656-1.479-.835-.905-1.555-1.392-.655-2.008z'
          fill='#131919'
        />
        <path
          d='M103.354 40.509c.441-.307 1.195 0 2.055.97.932 1.045 1.301 1.866.223 2.463-.842.465-1.107-.896-1.625-1.455-.823-.891-1.532-1.368-.653-1.978z'
          fill='#1e2323'
        />
        <path
          d='M103.373 40.537c.43-.305 1.176 0 2.021.956.918 1.029 1.268 1.837.221 2.425-.824.463-1.086-.882-1.596-1.433-.808-.877-1.505-1.344-.646-1.948z'
          fill='#282d2d'
        />
        <path
          d='M103.393 40.564c.42-.303 1.156 0 1.988.941.904 1.013 1.234 1.808.217 2.386-.803.462-1.064-.867-1.562-1.41-.796-.86-1.483-1.317-.643-1.917z'
          fill='#2f3534'
        />
        <path
          d='M103.412 40.593c.41-.301 1.139 0 1.957.926.889.997 1.199 1.779.213 2.348-.785.461-1.043-.854-1.531-1.387-.783-.847-1.461-1.293-.639-1.887z'
          fill='#383d3d'
        />
        <path
          d='M103.43 40.621c.402-.3 1.121 0 1.928.91.873.98 1.162 1.75.209 2.31-.768.458-1.021-.84-1.502-1.365-.77-.83-1.436-1.266-.635-1.855z'
          fill='#414747'
        />
        <path
          d='M103.449 40.649c.393-.297 1.102 0 1.895.895.859.964 1.129 1.722.205 2.272-.746.457-.998-.827-1.469-1.342-.758-.816-1.412-1.241-.631-1.825z'
          fill='#4a4e4e'
        />
        <path
          d='M103.469 40.677c.381-.295 1.084 0 1.861.88.846.948 1.096 1.693.203 2.234-.729.456-.979-.812-1.439-1.319-.744-.801-1.387-1.216-.625-1.795z'
          fill='#545859'
        />
        <path
          d='M103.488 40.706c.371-.294 1.064 0 1.83.865.83.932 1.061 1.663.199 2.195-.711.455-.957-.798-1.408-1.297-.732-.785-1.365-1.19-.621-1.763z'
          fill='#5c6060'
        />
        <path
          d='M103.508 40.733c.361-.292 1.045 0 1.797.85.816.916 1.025 1.635.197 2.157-.691.453-.936-.784-1.379-1.274-.719-.77-1.34-1.164-.615-1.733z'
          fill='#646768'
        />
        <path
          d='M103.525 40.761c.354-.29 1.027 0 1.768.835.801.899.99 1.605.191 2.119-.672.452-.914-.77-1.346-1.252-.706-.754-1.316-1.139-.613-1.702z'
          fill='#6a6d6e'
        />
        <path
          d='M103.545 40.789c.344-.288 1.008 0 1.734.82.787.883.957 1.576.189 2.08-.654.451-.893-.756-1.316-1.229-.693-.738-1.293-1.112-.607-1.671z'
          fill='#727575'
        />
        <path
          d='M103.564 40.817c.332-.286.99 0 1.703.805.771.867.92 1.547.184 2.042-.635.449-.869-.742-1.283-1.206-.682-.724-1.27-1.088-.604-1.641z'
          fill='#7b7c7d'
        />
        <path
          d='M103.584 40.846c.322-.284.971 0 1.67.79.76.851.887 1.519.182 2.004-.615.447-.85-.729-1.254-1.185-.668-.708-1.244-1.061-.598-1.609z'
          fill='#848586'
        />
        <path
          d='M103.602 40.874c.314-.282.955 0 1.639.774.744.834.854 1.49.18 1.966-.598.445-.828-.715-1.223-1.162-.657-.692-1.223-1.035-.596-1.578z'
          fill='#8c8d8d'
        />
        <path
          d='M103.621 40.901c.305-.28.936 0 1.607.76.73.818.818 1.46.174 1.928-.578.444-.805-.701-1.191-1.139-.643-.678-1.197-1.012-.59-1.549z'
          fill='#939394'
        />
        <path
          d='M103.641 40.93c.295-.278.916 0 1.574.745.715.801.783 1.431.172 1.889-.559.443-.785-.687-1.16-1.116-.631-.663-1.174-.986-.586-1.518z'
          fill='#9c9d9e'
        />
        <path
          d='M103.66 40.958c.283-.277.898 0 1.543.729.701.785.748 1.402.168 1.851-.541.441-.764-.673-1.131-1.094-.617-.646-1.148-.959-.58-1.486z'
          fill='#a4a4a5'
        />
        <path
          d='M103.68 40.986c.273-.275.879 0 1.51.714.688.769.715 1.374.166 1.813-.523.44-.742-.659-1.1-1.071-.606-.631-1.127-.934-.576-1.456z'
          fill='#adadae'
        />
        <path d='M90.327 41.969c.811-.313 1.442-.331 2.696.496 1.515 1 .816 2.511-.374 2.203-1.027-.267-1.157-.326-2.102-.52-1.484-.311-1.743-1.591-.22-2.179z' />
        <path
          d='M90.358 42.03c.794-.302 1.423-.314 2.659.49 1.468.962.792 2.399-.385 2.098-1.021-.262-1.123-.314-2.051-.504-1.462-.303-1.717-1.518-.223-2.084z'
          fill='#000807'
        />
        <path
          d='M90.39 42.09c.776-.288 1.405-.297 2.624.487 1.419.923.767 2.287-.398 1.99-1.014-.258-1.088-.301-2-.486-1.44-.297-1.692-1.448-.226-1.991z'
          fill='#061212'
        />
        <path
          d='M90.421 42.151c.76-.277 1.386-.281 2.588.481 1.372.886.743 2.176-.409 1.886-1.008-.254-1.054-.29-1.95-.471-1.418-.289-1.665-1.376-.229-1.896z'
          fill='#131919'
        />
        <path
          d='M90.453 42.212c.744-.265 1.367-.265 2.552.477 1.324.848.718 2.064-.421 1.779-1.002-.25-1.02-.278-1.899-.454-1.397-.282-1.64-1.304-.232-1.802z'
          fill='#1b1f1f'
        />
        <path
          d='M90.484 42.272c.727-.253 1.349-.248 2.517.471 1.276.811.693 1.953-.433 1.674-.995-.246-.986-.266-1.849-.438-1.374-.274-1.614-1.231-.235-1.707z'
          fill='#222727'
        />
        <path
          d='M90.516 42.333c.709-.241 1.33-.232 2.481.466 1.227.772.668 1.841-.446 1.568-.989-.242-.951-.253-1.798-.421-1.352-.267-1.588-1.16-.237-1.613z'
          fill='#282d2d'
        />
        <path
          d='M90.547 42.394c.693-.229 1.312-.216 2.446.461 1.179.735.644 1.729-.458 1.462-.983-.238-.917-.241-1.748-.405-1.329-.26-1.561-1.088-.24-1.518z'
          fill='#2e3333'
        />
        <path
          d='M90.579 42.455c.677-.217 1.294-.199 2.41.457 1.132.697.619 1.618-.469 1.356-.977-.233-.883-.229-1.697-.389-1.309-.253-1.537-1.017-.244-1.424z'
          fill='#323838'
        />
        <path
          d='M90.61 42.515c.66-.205 1.275-.183 2.375.452 1.083.659.595 1.506-.482 1.25-.97-.229-.849-.216-1.646-.372-1.286-.245-1.512-.945-.247-1.33z'
          fill='#383d3d'
        />
        <path
          d='M90.642 42.576c.643-.193 1.256-.166 2.338.447 1.035.621.57 1.395-.493 1.146-.964-.225-.815-.205-1.596-.356-1.264-.24-1.485-.875-.249-1.237z'
          fill='#3d4243'
        />
        <path
          d='M90.673 42.637c.626-.181 1.237-.15 2.303.441.987.583.546 1.284-.505 1.04-.958-.221-.781-.193-1.545-.339-1.243-.232-1.46-.803-.253-1.142z'
          fill='#434849'
        />
        <path
          d='M90.705 42.697c.609-.168 1.218-.133 2.267.437.939.546.521 1.171-.518.934-.951-.216-.747-.181-1.494-.323-1.22-.224-1.434-.73-.255-1.048z'
          fill='#4a4e4e'
        />
        <path
          d='M90.736 42.758c.593-.157 1.201-.117 2.232.432.891.508.497 1.06-.529.828-.945-.212-.712-.168-1.444-.307-1.199-.217-1.409-.658-.259-.953z'
          fill='#505555'
        />
        <path
          d='M90.768 42.818c.576-.145 1.182-.101 2.196.427.843.47.472.948-.541.722-.939-.208-.678-.156-1.394-.291-1.176-.208-1.382-.585-.261-.858z'
          fill='#565a5a'
        />
        <path
          d='M90.799 42.879c.559-.132 1.163-.084 2.16.422.795.432.447.836-.553.616-.934-.204-.644-.144-1.343-.273-1.154-.203-1.356-.515-.264-.765z'
          fill='#5c6060'
        />
        <path
          d='M90.831 42.939c.542-.12 1.144-.068 2.125.417.747.395.422.726-.565.511-.926-.2-.61-.132-1.292-.257-1.134-.195-1.332-.443-.268-.671z'
          fill='#616464'
        />
        <path
          d='M90.863 43c.525-.109 1.125-.052 2.089.412.698.356.397.613-.578.404-.92-.195-.576-.12-1.242-.24-1.111-.187-1.305-.371-.269-.576z'
          fill='#676a6a'
        />
        <path
          d='M90.894 43.062c.509-.097 1.107-.036 2.053.406.651.319.374.502-.589.299-.914-.191-.541-.107-1.191-.224-1.089-.181-1.28-.299-.273-.481z'
          fill='#6a6d6e'
        />
        <path
          d='M90.925 43.122c.492-.084 1.089-.019 2.018.402.603.281.348.39-.602.193-.908-.187-.507-.095-1.141-.208-1.065-.173-1.253-.228-.275-.387z'
          fill='#6f7273'
        />
        <path
          d='M90.957 43.183c.475-.073 1.07-.002 1.982.397.555.244.324.279-.613.087-.901-.183-.473-.083-1.09-.191-1.045-.166-1.228-.156-.279-.293zM87.69 69.899c-4.48.172-11.581-12.374-11.753-7.17-.138 4.412.103 4.343.103 8.617 0 2.861-1.31 3.068-4.136 7.376-1.447 2.275-2.585 4.723-3.481 7.204a54.12 54.12 0 0 0-1.482 4.55c-.172.758-.482 1.551-.655 2.309-1.413 5.205-6.445 11.616-7.617 16.821-1.172 5.17-2.551 8.479-2.379 15.406.173 6.929.242 4.929 2.344 6.652 2.068 1.724 4.205 3.344 7.479 6.239 3.447 2.998 10.616 8.237 11.582 9.857 1.033 1.654.999 5.377.379 6.583-.621 1.172-6.032 1.827-5.998 1.827-.034 0 4.722 6.549 5.653 7.479.896.896 4.757 5.204 20.474 2.274 8.857-1.654 15.717-6.617 20.68-11.409 6.412-6.238 3.172-8.03 3.998-11.167 1.207-4.516 5.137-6.204 6.033-11.306.104-.723.344-1.274 1-2.344.998-1.516.758-4.515.758-7.272 0-7.169-.828-14.477-2.482-19.854-1.516-5.032-3.93-8.548-5.996-13.097-4.137-9.065-3.93-13.132-7.619-18.923-4.205-6.687-2.137-11.133-7.721-10.857-6.961.382-12.581 9.929-19.164 10.205z'
          fill='#767778'
        />
        <path
          d='M87.682 70.418c-4.443.169-11.368-12.213-11.605-7.267-.151 4.192.047 4.155.014 8.257-.072 2.81-1.414 3.178-4.177 7.411-1.442 2.28-2.525 4.697-3.348 7.122-.463 1.557-.747 3.074-1.164 4.56-.199.786-.716 1.525-1.046 2.414-1.557 5.16-6.483 11.576-7.641 16.696-1.18 5.176-2.57 8.472-2.375 15.387.176 6.702.189 4.872 2.285 6.614 2.05 1.731 4.231 3.385 7.498 6.272 3.438 2.991 10.645 8.241 11.605 9.855 1.029 1.649 1.02 5.419.404 6.618-.615 1.167-6.022 1.854-5.988 1.854-.034 0 4.699 6.495 5.628 7.424.896.896 4.74 5.175 20.431 2.252 8.867-1.654 15.857-6.584 20.623-11.42 6.121-6.109 2.924-8.03 3.746-11.151 1.203-4.507 5.398-6.203 6.291-11.298.104-.721.361-1.27 1.012-2.334 1.004-1.532.748-4.522.758-7.27.031-7.192-.816-14.474-2.471-19.834-1.518-5.02-3.926-8.53-5.99-13.072-4.129-9.051-3.936-13.125-7.627-18.897-4.111-6.517-2.203-10.812-7.666-10.527-6.881.397-12.625 10.062-19.197 10.334z'
          fill='#7d7f80'
        />
        <path
          d='M87.673 70.938c-4.404.165-11.153-12.052-11.456-7.364-.165 3.971-.008 3.966-.075 7.896-.144 2.759-1.518 3.287-4.219 7.446-1.437 2.286-2.464 4.672-3.213 7.04-.376 1.632-.426 3.115-.845 4.571-.226.812-.951 1.499-1.438 2.517-1.701 5.117-6.521 11.537-7.664 16.574-1.188 5.179-2.59 8.462-2.372 15.364.179 6.477.137 4.814 2.227 6.577 2.032 1.739 4.257 3.425 7.516 6.306 3.431 2.983 10.675 8.245 11.63 9.854 1.024 1.643 1.041 5.459.429 6.652-.608 1.162-6.012 1.882-5.978 1.882-.034 0 4.676 6.44 5.604 7.369.894.893 4.724 5.143 20.387 2.228 8.879-1.653 15.996-6.549 20.564-11.432 5.834-5.979 2.676-8.028 3.494-11.133 1.201-4.5 5.662-6.204 6.553-11.292.102-.718.377-1.265 1.023-2.323 1.006-1.55.736-4.53.756-7.266.064-7.217-.805-14.471-2.459-19.816-1.518-5.006-3.924-8.512-5.984-13.046-4.121-9.037-3.941-13.116-7.635-18.87-4.018-6.347-2.27-10.489-7.609-10.198-6.804.413-12.674 10.196-19.236 10.464z'
          fill='#858686'
        />
        <path
          d='M87.666 71.458c-4.367.162-10.94-11.891-11.309-7.462-.178 3.75-.062 3.777-.163 7.536-.216 2.708-1.622 3.397-4.26 7.481-1.432 2.291-2.404 4.647-3.08 6.958-.288 1.707-.104 3.157-.526 4.582-.252.84-1.185 1.474-1.829 2.621-1.845 5.072-6.56 11.497-7.687 16.45-1.197 5.184-2.611 8.454-2.369 15.344.182 6.25.084 4.758 2.168 6.539 2.014 1.746 4.283 3.466 7.535 6.339 3.422 2.976 10.703 8.249 11.653 9.853 1.021 1.638 1.062 5.501.454 6.688-.603 1.156-6.002 1.909-5.968 1.909-.034 0 4.653 6.386 5.58 7.313.892.893 4.707 5.113 20.343 2.205 8.889-1.652 16.137-6.516 20.506-11.442 5.545-5.852 2.43-8.028 3.244-11.117 1.195-4.491 5.924-6.204 6.811-11.284.104-.716.395-1.26 1.035-2.314 1.012-1.564.727-4.536.756-7.262.096-7.241-.793-14.468-2.445-19.798-1.521-4.992-3.924-8.492-5.979-13.019-4.113-9.023-3.949-13.108-7.646-18.846-3.922-6.176-2.334-10.167-7.553-9.867-6.722.427-12.721 10.327-19.271 10.593z'
          fill='#8e8f8f'
        />
        <path
          d='M87.657 71.977c-4.329.158-10.727-11.729-11.16-7.559-.19 3.529-.118 3.588-.252 7.175-.288 2.657-1.725 3.507-4.301 7.517-1.427 2.296-2.343 4.623-2.946 6.876-.2 1.783.218 3.198-.207 4.592-.279.869-1.419 1.449-2.22 2.726-1.989 5.029-6.598 11.458-7.71 16.326-1.206 5.189-2.631 8.446-2.365 15.323.185 6.025.032 4.701 2.109 6.502 1.997 1.754 4.31 3.506 7.553 6.372 3.415 2.969 10.732 8.253 11.679 9.851 1.015 1.632 1.081 5.542.477 6.722-.597 1.152-5.992 1.938-5.958 1.938-.035 0 4.629 6.332 5.554 7.258.892.892 4.691 5.082 20.302 2.182 8.896-1.652 16.273-6.48 20.447-11.454 5.256-5.722 2.182-8.026 2.99-11.099 1.193-4.484 6.188-6.204 7.072-11.277.102-.714.41-1.255 1.047-2.305 1.014-1.582.713-4.544.754-7.26.129-7.264-.781-14.464-2.434-19.778-1.523-4.978-3.922-8.474-5.973-12.992-4.105-9.009-3.953-13.101-7.656-18.819-3.826-6.006-2.4-9.845-7.496-9.538-6.641.442-12.766 10.46-19.306 10.721z'
          fill='#989797'
        />
        <path
          d='M87.649 72.496c-4.291.155-10.514-11.568-11.012-7.656-.204 3.309-.173 3.399-.341 6.814-.361 2.606-1.829 3.616-4.343 7.552a23.083 23.083 0 0 0-2.812 6.793c-.112 1.858.54 3.239.112 4.603-.305.896-1.653 1.423-2.612 2.83-2.133 4.984-6.636 11.42-7.733 16.202-1.214 5.194-2.65 8.438-2.362 15.303.188 5.799-.021 4.644 2.051 6.464 1.979 1.762 4.336 3.547 7.571 6.405 3.406 2.961 10.761 8.257 11.703 9.849 1.01 1.627 1.102 5.584.502 6.757-.591 1.147-5.982 1.966-5.948 1.966-.034 0 4.606 6.277 5.53 7.201.89.891 4.674 5.052 20.258 2.159 8.906-1.651 16.416-6.447 20.389-11.465 4.967-5.593 1.934-8.026 2.738-11.083 1.191-4.477 6.451-6.204 7.332-11.27.104-.711.426-1.25 1.061-2.295 1.018-1.598.703-4.551.752-7.256.16-7.288-.771-14.462-2.422-19.76-1.523-4.965-3.92-8.457-5.965-12.967-4.1-8.995-3.961-13.093-7.666-18.793-3.732-5.836-2.467-9.523-7.441-9.208-6.561.463-12.812 10.598-19.342 10.855z'
          fill='#9e9fa0'
        />
        <path
          d='M87.64 73.016c-4.253.151-10.299-11.407-10.863-7.754-.217 3.089-.229 3.211-.43 6.455-.433 2.556-1.933 3.726-4.384 7.587a21.094 21.094 0 0 0-2.679 6.712c-.023 1.933.862 3.28.431 4.613-.332.923-1.887 1.397-3.002 2.935-2.277 4.94-6.674 11.379-7.756 16.079-1.223 5.197-2.67 8.429-2.359 15.28.191 5.573-.072 4.587 1.993 6.427 1.96 1.77 4.362 3.588 7.589 6.438 3.398 2.954 10.79 8.261 11.728 9.848 1.005 1.62 1.122 5.624.527 6.791-.585 1.142-5.973 1.993-5.938 1.993-.035 0 4.583 6.223 5.505 7.146.889.889 4.658 5.021 20.215 2.135 8.918-1.65 16.555-6.412 20.33-11.477 4.68-5.463 1.688-8.024 2.488-11.065 1.186-4.468 6.713-6.203 7.59-11.263.104-.708.443-1.244 1.072-2.284 1.021-1.615.691-4.56.752-7.253.191-7.312-.76-14.459-2.41-19.741-1.525-4.951-3.918-8.438-5.959-12.94-4.092-8.98-3.967-13.085-7.676-18.768-3.637-5.666-2.531-9.201-7.385-8.877-6.482.476-12.859 10.73-19.379 10.983z'
          fill='#a6a6a7'
        />
        <path
          d='M87.631 73.535c-4.215.147-10.085-11.247-10.714-7.852-.23 2.868-.284 3.022-.52 6.094-.504 2.505-2.036 3.836-4.425 7.623a19.265 19.265 0 0 0-2.544 6.63c.064 2.007 1.183 3.32.75 4.623-.358.95-2.121 1.372-3.395 3.038-2.42 4.897-6.712 11.341-7.779 15.955-1.231 5.203-2.69 8.422-2.355 15.26.194 5.349-.125 4.53 1.934 6.39 1.943 1.776 4.388 3.628 7.607 6.472 3.39 2.946 10.819 8.265 11.752 9.846 1 1.615 1.143 5.666.552 6.825-.58 1.138-5.962 2.021-5.928 2.021-.035 0 4.559 6.17 5.48 7.092.888.888 4.641 4.99 20.173 2.112 8.926-1.65 16.693-6.379 20.271-11.487 4.389-5.335 1.438-8.024 2.234-11.05 1.184-4.46 6.977-6.203 7.852-11.256.102-.705.459-1.239 1.084-2.273 1.025-1.632.68-4.566.75-7.25.225-7.336-.748-14.456-2.398-19.723-1.525-4.938-3.916-8.419-5.951-12.914-4.086-8.966-3.975-13.077-7.686-18.742-3.543-5.497-2.598-8.879-7.33-8.548-6.402.493-12.904 10.864-19.414 11.114z'
          fill='#b1b1b1'
        />
        <path
          d='M87.624 74.054c-4.177.144-9.872-11.085-10.565-7.949-.244 2.647-.34 2.833-.609 5.733-.576 2.454-2.14 3.945-4.466 7.658-1.406 2.317-2.101 4.523-2.411 6.548.152 2.083 1.505 3.362 1.069 4.634-.385.978-2.354 1.346-3.786 3.143-2.564 4.853-6.75 11.301-7.802 15.832-1.24 5.207-2.71 8.412-2.353 15.238.197 5.122-.177 4.473 1.876 6.351 1.925 1.785 4.414 3.669 7.625 6.506 3.382 2.939 10.849 8.269 11.776 9.844.996 1.61 1.164 5.707.576 6.86-.574 1.133-5.953 2.048-5.918 2.048-.034 0 4.536 6.115 5.457 7.036.886.887 4.624 4.96 20.129 2.09 8.936-1.65 16.832-6.345 20.213-11.5 4.102-5.204 1.191-8.022 1.982-11.031 1.18-4.453 7.24-6.204 8.111-11.249.104-.702.477-1.234 1.096-2.265 1.029-1.647.67-4.572.752-7.246.254-7.359-.738-14.453-2.389-19.704-1.527-4.924-3.912-8.4-5.945-12.887-4.078-8.953-3.98-13.069-7.695-18.716-3.447-5.326-2.662-8.557-7.273-8.217-6.322.508-12.952 10.998-19.45 11.243z'
          fill='#b8b8b9'
        />
        <path
          d='M87.615 74.574c-4.139.14-9.658-10.924-10.417-8.046-.257 2.426-.396 2.646-.698 5.373-.648 2.402-2.244 4.056-4.508 7.692-1.399 2.323-2.04 4.498-2.276 6.466.24 2.158 1.826 3.403 1.387 4.645-.411 1.006-2.589 1.32-4.177 3.246-2.708 4.809-6.788 11.262-7.825 15.709-1.248 5.212-2.73 8.404-2.349 15.217.2 4.896-.229 4.416 1.816 6.313 1.907 1.793 4.44 3.71 7.645 6.539 3.374 2.932 10.877 8.272 11.801 9.843.991 1.604 1.184 5.748.601 6.895-.567 1.127-5.942 2.076-5.908 2.076-.034 0 4.513 6.061 5.432 6.979.885.885 4.607 4.93 20.086 2.065 8.945-1.648 16.973-6.31 20.154-11.51 3.814-5.075.945-8.022 1.732-11.015 1.176-4.444 7.502-6.203 8.371-11.241.102-.7.49-1.229 1.107-2.255 1.033-1.664.658-4.581.75-7.244.285-7.383-.727-14.448-2.377-19.684-1.527-4.91-3.91-8.383-5.938-12.861-4.07-8.938-3.988-13.061-7.705-18.69-3.354-5.157-2.73-8.235-7.219-7.888-6.243.527-12.997 11.134-19.485 11.376z'
          fill='#bebdbe'
        />
        <path
          d='M87.607 75.093c-4.102.137-9.445-10.763-10.269-8.144-.27 2.206-.451 2.457-.786 5.013-.72 2.352-2.348 4.165-4.548 7.728-1.395 2.328-1.98 4.474-2.144 6.385.328 2.232 2.148 3.444 1.706 4.655-.438 1.033-2.823 1.295-4.568 3.351-2.852 4.765-6.827 11.222-7.848 15.584-1.256 5.218-2.75 8.397-2.346 15.197.204 4.67-.281 4.358 1.759 6.276 1.889 1.8 4.466 3.75 7.662 6.572 3.366 2.924 10.907 8.275 11.826 9.84.986 1.599 1.204 5.79.625 6.929-.562 1.123-5.933 2.104-5.898 2.104-.035 0 4.49 6.007 5.407 6.924.884.885 4.59 4.899 20.042 2.043 8.955-1.648 17.113-6.276 20.098-11.521 3.523-4.947.697-8.022 1.479-10.999 1.174-4.437 7.764-6.203 8.633-11.234.102-.697.506-1.224 1.119-2.244 1.035-1.68.646-4.588.748-7.24.318-7.407-.715-14.446-2.363-19.666-1.531-4.896-3.91-8.364-5.934-12.835-4.062-8.924-3.992-13.053-7.715-18.664-3.258-4.986-2.795-7.914-7.162-7.558-6.165.541-13.047 11.266-19.523 11.504z'
          fill='#c5c5c6'
        />
        <path
          d='M87.599 75.613c-4.063.133-9.231-10.603-10.121-8.242-.283 1.985-.506 2.268-.875 4.652-.793 2.301-2.452 4.275-4.59 7.763-1.39 2.333-1.919 4.448-2.009 6.303.415 2.308 2.469 3.485 2.024 4.666-.465 1.061-3.057 1.27-4.96 3.455-2.996 4.721-6.864 11.183-7.871 15.46-1.265 5.222-2.771 8.389-2.343 15.176.207 4.445-.334 4.302 1.7 6.238 1.871 1.808 4.492 3.791 7.68 6.606 3.358 2.916 10.936 8.279 11.85 9.839.981 1.592 1.225 5.83.65 6.963-.556 1.117-5.922 2.132-5.888 2.132-.034 0 4.466 5.952 5.383 6.869.882.882 4.574 4.868 20 2.02 8.963-1.648 17.25-6.242 20.037-11.533 3.234-4.816.449-8.021 1.227-10.98 1.17-4.43 8.027-6.203 8.893-11.229.102-.693.523-1.219 1.133-2.234 1.039-1.696.635-4.595.746-7.236.35-7.431-.705-14.443-2.352-19.647-1.531-4.883-3.908-8.346-5.926-12.809-4.057-8.91-4-13.045-7.725-18.638-3.164-4.816-2.861-7.592-7.107-7.227-6.081.555-13.091 11.398-19.556 11.633z'
          fill='#cccbcb'
        />
        <path
          d='M87.59 76.132c-4.025.129-9.018-10.441-9.972-8.339-.297 1.764-.562 2.079-.964 4.291-.865 2.251-2.556 4.386-4.631 7.798-1.384 2.338-1.859 4.423-1.875 6.221.504 2.383 2.791 3.526 2.344 4.676-.491 1.087-3.291 1.244-5.351 3.559-3.14 4.676-6.903 11.144-7.895 15.337-1.273 5.227-2.79 8.381-2.339 15.154.209 4.22-.386 4.245 1.642 6.201 1.853 1.816 4.518 3.832 7.699 6.64 3.35 2.909 10.965 8.283 11.874 9.837.977 1.587 1.245 5.872.674 6.998-.55 1.112-5.913 2.159-5.878 2.159-.034 0 4.443 5.898 5.359 6.813.881.881 4.557 4.837 19.957 1.996 8.975-1.647 17.391-6.208 19.979-11.544 2.947-4.688.203-8.02.977-10.963 1.166-4.422 8.289-6.204 9.152-11.222.1-.691.539-1.214 1.143-2.225 1.043-1.713.625-4.603.746-7.233.383-7.455-.693-14.44-2.342-19.629-1.531-4.869-3.904-8.327-5.918-12.782-4.047-8.896-4.006-13.038-7.734-18.612-3.068-4.646-2.926-7.269-7.051-6.897-6.006.575-13.141 11.535-19.596 11.766z'
          fill='#d2d1d1'
        />
        <path
          d='M87.582 76.651c-3.987.126-8.804-10.28-9.823-8.437-.31 1.544-.617 1.891-1.053 3.932-.937 2.199-2.659 4.495-4.673 7.833-1.379 2.343-1.798 4.398-1.742 6.139.592 2.458 3.113 3.568 2.663 4.687-.518 1.116-3.524 1.218-5.742 3.664-3.284 4.632-6.941 11.104-7.918 15.214-1.282 5.229-2.811 8.371-2.336 15.133.213 3.993-.438 4.188 1.583 6.163 1.835 1.823 4.544 3.872 7.717 6.673 3.342 2.901 10.994 8.287 11.898 9.835.973 1.582 1.266 5.913.7 7.032-.544 1.107-5.903 2.188-5.869 2.188-.034 0 4.42 5.844 5.334 6.758.881.881 4.542 4.807 19.914 1.973 8.984-1.646 17.529-6.173 19.92-11.555 2.66-4.559-.043-8.019.725-10.947 1.164-4.413 8.553-6.202 9.412-11.213.102-.689.557-1.209 1.156-2.215 1.047-1.729.613-4.609.744-7.23.414-7.479-.682-14.438-2.328-19.61-1.533-4.854-3.904-8.309-5.912-12.756-4.041-8.882-4.014-13.029-7.744-18.586-2.977-4.476-2.992-6.947-6.996-6.567-5.923.588-13.186 11.665-19.63 11.892z'
          fill='#d7d7d7'
        />
        <path
          d='M87.573 77.171c-3.949.123-8.59-10.119-9.674-8.534-.323 1.323-.673 1.702-1.142 3.57-1.009 2.149-2.763 4.605-4.714 7.869-1.374 2.349-1.738 4.374-1.608 6.057.68 2.533 3.435 3.609 2.982 4.698-.544 1.143-3.759 1.192-6.134 3.767-3.428 4.589-6.979 11.064-7.941 15.09-1.291 5.235-2.831 8.364-2.333 15.112.216 3.769-.491 4.131 1.525 6.126 1.817 1.831 4.57 3.913 7.735 6.706 3.334 2.895 11.022 8.291 11.922 9.834.967 1.575 1.286 5.954.724 7.066-.539 1.104-5.893 2.215-5.859 2.215-.033 0 4.397 5.79 5.31 6.702.879.88 4.524 4.776 19.87 1.95 8.994-1.646 17.67-6.139 19.863-11.566 2.369-4.43-.293-8.018.473-10.93 1.158-4.405 8.814-6.203 9.67-11.207.102-.686.574-1.203 1.17-2.205 1.049-1.745.602-4.616.742-7.227.445-7.502-.67-14.435-2.316-19.592-1.535-4.842-3.9-8.29-5.906-12.729-4.033-8.869-4.018-13.021-7.754-18.561-2.879-4.306-3.057-6.626-6.939-6.238-5.844.608-13.232 11.803-19.666 12.027z'
          fill='#dcdcdd'
        />
        <path
          d='M87.565 77.69c-3.911.119-8.376-9.958-9.526-8.632-.336 1.104-.728 1.514-1.23 3.21-1.082 2.098-2.867 4.715-4.755 7.904-1.368 2.354-1.678 4.349-1.474 5.975.767 2.608 3.755 3.65 3.3 4.708-.571 1.17-3.993 1.167-6.525 3.871-3.572 4.546-7.018 11.025-7.964 14.967-1.298 5.24-2.85 8.355-2.33 15.091.22 3.543-.542 4.074 1.466 6.089 1.799 1.839 4.596 3.953 7.753 6.739 3.326 2.887 11.052 8.295 11.947 9.832.963 1.57 1.306 5.996.749 7.102-.532 1.098-5.883 2.242-5.849 2.242-.034 0 4.374 5.735 5.285 6.646.878.879 4.508 4.746 19.829 1.928 9.002-1.646 17.809-6.105 19.803-11.578 2.08-4.3-.539-8.017.221-10.913 1.156-4.397 9.078-6.203 9.932-11.2.102-.683.588-1.198 1.18-2.194 1.055-1.762.592-4.624.742-7.224.479-7.525-.658-14.432-2.305-19.572-1.535-4.828-3.898-8.272-5.898-12.703-4.027-8.854-4.025-13.014-7.764-18.535-2.787-4.136-3.125-6.304-6.885-5.907-5.765.621-13.278 11.935-19.702 12.154z'
          fill='#e4e3e3'
        />
        <path
          d='M87.557 78.21c-3.874.115-8.163-9.797-9.378-8.729-.35.883-.784 1.325-1.32 2.85-1.153 2.047-2.971 4.825-4.796 7.939-1.363 2.359-1.617 4.324-1.34 5.893.855 2.683 4.078 3.691 3.62 4.719-.598 1.197-4.227 1.141-6.917 3.975-3.716 4.501-7.056 10.985-7.987 14.843-1.308 5.245-2.87 8.348-2.326 15.07.222 3.316-.596 4.017 1.407 6.051 1.781 1.847 4.623 3.994 7.772 6.772 3.318 2.88 11.081 8.299 11.971 9.83.958 1.564 1.327 6.037.773 7.137-.526 1.093-5.873 2.27-5.839 2.27-.034 0 4.351 5.682 5.26 6.592.876.876 4.491 4.715 19.785 1.903 9.014-1.646 17.947-6.071 19.746-11.589 1.791-4.171-.789-8.016-.033-10.896 1.154-4.389 9.342-6.202 10.191-11.191.102-.682.605-1.194 1.193-2.186 1.057-1.779.58-4.632.74-7.221.51-7.549-.646-14.429-2.293-19.554-1.537-4.815-3.896-8.254-5.893-12.677-4.02-8.84-4.031-13.006-7.773-18.509-2.691-3.966-3.189-5.982-6.826-5.578-5.683.638-13.324 12.07-19.737 12.286z'
          fill='#e9e9e9'
        />
        <path
          d='M87.548 78.729c-3.835.111-7.949-9.636-9.229-8.826-.363.661-.839 1.136-1.41 2.489-1.225 1.996-3.074 4.934-4.837 7.974-1.357 2.364-1.557 4.299-1.207 5.811.943 2.758 4.399 3.732 3.938 4.729-.624 1.225-4.461 1.116-7.308 4.08-3.859 4.457-7.094 10.947-8.011 14.719-1.315 5.25-2.89 8.339-2.323 15.049.226 3.091-.648 3.96 1.349 6.014 1.764 1.854 4.649 4.035 7.791 6.806 3.31 2.872 11.109 8.303 11.996 9.828.953 1.56 1.347 6.079.798 7.172-.521 1.087-5.863 2.297-5.829 2.297-.034 0 4.328 5.627 5.236 6.537.875.875 4.476 4.684 19.742 1.879 9.023-1.645 18.088-6.036 19.688-11.6 1.502-4.042-1.035-8.015-.283-10.879 1.148-4.383 9.604-6.203 10.451-11.186.1-.678.621-1.188 1.203-2.175 1.062-1.795.57-4.639.74-7.218.543-7.573-.637-14.425-2.281-19.535-1.537-4.801-3.895-8.235-5.885-12.65-4.012-8.826-4.039-12.998-7.783-18.483-2.598-3.795-3.256-5.66-6.771-5.248-5.606.655-13.373 12.204-19.775 12.416z'
          fill='#eeeded'
        />
        <path
          d='M87.541 79.248c-3.798.108-7.736-9.474-9.082-8.923-.375.441-.894.947-1.498 2.129-1.298 1.946-3.179 5.043-4.879 8.009-1.353 2.37-1.496 4.274-1.073 5.729 1.031 2.833 4.722 3.774 4.257 4.74-.65 1.253-4.694 1.09-7.698 4.184-4.003 4.413-7.132 10.907-8.034 14.596-1.324 5.255-2.91 8.331-2.32 15.028.229 2.865-.7 3.902 1.291 5.976 1.745 1.861 4.674 4.075 7.808 6.839 3.301 2.865 11.139 8.307 12.02 9.826.948 1.554 1.367 6.12.822 7.206-.515 1.083-5.854 2.325-5.819 2.325-.035 0 4.303 5.573 5.211 6.48.875.874 4.458 4.654 19.698 1.857 9.033-1.645 18.229-6.003 19.629-11.611 1.215-3.912-1.281-8.014-.535-10.862 1.146-4.374 9.867-6.202 10.711-11.178.102-.676.637-1.184 1.217-2.166 1.064-1.811.559-4.646.738-7.214.574-7.597-.625-14.423-2.27-19.516-1.539-4.788-3.893-8.217-5.879-12.625-4.004-8.812-4.045-12.99-7.793-18.458-2.502-3.625-3.32-5.338-6.715-4.917-5.524.672-13.417 12.338-19.807 12.546z'
          fill='#f2f3f4'
        />
        <path
          d='M87.532 79.768c-3.76.104-7.522-9.313-8.933-9.021-.389.22-.95.759-1.587 1.768-1.369 1.895-3.282 5.154-4.92 8.044-1.347 2.375-1.436 4.249-.938 5.647 1.119 2.909 5.042 3.814 4.576 4.75-.677 1.28-4.929 1.064-8.091 4.288-4.147 4.368-7.169 10.867-8.056 14.472-1.333 5.259-2.931 8.323-2.316 15.007.231 2.639-.753 3.846 1.231 5.938 1.728 1.87 4.701 4.117 7.827 6.874 3.293 2.856 11.168 8.31 12.044 9.824.944 1.548 1.388 6.161.847 7.24-.509 1.078-5.843 2.353-5.809 2.353-.034 0 4.281 5.519 5.187 6.426.873.872 4.442 4.623 19.656 1.833 9.041-1.644 18.365-5.968 19.568-11.622.928-3.783-1.529-8.014-.787-10.846 1.145-4.366 10.131-6.202 10.973-11.171.1-.673.654-1.179 1.229-2.155 1.068-1.827.547-4.653.736-7.211.605-7.62-.613-14.419-2.258-19.497-1.539-4.774-3.891-8.199-5.873-12.599-3.996-8.797-4.049-12.981-7.801-18.431-2.408-3.455-3.387-5.016-6.66-4.587-5.445.687-13.464 12.471-19.845 12.676z'
          fill='#f7f8f8'
        />
        <path
          d='M87.524 80.287c-3.722.101-7.309-9.152-8.785-9.118-.402 0-1.005.57-1.676 1.407-1.441 1.845-3.386 5.264-4.961 8.08-1.341 2.38-1.375 4.224-.805 5.565 1.207 2.984 5.364 3.856 4.895 4.761-.704 1.307-5.163 1.039-8.482 4.392-4.292 4.324-7.208 10.828-8.08 14.349-1.341 5.264-2.95 8.314-2.313 14.985.235 2.414-.805 3.789 1.173 5.9 1.71 1.878 4.727 4.157 7.845 6.906 3.285 2.851 11.197 8.315 12.069 9.823.938 1.542 1.408 6.202.872 7.275-.503 1.072-5.833 2.38-5.8 2.38-.034 0 4.257 5.465 5.162 6.37.873.872 4.425 4.593 19.613 1.811 9.051-1.643 18.506-5.935 19.512-11.634.637-3.654-1.777-8.012-1.039-10.829 1.139-4.357 10.393-6.201 11.23-11.163.102-.671.67-1.174 1.24-2.146 1.072-1.843.537-4.659.738-7.207a50.157 50.157 0 0 0-2.246-19.478c-1.543-4.762-3.891-8.181-5.867-12.573-3.99-8.783-4.057-12.974-7.812-18.405-2.312-3.285-3.453-4.694-6.604-4.258-5.364.705-13.51 12.606-19.879 12.807z'
          fill='#fff'
        />
        <path
          d='M90.068 46.254c2.516-.31 5.963.31 7.549 1.482 1.482 1.103 2.516 1.689 3.859 2.137 4.516 1.482 10.445 2.171 10.168 6.239-.309 4.86-1.723 7.031-5.756 8.307-3.24.999-9.03 6.618-13.511 6.618-1.999 0-4.791.103-6.411-.483-1.551-.551-3.722-3.171-6.273-5.273-2.551-2.068-4.929-4.274-4.998-7.169-.104-3.068 1.896-4.067 4.722-6.515 1.482-1.31 4.171-3.481 6.032-4.446 1.724-.862 2.827-.69 4.619-.897z'
          fill='#9f6437'
        />
        <path
          d='M90.121 46.299c2.501-.309 5.928.309 7.506 1.474 1.473 1.097 2.502 1.681 3.838 2.125 4.486 1.473 10.4 2.168 10.129 6.21-.307 4.831-1.756 6.98-5.764 8.245-3.219.992-8.985 6.506-13.439 6.53-2.016.016-4.751.112-6.36-.468-1.539-.546-3.704-3.163-6.239-5.251-2.533-2.055-4.912-4.2-4.939-7.068-.074-2.994 1.873-4.027 4.679-6.458 1.47-1.297 4.114-3.496 5.966-4.468 1.706-.863 2.841-.666 4.623-.871z'
          fill='#a36937'
        />
        <path
          d='M90.173 46.344c2.487-.307 5.895.307 7.461 1.465 1.465 1.09 2.486 1.673 3.816 2.113 4.459 1.464 10.359 2.165 10.09 6.182-.303 4.8-1.789 6.929-5.771 8.184-3.197.985-8.939 6.392-13.365 6.44-2.031.031-4.711.121-6.31-.452-1.527-.542-3.687-3.156-6.204-5.228-2.515-2.042-4.894-4.128-4.879-6.968-.044-2.919 1.85-3.987 4.634-6.4 1.459-1.285 4.059-3.512 5.902-4.49 1.688-.864 2.854-.642 4.626-.846z'
          fill='#a77038'
        />
        <path
          d='M90.226 46.388c2.473-.305 5.86.305 7.418 1.457 1.455 1.084 2.473 1.664 3.793 2.101 4.434 1.456 10.318 2.161 10.053 6.153-.301 4.772-1.822 6.878-5.781 8.123-3.174.977-8.893 6.279-13.292 6.352-2.047.046-4.672.129-6.259-.438-1.515-.537-3.667-3.148-6.169-5.206-2.498-2.027-4.876-4.053-4.82-6.867-.015-2.846 1.826-3.947 4.59-6.343 1.447-1.273 4.002-3.526 5.836-4.512 1.671-.864 2.869-.617 4.631-.82z'
          fill='#ae7839'
        />
        <path
          d='M90.278 46.433c2.458-.303 5.826.303 7.375 1.448 1.449 1.078 2.459 1.656 3.773 2.088 4.404 1.448 10.275 2.158 10.012 6.125-.297 4.742-1.854 6.827-5.789 8.061-3.152.97-8.846 6.167-13.217 6.264-2.063.061-4.632.137-6.209-.423-1.503-.533-3.649-3.141-6.135-5.182-2.48-2.015-4.858-3.981-4.761-6.767.015-2.771 1.803-3.906 4.546-6.285 1.436-1.261 3.947-3.542 5.771-4.533 1.653-.866 2.883-.594 4.634-.796z'
          fill='#b27d39'
        />
        <path
          d='M90.331 46.478c2.444-.301 5.792.301 7.332 1.44 1.439 1.071 2.443 1.647 3.75 2.075 4.377 1.439 10.234 2.155 9.975 6.097-.295 4.713-1.889 6.775-5.797 8-3.131.963-8.801 6.053-13.146 6.175-2.079.077-4.592.146-6.158-.407-1.491-.528-3.631-3.133-6.1-5.16-2.462-2.001-4.841-3.907-4.702-6.666.044-2.698 1.78-3.866 4.502-6.228 1.424-1.249 3.89-3.557 5.706-4.555 1.636-.868 2.897-.571 4.638-.771z'
          fill='#b98439'
        />
        <path
          d='M90.383 46.522c2.43-.3 5.757.3 7.287 1.431 1.432 1.065 2.43 1.64 3.729 2.063 4.35 1.431 10.193 2.151 9.936 6.069-.289 4.683-1.92 6.725-5.805 7.938-3.109.956-8.755 5.94-13.072 6.087-2.095.092-4.553.155-6.107-.393-1.479-.523-3.613-3.126-6.066-5.137-2.444-1.988-4.822-3.833-4.642-6.565.074-2.623 1.756-3.826 4.458-6.171 1.413-1.237 3.834-3.571 5.641-4.576 1.617-.868 2.911-.546 4.641-.746z'
          fill='#bc893a'
        />
        <path
          d='M90.436 46.567c2.415-.298 5.723.297 7.244 1.423 1.422 1.058 2.416 1.631 3.705 2.051 4.322 1.422 10.15 2.148 9.898 6.041-.287 4.653-1.953 6.674-5.814 7.876-3.088.949-8.708 5.829-12.998 6-2.111.107-4.513.164-6.057-.378-1.468-.519-3.594-3.118-6.031-5.114-2.427-1.974-4.805-3.76-4.583-6.465.104-2.548 1.734-3.786 4.415-6.113 1.401-1.225 3.779-3.586 5.575-4.598 1.6-.872 2.925-.524 4.646-.723z'
          fill='#c08f3a'
        />
        <path
          d='M90.488 46.611c2.4-.296 5.688.296 7.202 1.414 1.414 1.052 2.4 1.623 3.684 2.039 4.293 1.414 10.109 2.145 9.857 6.012-.283 4.625-1.984 6.623-5.82 7.814-3.066.942-8.665 5.716-12.926 5.911-2.127.122-4.473.172-6.006-.362-1.456-.514-3.576-3.11-5.997-5.092-2.409-1.961-4.787-3.687-4.524-6.364.133-2.475 1.71-3.746 4.371-6.056 1.39-1.213 3.723-3.602 5.51-4.62 1.582-.87 2.939-.498 4.649-.696z'
          fill='#c3943a'
        />
        <path
          d='M90.541 46.656c2.386-.294 5.654.294 7.159 1.406 1.404 1.045 2.385 1.615 3.66 2.026 4.268 1.405 10.066 2.142 9.82 5.984-.281 4.595-2.02 6.571-5.83 7.753-3.045.934-8.618 5.602-12.853 5.822-2.143.138-4.434.181-5.956-.347-1.443-.509-3.558-3.103-5.962-5.069-2.392-1.947-4.77-3.614-4.465-6.263.163-2.4 1.688-3.706 4.327-5.999 1.378-1.201 3.667-3.617 5.445-4.642 1.566-.871 2.955-.475 4.655-.671z'
          fill='#c89c3a'
        />
        <path
          d='M90.593 46.701c2.371-.292 5.619.292 7.114 1.396 1.396 1.04 2.371 1.607 3.639 2.014 4.24 1.397 10.025 2.138 9.781 5.957-.277 4.564-2.051 6.52-5.836 7.691-3.023.927-8.573 5.49-12.781 5.734-2.158.153-4.393.188-5.905-.333-1.431-.504-3.539-3.095-5.927-5.046-2.374-1.934-4.753-3.54-4.406-6.163.192-2.327 1.665-3.665 4.282-5.941 1.367-1.188 3.61-3.631 5.38-4.664 1.549-.872 2.97-.45 4.659-.645z'
          fill='#cba03a'
        />
        <path
          d='M90.645 46.746c2.357-.291 5.586.291 7.072 1.388 1.389 1.033 2.357 1.599 3.615 2.002 4.213 1.388 9.984 2.134 9.742 5.928-.273 4.535-2.082 6.469-5.844 7.63-3.002.92-8.527 5.377-12.707 5.646-2.174.168-4.354.198-5.854-.317-1.42-.5-3.521-3.088-5.894-5.024-2.355-1.921-4.734-3.467-4.346-6.062.222-2.252 1.642-3.625 4.239-5.884 1.354-1.177 3.554-3.647 5.315-4.685 1.531-.875 2.983-.429 4.662-.622z'
          fill='#cea53b'
        />
        <path
          d='M90.698 46.791c2.342-.289 5.551.289 7.026 1.379 1.381 1.027 2.344 1.591 3.594 1.99 4.186 1.379 9.943 2.131 9.705 5.9-.271 4.505-2.117 6.417-5.854 7.568-2.979.912-8.48 5.264-12.633 5.557-2.19.184-4.314.207-5.804-.303-1.407-.495-3.503-3.08-5.858-5.001-2.338-1.907-4.717-3.394-4.287-5.961.251-2.179 1.618-3.585 4.195-5.827 1.343-1.164 3.498-3.662 5.25-4.707 1.512-.875 2.997-.403 4.666-.595z'
          fill='#d2ab3b'
        />
        <path
          d='M90.75 46.835c2.328-.287 5.517.287 6.984 1.372 1.371 1.02 2.328 1.582 3.572 1.977 4.158 1.371 9.9 2.128 9.664 5.871-.266 4.477-2.148 6.367-5.861 7.507-2.957.905-8.434 5.152-12.56 5.469-2.206.198-4.273.215-5.752-.288-1.396-.49-3.484-3.073-5.824-4.978-2.32-1.894-4.699-3.32-4.228-5.86.282-2.104 1.596-3.545 4.151-5.77 1.332-1.152 3.442-3.677 5.185-4.729 1.495-.876 3.011-.38 4.669-.571z'
          fill='#d7b23b'
        />
        <path
          d='M90.803 46.879c2.313-.285 5.483.286 6.941 1.363 1.361 1.014 2.312 1.575 3.549 1.965 4.131 1.363 9.859 2.125 9.627 5.843-.264 4.447-2.182 6.316-5.869 7.445-2.938.898-8.389 5.039-12.488 5.38-2.222.214-4.234.224-5.702-.273-1.384-.486-3.466-3.065-5.79-4.955-2.303-1.88-4.682-3.247-4.169-5.76.311-2.03 1.573-3.504 4.108-5.712 1.319-1.14 3.386-3.692 5.119-4.75 1.478-.877 3.026-.356 4.674-.546z'
          fill='#dab73b'
        />
        <path
          d='M90.855 46.924c2.299-.283 5.449.284 6.897 1.354 1.355 1.008 2.299 1.566 3.527 1.953 4.104 1.354 9.816 2.121 9.588 5.814-.26 4.418-2.215 6.265-5.877 7.384-2.914.891-8.342 4.926-12.414 5.292-2.239.229-4.195.232-5.652-.258-1.372-.481-3.447-3.058-5.755-4.933-2.285-1.867-4.664-3.174-4.109-5.659.34-1.956 1.549-3.464 4.063-5.654 1.309-1.128 3.331-3.708 5.054-4.772 1.461-.878 3.04-.332 4.678-.521z'
          fill='#ddbc39'
        />
        <path
          d='M90.908 46.969c2.284-.281 5.414.282 6.854 1.346 1.346 1.002 2.283 1.558 3.504 1.941 4.076 1.345 9.775 2.118 9.551 5.786-.258 4.388-2.248 6.213-5.885 7.322-2.895.883-8.298 4.813-12.342 5.204-2.254.245-4.155.241-5.601-.243-1.36-.477-3.429-3.05-5.721-4.911-2.267-1.854-4.646-3.1-4.05-5.558.37-1.882 1.526-3.424 4.02-5.598 1.297-1.116 3.274-3.722 4.988-4.793 1.442-.88 3.054-.309 4.682-.496z'
          fill='#e1c239'
        />
        <path
          d='M90.96 47.013c2.27-.279 5.38.28 6.81 1.338 1.338.995 2.271 1.55 3.484 1.928 4.047 1.337 9.732 2.114 9.512 5.759-.256 4.358-2.283 6.162-5.895 7.26-2.871.876-8.251 4.701-12.268 5.115-2.271.26-4.115.249-5.551-.228-1.348-.472-3.411-3.042-5.686-4.888-2.25-1.84-4.628-3.026-3.99-5.457.399-1.809 1.503-3.384 3.975-5.54 1.285-1.104 3.218-3.738 4.923-4.816 1.426-.88 3.069-.284 4.686-.471z'
          fill='#e2c539'
        />
        <path
          d='M91.013 47.058c2.255-.277 5.345.279 6.767 1.329 1.328.989 2.256 1.542 3.461 1.916 4.02 1.329 9.691 2.111 9.473 5.729-.25 4.33-2.314 6.111-5.902 7.2-2.85.869-8.205 4.588-12.194 5.027-2.286.275-4.076.258-5.5-.213-1.336-.467-3.392-3.035-5.651-4.864-2.232-1.827-4.611-2.953-3.932-5.356.429-1.735 1.48-3.344 3.932-5.483 1.273-1.092 3.162-3.752 4.858-4.837 1.406-.883 3.081-.263 4.688-.448z'
          fill='#e6cb39'
        />
        <path
          d='M91.065 47.103c2.241-.276 5.311.276 6.724 1.32 1.318.982 2.24 1.533 3.438 1.903 3.994 1.32 9.65 2.108 9.434 5.702-.248 4.3-2.346 6.061-5.91 7.138-2.826.861-8.159 4.475-12.121 4.938-2.302.29-4.036.266-5.45-.198-1.323-.462-3.374-3.027-5.617-4.842-2.214-1.813-4.593-2.88-3.872-5.256.459-1.66 1.457-3.304 3.887-5.425 1.263-1.08 3.107-3.768 4.794-4.859 1.389-.883 3.096-.237 4.693-.421z'
          fill='#e8ce38'
        />
        <path
          d='M91.118 47.147c2.226-.274 5.276.275 6.679 1.312 1.312.977 2.227 1.525 3.416 1.892 3.967 1.312 9.609 2.104 9.396 5.673-.244 4.27-2.379 6.009-5.918 7.076-2.807.854-8.114 4.362-12.049 4.85-2.318.306-3.996.275-5.399-.183-1.312-.458-3.355-3.02-5.582-4.819-2.196-1.8-4.575-2.807-3.813-5.155.488-1.586 1.434-3.264 3.844-5.369 1.25-1.067 3.049-3.783 4.728-4.88 1.372-.884 3.111-.214 4.698-.397z'
          fill='#ecd238'
        />
        <path
          d='M91.516 48.529c.482.999 1.723 1.137 2.55 1.585.793.449 1.241.552 1.551.379.689-.379.172-1.62-.517-2.068-.654-.447-3.859-.551-3.584.104z'
          fill='#ecd238'
        />
        <path
          d='M91.584 48.54c.471.975 1.68 1.108 2.486 1.545.773.437 1.21.538 1.512.37.672-.37.168-1.579-.503-2.016-.64-.437-3.764-.538-3.495.101z'
          fill='#e8ce38'
        />
        <path
          d='M91.653 48.55c.458.949 1.635 1.08 2.421 1.505.753.426 1.178.524 1.473.36.654-.36.164-1.538-.491-1.963-.622-.426-3.665-.524-3.403.098z'
          fill='#e6cb39'
        />
        <path
          d='M91.72 48.56c.446.924 1.593 1.051 2.357 1.465.733.414 1.146.509 1.433.35.638-.35.16-1.497-.478-1.911-.604-.413-3.566-.509-3.312.096z'
          fill='#e2c539'
        />
        <path
          d='M91.789 48.57c.434.898 1.548 1.022 2.292 1.425.712.403 1.115.496 1.394.341.62-.341.155-1.456-.464-1.858-.59-.403-3.47-.496-3.222.092z'
          fill='#e1c239'
        />
        <path
          d='M91.857 48.581c.421.874 1.505.994 2.228 1.385.693.391 1.084.481 1.354.331.602-.331.15-1.415-.451-1.806-.572-.391-3.372-.482-3.131.09z'
          fill='#ddbc39'
        />
        <path
          d='M91.925 48.591c.409.847 1.461.964 2.163 1.344.672.38 1.052.468 1.314.322.585-.322.147-1.375-.438-1.754-.554-.379-3.273-.467-3.039.088z'
          fill='#dab73b'
        />
        <path
          d='M91.993 48.602c.397.822 1.417.936 2.099 1.304.652.369 1.02.454 1.276.312.567-.312.142-1.333-.426-1.701-.539-.369-3.175-.455-2.949.085z'
          fill='#d7b23b'
        />
        <path
          d='M92.061 48.612c.385.797 1.375.907 2.034 1.265.632.357.989.439 1.237.302.549-.302.137-1.292-.412-1.649-.523-.358-3.079-.44-2.859.082z'
          fill='#d2ab3b'
        />
        <path
          d='M92.129 48.622c.373.772 1.331.879 1.969 1.224.612.347.958.426 1.198.293.532-.293.133-1.25-.4-1.597-.505-.346-2.979-.426-2.767.08z'
          fill='#cea53b'
        />
        <path
          d='M92.198 48.633c.36.746 1.287.849 1.905 1.184.591.334.926.412 1.158.283.515-.283.129-1.209-.386-1.544-.49-.335-2.883-.412-2.677.077z'
          fill='#cba03a'
        />
        <path
          d='M92.266 48.643c.349.721 1.243.82 1.84 1.144.572.323.895.398 1.119.273.497-.273.125-1.168-.373-1.491-.473-.324-2.785-.399-2.586.074z'
          fill='#c89c3a'
        />
        <path
          d='M92.334 48.653c.336.696 1.199.792 1.775 1.104.552.312.863.384 1.08.264.479-.264.12-1.128-.36-1.439-.456-.312-2.687-.385-2.495.071z'
          fill='#c3943a'
        />
        <path
          d='M92.402 48.663c.324.671 1.156.763 1.711 1.064.532.3.832.369 1.041.254.462-.254.116-1.086-.347-1.388-.44-.3-2.59-.369-2.405.07z'
          fill='#c08f3a'
        />
        <path
          d='M92.471 48.674c.312.646 1.112.734 1.646 1.023.512.289.801.355 1.001.244.444-.244.111-1.045-.334-1.334-.423-.289-2.492-.356-2.313.067z'
          fill='#bc893a'
        />
        <path
          d='M92.539 48.684c.3.62 1.068.706 1.582.983.491.278.769.342.961.235.428-.235.107-1.004-.32-1.282-.407-.278-2.394-.342-2.223.064z'
          fill='#b98439'
        />
        <path
          d='M92.606 48.694c.288.594 1.025.676 1.517.943.472.266.738.328.923.225.41-.225.102-.963-.308-1.23-.389-.265-2.295-.327-2.132.062z'
          fill='#b27d39'
        />
        <path
          d='M92.675 48.705c.274.569.98.647 1.452.903.452.255.706.314.883.216.392-.216.099-.922-.294-1.177-.373-.256-2.198-.315-2.041.058z'
          fill='#ae7639'
        />
        <path
          d='M92.743 48.715c.263.544.938.619 1.388.863.431.244.675.3.843.206.375-.206.094-.882-.281-1.125-.357-.244-2.1-.3-1.95.056z'
          fill='#a77038'
        />
        <path
          d='M92.812 48.726c.25.519.894.59 1.323.822.411.232.643.286.804.196.357-.196.09-.839-.269-1.072-.339-.233-2.002-.286-1.858.054z'
          fill='#a36937'
        />
        <path
          d='M92.879 48.736c.238.493.851.561 1.259.782.391.221.611.272.765.187.34-.187.085-.799-.255-1.02-.323-.221-1.904-.272-1.769.051z'
          fill='#9f6437'
        />
        <path
          d='M84.284 49.812c-.155.389.935 1.129 1.48.623.584-.506 1.285-.973 1.519-1.129 1.051-.702.662-1.207-1.09-.896-1.754.312-1.754 1.012-1.909 1.402z'
          fill='#ecd238'
        />
        <path
          d='M84.312 49.807c-.152.38.913 1.103 1.446.609.571-.495 1.255-.951 1.484-1.104 1.027-.685.646-1.18-1.066-.875-1.712.305-1.712.99-1.864 1.37z'
          fill='#e8ce38'
        />
        <path
          d='M84.34 49.802c-.148.372.891 1.078 1.411.594.558-.482 1.226-.928 1.449-1.077 1.003-.669.631-1.152-1.04-.854-1.671.297-1.671.966-1.82 1.337z'
          fill='#e6cb39'
        />
        <path
          d='M84.369 49.797c-.145.362.869 1.05 1.377.58a14.837 14.837 0 0 1 1.414-1.051c.979-.653.616-1.124-1.016-.834-1.631.29-1.631.943-1.775 1.305z'
          fill='#e2c539'
        />
        <path
          d='M84.397 49.792c-.142.353.848 1.024 1.342.565a14.648 14.648 0 0 1 1.379-1.025c.954-.636.601-1.095-.99-.812-1.59.283-1.59.918-1.731 1.272z'
          fill='#e1c239'
        />
        <path
          d='M84.425 49.788c-.137.344.826.999 1.309.55.517-.447 1.136-.86 1.343-.999.93-.62.586-1.067-.964-.792-1.551.276-1.551.896-1.688 1.241z'
          fill='#ddbc39'
        />
        <path
          d='M84.453 49.782c-.134.335.805.973 1.274.537.503-.436 1.106-.838 1.308-.973.906-.604.57-1.04-.939-.771-1.51.269-1.51.872-1.643 1.207z'
          fill='#dab73b'
        />
        <path
          d='M84.481 49.778c-.13.326.783.946 1.24.521.49-.424 1.077-.815 1.273-.946.881-.587.555-1.012-.914-.75-1.469.26-1.469.848-1.599 1.175z'
          fill='#d7b23b'
        />
        <path
          d='M84.509 49.773c-.127.316.761.92 1.206.507a12.952 12.952 0 0 1 1.238-.92c.856-.572.54-.984-.889-.73s-1.428.825-1.555 1.143z'
          fill='#d2ab3b'
        />
        <path
          d='M84.538 49.768c-.123.309.739.895 1.171.493a12.582 12.582 0 0 1 1.203-.894c.832-.556.523-.956-.863-.71-1.388.248-1.388.802-1.511 1.111z'
          fill='#cea53b'
        />
        <path
          d='M84.566 49.763c-.12.299.718.868 1.137.479.45-.389.988-.748 1.167-.867.808-.54.509-.929-.838-.689-1.347.239-1.347.778-1.466 1.077z'
          fill='#cba03a'
        />
        <path
          d='M84.593 49.758c-.116.29.697.842 1.103.465.436-.377.958-.726 1.133-.842.784-.523.493-.9-.813-.667-1.307.231-1.307.753-1.423 1.044z'
          fill='#c89c3a'
        />
        <path
          d='M84.622 49.753c-.112.281.675.815 1.068.45.422-.366.929-.704 1.097-.816.76-.506.478-.872-.788-.646-1.265.224-1.265.731-1.377 1.012z'
          fill='#c3943a'
        />
        <path
          d='M84.65 49.748c-.109.271.653.789 1.035.436.408-.354.898-.681 1.062-.79.735-.491.463-.844-.762-.626-1.227.218-1.227.708-1.335.98z'
          fill='#c08f3a'
        />
        <path
          d='M84.678 49.743c-.105.263.632.763 1 .421.395-.342.869-.658 1.027-.764.711-.474.448-.816-.737-.605-1.185.211-1.185.684-1.29.948z'
          fill='#bc893a'
        />
        <path
          d='M84.706 49.738c-.102.254.61.737.966.407.381-.33.839-.636.991-.737.687-.457.432-.788-.711-.585-1.144.203-1.144.661-1.246.915z'
          fill='#b98439'
        />
        <path
          d='M84.734 49.733c-.098.245.589.711.931.392.368-.319.81-.613.957-.711.662-.441.417-.76-.687-.564-1.103.197-1.103.638-1.201.883z'
          fill='#b27d39'
        />
        <path
          d='M84.762 49.728c-.094.236.567.686.897.378.355-.307.78-.591.921-.685.638-.425.402-.732-.661-.543-1.063.189-1.063.614-1.157.85z'
          fill='#ae7639'
        />
        <path
          d='M84.791 49.724c-.091.226.545.659.863.363.341-.296.75-.568.886-.659.614-.409.387-.705-.636-.523-1.023.182-1.023.591-1.113.819z'
          fill='#a77038'
        />
        <path
          d='M84.818 49.718c-.087.218.523.633.829.349.328-.283.72-.546.851-.633.589-.393.371-.677-.61-.502-.982.176-.982.568-1.07.786z'
          fill='#a36937'
        />
        <path
          d='M84.847 49.714c-.083.208.502.606.794.334.314-.272.69-.523.816-.606.565-.377.356-.649-.585-.481-.942.167-.942.543-1.025.753z'
          fill='#9f6437'
        />
        <path
          d='M86.235 60.151c-.11 1.368 3.037-2.052 3.228-2.353.41-.739 1.778-2.818 1.997-3.693.41-1.532 1.149-2.654.684-4.268-.164-.52-1.313-.657-1.833-.329-1.477.876-1.258 1.943-1.423 2.928-.546 2.791-2.461 5.609-2.653 7.715z'
          fill='#ecd238'
        />
        <path
          d='M86.364 59.938c-.104 1.334 2.958-2.005 3.144-2.299.399-.721 1.73-2.75 1.942-3.604.397-1.495 1.117-2.589.66-4.163-.161-.506-1.281-.638-1.787-.317-1.439.856-1.224 1.896-1.383 2.856-.53 2.722-2.393 5.473-2.576 7.527z'
          fill='#ecd238'
        />
        <path
          d='M86.493 59.724c-.101 1.301 2.878-1.958 3.06-2.245.388-.702 1.682-2.682 1.887-3.515.385-1.456 1.084-2.524.638-4.057-.158-.493-1.25-.62-1.743-.307-1.401.837-1.19 1.85-1.343 2.786-.513 2.654-2.324 5.336-2.499 7.338z'
          fill='#ecd438'
        />
        <path
          d='M86.622 59.511c-.096 1.266 2.8-1.912 2.976-2.191.376-.685 1.632-2.615 1.831-3.426.372-1.419 1.051-2.459.613-3.951-.154-.48-1.218-.602-1.697-.295-1.363.817-1.156 1.803-1.303 2.714-.495 2.583-2.253 5.199-2.42 7.149z'
          fill='#ecd438'
        />
        <path
          d='M86.75 59.296c-.09 1.232 2.721-1.865 2.892-2.137.365-.667 1.584-2.547 1.776-3.336.36-1.382 1.019-2.396.59-3.846-.15-.467-1.186-.583-1.652-.285-1.325.797-1.122 1.756-1.263 2.643-.476 2.517-2.183 5.064-2.343 6.961z'
          fill='#ecd438'
        />
        <path
          d='M86.878 59.083c-.086 1.198 2.642-1.818 2.808-2.083.354-.65 1.535-2.479 1.721-3.247.347-1.343.986-2.331.566-3.74-.147-.454-1.154-.565-1.606-.274-1.287.777-1.087 1.709-1.224 2.573-.458 2.445-2.113 4.926-2.265 6.771z'
          fill='#ecd438'
        />
        <path
          d='M87.007 58.869c-.081 1.164 2.562-1.771 2.723-2.029.342-.631 1.486-2.412 1.665-3.158.335-1.306.954-2.267.543-3.634-.144-.441-1.122-.547-1.562-.263-1.248.757-1.052 1.663-1.183 2.502-.439 2.376-2.042 4.79-2.186 6.582z'
          fill='#ecd439'
        />
        <path
          d='M87.135 58.655c-.076 1.13 2.484-1.725 2.64-1.975.331-.614 1.438-2.344 1.609-3.068.323-1.269.921-2.202.52-3.529-.141-.428-1.09-.528-1.516-.252-1.21.738-1.019 1.616-1.144 2.431-.421 2.307-1.972 4.653-2.109 6.393z'
          fill='#ecd439'
        />
        <path
          d='M87.264 58.441c-.071 1.097 2.405-1.678 2.556-1.921.319-.596 1.389-2.275 1.554-2.979.31-1.23.888-2.137.497-3.423-.138-.415-1.059-.511-1.47-.242-1.172.718-.984 1.57-1.104 2.36-.406 2.24-1.905 4.517-2.033 6.205z'
          fill='#ecd439'
        />
        <path
          d='M87.392 58.228c-.066 1.062 2.327-1.631 2.472-1.867.308-.578 1.34-2.208 1.499-2.89.297-1.193.855-2.072.473-3.317-.134-.402-1.027-.492-1.425-.23-1.134.698-.95 1.522-1.064 2.289-.388 2.169-1.834 4.379-1.955 6.015z'
          fill='#ecd439'
        />
        <path
          d='M87.521 58.014c-.062 1.028 2.248-1.584 2.387-1.813.298-.561 1.293-2.141 1.444-2.801.285-1.156.823-2.009.45-3.212-.131-.388-.996-.474-1.38-.219-1.096.679-.916 1.477-1.024 2.217-.369 2.102-1.764 4.245-1.877 5.828z'
          fill='#edd63b'
        />
        <path
          d='M87.65 57.801c-.057.994 2.169-1.538 2.303-1.76.286-.543 1.244-2.073 1.388-2.711.273-1.118.791-1.944.426-3.107-.127-.374-.963-.455-1.335-.208-1.057.659-.881 1.43-.984 2.146-.351 2.033-1.694 4.109-1.798 5.64z'
          fill='#edd63b'
        />
        <path
          d='M87.778 57.587c-.052.96 2.09-1.491 2.219-1.706.274-.525 1.195-2.005 1.333-2.623.26-1.08.757-1.878.402-3.001-.124-.361-.931-.436-1.29-.197-1.019.64-.847 1.383-.944 2.076-.332 1.965-1.623 3.972-1.72 5.451z'
          fill='#edd63b'
        />
        <path
          d='M87.907 57.373c-.047.926 2.011-1.444 2.135-1.652.263-.506 1.146-1.937 1.278-2.533.248-1.042.725-1.814.38-2.895-.122-.349-.9-.419-1.245-.187-.981.62-.812 1.337-.904 2.005-.316 1.895-1.555 3.835-1.644 5.262z'
          fill='#edd63b'
        />
        <path
          d='M88.035 57.159c-.042.893 1.933-1.397 2.051-1.597.251-.49 1.098-1.87 1.222-2.444.235-1.005.692-1.75.356-2.79-.118-.335-.868-.399-1.199-.176-.942.601-.778 1.29-.864 1.934-.298 1.827-1.485 3.699-1.566 5.073z'
          fill='#edd63d'
        />
        <path
          d='M88.165 56.946c-.038.858 1.853-1.352 1.966-1.543.241-.471 1.05-1.803 1.167-2.354.223-.968.66-1.686.333-2.685-.114-.322-.835-.382-1.154-.165-.904.58-.744 1.243-.824 1.863-.281 1.756-1.416 3.562-1.488 4.884z'
          fill='#edd63d'
        />
        <path
          d='M88.292 56.732c-.033.824 1.775-1.304 1.883-1.49.229-.453 1-1.734 1.111-2.265.21-.929.627-1.621.31-2.579-.111-.309-.805-.363-1.109-.153-.866.56-.709 1.196-.785 1.792-.262 1.688-1.344 3.425-1.41 4.695z'
          fill='#edd63d'
        />
        <path
          d='M88.421 56.518c-.029.79 1.695-1.257 1.798-1.436.217-.435.952-1.666 1.056-2.175.197-.892.594-1.556.286-2.474-.108-.295-.772-.345-1.063-.143-.829.541-.676 1.15-.745 1.721-.244 1.62-1.274 3.29-1.332 4.507z'
          fill='#edd640'
        />
        <path
          d='M88.55 56.305c-.023.755 1.617-1.211 1.714-1.382.207-.418.903-1.599 1-2.086.185-.855.562-1.492.263-2.368-.104-.282-.741-.326-1.018-.132-.79.521-.641 1.104-.705 1.649-.226 1.551-1.205 3.153-1.254 4.319z'
          fill='#edd640'
        />
        <path
          d='M88.678 56.09c-.019.722 1.538-1.164 1.63-1.328.195-.399.855-1.531.946-1.997.172-.816.529-1.428.239-2.262-.101-.269-.709-.309-.973-.121-.752.501-.607 1.057-.665 1.579-.208 1.482-1.135 3.017-1.177 4.129z'
          fill='#edd642'
        />
        <path
          d='M88.807 55.877c-.014.688 1.459-1.117 1.546-1.274.184-.382.807-1.463.89-1.908.16-.779.497-1.363.215-2.157-.098-.255-.677-.29-.927-.11-.714.482-.573 1.011-.625 1.509-.191 1.413-1.066 2.879-1.099 3.94z'
          fill='#edd642'
        />
        <path
          d='M97.1 54.458c-1.517 1.655-2.585 3.24-3.447 4.309-.896 1.103-3.102 2.413-1.999 3.688.931 1.138 4.756-.931 7.721-3.136 2.93-2.207 7.514-4.171 5.273-6.687-1.172-1.275-3.93-1-4.998-.311-.828.516-1.31.792-2.55 2.137z'
          fill='#ecd238'
        />
        <path
          d='M97.166 54.531c-1.494 1.617-2.544 3.173-3.393 4.218-.881 1.076-3.052 2.367-1.978 3.606.906 1.105 4.655-.931 7.562-3.097 2.873-2.167 7.363-4.105 5.18-6.55-1.143-1.239-3.84-.957-4.887-.28-.81.51-1.277.801-2.484 2.103z'
          fill='#ecd238'
        />
        <path
          d='M97.23 54.604c-1.469 1.58-2.502 3.105-3.338 4.126-.867 1.05-3.002 2.321-1.956 3.525.882 1.074 4.554-.93 7.403-3.057 2.814-2.126 7.211-4.039 5.086-6.413-1.113-1.203-3.752-.913-4.777-.249-.793.5-1.242.807-2.418 2.068z'
          fill='#ecd438'
        />
        <path
          d='M97.295 54.678c-1.445 1.542-2.46 3.038-3.283 4.035-.852 1.023-2.952 2.276-1.934 3.443.858 1.042 4.453-.93 7.244-3.017 2.758-2.088 7.059-3.974 4.99-6.276-1.082-1.167-3.66-.87-4.666-.218-.773.491-1.207.813-2.351 2.033z'
          fill='#ecd438'
        />
        <path
          d='M97.361 54.751c-1.422 1.505-2.419 2.971-3.229 3.944-.837.997-2.902 2.23-1.912 3.362.834 1.01 4.351-.929 7.085-2.977 2.701-2.048 6.908-3.907 4.896-6.139-1.053-1.131-3.572-.827-4.557-.188-.755.482-1.171.819-2.283 1.998z'
          fill='#ecd438'
        />
        <path
          d='M97.426 54.825c-1.398 1.467-2.376 2.904-3.174 3.853-.823.97-2.852 2.184-1.891 3.28.811.979 4.25-.929 6.924-2.938 2.645-2.007 6.758-3.841 4.805-6.002-1.023-1.095-3.482-.783-4.445-.157-.741.473-1.139.827-2.219 1.964z'
          fill='#ecd438'
        />
        <path
          d='M97.49 54.898c-1.373 1.43-2.334 2.837-3.119 3.761-.808.943-2.802 2.139-1.87 3.199.787.947 4.149-.928 6.766-2.897 2.588-1.968 6.607-3.776 4.711-5.865-.994-1.058-3.395-.74-4.336-.126-.72.464-1.103.832-2.152 1.928z'
          fill='#ecd439'
        />
        <path
          d='M97.557 54.972c-1.351 1.393-2.294 2.77-3.066 3.67-.793.917-2.752 2.093-1.847 3.117.762.915 4.047-.927 6.606-2.857 2.531-1.928 6.455-3.71 4.617-5.728-.965-1.022-3.307-.697-4.225-.096-.704.456-1.07.839-2.085 1.894z'
          fill='#ecd439'
        />
        <path
          d='M97.621 55.045c-1.326 1.355-2.251 2.702-3.01 3.579-.779.891-2.702 2.048-1.826 3.036.738.883 3.947-.927 6.447-2.817 2.475-1.889 6.305-3.645 4.521-5.592-.934-.985-3.215-.654-4.113-.065-.687.447-1.036.846-2.019 1.859z'
          fill='#ecd439'
        />
        <path
          d='M97.688 55.119c-1.304 1.317-2.211 2.635-2.958 3.488-.764.864-2.652 2.002-1.803 2.954.714.852 3.845-.927 6.288-2.777 2.416-1.849 6.152-3.578 4.428-5.455-.904-.95-3.127-.61-4.002-.034-.67.437-1.004.851-1.953 1.824z'
          fill='#ecd439'
        />
        <path
          d='M97.752 55.192c-1.279 1.28-2.169 2.568-2.902 3.397-.749.837-2.602 1.956-1.782 2.873.689.82 3.743-.927 6.129-2.738 2.359-1.81 6.002-3.513 4.334-5.318-.873-.913-3.037-.567-3.893-.003-.652.429-.968.858-1.886 1.789z'
          fill='#edd63b'
        />
        <path
          d='M97.818 55.266c-1.256 1.243-2.128 2.5-2.849 3.306-.734.811-2.552 1.91-1.761 2.791.667.788 3.642-.926 5.971-2.698 2.303-1.77 5.85-3.448 4.24-5.181-.846-.876-2.947-.524-3.783.028-.634.419-.933.864-1.818 1.754z'
          fill='#edd63b'
        />
        <path
          d='M97.883 55.339c-1.232 1.206-2.086 2.434-2.793 3.215-.72.784-2.502 1.865-1.738 2.709.642.756 3.541-.926 5.811-2.658 2.244-1.73 5.699-3.381 4.145-5.044-.814-.84-2.857-.48-3.67.059-.62.41-.902.871-1.755 1.719z'
          fill='#edd63b'
        />
        <path
          d='M97.947 55.413c-1.208 1.167-2.043 2.366-2.738 3.123-.705.757-2.452 1.819-1.717 2.628.618.725 3.44-.926 5.652-2.618 2.188-1.691 5.547-3.316 4.051-4.907-.783-.805-2.768-.438-3.561.089-.599.402-.866.877-1.687 1.685z'
          fill='#edd63b'
        />
        <path
          d='M98.014 55.486c-1.185 1.13-2.003 2.299-2.685 3.032-.689.73-2.402 1.773-1.694 2.547.593.692 3.338-.925 5.493-2.579 2.131-1.651 5.396-3.25 3.957-4.77-.754-.769-2.68-.395-3.449.12-.585.393-.835.885-1.622 1.65z'
          fill='#edd63d'
        />
        <path
          d='M98.078 55.56c-1.161 1.093-1.961 2.232-2.63 2.941-.675.704-2.352 1.728-1.673 2.465.57.661 3.237-.925 5.334-2.539 2.072-1.611 5.244-3.184 3.863-4.633-.725-.732-2.59-.351-3.34.15-.566.385-.796.891-1.554 1.616z'
          fill='#edd63d'
        />
        <path
          d='M98.143 55.633c-1.137 1.056-1.918 2.165-2.575 2.85-.661.678-2.302 1.683-1.651 2.384.545.63 3.136-.925 5.175-2.499 2.016-1.571 5.094-3.118 3.77-4.497-.695-.695-2.502-.308-3.229.181-.549.376-.766.898-1.49 1.581z'
          fill='#edd63d'
        />
        <path
          d='M98.209 55.707c-1.113 1.018-1.878 2.098-2.521 2.758-.646.651-2.252 1.637-1.63 2.302.521.598 3.034-.924 5.016-2.459 1.959-1.532 4.943-3.052 3.674-4.359-.664-.66-2.41-.264-3.117.211-.531.367-.731.905-1.422 1.547z'
          fill='#edd640'
        />
        <path
          d='M98.273 55.78c-1.09.98-1.835 2.03-2.466 2.667-.631.624-2.201 1.591-1.607 2.221.497.565 2.933-.924 4.857-2.419 1.9-1.493 4.791-2.987 3.58-4.223-.635-.624-2.322-.221-3.008.242-.514.359-.695.912-1.356 1.512z'
          fill='#edd640'
        />
        <path
          d='M98.34 55.854c-1.066.943-1.795 1.963-2.413 2.576-.617.598-2.152 1.545-1.586 2.139.473.534 2.831-.923 4.698-2.379 1.844-1.453 4.639-2.92 3.486-4.086-.605-.587-2.232-.177-2.898.273-.494.349-.66.917-1.287 1.477z'
          fill='#edd642'
        />
        <path
          d='M98.404 55.927c-1.041.905-1.753 1.896-2.358 2.485-.602.571-2.101 1.5-1.564 2.058.449.502 2.731-.923 4.538-2.339 1.789-1.413 4.49-2.855 3.395-3.949-.576-.551-2.145-.135-2.787.303-.48.341-.628.924-1.224 1.442z'
          fill='#edd642'
        />
        <path
          d='M100.34 89.201c.414-1.345 13.822-4.516 16.027-3.481 2.172 1.034 12.58 16.166 10.719 16.992-1.859.793-5.859-5.031-11.684-7.72-5.826-2.689-15.476-4.412-15.062-5.791z'
          fill='#fff'
        />
        <path
          d='M100.734 89.229c.406-1.322 13.473-4.345 15.65-3.35 2.143.995 12.334 15.748 10.504 16.559-1.83.778-5.727-4.953-11.461-7.599-5.732-2.646-15.099-4.256-14.693-5.61z'
          fill='#faf9f9'
        />
        <path
          d='M101.129 89.255c.398-1.299 13.127-4.175 15.271-3.218 2.113.957 12.09 15.332 10.291 16.126-1.801.763-5.596-4.873-11.238-7.477-5.641-2.603-14.723-4.1-14.324-5.431z'
          fill='#f6f5f4'
        />
        <path
          d='M101.523 89.282c.391-1.276 12.777-4.004 14.893-3.086 2.088.918 11.848 14.915 10.078 15.692-1.77.748-5.465-4.794-11.014-7.355-5.548-2.561-14.347-3.944-13.957-5.251z'
          fill='#f2f3f4'
        />
        <path
          d='M101.918 89.31c.385-1.254 12.43-3.833 14.516-2.955 2.057.879 11.602 14.498 9.861 15.258-1.738.733-5.332-4.714-10.789-7.232s-13.971-3.789-13.588-5.071z'
          fill='#eeeded'
        />
        <path
          d='M102.314 89.337c.375-1.232 12.08-3.664 14.137-2.823 2.027.84 11.355 14.081 9.646 14.825-1.707.719-5.201-4.635-10.566-7.111-5.363-2.476-13.593-3.633-13.217-4.891z'
          fill='#ececed'
        />
        <path
          d='M102.709 89.364c.365-1.21 11.732-3.493 13.758-2.691 2 .801 11.111 13.664 9.434 14.392-1.678.703-5.07-4.555-10.344-6.989-5.272-2.434-13.217-3.478-12.848-4.712z'
          fill='#e9e9e9'
        />
        <path
          d='M103.104 89.392c.359-1.188 11.385-3.323 13.379-2.561 1.973.764 10.867 13.247 9.221 13.958-1.646.689-4.939-4.476-10.119-6.866-5.183-2.392-12.841-3.322-12.481-4.531z'
          fill='#e4e3e3'
        />
        <path
          d='M103.498 89.418c.352-1.164 11.037-3.151 13.002-2.428 1.943.725 10.623 12.83 9.006 13.524-1.617.674-4.809-4.396-9.896-6.745-5.089-2.348-12.464-3.165-12.112-4.351z'
          fill='#e1e0e0'
        />
        <path
          d='M103.893 89.446c.344-1.143 10.689-2.982 12.623-2.296 1.916.686 10.379 12.413 8.793 13.091-1.586.658-4.678-4.316-9.674-6.623-4.996-2.306-12.086-3.011-11.742-4.172z'
          fill='#dcdcdd'
        />
        <path
          d='M104.287 89.473c.336-1.119 10.342-2.812 12.246-2.165 1.887.646 10.133 11.997 8.576 12.658-1.555.644-4.543-4.238-9.449-6.501-4.904-2.264-11.709-2.855-11.373-3.992z'
          fill='#dadada'
        />
        <path
          d='M104.682 89.5c.328-1.098 9.992-2.641 11.867-2.033 1.859.608 9.889 11.579 8.363 12.224-1.523.629-4.412-4.157-9.225-6.379-4.812-2.221-11.333-2.699-11.005-3.812z'
          fill='#d5d6d7'
        />
        <path
          d='M105.076 89.528c.322-1.076 9.645-2.471 11.49-1.902 1.83.569 9.643 11.163 8.148 11.791-1.494.614-4.281-4.078-9.002-6.257s-10.956-2.543-10.636-3.632z'
          fill='#d2d1d1'
        />
        <path
          d='M105.471 89.554c.314-1.053 9.297-2.3 11.111-1.77 1.803.53 9.398 10.746 7.936 11.357-1.463.599-4.15-3.999-8.779-6.135s-10.581-2.386-10.268-3.452z'
          fill='#cecece'
        />
        <path
          d='M105.865 89.582c.307-1.03 8.949-2.129 10.732-1.638 1.773.492 9.154 10.329 7.721 10.924-1.432.584-4.018-3.92-8.555-6.014-4.534-2.094-10.202-2.231-9.898-3.272z'
          fill='#cacacb'
        />
        <path
          d='M106.26 89.609c.299-1.007 8.602-1.96 10.355-1.507 1.744.454 8.908 9.912 7.506 10.49-1.402.569-3.887-3.84-8.33-5.891-4.445-2.052-9.828-2.075-9.531-3.092z'
          fill='#c5c5c6'
        />
        <path
          d='M106.656 89.636c.289-.986 8.252-1.79 9.975-1.375 1.717.415 8.664 9.495 7.293 10.057-1.371.555-3.756-3.76-8.107-5.769-4.352-2.009-9.452-1.92-9.161-2.913z'
          fill='silver'
        />
        <path
          d='M107.051 89.664c.281-.963 7.904-1.619 9.598-1.244 1.688.375 8.42 9.077 7.078 9.622-1.342.54-3.625-3.68-7.885-5.646-4.26-1.967-9.074-1.764-8.791-2.732z'
          fill='#bcbbbb'
        />
        <path
          d='M107.443 89.69c.275-.94 7.559-1.448 9.221-1.111 1.66.336 8.176 8.66 6.865 9.189-1.311.524-3.494-3.602-7.662-5.525-4.168-1.924-8.697-1.608-8.424-2.553z'
          fill='#b8b8b9'
        />
        <path
          d='M107.84 89.718c.268-.918 7.209-1.278 8.842-.98 1.631.298 7.93 8.244 6.65 8.756-1.281.51-3.363-3.521-7.439-5.403-4.075-1.882-8.321-1.453-8.053-2.373z'
          fill='#b1b2b3'
        />
        <path
          d='M108.234 89.745c.26-.895 6.859-1.107 8.463-.849 1.604.26 7.686 7.828 6.436 8.323-1.248.495-3.229-3.442-7.213-5.282-3.984-1.838-7.945-1.296-7.686-2.192z'
          fill='#adadae'
        />
        <path
          d='M83.451 109.812c1.379.069.069 7.79.069 15.752s1.068 10.754.068 11.753c-1 1-2.723-2.584-2.723-10.546.001-7.962 1.207-17.027 2.586-16.959z'
          fill='#fff'
        />
        <path
          d='M83.437 110c1.332.077.052 7.685.046 15.52-.005 7.835 1.047 10.631.079 11.603-.967.971-2.662-2.577-2.655-10.413.006-7.836 1.197-16.787 2.53-16.71z'
          fill='#faf9f9'
        />
        <path
          d='M83.422 110.188c1.286.085.036 7.578.024 15.286-.012 7.709 1.025 10.509.088 11.453-.935.941-2.602-2.569-2.587-10.28.012-7.708 1.19-16.545 2.475-16.459z'
          fill='#f6f5f4'
        />
        <path
          d='M83.409 110.374c1.239.094.02 7.474.002 15.055-.018 7.581 1.004 10.386.098 11.303-.903.913-2.541-2.562-2.52-10.146.017-7.582 1.18-16.306 2.42-16.212z'
          fill='#f2f3f4'
        />
        <path
          d='M83.394 110.562c1.193.102.003 7.368-.02 14.822s.982 10.263.107 11.152c-.87.884-2.48-2.555-2.452-10.014.023-7.454 1.173-16.063 2.365-15.96z'
          fill='#eeeded'
        />
        <path
          d='M83.38 110.748c1.146.111-.013 7.263-.042 14.591-.029 7.326.96 10.14.116 11.001-.838.856-2.419-2.546-2.384-9.879.03-7.327 1.164-15.823 2.31-15.713z'
          fill='#ececed'
        />
        <path
          d='M83.366 110.937c1.099.118-.03 7.156-.065 14.356-.036 7.2.938 10.018.126 10.852-.806.827-2.358-2.538-2.316-9.745.035-7.201 1.156-15.584 2.255-15.463z'
          fill='#e9e9e9'
        />
        <path
          d='M83.352 111.123c1.053.126-.046 7.052-.087 14.124-.042 7.074.917 9.896.135 10.702-.773.798-2.298-2.53-2.248-9.612.042-7.073 1.147-15.341 2.2-15.214z'
          fill='#e4e3e3'
        />
        <path
          d='M83.338 111.31c1.006.136-.062 6.947-.11 13.894-.047 6.945.896 9.771.145 10.551-.741.769-2.237-2.523-2.181-9.479.048-6.947 1.14-15.101 2.146-14.966z'
          fill='#e1e0e0'
        />
        <path
          d='M83.324 111.497c.96.144-.078 6.842-.132 13.66-.053 6.819.875 9.648.155 10.4-.708.74-2.177-2.515-2.113-9.344.053-6.818 1.13-14.86 2.09-14.716z'
          fill='#dcdcdd'
        />
        <path
          d='M83.31 111.685c.913.151-.096 6.735-.154 13.428-.059 6.692.853 9.526.164 10.25-.676.712-2.116-2.507-2.045-9.211.059-6.693 1.121-14.62 2.035-14.467z'
          fill='#dadada'
        />
        <path
          d='M83.296 111.871c.867.161-.112 6.63-.176 13.196-.065 6.564.83 9.403.173 10.099-.645.684-2.056-2.499-1.978-9.076.065-6.566 1.114-14.379 1.981-14.219z'
          fill='#d5d6d7'
        />
        <path
          d='M83.282 112.059c.82.169-.128 6.524-.198 12.963-.071 6.438.809 9.28.183 9.949-.611.654-1.995-2.49-1.909-8.943.069-6.439 1.103-14.139 1.924-13.969z'
          fill='#d2d1d1'
        />
        <path
          d='M83.268 112.245c.774.178-.144 6.42-.221 12.731s.788 9.158.193 9.799c-.58.626-1.934-2.483-1.842-8.81.076-6.311 1.096-13.897 1.87-13.72z'
          fill='#cecece'
        />
        <path
          d='M83.253 112.433c.728.186-.16 6.314-.243 12.498-.083 6.185.766 9.036.202 9.648-.547.598-1.873-2.475-1.774-8.676.083-6.183 1.089-13.656 1.815-13.47z'
          fill='#cacacb'
        />
        <path
          d='M83.24 112.62c.681.193-.177 6.208-.266 12.266s.744 8.913.211 9.498c-.514.568-1.812-2.468-1.706-8.542.089-6.058 1.08-13.416 1.761-13.222z'
          fill='#c5c5c6'
        />
        <path
          d='M83.226 112.807c.634.203-.193 6.104-.288 12.034-.095 5.93.722 8.789.221 9.348-.482.539-1.752-2.459-1.638-8.408.094-5.931 1.071-13.177 1.705-12.974z'
          fill='silver'
        />
        <path
          d='M83.211 112.994c.588.211-.21 5.998-.31 11.802-.1 5.803.701 8.666.23 9.197-.45.511-1.691-2.452-1.57-8.275.101-5.804 1.063-12.934 1.65-12.724z'
          fill='#bcbbbb'
        />
        <path
          d='M83.198 113.182c.541.219-.227 5.892-.333 11.568-.106 5.677.679 8.545.24 9.048-.418.481-1.63-2.444-1.503-8.142.106-5.677 1.054-12.694 1.596-12.474z'
          fill='#b8b8b9'
        />
        <path
          d='M83.183 113.369c.495.227-.242 5.786-.354 11.336s.658 8.421.25 8.897c-.386.453-1.57-2.438-1.436-8.009.112-5.549 1.046-12.452 1.54-12.224z'
          fill='#b1b2b3'
        />
        <path
          d='M83.169 113.556c.448.235-.259 5.682-.376 11.104-.118 5.422.636 8.298.259 8.746-.354.424-1.509-2.429-1.367-7.875.117-5.421 1.036-12.212 1.484-11.975z'
          fill='#adadae'
        />
        <path
          d='M86.466 88.527c.832.092 5.954-1.062 6.139-.6.184.369-3.185 1.385-4.292 2.539-.369.369-1.338 1.246-1.938 1.153-.461-.045-.738-1.015-1.431-1.753-1.662-1.661-2.723-1.523-2.4-2.169.277-.509 2.492.691 3.922.83z'
          fill='#fff'
        />
        <path
          d='M86.488 88.546c.814.09 5.829-1.039 6.01-.588.18.362-3.118 1.356-4.202 2.486-.362.361-1.311 1.22-1.898 1.129-.452-.045-.723-.994-1.4-1.717-1.627-1.626-2.666-1.491-2.35-2.124.27-.496 2.44.678 3.84.814z'
          fill='#fbfdff'
        />
        <path
          d='M86.51 88.565c.796.088 5.704-1.018 5.88-.575.177.354-3.05 1.327-4.111 2.432-.354.353-1.282 1.193-1.857 1.104-.442-.043-.708-.973-1.37-1.68-1.592-1.591-2.609-1.458-2.299-2.078.265-.485 2.386.665 3.757.797z'
          fill='#faf9f9'
        />
        <path
          d='M86.532 88.584c.778.087 5.578-.994 5.751-.562.173.346-2.984 1.297-4.021 2.378-.346.346-1.253 1.167-1.816 1.081-.433-.043-.692-.951-1.341-1.644-1.556-1.557-2.551-1.427-2.249-2.032.26-.475 2.335.649 3.676.779z'
          fill='#f6f5f4'
        />
        <path
          d='M86.553 88.604c.761.084 5.453-.973 5.622-.55.169.338-2.917 1.269-3.931 2.325-.338.338-1.226 1.141-1.775 1.056-.422-.042-.676-.929-1.311-1.606-1.522-1.522-2.494-1.395-2.198-1.987.253-.465 2.283.635 3.593.762z'
          fill='#f4f4f4'
        />
        <path
          d='M86.575 88.622c.744.083 5.328-.949 5.493-.537.165.331-2.85 1.239-3.841 2.272-.33.331-1.198 1.115-1.734 1.032-.414-.041-.661-.908-1.281-1.569-1.487-1.487-2.437-1.363-2.148-1.941.248-.454 2.231.62 3.511.743z'
          fill='#f1f0f0'
        />
        <path
          d='M86.596 88.642c.726.08 5.203-.928 5.364-.524.161.323-2.782 1.21-3.75 2.219-.322.322-1.17 1.088-1.694 1.008-.403-.04-.646-.888-1.25-1.533-1.452-1.451-2.379-1.33-2.097-1.896.242-.444 2.177.605 3.427.726z'
          fill='#f1f0f0'
        />
        <path
          d='M86.618 88.661c.709.079 5.078-.906 5.235-.512.157.315-2.716 1.182-3.66 2.165-.315.315-1.142 1.062-1.653.984-.394-.04-.63-.866-1.221-1.496-1.417-1.417-2.322-1.299-2.046-1.85.236-.432 2.125.59 3.345.709z'
          fill='#eeeded'
        />
        <path
          d='M86.64 88.68c.691.076 4.952-.883 5.105-.499.153.307-2.648 1.152-3.57 2.111-.308.307-1.114 1.036-1.613.96-.384-.039-.614-.845-1.19-1.459-1.382-1.382-2.265-1.267-1.996-1.805.23-.422 2.074.576 3.264.692z'
          fill='#ececed'
        />
        <path
          d='M86.662 88.699c.673.075 4.827-.861 4.977-.486.15.299-2.582 1.122-3.48 2.058-.299.299-1.085 1.01-1.571.936-.375-.037-.599-.824-1.161-1.422-1.347-1.347-2.207-1.235-1.945-1.758.223-.413 2.019.559 3.18.672z'
          fill='#e9e9e9'
        />
        <path
          d='M86.683 88.718c.656.073 4.702-.838 4.848-.474.146.292-2.515 1.094-3.39 2.005-.292.292-1.058.983-1.531.911-.365-.037-.583-.802-1.13-1.385-1.312-1.312-2.15-1.203-1.895-1.713.218-.401 1.968.546 3.098.656z'
          fill='#e9e9e9'
        />
        <path
          d='M86.705 88.737c.639.071 4.577-.816 4.719-.462.142.284-2.448 1.065-3.3 1.952-.284.284-1.029.958-1.49.887-.354-.036-.567-.78-1.099-1.348-1.277-1.277-2.093-1.171-1.845-1.668.211-.39 1.915.532 3.015.639z'
          fill='#e7e6e6'
        />
        <path
          d='M86.726 88.756c.622.069 4.452-.793 4.59-.448.138.276-2.381 1.035-3.209 1.898-.276.276-1.001.932-1.45.862-.345-.034-.552-.759-1.07-1.311-1.242-1.242-2.036-1.139-1.794-1.622.207-.38 1.864.517 2.933.621z'
          fill='#e4e3e3'
        />
        <path
          d='M86.748 88.775c.604.067 4.326-.771 4.46-.436.134.268-2.313 1.006-3.119 1.844-.268.268-.973.906-1.408.838-.336-.033-.537-.738-1.04-1.274-1.207-1.208-1.979-1.106-1.744-1.576.201-.368 1.811.503 2.851.604z'
          fill='#dfdfe0'
        />
        <path
          d='M86.77 88.794c.586.065 4.201-.749 4.332-.423.13.26-2.247.977-3.029 1.791-.261.261-.945.879-1.368.814-.326-.033-.521-.716-1.01-1.238-1.172-1.172-1.922-1.074-1.693-1.53.195-.358 1.758.488 2.768.586z'
          fill='#dedddd'
        />
        <path
          d='M86.791 88.813c.569.063 4.076-.727 4.203-.41.126.252-2.18.947-2.938 1.737-.252.253-.917.854-1.327.791-.316-.032-.505-.695-.979-1.201-1.138-1.137-1.864-1.042-1.643-1.485.187-.348 1.705.474 2.684.568z'
          fill='#dcdcdd'
        />
        <path
          d='M86.813 88.832c.551.062 3.95-.704 4.073-.397.123.245-2.113.919-2.848 1.684-.245.245-.888.827-1.287.766-.307-.031-.49-.674-.949-1.165-1.103-1.102-1.808-1.01-1.593-1.439.184-.337 1.654.459 2.604.551z'
          fill='#dadada'
        />
        <path
          d='M86.834 88.852c.534.06 3.826-.682 3.944-.386.119.237-2.046.891-2.758 1.632-.238.236-.861.8-1.246.741-.296-.03-.475-.652-.919-1.127-1.068-1.068-1.75-.979-1.542-1.394.179-.326 1.602.445 2.521.534z'
          fill='#d7d7d7'
        />
        <path
          d='M86.856 88.871c.517.058 3.701-.66 3.815-.373.114.229-1.979.86-2.668 1.578-.229.229-.832.774-1.205.717-.287-.029-.459-.631-.89-1.09-1.033-1.033-1.692-.947-1.492-1.349.173-.315 1.551.43 2.44.517z'
          fill='#d5d4d4'
        />
        <path
          d='M86.877 88.89c.499.056 3.576-.637 3.687-.36.111.221-1.912.832-2.577 1.524-.222.222-.805.749-1.165.693-.277-.028-.444-.61-.859-1.054-.998-.998-1.635-.915-1.441-1.302.166-.305 1.497.416 2.355.499z'
          fill='#d2d1d1'
        />
        <path
          d='M86.899 88.909c.481.053 3.45-.615 3.557-.348.106.213-1.845.802-2.487 1.471-.214.214-.776.722-1.124.668-.268-.026-.428-.588-.829-1.016-.963-.963-1.578-.883-1.391-1.257.161-.294 1.445.402 2.274.482z'
          fill='#d0d0d1'
        />
        <path d='M104.092 14.211c-.584 1.26-1.824 11.113-.117 10.002 1.719-1.121 6.496-2.543 9.52-3.357 3.351-.904-8.229-9.318-9.403-6.645z' />
        <path
          d='M104.203 14.396c-.58 1.242-1.773 10.654-.111 9.578 1.67-1.083 6.26-2.442 9.113-3.247 3.156-.913-7.867-8.91-9.002-6.331z'
          fill='#000807'
        />
        <path
          d='M104.314 14.579c-.574 1.225-1.721 10.195-.105 9.155 1.623-1.045 6.025-2.34 8.709-3.136 2.957-.921-7.508-8.501-8.604-6.019z'
          fill='#101616'
        />
        <path
          d='M104.426 14.764c-.568 1.206-1.67 9.735-.1 8.731 1.574-1.008 5.789-2.239 8.305-3.026 2.76-.929-7.149-8.092-8.205-5.705z'
          fill='#171d1d'
        />
        <path
          d='M104.537 14.947c-.562 1.189-1.617 9.277-.094 8.309 1.525-.971 5.555-2.137 7.9-2.916 2.561-.938-6.788-7.683-7.806-5.393z'
          fill='#222727'
        />
        <path
          d='M104.648 15.131c-.559 1.171-1.566 8.818-.086 7.885 1.477-.934 5.316-2.036 7.494-2.805 2.364-.946-6.429-7.274-7.408-5.08z'
          fill='#292e2e'
        />
        <path
          d='M104.76 15.316c-.553 1.153-1.514 8.358-.08 7.461 1.428-.895 5.082-1.934 7.09-2.694 2.164-.956-6.071-6.867-7.01-4.767z'
          fill='#2f3534'
        />
        <path
          d='M104.869 15.5c-.545 1.135-1.461 7.899-.072 7.038 1.381-.858 4.848-1.833 6.686-2.583 1.966-.965-5.71-6.46-6.614-4.455z'
          fill='#373c3d'
        />
        <path
          d='M104.98 15.684c-.541 1.118-1.41 7.44-.066 6.615 1.332-.82 4.611-1.731 6.281-2.474 1.768-.972-5.351-6.05-6.215-4.141z'
          fill='#3d4243'
        />
        <path
          d='M105.092 15.868c-.535 1.1-1.357 6.98-.061 6.191 1.283-.783 4.375-1.63 5.877-2.363 1.571-.981-4.99-5.641-5.816-3.828z'
          fill='#454a4b'
        />
        <path
          d='M105.203 16.052c-.529 1.083-1.307 6.522-.053 5.768 1.232-.745 4.139-1.528 5.471-2.252 1.371-.99-4.631-5.233-5.418-3.516z'
          fill='#4d5252'
        />
        <path
          d='M105.314 16.236c-.525 1.064-1.254 6.063-.047 5.344 1.186-.708 3.902-1.427 5.066-2.142 1.175-.998-4.271-4.824-5.019-3.202z'
          fill='#545959'
        />
        <path
          d='M105.426 16.42c-.52 1.048-1.203 5.604-.041 4.921 1.137-.67 3.668-1.325 4.662-2.031.974-1.007-3.912-4.416-4.621-2.89z'
          fill='#5c6060'
        />
        <path
          d='M105.537 16.604c-.514 1.029-1.152 5.145-.035 4.498 1.088-.633 3.432-1.224 4.258-1.922.777-1.014-3.553-4.007-4.223-2.576z'
          fill='#626667'
        />
        <path
          d='M105.648 16.788c-.51 1.012-1.1 4.686-.027 4.075 1.039-.595 3.195-1.122 3.852-1.811.58-1.024-3.194-3.599-3.825-2.264z'
          fill='#696c6c'
        />
        <path
          d='M105.76 16.972c-.504.994-1.049 4.227-.021 3.651.99-.558 2.959-1.021 3.447-1.701.38-1.032-2.834-3.189-3.426-1.95z'
          fill='#6f7273'
        />
        <path
          d='M105.869 17.156c-.496.977-.996 3.768-.014 3.228.943-.52 2.725-.919 3.043-1.59.182-1.041-2.474-2.782-3.029-1.638z'
          fill='#767778'
        />
        <path
          d='M105.98 17.341c-.49.958-.943 3.308-.008 2.804.895-.483 2.488-.818 2.637-1.479-.013-1.051-2.113-2.374-2.629-1.325z'
          fill='#7c7d7e'
        />
        <path
          d='M106.092 17.524c-.486.941-.893 2.85-.002 2.381.846-.445 2.254-.716 2.232-1.369-.211-1.058-1.754-1.964-2.23-1.012z'
          fill='#848586'
        />
        <path
          d='M106.203 17.709c-.48.923-.84 2.39.004 1.958.797-.408 2.02-.615 1.828-1.258-.41-1.068-1.392-1.557-1.832-.7z'
          fill='#8a8b8b'
        />
        <path
          d='M106.314 17.893c-.475.906-.789 1.931.012 1.535.748-.37 1.781-.513 1.422-1.148-.609-1.077-1.033-1.149-1.434-.387z'
          fill='#919394'
        />
        <path d='M79.797 30.709c-.345 1.034 4.17 2.447 5.17 3.343 1.344 1.206 1.447 3.585 3.033 2.62 1.034-.621.241-2-1.655-4.102-2.791-3.101-6.238-2.722-6.548-1.861z' />
        <path
          d='M80.031 30.804c-.342.992 3.962 2.346 4.948 3.227 1.314 1.176 1.417 3.47 2.942 2.543 1.002-.6.215-1.949-1.6-3.963-2.669-2.97-5.98-2.636-6.29-1.807z'
          fill='#000807'
        />
        <path
          d='M80.265 30.897c-.34.951 3.753 2.244 4.727 3.11 1.284 1.146 1.387 3.355 2.851 2.467.972-.581.189-1.899-1.544-3.826-2.549-2.836-5.724-2.546-6.034-1.751z'
          fill='#061212'
        />
        <path
          d='M80.499 30.992c-.338.909 3.544 2.142 4.504 2.994 1.254 1.115 1.357 3.24 2.76 2.391.94-.561.163-1.85-1.49-3.689-2.424-2.704-5.464-2.459-5.774-1.696z'
          fill='#131919'
        />
        <path
          d='M80.732 31.086c-.335.868 3.335 2.041 4.283 2.878 1.224 1.084 1.327 3.126 2.669 2.314.908-.541.137-1.8-1.436-3.551-2.3-2.571-5.205-2.371-5.516-1.641z'
          fill='#1b1f1f'
        />
        <path
          d='M80.966 31.18c-.333.827 3.126 1.94 4.061 2.762 1.194 1.054 1.297 3.012 2.578 2.238.877-.521.111-1.75-1.381-3.413-2.179-2.438-4.947-2.284-5.258-1.587z'
          fill='#222727'
        />
        <path
          d='M81.2 31.274c-.331.785 2.917 1.837 3.839 2.645 1.164 1.025 1.267 2.897 2.487 2.163.846-.5.085-1.701-1.326-3.276-2.056-2.306-4.689-2.195-5-1.532z'
          fill='#282d2d'
        />
        <path
          d='M81.434 31.368c-.329.744 2.708 1.736 3.617 2.529 1.133.993 1.236 2.782 2.396 2.085.814-.481.06-1.651-1.271-3.138-1.934-2.172-4.431-2.107-4.742-1.476z'
          fill='#2e3333'
        />
        <path
          d='M81.667 31.463c-.326.702 2.5 1.634 3.396 2.412 1.104.964 1.207 2.668 2.305 2.01.783-.46.033-1.601-1.216-3-1.811-2.04-4.173-2.02-4.485-1.422z'
          fill='#323838'
        />
        <path
          d='M81.902 31.557c-.324.66 2.29 1.533 3.173 2.296 1.073.933 1.176 2.553 2.213 1.934.752-.441.007-1.551-1.161-2.863-1.688-1.907-3.915-1.932-4.225-1.367z'
          fill='#383d3d'
        />
        <path
          d='M82.135 31.651c-.322.619 2.082 1.431 2.952 2.179 1.043.903 1.146 2.438 2.122 1.857.721-.42-.019-1.501-1.106-2.726-1.565-1.773-3.656-1.842-3.968-1.31z'
          fill='#3d4243'
        />
        <path
          d='M82.369 31.745c-.319.577 1.873 1.33 2.73 2.062 1.013.873 1.116 2.325 2.031 1.781.689-.4-.045-1.451-1.052-2.587-1.442-1.64-3.398-1.755-3.709-1.256z'
          fill='#434849'
        />
        <path
          d='M82.603 31.839c-.317.536 1.664 1.229 2.508 1.947.983.842 1.086 2.209 1.939 1.705.659-.381-.07-1.402-.997-2.45-1.319-1.508-3.138-1.668-3.45-1.202z'
          fill='#4a4e4e'
        />
        <path
          d='M82.836 31.934c-.314.494 1.455 1.126 2.287 1.83.952.812 1.056 2.095 1.848 1.628.627-.36-.096-1.352-.942-2.313-1.196-1.374-2.881-1.579-3.193-1.145z'
          fill='#505555'
        />
        <path
          d='M83.07 32.027c-.312.453 1.246 1.025 2.065 1.714.922.781 1.025 1.98 1.757 1.552.596-.34-.123-1.302-.887-2.175-1.074-1.241-2.623-1.491-2.935-1.091z'
          fill='#565a5a'
        />
        <path
          d='M83.304 32.122c-.311.411 1.037.924 1.843 1.597.893.75.996 1.865 1.666 1.476.564-.321-.147-1.253-.832-2.038-.952-1.108-2.365-1.402-2.677-1.035z'
          fill='#5c6060'
        />
        <path
          d='M83.538 32.216c-.308.37.829.822 1.621 1.48.863.721.966 1.751 1.575 1.4.534-.301-.174-1.203-.777-1.899-.83-.976-2.107-1.315-2.419-.981z'
          fill='#616464'
        />
        <path
          d='M83.772 32.311c-.306.328.619.72 1.399 1.364.832.69.935 1.637 1.484 1.324.501-.28-.2-1.153-.723-1.762-.706-.844-1.849-1.228-2.16-.926z'
          fill='#676a6a'
        />
        <path
          d='M84.005 32.404c-.303.286.41.619 1.178 1.248.802.66.905 1.522 1.393 1.248.47-.26-.226-1.103-.668-1.625-.583-.71-1.59-1.139-1.903-.871z'
          fill='#6a6d6e'
        />
        <path
          d='M84.24 32.498c-.301.245.201.518.955 1.132.772.629.875 1.407 1.302 1.171.439-.241-.252-1.053-.612-1.487-.462-.577-1.334-1.051-1.645-.816z'
          fill='#6f7273'
        />
        <path
          d='M84.473 32.593c-.299.203-.008.416.734 1.015.742.6.845 1.293 1.21 1.095.408-.22-.278-1.003-.558-1.349-.338-.445-1.073-.963-1.386-.761z'
          fill='#767778'
        />
        <path d='M103.717 30.33c-.033.517 1.414.827 2.344 1.172.932.311 2.758 1.551 3.619 2.516.863.931 2.725 3.688 3.137 2.964.414-.655-.965-2.551-1.344-3.344-.379-.792-1.551-2.516-3.412-3.102-1.62-.481-4.309-.619-4.344-.206z' />
        <path
          d='M103.865 30.367c-.033.502 1.373.806 2.277 1.143.906.303 2.68 1.513 3.518 2.452.836.906 2.648 3.587 3.051 2.885.402-.636-.926-2.477-1.311-3.253-.383-.776-1.52-2.437-3.328-3.016-1.57-.477-4.172-.614-4.207-.211z'
          fill='#000807'
        />
        <path
          d='M104.014 30.403c-.033.489 1.332.786 2.211 1.114.879.296 2.604 1.476 3.414 2.388.811.882 2.576 3.486 2.967 2.806.391-.616-.889-2.403-1.277-3.163-.387-.759-1.488-2.357-3.244-2.93-1.523-.471-4.038-.605-4.071-.215z'
          fill='#101616'
        />
        <path
          d='M104.162 30.44c-.035.475 1.293.765 2.145 1.085.854.288 2.523 1.438 3.311 2.324.785.857 2.504 3.385 2.883 2.727.379-.597-.85-2.329-1.246-3.073-.389-.743-1.457-2.278-3.158-2.844-1.474-.465-3.904-.598-3.935-.219z'
          fill='#171d1d'
        />
        <path
          d='M104.309 30.477c-.033.46 1.254.744 2.08 1.056.826.281 2.447 1.399 3.209 2.259.758.833 2.43 3.284 2.797 2.648.367-.578-.811-2.255-1.213-2.982-.393-.727-1.426-2.199-3.074-2.759-1.422-.459-3.766-.59-3.799-.222z'
          fill='#222727'
        />
        <path
          d='M104.457 30.514c-.033.445 1.213.723 2.014 1.027.801.274 2.367 1.361 3.105 2.196.732.809 2.355 3.183 2.713 2.569.354-.559-.773-2.181-1.18-2.893-.398-.71-1.395-2.12-2.99-2.673-1.373-.453-3.629-.583-3.662-.226z'
          fill='#292e2e'
        />
        <path
          d='M104.605 30.55c-.033.432 1.174.703 1.947.999.775.267 2.291 1.323 3.002 2.131.709.784 2.283 3.082 2.629 2.49.342-.54-.734-2.107-1.146-2.802-.402-.695-1.363-2.041-2.906-2.587-1.324-.447-3.494-.576-3.526-.231z'
          fill='#2f3534'
        />
        <path
          d='M104.752 30.587c-.031.418 1.135.682 1.883.97.748.259 2.213 1.285 2.898 2.067.682.76 2.211 2.98 2.545 2.41.33-.52-.697-2.033-1.113-2.711-.406-.679-1.332-1.961-2.822-2.501-1.274-.442-3.358-.569-3.391-.235z'
          fill='#373c3d'
        />
        <path
          d='M104.9 30.624c-.033.403 1.094.661 1.816.94.723.252 2.135 1.247 2.797 2.003.656.735 2.135 2.879 2.459 2.332.316-.501-.658-1.96-1.08-2.622-.41-.661-1.301-1.882-2.738-2.415-1.224-.436-3.222-.561-3.254-.238z'
          fill='#3d4243'
        />
        <path
          d='M105.049 30.66c-.033.389 1.053.641 1.75.912.697.245 2.057 1.209 2.693 1.939.631.711 2.062 2.779 2.375 2.253.305-.482-.621-1.886-1.047-2.532-.414-.645-1.27-1.803-2.654-2.329-1.176-.43-3.086-.553-3.117-.243z'
          fill='#454a4b'
        />
        <path
          d='M105.197 30.697c-.033.375 1.014.62 1.684.883.672.238 1.979 1.17 2.59 1.875.605.687 1.99 2.678 2.291 2.174.293-.462-.582-1.812-1.016-2.441-.416-.629-1.236-1.724-2.568-2.244-1.125-.424-2.951-.546-2.981-.247z'
          fill='#4d5252'
        />
        <path
          d='M105.344 30.734c-.031.36.975.599 1.619.853.645.231 1.9 1.133 2.488 1.812.578.662 1.914 2.577 2.205 2.094.281-.443-.545-1.738-.982-2.351-.42-.612-1.207-1.644-2.484-2.158-1.077-.418-2.815-.539-2.846-.25z'
          fill='#545959'
        />
        <path
          d='M105.492 30.771c-.031.346.934.578 1.553.825.619.223 1.822 1.095 2.385 1.747.553.638 1.842 2.476 2.121 2.015.27-.423-.506-1.664-.949-2.26-.424-.596-1.176-1.565-2.4-2.072-1.028-.413-2.681-.532-2.71-.255z'
          fill='#5c6060'
        />
        <path
          d='M105.641 30.808c-.031.332.895.558 1.484.795.594.216 1.746 1.057 2.283 1.683.527.613 1.77 2.375 2.037 1.937.258-.405-.467-1.59-.916-2.171-.43-.579-1.145-1.485-2.316-1.986-.979-.407-2.543-.524-2.572-.258z'
          fill='#626667'
        />
        <path
          d='M105.787 30.844c-.029.317.855.537 1.42.767.568.208 1.668 1.019 2.182 1.618.5.589 1.695 2.274 1.951 1.857.246-.385-.43-1.516-.883-2.08-.432-.563-1.113-1.406-2.232-1.9-.928-.401-2.409-.516-2.438-.262z'
          fill='#696c6c'
        />
        <path
          d='M105.936 30.88c-.031.304.814.516 1.355.738.541.202 1.588.981 2.076 1.555.477.564 1.621 2.172 1.867 1.778.232-.366-.391-1.442-.85-1.99-.438-.548-1.084-1.327-2.148-1.814-.879-.395-2.271-.509-2.3-.267z'
          fill='#6f7273'
        />
        <path
          d='M106.084 30.917c-.031.29.773.496 1.289.709.514.194 1.51.943 1.973 1.491.451.54 1.549 2.072 1.783 1.699.221-.347-.354-1.368-.818-1.9-.439-.53-1.051-1.248-2.062-1.728-.831-.39-2.136-.501-2.165-.271z'
          fill='#767778'
        />
        <path
          d='M106.232 30.955c-.031.275.732.474 1.223.68.488.188 1.432.905 1.871 1.426.424.516 1.475 1.971 1.697 1.62.209-.327-.314-1.294-.785-1.81-.443-.514-1.02-1.168-1.979-1.643-.78-.383-1.999-.494-2.027-.273z'
          fill='#7c7d7e'
        />
        <path
          d='M106.379 30.991c-.029.261.695.453 1.156.651.463.18 1.355.867 1.77 1.362.398.491 1.4 1.87 1.613 1.542.195-.309-.275-1.221-.752-1.72-.447-.498-.988-1.089-1.893-1.557-.732-.377-1.867-.487-1.894-.278z'
          fill='#848586'
        />
        <path
          d='M106.527 31.027c-.029.247.654.434 1.09.623.438.172 1.279.828 1.666 1.298.373.467 1.328 1.769 1.529 1.462.184-.289-.238-1.147-.719-1.629-.451-.481-.957-1.009-1.811-1.471-.68-.372-1.727-.479-1.755-.283z'
          fill='#8a8b8b'
        />
        <path
          d='M106.676 31.064c-.031.232.615.412 1.023.593.412.166 1.199.792 1.562 1.234.348.442 1.256 1.668 1.445 1.383.172-.27-.199-1.073-.686-1.54-.455-.464-.926-.931-1.727-1.385-.631-.365-1.592-.47-1.617-.285z'
          fill='#919394'
        />
        <path
          d='M156.453 134.182c2.137 3.102-.068 7.135 1.482 9.616 2.551 4.067 7.686 8.169 9.65 9.307 1.414.861 3.412 1.688 3.344 4.205-.104 2.86-1.516 3.619-2.309 4.412-1.586 1.585-9.582 5.549-14.891 9.168-6.688 4.584-8.996 6.617-11.168 8.789-3.377 3.378-6.584 4.516-11.719 4.516s-7.445-1.068-9.031-2.482c-1.584-1.378-3.342-4.895-3.17-10.133.137-5.205 1.793-9.962 2.584-18.062.346-3.481.311-7.789.311-11.615 0-4.826.068-8.927 1.104-9.548 1.861-1.172 2.275-1.241 4.48-1.241s3.205.139 3.93.862c.689.689.414 2.274.172 4.516-.207 2.24.861 2.964 1.93 3.895 1.068.896 2.035 2.033 5.412 2.31 3.379.241 4.619-.311 6.205-1.31 1.584-1 3.824-2.792 4.652-3.826.793-1 2.758-4.447 3.137-4.447.344-.002 2.62-.794 3.895 1.068z'
          fill='#9f6437'
        />
        <path
          d='M156.453 134.182c2.137 3.102-.068 7.135 1.482 9.616 2.551 4.067 7.686 8.169 9.65 9.307 1.414.861 3.412 1.688 3.344 4.205-.104 2.86-1.516 3.619-2.309 4.412-1.586 1.585-9.633 5.531-14.965 9.1-6.646 4.485-9.049 6.48-11.26 8.569-3.309 3.227-6.422 4.349-11.449 4.317-4.984-.022-7.236-1.044-8.814-2.45-1.58-1.372-3.318-4.771-3.166-9.852.098-5.194 1.771-9.9 2.516-17.846.318-3.532.195-7.805.16-11.669-.037-4.82-.035-8.916.998-9.537 1.861-1.17 2.275-1.241 4.48-1.241s3.207.141 3.93.862c.713.713.367 2.339.098 4.503-.301 2.229.699 3.105 1.816 4.12 1.121.986 2.15 2.142 5.521 2.405 3.463.23 4.795-.398 6.398-1.488 1.58-1.056 3.721-2.825 4.553-3.91.785-1.021 2.742-4.493 3.121-4.493.345 0 2.621-.792 3.896 1.07z'
          fill='#a36937'
        />
        <path
          d='M156.453 134.182c2.137 3.102-.068 7.135 1.482 9.616 2.551 4.067 7.686 8.169 9.65 9.307 1.414.861 3.412 1.688 3.344 4.205-.104 2.86-1.516 3.619-2.309 4.412-1.586 1.585-9.684 5.515-15.039 9.03-6.607 4.388-9.104 6.345-11.354 8.352-3.24 3.074-6.26 4.181-11.178 4.118-4.832-.045-7.029-1.02-8.6-2.419-1.572-1.365-3.291-4.646-3.16-9.568.059-5.185 1.75-9.841 2.447-17.63.293-3.585.078-7.821.01-11.723-.072-4.815-.139-8.906.893-9.527 1.861-1.168 2.275-1.241 4.48-1.241s3.209.142 3.93.862c.738.737.32 2.402.023 4.491-.396 2.22.535 3.247 1.703 4.346 1.172 1.076 2.266 2.248 5.633 2.499 3.547.221 4.969-.485 6.59-1.665 1.574-1.112 3.615-2.86 4.453-3.994.779-1.041 2.727-4.54 3.105-4.54.346-.001 2.622-.793 3.897 1.069z'
          fill='#a77038'
        />
        <path
          d='M156.453 134.182c2.137 3.102-.068 7.135 1.482 9.616 2.551 4.067 7.686 8.169 9.65 9.307 1.414.861 3.412 1.688 3.344 4.205-.104 2.86-1.516 3.619-2.309 4.412-1.586 1.585-9.732 5.497-15.113 8.961-6.568 4.289-9.156 6.209-11.447 8.133-3.17 2.923-6.096 4.014-10.906 3.921-4.682-.067-6.82-.996-8.385-2.389-1.564-1.358-3.266-4.522-3.156-9.286.02-5.174 1.73-9.779 2.379-17.414.268-3.637-.035-7.837-.139-11.776-.109-4.81-.242-8.896.787-9.517 1.861-1.167 2.275-1.241 4.48-1.241s3.211.144 3.93.862c.762.762.273 2.466-.051 4.479-.49 2.21.371 3.389 1.59 4.572 1.223 1.165 2.379 2.354 5.742 2.594 3.631.211 5.146-.573 6.783-1.842 1.57-1.17 3.51-2.896 4.354-4.08.771-1.061 2.711-4.586 3.09-4.586.344-.001 2.62-.793 3.895 1.069z'
          fill='#ae7639'
        />
        <path
          d='M156.453 134.182c2.137 3.102-.068 7.135 1.482 9.616 2.551 4.067 7.686 8.169 9.65 9.307 1.414.861 3.412 1.688 3.344 4.205-.104 2.86-1.516 3.619-2.309 4.412-1.586 1.585-9.783 5.479-15.188 8.892-6.527 4.192-9.209 6.073-11.539 7.914-3.104 2.771-5.936 3.847-10.637 3.723-4.529-.09-6.611-.972-8.17-2.357-1.557-1.351-3.24-4.398-3.15-9.003-.02-5.163 1.709-9.72 2.311-17.199.24-3.688-.152-7.852-.291-11.829-.145-4.806-.344-8.886.684-9.507 1.861-1.165 2.275-1.241 4.48-1.241s3.213.145 3.93.862c.785.786.227 2.53-.123 4.467-.588 2.199.205 3.529 1.475 4.798 1.273 1.255 2.494 2.461 5.852 2.688 3.717.2 5.322-.661 6.977-2.02 1.564-1.228 3.404-2.93 4.254-4.164.766-1.082 2.695-4.633 3.074-4.633.343-.001 2.619-.793 3.894 1.069z'
          fill='#b27d39'
        />
        <path
          d='M156.453 134.182c2.137 3.102-.068 7.135 1.482 9.616 2.551 4.067 7.686 8.169 9.65 9.307 1.414.861 3.412 1.688 3.344 4.205-.104 2.86-1.516 3.619-2.309 4.412-1.586 1.585-9.832 5.463-15.262 8.823-6.488 4.093-9.262 5.938-11.633 7.695-3.033 2.619-5.773 3.679-10.365 3.523-4.379-.111-6.402-.947-7.953-2.326-1.553-1.345-3.215-4.273-3.146-8.72-.061-5.153 1.689-9.66 2.24-16.984.217-3.739-.266-7.867-.439-11.883-.18-4.8-.447-8.875.578-9.496 1.861-1.163 2.275-1.241 4.48-1.241s3.215.147 3.93.862c.811.81.182 2.594-.199 4.455-.68 2.188.043 3.671 1.363 5.023 1.326 1.344 2.609 2.567 5.963 2.783 3.799.189 5.496-.749 7.168-2.197 1.561-1.284 3.301-2.965 4.154-4.248.758-1.103 2.68-4.68 3.059-4.68.344.001 2.62-.791 3.895 1.071z'
          fill='#b98439'
        />
        <path
          d='M156.453 134.182c2.137 3.102-.068 7.135 1.482 9.616 2.551 4.067 7.686 8.169 9.65 9.307 1.414.861 3.412 1.688 3.344 4.205-.104 2.86-1.516 3.619-2.309 4.412-1.586 1.585-9.883 5.445-15.336 8.754-6.449 3.995-9.316 5.801-11.725 7.477-2.965 2.468-5.611 3.512-10.096 3.326-4.227-.135-6.195-.924-7.738-2.295-1.545-1.338-3.189-4.15-3.141-8.438-.1-5.143 1.668-9.6 2.172-16.769.189-3.792-.383-7.883-.59-11.937-.217-4.794-.551-8.864.473-9.485 1.861-1.161 2.275-1.241 4.48-1.241s3.217.149 3.93.862c.834.834.135 2.657-.273 4.442-.775 2.179-.119 3.812 1.248 5.25 1.379 1.434 2.727 2.675 6.074 2.878 3.885.179 5.674-.838 7.361-2.375 1.555-1.341 3.195-2.998 4.053-4.332.752-1.124 2.666-4.727 3.045-4.727.345 0 2.621-.792 3.896 1.07z'
          fill='#bc893a'
        />
        <path
          d='M156.453 134.182c2.137 3.102-.068 7.135 1.482 9.616 2.551 4.067 7.686 8.169 9.65 9.307 1.414.861 3.412 1.688 3.344 4.205-.104 2.86-1.516 3.619-2.309 4.412-1.586 1.585-9.934 5.428-15.41 8.686-6.408 3.896-9.369 5.664-11.818 7.257-2.895 2.316-5.449 3.345-9.824 3.128-4.074-.156-5.986-.899-7.523-2.265-1.537-1.331-3.162-4.025-3.135-8.155-.141-5.132 1.648-9.539 2.104-16.553.162-3.843-.5-7.898-.74-11.989-.254-4.79-.654-8.854.367-9.476 1.861-1.16 2.275-1.241 4.48-1.241s3.217.15 3.93.862c.857.858.088 2.721-.348 4.431-.869 2.168-.283 3.954 1.135 5.476 1.43 1.523 2.842 2.781 6.184 2.973 3.969.169 5.85-.926 7.555-2.553 1.551-1.397 3.092-3.033 3.955-4.417.744-1.144 2.648-4.772 3.027-4.772.343-.002 2.619-.794 3.894 1.068z'
          fill='#c08f3a'
        />
        <path
          d='M156.453 134.182c2.137 3.102-.068 7.135 1.482 9.616 2.551 4.067 7.686 8.169 9.65 9.307 1.414.861 3.412 1.688 3.344 4.205-.104 2.86-1.516 3.619-2.309 4.412-1.586 1.585-9.982 5.411-15.484 8.616-6.369 3.798-9.422 5.529-11.912 7.038-2.826 2.165-5.287 3.179-9.553 2.931-3.924-.18-5.777-.876-7.309-2.234-1.529-1.323-3.137-3.901-3.129-7.872-.18-5.122 1.627-9.479 2.033-16.338.139-3.895-.613-7.914-.889-12.043-.289-4.784-.758-8.844.262-9.465 1.861-1.158 2.275-1.241 4.48-1.241s3.219.152 3.93.862c.883.882.041 2.785-.42 4.418-.967 2.158-.449 4.096 1.02 5.701 1.482 1.613 2.957 2.889 6.293 3.068 4.055.158 6.025-1.014 7.75-2.73 1.543-1.454 2.984-3.067 3.852-4.501.738-1.165 2.635-4.819 3.014-4.819.344-.001 2.62-.793 3.895 1.069z'
          fill='#c3943a'
        />
        <path
          d='M156.453 134.182c2.137 3.102-.068 7.135 1.482 9.616 2.551 4.067 7.686 8.169 9.65 9.307 1.414.861 3.412 1.688 3.344 4.205-.104 2.86-1.516 3.619-2.309 4.412-1.586 1.585-10.033 5.394-15.559 8.547-6.328 3.701-9.477 5.394-12.004 6.82-2.758 2.013-5.125 3.01-9.283 2.731-3.771-.202-5.568-.852-7.092-2.202-1.525-1.316-3.111-3.778-3.125-7.59-.219-5.112 1.605-9.419 1.965-16.123.111-3.946-.73-7.929-1.041-12.097-.324-4.778-.861-8.833.158-9.454 1.861-1.156 2.275-1.241 4.48-1.241s3.221.153 3.93.862c.906.906-.006 2.849-.494 4.406-1.061 2.147-.613 4.236.906 5.927 1.533 1.703 3.072 2.995 6.404 3.163 4.137.148 6.199-1.102 7.941-2.907 1.539-1.512 2.879-3.103 3.754-4.587.73-1.186 2.617-4.865 2.996-4.865.346 0 2.622-.792 3.897 1.07z'
          fill='#c89c3a'
        />
        <path
          d='M156.453 134.182c2.137 3.102-.068 7.135 1.482 9.616 2.551 4.067 7.686 8.169 9.65 9.307 1.414.861 3.412 1.688 3.344 4.205-.104 2.86-1.516 3.619-2.309 4.412-1.586 1.585-10.082 5.376-15.631 8.479-6.291 3.602-9.531 5.256-12.1 6.601-2.688 1.861-4.963 2.844-9.014 2.533-3.619-.224-5.359-.827-6.875-2.171-1.518-1.311-3.086-3.654-3.119-7.308-.26-5.101 1.586-9.358 1.895-15.907.088-3.998-.844-7.944-1.189-12.149-.361-4.773-.965-8.823.053-9.444 1.861-1.154 2.275-1.241 4.48-1.241s3.223.155 3.93.862c.93.931-.053 2.912-.568 4.395-1.156 2.137-.775 4.377.793 6.152 1.586 1.792 3.188 3.103 6.514 3.258 4.223.137 6.377-1.189 8.135-3.085 1.533-1.568 2.773-3.138 3.654-4.671.723-1.206 2.602-4.912 2.98-4.912.344-.002 2.62-.794 3.895 1.068z'
          fill='#cba03a'
        />
        <path
          d='M156.453 134.182c2.137 3.102-.068 7.135 1.482 9.616 2.551 4.067 7.686 8.169 9.65 9.307 1.414.861 3.412 1.688 3.344 4.205-.104 2.86-1.516 3.619-2.309 4.412-1.586 1.585-10.133 5.359-15.707 8.409-6.25 3.504-9.582 5.121-12.189 6.382-2.621 1.71-4.803 2.677-8.744 2.336-3.467-.247-5.15-.804-6.66-2.141-1.51-1.303-3.059-3.529-3.113-7.024-.299-5.092 1.564-9.298 1.826-15.692.061-4.05-.959-7.96-1.34-12.203-.398-4.769-1.068-8.812-.053-9.434 1.861-1.152 2.275-1.241 4.48-1.241s3.225.157 3.93.862c.955.954-.098 2.977-.643 4.383-1.25 2.127-.939 4.519.68 6.378 1.637 1.882 3.303 3.209 6.623 3.352 4.307.128 6.553-1.276 8.328-3.262 1.529-1.625 2.67-3.171 3.553-4.755.717-1.228 2.588-4.959 2.967-4.959.344-.001 2.62-.793 3.895 1.069z'
          fill='#cea53b'
        />
        <path
          d='M156.453 134.182c2.137 3.102-.068 7.135 1.482 9.616 2.551 4.067 7.686 8.169 9.65 9.307 1.414.861 3.412 1.688 3.344 4.205-.104 2.86-1.516 3.619-2.309 4.412-1.586 1.585-10.182 5.342-15.781 8.341-6.209 3.405-9.637 4.983-12.283 6.162-2.551 1.559-4.639 2.51-8.473 2.138-3.314-.27-4.941-.779-6.445-2.109-1.502-1.297-3.033-3.405-3.107-6.742-.338-5.08 1.543-9.237 1.758-15.477.033-4.101-1.076-7.975-1.49-12.256-.434-4.764-1.172-8.803-.158-9.424 1.861-1.151 2.275-1.241 4.48-1.241s3.227.159 3.93.862c.979.979-.145 3.04-.717 4.371-1.346 2.115-1.104 4.659.564 6.604 1.689 1.972 3.42 3.315 6.736 3.446 4.391.117 6.727-1.364 8.52-3.439 1.523-1.683 2.564-3.206 3.453-4.839.711-1.248 2.572-5.006 2.951-5.006.344-.001 2.62-.793 3.895 1.069z'
          fill='#d2ab3b'
        />
        <path
          d='M156.453 134.182c2.137 3.102-.068 7.135 1.482 9.616 2.551 4.067 7.686 8.169 9.65 9.307 1.414.861 3.412 1.688 3.344 4.205-.104 2.86-1.516 3.619-2.309 4.412-1.586 1.585-10.232 5.324-15.854 8.271-6.172 3.308-9.691 4.848-12.379 5.944-2.48 1.406-4.477 2.342-8.201 1.938-3.164-.291-4.732-.755-6.229-2.078-1.496-1.289-3.008-3.281-3.105-6.459-.377-5.07 1.523-9.178 1.689-15.262.008-4.152-1.191-7.99-1.639-12.31-.471-4.758-1.275-8.792-.264-9.413 1.861-1.149 2.275-1.241 4.48-1.241s3.229.161 3.93.862c1.002 1.003-.191 3.104-.791 4.358-1.439 2.106-1.268 4.802.451 6.829 1.74 2.062 3.535 3.424 6.846 3.542 4.475.107 6.904-1.453 8.713-3.617 1.518-1.739 2.459-3.24 3.354-4.924.703-1.269 2.557-5.052 2.936-5.052.345.002 2.621-.79 3.896 1.072z'
          fill='#d7b23b'
        />
        <path
          d='M156.453 134.182c2.137 3.102-.068 7.135 1.482 9.616 2.551 4.067 7.686 8.169 9.65 9.307 1.414.861 3.412 1.688 3.344 4.205-.104 2.86-1.516 3.619-2.309 4.412-1.586 1.585-10.283 5.308-15.928 8.203-6.133 3.209-9.744 4.712-12.471 5.725-2.412 1.255-4.316 2.175-7.932 1.74-3.012-.313-4.525-.73-6.014-2.047-1.49-1.282-2.982-3.157-3.1-6.177-.416-5.06 1.502-9.116 1.621-15.045-.018-4.205-1.307-8.007-1.789-12.364-.508-4.753-1.379-8.781-.369-9.402 1.861-1.148 2.275-1.241 4.48-1.241s3.23.162 3.93.862c1.027 1.027-.238 3.168-.865 4.346-1.533 2.096-1.43 4.943.338 7.057 1.791 2.15 3.65 3.529 6.955 3.636 4.561.097 7.08-1.54 8.906-3.795 1.514-1.796 2.355-3.274 3.254-5.008.697-1.289 2.541-5.099 2.92-5.099.346-.001 2.622-.793 3.897 1.069z'
          fill='#dab73b'
        />
        <path
          d='M156.453 134.182c2.137 3.102-.068 7.135 1.482 9.616 2.551 4.067 7.686 8.169 9.65 9.307 1.414.861 3.412 1.688 3.344 4.205-.104 2.86-1.516 3.619-2.309 4.412-1.586 1.585-10.332 5.29-16.002 8.134-6.092 3.11-9.797 4.576-12.564 5.506-2.344 1.104-4.152 2.008-7.66 1.543-2.859-.336-4.316-.707-5.799-2.017-1.482-1.275-2.955-3.033-3.094-5.895-.457-5.049 1.482-9.056 1.551-14.829-.043-4.257-1.422-8.022-1.938-12.417-.543-4.748-1.482-8.771-.475-9.393 1.861-1.146 2.275-1.241 4.48-1.241s3.232.164 3.93.862c1.051 1.051-.285 3.23-.939 4.334-1.629 2.086-1.594 5.084.225 7.281 1.844 2.24 3.766 3.637 7.064 3.731 4.645.086 7.256-1.629 9.1-3.973 1.51-1.853 2.25-3.309 3.154-5.093.689-1.31 2.525-5.145 2.904-5.145.345.002 2.621-.79 3.896 1.072z'
          fill='#ddbc39'
        />
        <path
          d='M156.453 134.182c2.137 3.102-.068 7.135 1.482 9.616 2.551 4.067 7.686 8.169 9.65 9.307 1.414.861 3.412 1.688 3.344 4.205-.104 2.86-1.516 3.619-2.309 4.412-1.586 1.585-10.383 5.273-16.076 8.064-6.053 3.013-9.852 4.439-12.656 5.288-2.275.951-3.992 1.84-7.391 1.344-2.709-.358-4.107-.683-5.584-1.985-1.475-1.269-2.93-2.909-3.088-5.611-.496-5.039 1.461-8.996 1.482-14.614-.07-4.309-1.537-8.037-2.09-12.471-.578-4.742-1.584-8.761-.578-9.382 1.861-1.144 2.275-1.241 4.48-1.241s3.234.166 3.93.862c1.076 1.075-.33 3.295-1.014 4.322-1.723 2.074-1.758 5.225.111 7.507 1.895 2.33 3.881 3.743 7.176 3.826 4.729.075 7.432-1.717 9.291-4.149 1.504-1.91 2.145-3.344 3.055-5.178.682-1.33 2.51-5.191 2.889-5.191.345-.001 2.621-.793 3.896 1.069z'
          fill='#e1c239'
        />
        <path
          d='M156.453 134.182c2.137 3.102-.068 7.135 1.482 9.616 2.551 4.067 7.686 8.169 9.65 9.307 1.414.861 3.412 1.688 3.344 4.205-.104 2.86-1.516 3.619-2.309 4.412-1.586 1.585-10.432 5.256-16.15 7.996-6.014 2.914-9.904 4.303-12.75 5.068-2.205.8-3.83 1.674-7.119 1.146-2.557-.381-3.9-.659-5.369-1.955-1.467-1.261-2.902-2.785-3.082-5.328-.537-5.029 1.439-8.936 1.414-14.399-.096-4.36-1.654-8.053-2.24-12.523-.615-4.737-1.688-8.751-.684-9.372 1.861-1.143 2.275-1.241 4.48-1.241s3.234.168 3.93.862c1.1 1.1-.377 3.358-1.088 4.311-1.818 2.064-1.922 5.366-.004 7.732 1.947 2.42 3.998 3.85 7.287 3.921 4.814.065 7.607-1.805 9.486-4.327 1.496-1.967 2.037-3.378 2.953-5.262.676-1.352 2.494-5.238 2.873-5.238.345-.001 2.621-.793 3.896 1.069z'
          fill='#e2c539'
        />
        <path
          d='M156.453 134.182c2.137 3.102-.068 7.135 1.482 9.616 2.551 4.067 7.686 8.169 9.65 9.307 1.414.861 3.412 1.688 3.344 4.205-.104 2.86-1.516 3.619-2.309 4.412-1.586 1.585-10.482 5.238-16.225 7.927-5.975 2.816-9.957 4.167-12.844 4.85-2.137.648-3.666 1.506-6.848.948-2.406-.403-3.691-.634-5.152-1.924-1.463-1.254-2.879-2.66-3.078-5.046-.576-5.018 1.42-8.875 1.344-14.184-.121-4.411-1.768-8.068-2.389-12.577-.65-4.732-1.791-8.74-.789-9.361 1.861-1.141 2.275-1.241 4.48-1.241s3.236.17 3.93.862c1.123 1.124-.424 3.423-1.162 4.298-1.912 2.055-2.084 5.508-.117 7.959 2 2.509 4.111 3.957 7.396 4.015 4.898.056 7.783-1.892 9.68-4.505 1.492-2.022 1.934-3.412 2.854-5.345.668-1.372 2.479-5.285 2.857-5.285.345-.001 2.621-.793 3.896 1.069z'
          fill='#e6cb39'
        />
        <path
          d='M156.453 134.182c2.137 3.102-.068 7.135 1.482 9.616 2.551 4.067 7.686 8.169 9.65 9.307 1.414.861 3.412 1.688 3.344 4.205-.104 2.86-1.516 3.619-2.309 4.412-1.586 1.585-10.533 5.222-16.299 7.858-5.934 2.718-10.012 4.03-12.936 4.63-2.068.497-3.506 1.34-6.578.75-2.254-.426-3.484-.609-4.938-1.893-1.455-1.247-2.854-2.537-3.072-4.763-.615-5.009 1.398-8.815 1.273-13.969-.145-4.463-1.883-8.084-2.537-12.63-.688-4.728-1.896-8.73-.895-9.352 1.861-1.14 2.275-1.241 4.48-1.241s3.238.171 3.93.862c1.148 1.147-.471 3.486-1.236 4.286-2.008 2.043-2.248 5.648-.23 8.185 2.051 2.599 4.227 4.063 7.508 4.109 4.982.045 7.959-1.979 9.871-4.682 1.486-2.08 1.828-3.447 2.754-5.431.662-1.393 2.463-5.331 2.842-5.331.345.002 2.621-.79 3.896 1.072z'
          fill='#e8ce38'
        />
        <path
          d='M156.453 134.182c2.137 3.102-.068 7.135 1.482 9.616 2.551 4.067 7.686 8.169 9.65 9.307 1.414.861 3.412 1.688 3.344 4.205-.104 2.86-1.516 3.619-2.309 4.412-1.586 1.585-10.582 5.204-16.373 7.789-5.895 2.619-10.064 3.895-13.029 4.412-1.998.345-3.342 1.172-6.307.551-2.102-.447-3.275-.585-4.723-1.86-1.447-1.241-2.826-2.413-3.066-4.481-.656-4.998 1.377-8.755 1.205-13.752-.172-4.516-1.998-8.101-2.688-12.685-.725-4.722-2-8.72-1-9.341 1.861-1.138 2.275-1.241 4.48-1.241s3.24.173 3.93.862c1.172 1.172-.518 3.55-1.311 4.274-2.102 2.033-2.412 5.79-.344 8.409 2.102 2.689 4.342 4.171 7.617 4.205 5.066.035 8.135-2.067 10.064-4.859 1.482-2.137 1.723-3.481 2.654-5.515.654-1.414 2.447-5.378 2.826-5.378.347 0 2.623-.792 3.898 1.07z'
          fill='#ecd238'
        />
        <path
          d='M124.203 140.517c-.338-.123-1.881-7.28-1.049-7.774 1.604-.956 2.037-1.11 4.01-1.11 1.975 0 2.9.154 3.518.771 1.018 1.018-.557 3.208-1.172 3.825-1.789 1.758-4.967 4.411-5.307 4.288z'
          fill='#ecd238'
        />
        <path
          d='M124.312 140.288c-.367-.156-1.963-7.046-1.133-7.537 1.6-.952 2.049-1.058 3.984-1.058 1.975 0 2.859.129 3.484.767 1.01 1.022-.535 3.146-1.146 3.759-1.784 1.754-4.825 4.219-5.189 4.069z'
          fill='#ecd238'
        />
        <path
          d='M124.422 140.06c-.396-.188-2.045-6.812-1.219-7.299 1.6-.95 2.062-1.006 3.959-1.006 1.975 0 2.822.103 3.455.763.998 1.027-.516 3.085-1.123 3.692-1.779 1.749-4.685 4.025-5.072 3.85z'
          fill='#ecd438'
        />
        <path
          d='M124.531 139.831c-.426-.22-2.127-6.576-1.303-7.062 1.596-.946 2.074-.952 3.932-.952 1.975 0 2.783.075 3.424.757.99 1.032-.494 3.023-1.098 3.626-1.775 1.745-4.545 3.834-4.955 3.631z'
          fill='#ecd438'
        />
        <path
          d='M124.643 139.603c-.457-.253-2.209-6.342-1.389-6.823 1.592-.943 2.084-.9 3.904-.9 1.975 0 2.746.049 3.395.753.98 1.036-.477 2.961-1.074 3.56-1.772 1.738-4.405 3.638-4.836 3.41z'
          fill='#ecd438'
        />
        <path
          d='M124.752 139.375c-.486-.285-2.291-6.108-1.475-6.587 1.59-.94 2.098-.848 3.881-.848 1.973 0 2.705.023 3.361.748.971 1.041-.455 2.9-1.049 3.493-1.765 1.736-4.263 3.449-4.718 3.194z'
          fill='#ecd438'
        />
        <path
          d='M124.861 139.146c-.516-.317-2.373-5.873-1.559-6.349 1.586-.938 2.109-.796 3.854-.796 1.973 0 2.668-.002 3.33.744.963 1.045-.434 2.837-1.023 3.427-1.762 1.731-4.125 3.256-4.602 2.974z'
          fill='#ecd439'
        />
        <path
          d='M124.971 138.918c-.545-.35-2.455-5.639-1.643-6.111 1.582-.935 2.121-.743 3.826-.743 1.975 0 2.629-.029 3.301.739.953 1.051-.416 2.776-1 3.36-1.756 1.727-3.984 3.063-4.484 2.755z'
          fill='#ecd439'
        />
        <path
          d='M125.08 138.69c-.574-.383-2.537-5.405-1.729-5.874 1.58-.931 2.135-.691 3.801-.691 1.975 0 2.592-.055 3.27.734.945 1.056-.395 2.715-.975 3.295-1.752 1.722-3.843 2.869-4.367 2.536z'
          fill='#ecd439'
        />
        <path
          d='M125.189 138.462c-.604-.415-2.617-5.17-1.812-5.637 1.576-.928 2.146-.639 3.773-.639 1.975 0 2.553-.081 3.24.73.934 1.06-.375 2.653-.951 3.229-1.748 1.716-3.703 2.676-4.25 2.317z'
          fill='#ecd439'
        />
        <path
          d='M125.299 138.233c-.633-.447-2.699-4.936-1.898-5.397 1.574-.926 2.16-.587 3.75-.587 1.973 0 2.514-.107 3.207.725.926 1.064-.355 2.591-.926 3.162-1.743 1.712-3.563 2.483-4.133 2.097z'
          fill='#edd63b'
        />
        <path
          d='M125.408 138.005c-.662-.479-2.781-4.701-1.982-5.16 1.57-.923 2.172-.534 3.723-.534 1.975 0 2.475-.134 3.176.721.918 1.068-.334 2.529-.9 3.096-1.739 1.706-3.423 2.289-4.017 1.877z'
          fill='#edd63b'
        />
        <path
          d='M125.518 137.777c-.691-.513-2.863-4.467-2.066-4.924 1.566-.919 2.184-.481 3.695-.481 1.975 0 2.438-.16 3.146.716.906 1.073-.314 2.468-.877 3.029-1.732 1.702-3.281 2.098-3.898 1.66z'
          fill='#edd63b'
        />
        <path
          d='M125.627 137.549c-.721-.545-2.945-4.232-2.152-4.686 1.564-.917 2.197-.43 3.67-.43 1.975 0 2.398-.187 3.115.711.898 1.078-.295 2.407-.85 2.964-1.73 1.698-3.142 1.905-3.783 1.441z'
          fill='#edd63b'
        />
        <path
          d='M125.736 137.32c-.75-.576-3.025-3.998-2.236-4.448 1.561-.913 2.209-.376 3.643-.376 1.975 0 2.359-.213 3.086.706.889 1.082-.275 2.345-.828 2.896-1.723 1.694-3.001 1.714-3.665 1.222z'
          fill='#edd63d'
        />
        <path
          d='M125.846 137.092c-.779-.608-3.107-3.763-2.32-4.21 1.559-.911 2.221-.324 3.617-.324 1.973 0 2.32-.238 3.053.702.879 1.087-.254 2.282-.801 2.83-1.721 1.688-2.862 1.518-3.549 1.002z'
          fill='#edd63d'
        />
        <path
          d='M125.955 136.864c-.807-.643-3.189-3.529-2.406-3.974 1.557-.907 2.234-.271 3.592-.271 1.975 0 2.283-.265 3.023.697.869 1.092-.234 2.222-.779 2.764-1.715 1.685-2.719 1.327-3.43.784z'
          fill='#edd63d'
        />
        <path
          d='M126.064 136.636c-.836-.674-3.271-3.295-2.49-3.735 1.553-.904 2.246-.22 3.564-.22 1.975 0 2.244-.291 2.992.693.861 1.096-.215 2.159-.752 2.697-1.71 1.68-2.579 1.134-3.314.565z'
          fill='#edd640'
        />
        <path
          d='M126.174 136.407c-.865-.706-3.352-3.061-2.574-3.498 1.549-.9 2.256-.167 3.537-.167 1.975 0 2.205-.317 2.961.688.852 1.102-.193 2.099-.727 2.632-1.707 1.675-2.441.941-3.197.345z'
          fill='#edd640'
        />
        <path
          d='M126.283 136.179c-.895-.738-3.434-2.825-2.66-3.26 1.547-.898 2.271-.115 3.514-.115 1.973 0 2.166-.343 2.93.684.842 1.105-.174 2.036-.703 2.565-1.702 1.671-2.302.748-3.081.126z'
          fill='#edd642'
        />
        <path
          d='M126.393 135.951c-.924-.771-3.516-2.592-2.744-3.023 1.543-.895 2.281-.062 3.486-.062 1.973 0 2.127-.37 2.898.679.834 1.11-.154 1.974-.678 2.498-1.697 1.666-2.16.556-2.962-.092z'
          fill='#edd642'
        />
        <path
          d='M156.041 134.586c1.791 2.613-.029 6.108 1.264 8.193-3.26-2.995-4.758-4.229-9.252.176 1.234-1.791 1.498-2.995 2.262-4.698.529-1.175 2.084-4.581 2.408-4.581.293 0 2.261-.647 3.318.91z'
          fill='#ecd238'
        />
        <path
          d='M156.023 134.604c1.748 2.555.016 6.032 1.234 8.006-3.193-2.919-4.658-4.148-9.053.155 1.158-1.689 1.447-2.919 2.205-4.593.537-1.178 2.031-4.452 2.369-4.457.302-.004 2.212-.631 3.245.889z'
          fill='#ecd238'
        />
        <path
          d='M156.004 134.622c1.707 2.494.062 5.955 1.205 7.817-3.125-2.842-4.557-4.067-8.854.134 1.086-1.587 1.4-2.841 2.148-4.486.547-1.182 1.979-4.324 2.334-4.333.308-.007 2.157-.615 3.167.868z'
          fill='#ecd438'
        />
        <path
          d='M155.984 134.64c1.666 2.434.109 5.88 1.178 7.631-3.059-2.766-4.457-3.988-8.654.111 1.012-1.484 1.35-2.764 2.092-4.381.555-1.185 1.924-4.194 2.295-4.209.314-.01 2.107-.6 3.089.848z'
          fill='#ecd438'
        />
        <path
          d='M155.967 134.657c1.623 2.375.154 5.805 1.146 7.443-2.988-2.689-4.355-3.908-8.453.09.936-1.382 1.301-2.686 2.035-4.274.562-1.188 1.871-4.066 2.256-4.085.322-.013 2.055-.584 3.016.826z'
          fill='#ecd438'
        />
        <path
          d='M155.949 134.676c1.582 2.314.199 5.728 1.117 7.255-2.922-2.612-4.256-3.828-8.256.068.861-1.279 1.252-2.608 1.979-4.168.572-1.191 1.818-3.938 2.221-3.962.328-.015 2.002-.567 2.939.807z'
          fill='#ecd438'
        />
        <path
          d='M155.93 134.693c1.539 2.255.246 5.651 1.09 7.068-2.855-2.536-4.158-3.748-8.057.046.787-1.177 1.201-2.531 1.922-4.062.58-1.194 1.766-3.808 2.182-3.837.335-.018 1.951-.552 2.863.785z'
          fill='#ecd439'
        />
        <path
          d='M155.91 134.712c1.5 2.194.293 5.574 1.061 6.88-2.787-2.46-4.057-3.668-7.855.025.711-1.075 1.15-2.454 1.865-3.957.588-1.198 1.711-3.68 2.145-3.713.339-.022 1.897-.537 2.784.765z'
          fill='#ecd439'
        />
        <path
          d='M155.893 134.729c1.457 2.135.338 5.499 1.031 6.692-2.721-2.383-3.955-3.588-7.658.004.639-.973 1.102-2.377 1.809-3.851.598-1.201 1.658-3.551 2.107-3.589.347-.024 1.847-.52 2.711.744z'
          fill='#ecd439'
        />
        <path
          d='M155.873 134.747c1.416 2.076.385 5.423 1.002 6.505-2.65-2.306-3.855-3.507-7.457-.018.562-.87 1.053-2.299 1.752-3.744.605-1.204 1.605-3.423 2.07-3.466.354-.027 1.795-.505 2.633.723z'
          fill='#ecd439'
        />
        <path
          d='M155.855 134.765c1.373 2.016.432 5.347.973 6.318-2.584-2.23-3.756-3.428-7.26-.039.49-.77 1.004-2.223 1.695-3.639.615-1.209 1.553-3.293 2.033-3.342.362-.031 1.743-.49 2.559.702z'
          fill='#edd63b'
        />
        <path
          d='M155.836 134.783c1.332 1.955.477 5.271.945 6.13-2.518-2.153-3.656-3.348-7.061-.061.416-.667.953-2.145 1.639-3.533.623-1.211 1.498-3.164 1.994-3.217.37-.035 1.692-.474 2.483.681z'
          fill='#edd63b'
        />
        <path
          d='M155.818 134.801c1.289 1.896.523 5.194.914 5.942-2.449-2.076-3.555-3.268-6.859-.082.34-.564.904-2.067 1.582-3.427.631-1.215 1.445-3.035 1.955-3.093.377-.037 1.641-.458 2.408.66z'
          fill='#edd63b'
        />
        <path
          d='M155.799 134.819c1.248 1.836.57 5.117.887 5.754-2.383-2-3.455-3.187-6.662-.104.268-.461.855-1.99 1.527-3.32.639-1.218 1.391-2.907 1.918-2.969.383-.041 1.588-.443 2.33.639z'
          fill='#edd63b'
        />
        <path
          d='M155.779 134.838c1.207 1.774.617 5.04.859 5.565-2.314-1.924-3.355-3.106-6.463-.125.191-.359.807-1.912 1.469-3.214.648-1.222 1.338-2.779 1.881-2.845.389-.044 1.537-.428 2.254.619z'
          fill='#edd63d'
        />
        <path
          d='M155.762 134.855c1.164 1.716.662 4.965.828 5.379-2.246-1.848-3.254-3.026-6.264-.147.117-.257.758-1.835 1.414-3.108.656-1.225 1.285-2.649 1.844-2.721.395-.048 1.484-.412 2.178.597z'
          fill='#edd63d'
        />
        <path
          d='M155.744 134.873c1.121 1.656.707 4.889.799 5.191-2.18-1.771-3.154-2.946-6.064-.168.043-.155.707-1.759 1.355-3.003.666-1.229 1.232-2.521 1.807-2.597.402-.051 1.431-.396 2.103.577z'
          fill='#edd63d'
        />
        <path
          d='M155.725 134.892c1.08 1.596.754 4.812.771 5.003-2.113-1.693-3.055-2.866-5.865-.19-.031-.052.656-1.68 1.299-2.896.674-1.23 1.18-2.391 1.768-2.472.409-.056 1.38-.383 2.027.555z'
          fill='#edd640'
        />
        <path
          d='M155.705 134.909c1.039 1.536.801 4.736.742 4.815-2.043-1.617-2.953-2.786-5.666-.212-.105.051.609-1.602 1.242-2.789.686-1.235 1.127-2.264 1.732-2.35.417-.056 1.329-.364 1.95.536z'
          fill='#edd640'
        />
        <path
          d='M155.688 134.927c.996 1.477.846 4.66.713 4.629-1.977-1.541-2.854-2.707-5.467-.233-.18.152.559-1.525 1.186-2.685.693-1.238 1.072-2.134 1.693-2.225.423-.06 1.277-.351 1.875.514z'
          fill='#edd642'
        />
        <path
          d='M155.668 134.945c.955 1.416.893 4.584.686 4.44-1.91-1.465-2.754-2.626-5.268-.255-.256.255.508-1.448 1.129-2.578.699-1.241 1.018-2.006 1.656-2.101.43-.063 1.225-.334 1.797.494z'
          fill='#edd642'
        />
        <path
          d='M157.521 144.212c2.861 3.757 8.17 7.789 10.064 8.893 1.379.792 3.379 1.723 3.344 4.205-.068 2.826-1.516 3.619-2.309 4.412-1.586 1.585-10.617 5.238-16.373 7.789-5.859 2.585-10.1 3.93-13.029 4.412-1.965.31-3.377 1.137-6.307.551-2.068-.413-3.309-.62-4.723-1.86-1.412-1.207-2.859-2.482-3.066-4.481-.588-4.963 1.758-8.1 4.031-12.408 1.861-3.446 5.619-4.136 7.963-3.826 8.41 1.138 7.857-4.136 10.168-6.445 2.138-2.139 8.239-3.862 10.237-1.242z'
          fill='#ecd238'
        />
        <path
          d='M157.498 144.247c2.854 3.747 8.148 7.771 10.041 8.871 1.375.791 3.369 1.72 3.334 4.195-.068 2.82-1.514 3.61-2.303 4.401-1.582 1.582-10.695 5.263-16.393 7.702-5.867 2.478-9.885 3.77-12.83 4.289-1.951.327-3.426 1.146-6.346.562-2.061-.409-3.24-.589-4.648-1.824-1.406-1.201-2.756-2.375-2.961-4.365-.578-4.949 1.611-7.974 3.852-12.278 1.861-3.53 5.645-4.038 7.979-3.724 8.383 1.156 7.758-4.289 10.061-6.593 2.132-2.13 8.218-3.85 10.214-1.236z'
          fill='#ecd238'
        />
        <path
          d='M157.473 144.281c2.848 3.739 8.131 7.753 10.018 8.851 1.371.789 3.361 1.716 3.326 4.186-.068 2.813-1.508 3.603-2.297 4.391-1.578 1.579-10.773 5.287-16.41 7.616-5.879 2.369-9.672 3.608-12.633 4.165-1.939.344-3.475 1.154-6.387.574-2.053-.405-3.17-.559-4.572-1.787-1.4-1.197-2.65-2.27-2.854-4.249-.572-4.937 1.465-7.849 3.67-12.149 1.861-3.615 5.67-3.939 7.994-3.621 8.357 1.175 7.656-4.441 9.955-6.74 2.127-2.128 8.199-3.844 10.19-1.237z'
          fill='#ecd438'
        />
        <path
          d='M157.447 144.316c2.842 3.73 8.111 7.734 9.994 8.83 1.369.787 3.354 1.712 3.32 4.176-.07 2.806-1.506 3.593-2.293 4.38-1.576 1.575-10.852 5.311-16.43 7.528-5.887 2.262-9.457 3.448-12.436 4.041-1.926.362-3.521 1.164-6.426.587-2.043-.401-3.098-.527-4.496-1.75-1.393-1.192-2.545-2.163-2.746-4.133-.566-4.924 1.316-7.724 3.488-12.021 1.863-3.698 5.697-3.841 8.008-3.519 8.332 1.193 7.559-4.595 9.852-6.888 2.122-2.12 8.181-3.832 10.165-1.231z'
          fill='#ecd438'
        />
        <path
          d='M157.422 144.352c2.834 3.722 8.094 7.716 9.971 8.81 1.365.785 3.346 1.707 3.312 4.165-.068 2.8-1.504 3.585-2.289 4.37-1.57 1.571-10.93 5.335-16.447 7.441-5.896 2.152-9.242 3.287-12.236 3.917-1.914.38-3.57 1.172-6.465.599-2.035-.396-3.027-.496-4.422-1.713-1.387-1.188-2.439-2.057-2.639-4.018-.561-4.91 1.17-7.597 3.307-11.892 1.861-3.782 5.723-3.742 8.023-3.415 8.305 1.212 7.457-4.748 9.746-7.035 2.115-2.117 8.16-3.824 10.139-1.229z'
          fill='#ecd438'
        />
        <path
          d='M157.398 144.387c2.826 3.713 8.072 7.698 9.945 8.788 1.363.783 3.338 1.703 3.305 4.156-.068 2.792-1.498 3.576-2.281 4.359-1.568 1.567-11.01 5.358-16.469 7.354-5.906 2.046-9.027 3.127-12.039 3.793-1.898.396-3.617 1.182-6.504.611-2.027-.393-2.957-.466-4.346-1.676-1.381-1.184-2.334-1.951-2.531-3.902-.555-4.896 1.023-7.471 3.123-11.762 1.865-3.866 5.75-3.644 8.041-3.313 8.279 1.231 7.355-4.899 9.639-7.183 2.112-2.111 8.141-3.814 10.117-1.225z'
          fill='#ecd438'
        />
        <path
          d='M157.373 144.422c2.82 3.704 8.055 7.68 9.922 8.767 1.359.781 3.33 1.699 3.297 4.146-.068 2.786-1.496 3.567-2.277 4.35-1.562 1.563-11.086 5.382-16.486 7.266-5.914 1.938-8.812 2.968-11.84 3.67-1.887.414-3.664 1.19-6.543.623-2.02-.389-2.889-.435-4.271-1.639-1.373-1.18-2.23-1.846-2.424-3.786-.547-4.885.877-7.346 2.941-11.633 1.865-3.95 5.775-3.546 8.057-3.211 8.252 1.25 7.256-5.053 9.531-7.33 2.107-2.107 8.122-3.806 10.093-1.223z'
          fill='#ecd439'
        />
        <path
          d='M157.348 144.456c2.814 3.695 8.035 7.662 9.9 8.747 1.355.78 3.322 1.694 3.287 4.136-.066 2.78-1.49 3.56-2.271 4.34-1.559 1.559-11.164 5.405-16.504 7.178-5.926 1.83-8.6 2.808-11.643 3.547-1.875.432-3.713 1.199-6.584.634-2.01-.384-2.816-.403-4.195-1.601-1.367-1.175-2.125-1.739-2.316-3.671-.541-4.87.729-7.22 2.76-11.503 1.865-4.034 5.801-3.447 8.072-3.108 8.227 1.269 7.154-5.206 9.426-7.478 2.101-2.102 8.103-3.797 10.068-1.221z'
          fill='#ecd439'
        />
        <path
          d='M157.324 144.491c2.807 3.687 8.016 7.644 9.875 8.726 1.352.778 3.314 1.691 3.281 4.126-.068 2.773-1.488 3.552-2.268 4.329-1.555 1.556-11.244 5.43-16.523 7.092-5.934 1.722-8.385 2.646-11.443 3.422-1.863.449-3.762 1.208-6.623.646-2.004-.38-2.748-.372-4.121-1.563-1.361-1.171-2.02-1.634-2.209-3.556-.535-4.856.582-7.094 2.578-11.374 1.865-4.117 5.828-3.349 8.088-3.005 8.199 1.287 7.055-5.359 9.32-7.625 2.096-2.097 8.082-3.788 10.045-1.218z'
          fill='#ecd439'
        />
        <path
          d='M157.299 144.526c2.801 3.678 7.996 7.625 9.852 8.705 1.35.775 3.307 1.687 3.273 4.116-.068 2.767-1.484 3.542-2.262 4.318-1.553 1.552-11.322 5.454-16.543 7.004-5.943 1.614-8.172 2.486-11.246 3.299-1.85.466-3.809 1.217-6.662.658-1.994-.375-2.678-.341-4.045-1.526-1.354-1.166-1.914-1.527-2.102-3.44-.529-4.843.436-6.968 2.396-11.244 1.865-4.202 5.854-3.25 8.102-2.902 8.176 1.306 6.955-5.512 9.215-7.772 2.092-2.093 8.065-3.78 10.022-1.216z'
          fill='#ecd439'
        />
        <path
          d='M157.273 144.562c2.795 3.669 7.977 7.606 9.828 8.684 1.348.774 3.299 1.683 3.266 4.106-.068 2.761-1.48 3.534-2.256 4.308-1.549 1.549-11.4 5.479-16.562 6.917-5.951 1.507-7.957 2.326-11.047 3.176-1.836.483-3.857 1.226-6.701.67-1.986-.371-2.607-.311-3.971-1.489-1.348-1.161-1.809-1.422-1.994-3.324-.523-4.831.287-6.842 2.215-11.115 1.865-4.286 5.879-3.152 8.119-2.8 8.146 1.324 6.852-5.665 9.107-7.921 2.086-2.087 8.045-3.771 9.996-1.212z'
          fill='#edd63b'
        />
        <path
          d='M157.248 144.597c2.789 3.66 7.959 7.588 9.805 8.662 1.344.772 3.291 1.68 3.258 4.098-.066 2.752-1.479 3.524-2.25 4.297-1.545 1.545-11.48 5.502-16.582 6.83-5.961 1.398-7.742 2.166-10.848 3.051-1.824.502-3.906 1.235-6.742.683-1.979-.367-2.537-.28-3.895-1.452-1.342-1.157-1.703-1.315-1.887-3.209-.518-4.817.141-6.716 2.031-10.986 1.867-4.368 5.906-3.053 8.135-2.696 8.121 1.343 6.754-5.818 9.002-8.067 2.082-2.084 8.028-3.764 9.973-1.211z'
          fill='#edd63b'
        />
        <path
          d='M157.225 144.632c2.779 3.65 7.938 7.57 9.781 8.642 1.34.771 3.281 1.675 3.248 4.087-.066 2.746-1.475 3.517-2.244 4.287-1.541 1.541-11.559 5.524-16.602 6.742-5.969 1.29-7.527 2.006-10.65 2.928-1.811.519-3.953 1.243-6.779.693-1.971-.362-2.467-.247-3.82-1.414-1.334-1.152-1.598-1.21-1.779-3.094-.512-4.803-.006-6.59 1.85-10.855 1.867-4.454 5.932-2.956 8.15-2.595 8.096 1.361 6.652-5.972 8.896-8.216 2.076-2.076 8.005-3.752 9.949-1.205z'
          fill='#edd63b'
        />
        <path
          d='M157.199 144.667c2.773 3.642 7.92 7.551 9.758 8.621 1.336.769 3.273 1.671 3.24 4.076-.066 2.74-1.469 3.509-2.238 4.277-1.537 1.537-11.637 5.549-16.619 6.655-5.98 1.182-7.314 1.845-10.453 2.804-1.799.535-4.002 1.252-6.82.705-1.963-.357-2.396-.217-3.746-1.377-1.326-1.148-1.492-1.104-1.67-2.978-.504-4.79-.154-6.465 1.668-10.728 1.867-4.537 5.959-2.857 8.166-2.492 8.068 1.381 6.551-6.123 8.791-8.362 2.072-2.071 7.986-3.742 9.923-1.201z'
          fill='#edd63b'
        />
        <path
          d='M157.176 144.701c2.766 3.634 7.898 7.534 9.732 8.601 1.334.767 3.268 1.666 3.234 4.066-.068 2.733-1.467 3.5-2.234 4.267-1.533 1.534-11.717 5.573-16.639 6.568-5.988 1.074-7.1 1.685-10.256 2.681-1.785.553-4.047 1.261-6.857.717-1.955-.354-2.326-.187-3.672-1.34-1.32-1.145-1.387-.998-1.562-2.862-.498-4.777-.301-6.339 1.486-10.598 1.867-4.621 5.984-2.759 8.182-2.39 8.043 1.399 6.451-6.276 8.684-8.51 2.068-2.066 7.968-3.733 9.902-1.2z'
          fill='#edd63d'
        />
        <path
          d='M157.15 144.736c2.76 3.625 7.881 7.516 9.709 8.579 1.33.765 3.26 1.663 3.227 4.058-.066 2.727-1.463 3.491-2.229 4.256-1.531 1.53-11.795 5.597-16.658 6.48-5.998.967-6.885 1.524-10.057 2.558-1.771.569-4.098 1.27-6.898.729-1.945-.35-2.256-.155-3.596-1.303-1.312-1.14-1.281-.893-1.455-2.746-.492-4.765-.449-6.214 1.303-10.469 1.869-4.705 6.012-2.66 8.199-2.287 8.016 1.418 6.35-6.43 8.578-8.657 2.063-2.062 7.948-3.725 9.877-1.198z'
          fill='#edd63d'
        />
        <path
          d='M157.125 144.771c2.754 3.616 7.861 7.497 9.688 8.558 1.326.764 3.25 1.659 3.217 4.048-.066 2.721-1.459 3.483-2.223 4.246-1.525 1.526-11.873 5.62-16.678 6.394-6.008.858-6.67 1.363-9.857 2.433-1.76.588-4.145 1.278-6.938.74-1.939-.345-2.188-.124-3.521-1.265-1.307-1.136-1.176-.785-1.348-2.631-.486-4.751-.596-6.088 1.121-10.34 1.869-4.788 6.037-2.562 8.215-2.184 7.988 1.437 6.25-6.582 8.471-8.805 2.056-2.056 7.929-3.715 9.853-1.194z'
          fill='#edd63d'
        />
        <path
          d='M157.1 144.807c2.748 3.606 7.844 7.478 9.664 8.537 1.322.761 3.242 1.655 3.209 4.037-.066 2.714-1.455 3.475-2.217 4.235-1.523 1.523-11.951 5.645-16.695 6.307-6.018.751-6.459 1.203-9.662 2.309-1.746.605-4.191 1.288-6.977.754-1.93-.342-2.115-.094-3.445-1.229-1.301-1.13-1.07-.679-1.242-2.515-.479-4.737-.74-5.961.941-10.209 1.871-4.874 6.062-2.464 8.23-2.082 7.963 1.455 6.148-6.735 8.365-8.953 2.053-2.053 7.911-3.707 9.829-1.191z'
          fill='#edd640'
        />
        <path
          d='M157.076 144.841c2.738 3.599 7.822 7.461 9.639 8.518 1.32.759 3.234 1.65 3.201 4.026-.066 2.707-1.451 3.467-2.211 4.226-1.52 1.52-12.029 5.668-16.715 6.219-6.027.644-6.244 1.043-9.463 2.186-1.734.622-4.24 1.297-7.016.765-1.922-.337-2.047-.062-3.371-1.191-1.295-1.125-.965-.573-1.135-2.399-.471-4.724-.889-5.836.758-10.08 1.873-4.956 6.092-2.364 8.248-1.979 7.936 1.473 6.047-6.889 8.26-9.1 2.047-2.049 7.889-3.7 9.805-1.191z'
          fill='#edd640'
        />
        <path
          d='M157.051 144.876c2.732 3.59 7.805 7.442 9.615 8.496 1.316.758 3.227 1.646 3.193 4.018-.064 2.7-1.447 3.457-2.205 4.215-1.516 1.515-12.109 5.691-16.734 6.132-6.035.534-6.029.883-9.264 2.062-1.723.64-4.289 1.305-7.057.776-1.914-.333-1.975-.03-3.295-1.154-1.287-1.121-.861-.467-1.027-2.283-.465-4.711-1.035-5.711.576-9.951 1.873-5.04 6.117-2.267 8.262-1.877 7.912 1.493 5.949-7.04 8.156-9.247 2.041-2.043 7.87-3.69 9.78-1.187z'
          fill='#edd642'
        />
        <path
          d='M157.025 144.911c2.727 3.58 7.785 7.424 9.592 8.475 1.314.756 3.219 1.643 3.188 4.008-.066 2.693-1.445 3.449-2.201 4.205-1.512 1.511-12.188 5.715-16.754 6.044-6.045.428-5.814.723-9.066 1.938-1.707.656-4.336 1.313-7.096.788-1.904-.329-1.904 0-3.219-1.117-1.281-1.116-.756-.361-.92-2.167-.459-4.698-1.182-5.585.395-9.822 1.873-5.125 6.143-2.168 8.277-1.774 7.885 1.512 5.848-7.193 8.049-9.395 2.037-2.037 7.851-3.679 9.755-1.183z'
          fill='#edd642'
        />
        <path
          d='M36.23 139.489c-3.103 2.24-10.961 2.033-12.96 4.653-1.965 2.62.069 6.411.034 13.442 0 2.999-.517 5.273-.861 7.101-.483 2.31-.793 3.929.172 5.583 1.757 2.93 4.584 3.654 20.75 7.032 8.651 1.792 16.82 6.445 22.3 6.894 5.48.413 6.653-1.414 9.996-4.378 3.31-2.964 4.378-2.033 4.274-8.548-.103-6.48-4.274-8.41-8.996-15.89-4.722-7.479-5.48-8.858-8.617-13.821-3.136-4.895-9.341-13.787-12.891-13.891-2.827-.068-4.412 1.447-6.17 3.24-1.758 1.793-3.929 6.343-7.031 8.583z'
          fill='#9f6437'
        />
        <path
          d='M36.28 139.627c-3.062 2.251-10.759 1.813-12.955 4.554-1.988 2.592.109 6.355.047 13.382-.012 2.983-.552 5.21-.927 7.123-.497 2.331-.803 3.926.167 5.561 1.812 2.951 4.641 3.411 20.803 6.784 8.646 1.788 16.536 6.369 22.185 6.756 5.355.379 6.494-1.355 9.796-4.29 3.302-2.966 4.321-2.048 4.241-8.333-.035-6.473-3.976-8.305-8.697-15.784-4.722-7.479-5.48-8.858-8.617-13.821-3.136-4.895-9.341-13.787-12.891-13.891-2.827-.068-4.412 1.447-6.17 3.24-1.759 1.791-3.904 6.451-6.982 8.719z'
          fill='#a36937'
        />
        <path
          d='M36.33 139.766c-3.023 2.261-10.558 1.592-12.95 4.453-2.013 2.564.148 6.3.059 13.321-.024 2.968-.586 5.146-.993 7.146-.51 2.354-.814 3.922.162 5.538 1.868 2.972 4.698 3.168 20.856 6.535 8.641 1.786 16.252 6.295 22.07 6.618 5.229.345 6.335-1.296 9.596-4.201 3.295-2.968 4.264-2.062 4.208-8.117.031-6.467-3.678-8.2-8.399-15.68-4.722-7.479-5.48-8.858-8.617-13.821-3.136-4.895-9.341-13.787-12.891-13.891-2.827-.068-4.412 1.447-6.17 3.24-1.758 1.792-3.878 6.563-6.931 8.859z'
          fill='#a77038'
        />
        <path
          d='M36.379 139.903c-2.983 2.271-10.355 1.371-12.944 4.354-2.037 2.536.188 6.245.07 13.261-.036 2.952-.621 5.083-1.058 7.168-.524 2.376-.823 3.919.157 5.517 1.923 2.991 4.754 2.924 20.91 6.286 8.636 1.782 15.967 6.219 21.954 6.48 5.103.311 6.177-1.237 9.396-4.114 3.289-2.969 4.207-2.074 4.176-7.901.098-6.459-3.38-8.095-8.102-15.574-4.722-7.479-5.48-8.858-8.617-13.821-3.136-4.895-9.341-13.787-12.891-13.891-2.827-.068-4.412 1.447-6.17 3.24-1.757 1.791-3.85 6.672-6.881 8.995z'
          fill='#ae7639'
        />
        <path
          d='M36.43 140.041c-2.944 2.282-10.154 1.151-12.939 4.253-2.062 2.51.228 6.19.083 13.201-.048 2.938-.655 5.019-1.124 7.19-.538 2.399-.834 3.915.151 5.493 1.979 3.014 4.812 2.682 20.963 6.04 8.631 1.778 15.683 6.142 21.839 6.342 4.977.275 6.018-1.179 9.196-4.026 3.282-2.971 4.15-2.089 4.144-7.686.165-6.453-3.082-7.99-7.804-15.47-4.722-7.479-5.48-8.858-8.617-13.821-3.136-4.895-9.341-13.787-12.891-13.891-2.827-.068-4.412 1.447-6.17 3.24-1.758 1.793-3.826 6.784-6.831 9.135z'
          fill='#b27d39'
        />
        <path
          d='M36.479 140.179c-2.904 2.293-9.953.931-12.934 4.153-2.085 2.481.268 6.135.095 13.142-.06 2.92-.689 4.954-1.189 7.212-.551 2.421-.844 3.912.146 5.472 2.034 3.033 4.869 2.438 21.017 5.791 8.625 1.774 15.398 6.065 21.724 6.204 4.851.241 5.859-1.12 8.996-3.938 3.274-2.973 4.093-2.103 4.11-7.471.232-6.445-2.784-7.885-7.505-15.364-4.722-7.479-5.48-8.858-8.617-13.821-3.136-4.895-9.341-13.787-12.891-13.891-2.827-.068-4.412 1.447-6.17 3.24-1.758 1.791-3.8 6.892-6.782 9.271z'
          fill='#b98439'
        />
        <path
          d='M36.53 140.317c-2.864 2.302-9.751.709-12.929 4.053-2.109 2.454.306 6.08.106 13.08-.072 2.906-.724 4.892-1.254 7.235-.565 2.443-.855 3.908.141 5.449 2.089 3.054 4.926 2.195 21.07 5.542 8.621 1.771 15.114 5.99 21.607 6.066 4.726.207 5.701-1.062 8.797-3.85 3.267-2.975 4.036-2.116 4.077-7.256.3-6.438-2.485-7.779-7.207-15.259-4.722-7.479-5.48-8.858-8.617-13.821-3.136-4.895-9.341-13.787-12.891-13.891-2.827-.068-4.412 1.447-6.17 3.24-1.757 1.794-3.773 7.006-6.73 9.412z'
          fill='#bc893a'
        />
        <path
          d='M36.58 140.454c-2.824 2.313-9.549.489-12.924 3.954-2.133 2.426.347 6.024.119 13.021-.084 2.89-.758 4.826-1.32 7.257-.579 2.466-.865 3.905.136 5.427 2.144 3.074 4.982 1.952 21.124 5.294 8.615 1.769 14.83 5.915 21.492 5.929 4.601.172 5.542-1.003 8.597-3.762 3.26-2.977 3.979-2.131 4.045-7.041.367-6.431-2.188-7.674-6.909-15.153-4.722-7.479-5.48-8.858-8.617-13.821-3.136-4.895-9.341-13.787-12.891-13.891-2.827-.068-4.412 1.447-6.17 3.24-1.759 1.791-3.749 7.113-6.682 9.546z'
          fill='#c08f3a'
        />
        <path
          d='M36.629 140.593c-2.785 2.323-9.348.269-12.918 3.854-2.158 2.398.386 5.97.13 12.959-.096 2.875-.792 4.764-1.385 7.28-.593 2.488-.875 3.901.131 5.404 2.199 3.096 5.039 1.71 21.177 5.046 8.61 1.765 14.546 5.839 21.377 5.791 4.474.138 5.384-.944 8.396-3.675 3.254-2.978 3.922-2.144 4.012-6.824.435-6.425-1.889-7.569-6.611-15.049-4.722-7.479-5.48-8.858-8.617-13.821-3.136-4.895-9.341-13.787-12.891-13.891-2.827-.068-4.412 1.447-6.17 3.24-1.757 1.792-3.721 7.225-6.631 9.686z'
          fill='#c3943a'
        />
        <path
          d='M36.679 140.73c-2.745 2.333-9.146.048-12.913 3.753-2.182 2.372.426 5.915.144 12.9-.109 2.858-.828 4.699-1.451 7.302-.607 2.511-.886 3.898.126 5.382 2.254 3.116 5.096 1.467 21.23 4.798 8.604 1.761 14.261 5.763 21.261 5.653 4.349.103 5.226-.887 8.196-3.587 3.247-2.979 3.866-2.158 3.979-6.609.501-6.418-1.591-7.464-6.312-14.943-4.722-7.479-5.48-8.858-8.617-13.821-3.136-4.895-9.341-13.787-12.891-13.891-2.827-.068-4.412 1.447-6.17 3.24-1.758 1.792-3.697 7.334-6.582 9.823z'
          fill='#c89c3a'
        />
        <path
          d='M36.729 140.868c-2.706 2.344-8.944-.172-12.908 3.653-2.206 2.345.465 5.859.155 12.84-.121 2.844-.862 4.636-1.517 7.324-.62 2.533-.896 3.895.121 5.359 2.31 3.137 5.153 1.224 21.284 4.55 8.6 1.758 13.977 5.688 21.146 5.516 4.222.068 5.067-.828 7.997-3.499 3.24-2.981 3.809-2.172 3.946-6.394.569-6.412-1.292-7.359-6.014-14.839-4.722-7.479-5.48-8.858-8.617-13.821-3.136-4.895-9.341-13.787-12.891-13.891-2.827-.068-4.412 1.447-6.17 3.24-1.758 1.793-3.671 7.446-6.532 9.962z'
          fill='#cba03a'
        />
        <path
          d='M36.78 141.006c-2.666 2.354-8.743-.393-12.903 3.554-2.23 2.316.505 5.805.167 12.779-.132 2.828-.896 4.572-1.582 7.347-.634 2.556-.906 3.891.116 5.337 2.364 3.157 5.209.981 21.337 4.302 8.594 1.755 13.692 5.611 21.03 5.377 4.097.035 4.908-.769 7.797-3.411 3.233-2.982 3.751-2.185 3.914-6.178.636-6.404-.995-7.254-5.716-14.733-4.722-7.479-5.48-8.858-8.617-13.821-3.136-4.895-9.341-13.787-12.891-13.891-2.827-.068-4.412 1.447-6.17 3.24-1.759 1.791-3.645 7.554-6.482 10.098z'
          fill='#cea53b'
        />
        <path
          d='M36.83 141.144c-2.626 2.365-8.541-.613-12.898 3.454-2.254 2.288.544 5.749.179 12.718-.145 2.812-.931 4.51-1.647 7.37-.648 2.578-.917 3.888.11 5.314 2.419 3.179 5.267.738 21.391 4.054 8.589 1.751 13.408 5.535 20.915 5.239 3.971 0 4.75-.71 7.597-3.323 3.226-2.984 3.695-2.199 3.881-5.963.703-6.396-.696-7.148-5.418-14.628-4.722-7.479-5.48-8.858-8.617-13.821-3.136-4.895-9.341-13.787-12.891-13.891-2.827-.068-4.412 1.447-6.17 3.24-1.759 1.792-3.62 7.666-6.432 10.237z'
          fill='#d2ab3b'
        />
        <path
          d='M36.879 141.282c-2.587 2.374-8.339-.834-12.893 3.354-2.278 2.261.584 5.693.191 12.658-.157 2.797-.965 4.444-1.713 7.392-.662 2.601-.928 3.884.105 5.292 2.475 3.199 5.324.495 21.444 3.806 8.584 1.747 13.124 5.46 20.8 5.101 3.845-.034 4.591-.651 7.397-3.234 3.219-2.986 3.638-2.213 3.848-5.747.771-6.391-.398-7.044-5.12-14.523s-5.48-8.858-8.617-13.821c-3.136-4.895-9.341-13.787-12.891-13.891-2.827-.068-4.412 1.447-6.17 3.24-1.757 1.79-3.592 7.774-6.381 10.373z'
          fill='#d7b23b'
        />
        <path
          d='M36.929 141.42c-2.547 2.385-8.138-1.055-12.887 3.253-2.302 2.234.624 5.64.204 12.599-.169 2.781-1 4.381-1.779 7.414-.675 2.623-.938 3.881.1 5.27 2.53 3.22 5.38.252 21.497 3.558 8.579 1.744 12.84 5.384 20.685 4.963 3.719-.068 4.433-.592 7.196-3.146 3.213-2.988 3.582-2.227 3.816-5.532.837-6.383-.101-6.938-4.822-14.418-4.722-7.479-5.48-8.858-8.617-13.821-3.136-4.895-9.341-13.787-12.891-13.891-2.827-.068-4.412 1.447-6.17 3.24-1.758 1.79-3.567 7.884-6.332 10.511z'
          fill='#dab73b'
        />
        <path
          d='M36.979 141.558c-2.507 2.396-7.936-1.275-12.882 3.154-2.327 2.205.664 5.583.215 12.537-.181 2.766-1.034 4.317-1.844 7.437-.689 2.646-.948 3.878.095 5.247 2.585 3.24 5.437.009 21.551 3.31 8.574 1.74 12.555 5.308 20.569 4.825 3.593-.104 4.273-.534 6.997-3.059 3.205-2.99 3.524-2.241 3.783-5.317.905-6.376.198-6.833-4.524-14.312-4.722-7.479-5.48-8.858-8.617-13.821-3.136-4.895-9.341-13.787-12.891-13.891-2.827-.068-4.412 1.447-6.17 3.24-1.758 1.791-3.541 7.995-6.282 10.65z'
          fill='#ddbc39'
        />
        <path
          d='M37.03 141.695c-2.468 2.406-7.735-1.496-12.877 3.054-2.351 2.179.703 5.529.228 12.478-.193 2.751-1.069 4.253-1.91 7.459-.703 2.668-.958 3.874.09 5.225 2.64 3.261 5.494-.234 21.604 3.062 8.568 1.736 12.271 5.232 20.453 4.688 3.467-.138 4.115-.476 6.797-2.972 3.198-2.991 3.468-2.254 3.75-5.101.972-6.37.496-6.729-4.226-14.208-4.722-7.479-5.48-8.858-8.617-13.821-3.136-4.895-9.341-13.787-12.891-13.891-2.827-.068-4.412 1.447-6.17 3.24-1.758 1.791-3.515 8.106-6.231 10.787z'
          fill='#e1c239'
        />
        <path
          d='M37.08 141.833c-2.428 2.416-7.533-1.717-12.872 2.954-2.375 2.151.743 5.474.239 12.417-.205 2.735-1.103 4.189-1.975 7.481-.717 2.69-.969 3.87.084 5.202 2.696 3.282 5.551-.477 21.658 2.813 8.563 1.733 11.986 5.155 20.338 4.55 3.341-.173 3.957-.417 6.597-2.884 3.191-2.993 3.411-2.269 3.717-4.886 1.04-6.363.794-6.623-3.927-14.103-4.722-7.479-5.48-8.858-8.617-13.821-3.136-4.895-9.341-13.787-12.891-13.891-2.827-.068-4.412 1.447-6.17 3.24-1.758 1.794-3.49 8.219-6.181 10.928z'
          fill='#e2c539'
        />
        <path
          d='M37.129 141.971c-2.389 2.427-7.331-1.937-12.867 2.854-2.399 2.123.783 5.418.251 12.356-.217 2.72-1.137 4.126-2.041 7.504-.731 2.713-.979 3.867.08 5.181 2.75 3.302 5.607-.721 21.711 2.564 8.559 1.729 11.702 5.08 20.222 4.411 3.216-.206 3.798-.358 6.397-2.795 3.185-2.995 3.353-2.282 3.685-4.671 1.106-6.355 1.092-6.518-3.629-13.997-4.722-7.479-5.48-8.858-8.617-13.821-3.136-4.895-9.341-13.787-12.891-13.891-2.827-.068-4.412 1.447-6.17 3.24-1.757 1.793-3.463 8.328-6.131 11.065z'
          fill='#e6cb39'
        />
        <path
          d='M37.179 142.108c-2.349 2.438-7.129-2.157-12.861 2.754-2.423 2.097.822 5.363.264 12.297-.229 2.704-1.172 4.062-2.106 7.526-.745 2.735-.989 3.863.074 5.158 2.806 3.322 5.665-.964 21.765 2.315 8.553 1.728 11.417 5.005 20.106 4.274 3.09-.241 3.64-.3 6.197-2.707 3.178-2.998 3.297-2.296 3.652-4.455 1.173-6.349 1.391-6.413-3.331-13.893-4.722-7.479-5.48-8.858-8.617-13.821-3.136-4.895-9.341-13.787-12.891-13.891-2.827-.068-4.412 1.447-6.17 3.24-1.758 1.794-3.438 8.44-6.082 11.203z'
          fill='#e8ce38'
        />
        <path
          d='M37.229 142.247c-2.31 2.447-6.928-2.379-12.857 2.653-2.447 2.068.862 5.309.276 12.236-.241 2.688-1.207 3.998-2.172 7.549-.758 2.757-.999 3.86.069 5.136 2.86 3.343 5.722-1.207 21.818 2.067 8.548 1.724 11.133 4.929 19.991 4.137 2.964-.276 3.481-.241 5.998-2.62 3.171-2.998 3.24-2.309 3.619-4.239 1.241-6.342 1.689-6.308-3.033-13.787-4.722-7.479-5.48-8.858-8.617-13.821-3.136-4.895-9.341-13.787-12.891-13.891-2.827-.068-4.412 1.447-6.17 3.24-1.757 1.792-3.411 8.548-6.031 11.34z'
          fill='#ecd238'
        />
        <path
          d='M38.722 144.94c-2.048 2.172-8.751-.652-11.916 1.737-2.265 1.676 1.148 3.786.683 9.992-.186 2.358-1.831 2.793-1.148 6.052.528 2.451 1.086 2.389 2.017 3.506 2.544 2.979 1.955.807 16.446 3.724 7.665 1.552 10.023 4.438 17.999 3.724 2.668-.248 3.165-.217 5.399-2.358 2.855-2.668 1.396-2.11 1.707-3.816 1.086-5.679.217-6.672-4.034-13.405-4.251-6.734-3.879-7.106-6.671-11.544-2.793-4.376-6.641-12.351-9.837-12.413-2.513-.062-3.972 1.304-5.554 2.918-1.584 1.612-2.764 9.401-5.091 11.883z'
          fill='#ecd238'
        />
        <path
          d='M38.863 144.983c-2.136 2.251-8.907-.572-11.997 1.774-2.25 1.682 1.234 3.853.77 9.899-.192 2.344-1.788 2.812-1.177 6.048.473 2.438 1.129 2.364 2.075 3.447 2.722 2.936 2.237.874 16.333 3.707 7.621 1.537 9.965 4.411 17.891 3.702 2.653-.246 3.101-.222 5.34-2.321 2.808-2.589 1.369-2.053 1.633-3.857.99-5.644.212-6.693-3.919-13.237-4.228-6.693-3.898-7.036-6.675-11.447-2.776-4.35-6.559-12.353-9.736-12.414-2.499-.062-3.948 1.295-5.521 2.898-1.572 1.605-2.701 9.339-5.017 11.801z'
          fill='#ecd238'
        />
        <path
          d='M39.004 145.026c-2.224 2.33-9.062-.492-12.078 1.811-2.235 1.688 1.321 3.921.858 9.808-.197 2.33-1.744 2.833-1.206 6.044.418 2.426 1.172 2.34 2.133 3.389 2.899 2.892 2.519.941 16.221 3.69 7.576 1.522 9.906 4.385 17.783 3.679 2.637-.242 3.037-.225 5.28-2.283 2.761-2.508 1.342-1.995 1.561-3.897.893-5.608.207-6.715-3.806-13.067-4.203-6.654-3.917-6.967-6.677-11.352-2.76-4.323-6.477-12.356-9.635-12.418-2.483-.062-3.925 1.287-5.488 2.882-1.563 1.593-2.641 9.27-4.946 11.714z'
          fill='#ecd438'
        />
        <path
          d='M39.145 145.07c-2.311 2.408-9.218-.414-12.158 1.847-2.221 1.694 1.406 3.988.945 9.715-.203 2.315-1.7 2.854-1.234 6.041.362 2.412 1.214 2.314 2.19 3.329 3.078 2.848 2.802 1.01 16.108 3.674 7.531 1.507 9.848 4.358 17.676 3.657 2.621-.24 2.973-.23 5.221-2.247 2.714-2.428 1.314-1.937 1.488-3.937.796-5.573.201-6.737-3.691-12.899-4.18-6.613-3.937-6.897-6.68-11.256-2.743-4.297-6.395-12.358-9.534-12.42-2.469-.062-3.901 1.279-5.456 2.864s-2.581 9.207-4.875 11.632z'
          fill='#ecd438'
        />
        <path
          d='M39.286 145.114c-2.398 2.486-9.373-.336-12.239 1.882-2.206 1.702 1.493 4.057 1.033 9.623-.209 2.302-1.656 2.874-1.263 6.038.307 2.398 1.257 2.288 2.248 3.27 3.254 2.805 3.083 1.077 15.994 3.657 7.487 1.492 9.79 4.332 17.569 3.635 2.605-.237 2.91-.234 5.162-2.209 2.667-2.349 1.287-1.88 1.415-3.978.699-5.538.196-6.759-3.577-12.73-4.156-6.574-3.956-6.827-6.683-11.159-2.726-4.271-6.313-12.362-9.433-12.423-2.454-.061-3.877 1.271-5.422 2.847s-2.521 9.14-4.804 11.547z'
          fill='#ecd438'
        />
        <path
          d='M39.427 145.157c-2.486 2.565-9.529-.256-12.319 1.919-2.191 1.708 1.579 4.123 1.12 9.53-.215 2.288-1.612 2.894-1.292 6.034.252 2.386 1.3 2.264 2.306 3.211 3.432 2.761 3.366 1.146 15.881 3.64 7.443 1.479 9.731 4.307 17.462 3.613 2.589-.234 2.845-.238 5.102-2.172 2.62-2.268 1.259-1.821 1.341-4.018.603-5.502.19-6.78-3.463-12.562-4.131-6.533-3.975-6.758-6.685-11.063-2.709-4.245-6.231-12.364-9.332-12.425-2.438-.061-3.854 1.264-5.389 2.83-1.535 1.567-2.46 9.076-4.732 11.463z'
          fill='#ecd438'
        />
        <path
          d='M39.568 145.201c-2.574 2.644-9.685-.178-12.4 1.954-2.176 1.715 1.665 4.191 1.208 9.438-.22 2.273-1.569 2.914-1.32 6.031.197 2.372 1.342 2.238 2.363 3.151 3.61 2.717 3.648 1.213 15.768 3.624 7.399 1.463 9.673 4.278 17.354 3.59 2.574-.23 2.782-.242 5.043-2.135 2.572-2.188 1.231-1.764 1.268-4.058.506-5.467.185-6.802-3.348-12.393-4.107-6.493-3.995-6.688-6.688-10.967-2.693-4.219-6.149-12.368-9.231-12.428-2.423-.061-3.83 1.256-5.355 2.812-1.527 1.559-2.402 9.012-4.662 11.381z'
          fill='#ecd439'
        />
        <path
          d='M39.708 145.244c-2.661 2.723-9.84-.099-12.481 1.991-2.161 1.721 1.751 4.259 1.295 9.346-.227 2.26-1.525 2.934-1.349 6.028.142 2.358 1.385 2.213 2.42 3.092 3.788 2.673 3.931 1.28 15.656 3.606 7.354 1.449 9.615 4.253 17.248 3.568 2.557-.228 2.717-.246 4.982-2.097 2.526-2.108 1.204-1.707 1.196-4.098.409-5.433.179-6.824-3.235-12.225-4.083-6.453-4.013-6.618-6.69-10.871-2.676-4.192-6.067-12.371-9.129-12.431-2.409-.06-3.807 1.249-5.323 2.796-1.516 1.548-2.34 8.945-4.59 11.295z'
          fill='#ecd439'
        />
        <path
          d='M39.849 145.288c-2.749 2.801-9.995-.02-12.561 2.026-2.147 1.728 1.837 4.327 1.382 9.254-.232 2.245-1.481 2.954-1.377 6.024.087 2.346 1.428 2.188 2.479 3.033 3.965 2.629 4.212 1.348 15.542 3.59 7.31 1.435 9.556 4.227 17.14 3.547 2.542-.226 2.654-.251 4.923-2.061 2.479-2.027 1.177-1.648 1.123-4.138.312-5.397.174-6.846-3.12-12.056-4.06-6.413-4.033-6.549-6.692-10.775-2.66-4.166-5.985-12.373-9.029-12.432-2.394-.061-3.783 1.24-5.29 2.777-1.508 1.538-2.282 8.879-4.52 11.211z'
          fill='#ecd439'
        />
        <path
          d='M39.99 145.331c-2.835 2.88-10.15.061-12.642 2.063-2.132 1.733 1.923 4.395 1.47 9.161-.237 2.231-1.438 2.975-1.406 6.021.032 2.332 1.471 2.163 2.536 2.974 4.143 2.586 4.495 1.415 15.429 3.573 7.266 1.42 9.498 4.199 17.033 3.524 2.526-.223 2.589-.255 4.864-2.022 2.432-1.948 1.149-1.592 1.05-4.178.215-5.363.168-6.868-3.006-11.888-4.036-6.373-4.052-6.479-6.695-10.679-2.644-4.141-5.903-12.377-8.928-12.436-2.379-.059-3.759 1.233-5.257 2.76-1.499 1.529-2.221 8.815-4.448 11.127z'
          fill='#ecd439'
        />
        <path
          d='M40.131 145.375c-2.924 2.958-10.307.139-12.723 2.099-2.117 1.74 2.009 4.463 1.558 9.068-.243 2.218-1.394 2.995-1.435 6.019-.023 2.319 1.514 2.138 2.594 2.915 4.32 2.542 4.777 1.483 15.316 3.557 7.222 1.404 9.439 4.173 16.926 3.502 2.509-.22 2.525-.26 4.804-1.985 2.384-1.867 1.122-1.533.976-4.218.12-5.327.164-6.89-2.892-11.719-4.012-6.333-4.071-6.409-6.698-10.583-2.626-4.114-5.821-12.379-8.827-12.438-2.364-.059-3.736 1.226-5.224 2.743-1.487 1.518-2.159 8.746-4.375 11.04z'
          fill='#edd63b'
        />
        <path
          d='M40.272 145.418c-3.011 3.037-10.462.218-12.804 2.135-2.102 1.747 2.095 4.53 1.646 8.978-.25 2.203-1.351 3.015-1.464 6.015-.079 2.306 1.556 2.112 2.651 2.855 4.498 2.498 5.059 1.551 15.203 3.54 7.178 1.39 9.381 4.146 16.818 3.479 2.494-.217 2.462-.263 4.745-1.948 2.337-1.787 1.094-1.476.903-4.258.023-5.291.158-6.911-2.777-11.55-3.987-6.292-4.09-6.34-6.701-10.486-2.609-4.089-5.739-12.383-8.726-12.44-2.349-.059-3.711 1.218-5.19 2.726s-2.099 8.68-4.304 10.954z'
          fill='#edd63b'
        />
        <path
          d='M40.413 145.462c-3.099 3.115-10.618.297-12.884 2.171-2.087 1.753 2.181 4.597 1.732 8.884-.255 2.189-1.306 3.036-1.492 6.012-.134 2.293 1.599 2.088 2.709 2.797 4.675 2.454 5.341 1.618 15.09 3.523 7.133 1.375 9.323 4.12 16.711 3.457 2.478-.214 2.398-.268 4.685-1.911 2.29-1.706 1.067-1.418.831-4.298-.074-5.256.152-6.933-2.664-11.381-3.963-6.252-4.109-6.27-6.703-10.391-2.593-4.062-5.658-12.385-8.625-12.443-2.334-.057-3.688 1.21-5.158 2.709-1.468 1.498-2.039 8.615-4.232 10.871z'
          fill='#edd63b'
        />
        <path
          d='M40.554 145.505c-3.186 3.195-10.773.377-12.965 2.207-2.072 1.76 2.267 4.666 1.82 8.792-.261 2.176-1.263 3.057-1.521 6.009-.188 2.279 1.642 2.062 2.767 2.737 4.853 2.411 5.623 1.687 14.977 3.507 7.089 1.36 9.265 4.094 16.604 3.436 2.462-.212 2.334-.272 4.626-1.874 2.243-1.627 1.04-1.36.757-4.339-.17-5.221.147-6.954-2.549-11.212-3.94-6.212-4.129-6.2-6.706-10.294-2.576-4.037-5.575-12.389-8.523-12.445-2.319-.059-3.665 1.201-5.125 2.69-1.461 1.489-1.979 8.55-4.162 10.786z'
          fill='#edd63b'
        />
        <path
          d='M40.695 145.549c-3.274 3.272-10.929.455-13.046 2.243-2.057 1.766 2.354 4.732 1.908 8.699-.266 2.162-1.219 3.076-1.55 6.006-.244 2.266 1.684 2.037 2.825 2.678 5.03 2.367 5.905 1.754 14.864 3.49 7.044 1.345 9.206 4.066 16.497 3.413 2.446-.209 2.27-.276 4.566-1.837 2.196-1.547 1.012-1.303.684-4.379-.267-5.186.142-6.976-2.435-11.043-3.916-6.172-4.148-6.131-6.708-10.197-2.56-4.011-5.494-12.393-8.423-12.449-2.304-.057-3.641 1.194-5.091 2.674-1.452 1.479-1.92 8.484-4.091 10.702z'
          fill='#edd63d'
        />
        <path
          d='M40.835 145.592c-3.361 3.352-11.084.534-13.126 2.279-2.042 1.772 2.439 4.801 1.995 8.607-.272 2.147-1.175 3.097-1.578 6.003-.299 2.252 1.727 2.012 2.882 2.618 5.208 2.324 6.188 1.821 14.751 3.474 7 1.33 9.147 4.04 16.389 3.391 2.43-.206 2.206-.28 4.506-1.799 2.149-1.467.984-1.246.611-4.419-.364-5.15.137-6.998-2.32-10.875-3.892-6.132-4.167-6.061-6.711-10.102-2.543-3.984-5.411-12.394-8.322-12.451-2.289-.057-3.617 1.188-5.058 2.656-1.44 1.47-1.858 8.42-4.019 10.618z'
          fill='#edd63d'
        />
        <path
          d='M40.977 145.636c-3.449 3.43-11.24.613-13.207 2.315-2.028 1.778 2.525 4.868 2.083 8.515-.277 2.134-1.132 3.117-1.607 5.999-.354 2.239 1.77 1.987 2.94 2.56 5.385 2.279 6.47 1.889 14.638 3.456 6.956 1.316 9.089 4.015 16.282 3.369 2.414-.203 2.142-.284 4.447-1.762 2.102-1.387.957-1.187.538-4.459-.461-5.115.131-7.02-2.207-10.706-3.868-6.092-4.187-5.991-6.713-10.006-2.526-3.958-5.33-12.396-8.221-12.453-2.274-.057-3.594 1.179-5.025 2.639s-1.8 8.353-3.948 10.533z'
          fill='#edd63d'
        />
        <path
          d='M41.117 145.679c-3.537 3.51-11.396.692-13.288 2.352-2.013 1.785 2.612 4.937 2.17 8.423-.284 2.12-1.088 3.138-1.636 5.995-.409 2.227 1.812 1.962 2.998 2.501 5.563 2.236 6.752 1.956 14.525 3.439 6.911 1.302 9.03 3.988 16.174 3.347 2.398-.2 2.078-.288 4.388-1.725 2.055-1.306.93-1.129.465-4.498-.557-5.08.125-7.042-2.092-10.538-3.844-6.052-4.207-5.922-6.716-9.909-2.51-3.933-5.248-12.4-8.12-12.457-2.259-.056-3.57 1.172-4.992 2.623-1.422 1.449-1.738 8.286-3.876 10.447z'
          fill='#edd640'
        />
        <path
          d='M41.258 145.723c-3.625 3.587-11.552.771-13.369 2.388-1.998 1.791 2.698 5.003 2.258 8.33-.29 2.105-1.044 3.157-1.665 5.992-.464 2.213 1.855 1.937 3.055 2.441 5.74 2.193 7.034 2.024 14.412 3.423 6.867 1.287 8.972 3.962 16.067 3.325 2.382-.197 2.014-.293 4.328-1.688 2.007-1.227.902-1.072.392-4.539-.654-5.045.12-7.063-1.978-10.369-3.82-6.011-4.226-5.852-6.719-9.813-2.493-3.906-5.166-12.403-8.019-12.459-2.244-.055-3.546 1.164-4.959 2.604-1.411 1.441-1.676 8.222-3.803 10.365z'
          fill='#edd640'
        />
        <path
          d='M41.399 145.767c-3.711 3.666-11.707.85-13.449 2.424-1.982 1.797 2.784 5.07 2.346 8.237-.295 2.091-1.001 3.178-1.693 5.989-.519 2.199 1.897 1.911 3.113 2.383 5.917 2.147 7.316 2.091 14.299 3.405 6.823 1.272 8.915 3.936 15.96 3.303 2.366-.195 1.95-.298 4.269-1.65 1.96-1.146.875-1.015.319-4.579-.751-5.01.114-7.085-1.864-10.2-3.796-5.971-4.245-5.782-6.721-9.717-2.477-3.88-5.084-12.406-7.918-12.462-2.229-.055-3.522 1.157-4.925 2.588-1.406 1.429-1.62 8.155-3.736 10.279z'
          fill='#edd642'
        />
        <path
          d='M41.54 145.81c-3.799 3.744-11.863.93-13.53 2.46-1.968 1.804 2.87 5.139 2.433 8.146-.301 2.077-.957 3.197-1.722 5.985-.574 2.187 1.94 1.886 3.17 2.323 6.095 2.105 7.598 2.16 14.186 3.39 6.779 1.257 8.856 3.909 15.853 3.28 2.351-.191 1.886-.301 4.209-1.613 1.914-1.065.848-.956.247-4.619-.847-4.974.109-7.106-1.75-10.031-3.772-5.931-4.264-5.713-6.724-9.621-2.46-3.854-5.002-12.408-7.817-12.464-2.214-.055-3.499 1.148-4.893 2.569-1.394 1.421-1.557 8.09-3.662 10.195z'
          fill='#edd642'
        />
        <path d='M53.602 113.19c-.448 1-.827 9.582 1.413 12.926 2.24 3.309 1.585 5.205-.896 2.688-2.585-2.446-4.274-6.135-4.309-8.686 0-1.481 1.138-7.582 1.586-8.237.482-.69 2.55.552 2.206 1.309z' />
        <path
          d='M53.558 113.357c-.432 1.12-.792 9.431 1.441 12.767 2.234 3.305 1.525 5.022-.88 2.565-2.504-2.393-4.121-5.971-4.185-8.516-.021-1.491 1.07-7.382 1.5-8.038.46-.666 2.454.475 2.124 1.222z'
          fill='#000202'
        />
        <path
          d='M53.515 113.524c-.417 1.241-.758 9.279 1.469 12.609 2.227 3.298 1.465 4.839-.865 2.439-2.423-2.337-3.968-5.804-4.061-8.345-.042-1.499 1.003-7.179 1.413-7.837.438-.644 2.358.397 2.044 1.134z'
          fill='#021010'
        />
        <path
          d='M53.472 113.692c-.401 1.361-.724 9.127 1.496 12.449 2.22 3.294 1.404 4.656-.85 2.316-2.342-2.281-3.814-5.639-3.937-8.174-.062-1.508.936-6.979 1.327-7.639.417-.621 2.263.321 1.964 1.048z'
          fill='#0b1413'
        />
        <path
          d='M53.429 113.859c-.386 1.482-.689 8.976 1.523 12.291 2.213 3.288 1.344 4.475-.834 2.192-2.261-2.227-3.661-5.474-3.812-8.004-.083-1.517.869-6.776 1.241-7.438.393-.599 2.165.242 1.882.959z'
          fill='#131919'
        />
        <path
          d='M53.386 114.026c-.371 1.603-.655 8.824 1.551 12.133 2.206 3.283 1.284 4.291-.819 2.068-2.18-2.172-3.507-5.309-3.688-7.833-.104-1.525.801-6.575 1.155-7.238.371-.577 2.068.163 1.801.87z'
          fill='#171d1d'
        />
        <path
          d='M53.343 114.193c-.355 1.724-.621 8.672 1.578 11.974 2.199 3.278 1.224 4.109-.803 1.945-2.099-2.117-3.354-5.144-3.564-7.663-.124-1.533.734-6.373 1.069-7.038.348-.555 1.972.086 1.72.782z'
          fill='#1e2323'
        />
        <path
          d='M53.3 114.36c-.34 1.845-.586 8.521 1.605 11.816 2.192 3.272 1.164 3.926-.787 1.819-2.018-2.061-3.2-4.978-3.44-7.492-.145-1.542.667-6.171.982-6.837.327-.532 1.876.009 1.64.694z'
          fill='#232828'
        />
        <path
          d='M53.257 114.528c-.324 1.965-.551 8.368 1.634 11.656 2.185 3.269 1.103 3.744-.772 1.695-1.937-2.005-3.047-4.811-3.316-7.32-.166-1.551.6-5.97.896-6.639.303-.509 1.779-.067 1.558.608z'
          fill='#282d2d'
        />
        <path
          d='M53.213 114.695c-.308 2.085-.517 8.217 1.662 11.499 2.178 3.262 1.043 3.56-.756 1.57-1.856-1.95-2.894-4.646-3.191-7.149-.187-1.56.532-5.769.81-6.438.28-.489 1.681-.147 1.475.518z'
          fill='#2b3131'
        />
        <path
          d='M53.171 114.862c-.293 2.206-.483 8.065 1.689 11.34 2.171 3.257.982 3.378-.741 1.447-1.775-1.895-2.74-4.48-3.068-6.979-.207-1.568.465-5.566.724-6.238.258-.466 1.585-.224 1.396.43z'
          fill='#2f3534'
        />
        <path
          d='M53.127 115.029c-.277 2.327-.448 7.914 1.717 11.181 2.165 3.253.922 3.196-.726 1.324-1.694-1.84-2.587-4.315-2.944-6.81-.227-1.577.398-5.364.638-6.038.237-.442 1.49-.3 1.315.343z'
          fill='#343a39'
        />
        <path
          d='M53.084 115.196c-.262 2.447-.414 7.763 1.744 11.023 2.158 3.246.862 3.012-.71 1.199-1.613-1.785-2.434-4.15-2.819-6.639-.249-1.586.331-5.163.551-5.839.214-.419 1.393-.377 1.234.256z'
          fill='#383d3d'
        />
        <path
          d='M53.042 115.364c-.247 2.567-.379 7.61 1.771 10.863 2.151 3.242.801 2.83-.694 1.075-1.532-1.729-2.28-3.984-2.695-6.467-.269-1.595.264-4.962.465-5.64.191-.396 1.296-.456 1.153.169z'
          fill='#3c4242'
        />
        <path
          d='M52.999 115.531c-.231 2.688-.345 7.459 1.799 10.705 2.144 3.237.741 2.647-.679.951-1.451-1.675-2.127-3.818-2.571-6.297-.29-1.603.196-4.76.379-5.438.168-.377 1.199-.535 1.072.079z'
          fill='#414747'
        />
        <path
          d='M52.955 115.698c-.215 2.809-.31 7.307 1.827 10.547 2.137 3.231.68 2.465-.664.827-1.37-1.62-1.974-3.653-2.447-6.126-.311-1.611.129-4.559.293-5.239.147-.353 1.103-.612.991-.009z'
          fill='#454a4b'
        />
        <path
          d='M52.913 115.865c-.2 2.93-.276 7.155 1.854 10.389 2.13 3.226.621 2.281-.648.703-1.289-1.565-1.82-3.488-2.323-5.956-.331-1.62.062-4.356.207-5.039.123-.331 1.006-.69.91-.097z'
          fill='#4a4e4e'
        />
        <path
          d='M52.869 116.032c-.184 3.051-.241 7.004 1.882 10.23 2.124 3.221.56 2.099-.632.579-1.208-1.51-1.667-3.322-2.199-5.786-.352-1.629-.005-4.154.121-4.839.101-.308.909-.766.828-.184z'
          fill='#4f5455'
        />
        <path
          d='M52.826 116.2c-.169 3.171-.207 6.852 1.91 10.07 2.116 3.217.5 1.917-.617.455-1.127-1.454-1.513-3.157-2.075-5.614-.372-1.637-.072-3.954.035-4.64.078-.284.812-.843.747-.271z'
          fill='#545859'
        />
        <path
          d='M52.783 116.367c-.153 3.291-.173 6.7 1.937 9.912 2.109 3.211.439 1.734-.602.331-1.046-1.399-1.36-2.991-1.951-5.443-.393-1.646-.14-3.752-.052-4.44.058-.264.718-.922.668-.36z'
          fill='#575c5c'
        />
        <path
          d='M52.74 116.534c-.138 3.412-.138 6.549 1.965 9.754 2.103 3.206.379 1.551-.586.207-.965-1.345-1.206-2.826-1.827-5.273-.413-1.654-.207-3.551-.138-4.239.034-.242.62-1.001.586-.449z'
          fill='#5c6060'
        />
        <path d='M53.602 113.19c2.068-4.516 3.206-9.375 6.169-14.097 2.93-4.688 1.758-6.515-.413-4.137-2.172 2.378-4.55 7.411-4.55 7.411s-2.792 4.411-3.412 9.513c-.104.862 1.861 2.034 2.206 1.31z' />
        <path d='M53.69 112.898c2.049-4.548 3.167-9.214 6.025-13.788 2.825-4.542 1.728-6.357-.365-4.056-2.108 2.32-4.453 7.293-4.485 7.363 0 0-2.699 4.304-3.292 9.21-.1.849 1.781 1.985 2.117 1.271z' />
        <path
          d='M53.779 112.605c2.029-4.579 3.128-9.052 5.881-13.479 2.72-4.396 1.697-6.199-.318-3.975-2.044 2.262-4.356 7.177-4.419 7.315 0 0-2.605 4.197-3.172 8.907-.097.835 1.7 1.937 2.028 1.232z'
          fill='#000807'
        />
        <path
          d='M53.867 112.313c2.01-4.611 3.09-8.891 5.736-13.17 2.617-4.25 1.668-6.042-.269-3.894-1.981 2.205-4.259 7.06-4.355 7.269 0 0-2.512 4.089-3.052 8.604-.092.819 1.621 1.885 1.94 1.191z'
          fill='#021010'
        />
        <path
          d='M53.956 112.021c1.991-4.645 3.051-8.729 5.591-12.861 2.512-4.104 1.638-5.884-.221-3.813-1.917 2.146-4.162 6.943-4.289 7.221 0 0-2.419 3.981-2.933 8.3-.088.806 1.542 1.836 1.852 1.153z'
          fill='#061212'
        />
        <path
          d='M54.045 111.729c1.971-4.677 3.013-8.567 5.446-12.552 2.407-3.957 1.608-5.726-.172-3.732-1.853 2.088-4.064 6.826-4.224 7.174 0 0-2.326 3.875-2.812 7.998-.086.79 1.46 1.784 1.762 1.112z'
          fill='#101616'
        />
        <path
          d='M54.133 111.437c1.952-4.709 2.975-8.407 5.302-12.242 2.303-3.812 1.578-5.569-.125-3.652-1.79 2.03-3.967 6.709-4.159 7.127 0 0-2.232 3.767-2.693 7.694-.081.776 1.382 1.736 1.675 1.073z'
          fill='#131919'
        />
        <path
          d='M54.222 111.144c1.933-4.741 2.936-8.244 5.156-11.934 2.199-3.665 1.549-5.411-.076-3.57-1.726 1.972-3.871 6.591-4.094 7.08 0 0-2.139 3.658-2.573 7.39-.076.762 1.302 1.687 1.587 1.034z'
          fill='#161c1c'
        />
        <path
          d='M54.311 110.852c1.914-4.773 2.897-8.084 5.012-11.624 2.094-3.52 1.519-5.253-.028-3.491-1.663 1.915-3.773 6.475-4.029 7.032 0 0-2.046 3.552-2.453 7.088-.074.748 1.221 1.638 1.498.995z'
          fill='#1b1f1f'
        />
        <path
          d='M54.399 110.56c1.894-4.806 2.859-7.922 4.867-11.315 1.99-3.374 1.489-5.096.02-3.409-1.599 1.855-3.676 6.357-3.963 6.984 0 0-1.953 3.443-2.333 6.785-.07.734 1.141 1.587 1.409.955z'
          fill='#1e2323'
        />
        <path
          d='M54.488 110.267c1.875-4.837 2.82-7.76 4.723-11.006 1.886-3.228 1.459-4.938.069-3.329-1.535 1.797-3.58 6.241-3.898 6.937 0 0-1.859 3.337-2.213 6.481-.067.721 1.06 1.54 1.319.917z'
          fill='#222727'
        />
        <path
          d='M54.577 109.974c1.855-4.869 2.781-7.599 4.578-10.695 1.78-3.083 1.428-4.781.116-3.249-1.471 1.74-3.482 6.125-3.833 6.891 0 0-1.766 3.229-2.093 6.178-.063.706.981 1.488 1.232.875z'
          fill='#232929'
        />
        <path
          d='M54.666 109.682c1.836-4.901 2.743-7.437 4.433-10.387 1.677-2.937 1.399-4.624.165-3.168-1.407 1.683-3.385 6.008-3.768 6.843 0 0-1.673 3.121-1.974 5.875-.059.693.901 1.439 1.144.837z'
          fill='#282d2d'
        />
        <path
          d='M54.754 109.39c1.816-4.935 2.705-7.276 4.289-10.078 1.572-2.79 1.369-4.466.213-3.087-1.344 1.623-3.288 5.891-3.702 6.795 0 0-1.581 3.015-1.854 5.572-.056.679.82 1.389 1.054.798z'
          fill='#2a2f2f'
        />
        <path
          d='M54.842 109.097c1.798-4.966 2.667-7.113 4.144-9.769 1.467-2.644 1.339-4.308.261-3.006-1.28 1.565-3.191 5.773-3.637 6.747 0 0-1.487 2.907-1.734 5.27-.051.664.742 1.341.966.758z'
          fill='#2e3333'
        />
        <path
          d='M54.931 108.805c1.778-4.999 2.627-6.953 3.999-9.46 1.363-2.498 1.309-4.15.31-2.925-1.217 1.507-3.094 5.657-3.572 6.7 0 0-1.394 2.799-1.614 4.966-.048.649.66 1.291.877.719z'
          fill='#2f3534'
        />
        <path
          d='M55.02 108.512c1.759-5.03 2.589-6.79 3.854-9.15 1.258-2.353 1.279-3.993.358-2.844-1.153 1.448-2.997 5.54-3.507 6.652 0 0-1.301 2.691-1.494 4.663-.044.636.581 1.241.789.679z'
          fill='#323838'
        />
        <path
          d='M55.108 108.22c1.739-5.063 2.55-6.63 3.709-8.842 1.154-2.205 1.25-3.835.406-2.764-1.089 1.392-2.899 5.424-3.441 6.606 0 0-1.208 2.583-1.375 4.358-.039.624.502 1.193.701.642z'
          fill='#353a3a'
        />
        <path
          d='M55.197 107.928c1.72-5.096 2.512-6.469 3.565-8.532 1.049-2.061 1.219-3.678.454-2.684-1.026 1.333-2.803 5.307-3.376 6.559 0 0-1.115 2.477-1.255 4.057-.036.607.421 1.142.612.6z'
          fill='#383d3d'
        />
        <path
          d='M55.286 107.635c1.701-5.127 2.474-6.307 3.42-8.223.945-1.915 1.189-3.52.502-2.604-.962 1.276-2.706 5.19-3.312 6.513 0 0-1.021 2.368-1.135 3.753-.032.594.343 1.093.525.561z'
          fill='#3b3f3f'
        />
        <path
          d='M55.375 107.343c1.681-5.16 2.435-6.146 3.275-7.914s1.159-3.362.551-2.521c-.898 1.217-2.608 5.072-3.247 6.464 0 0-.927 2.261-1.014 3.449-.029.579.261 1.043.435.522z'
          fill='#3d4243'
        />
        <path d='M141.805 116.5c1-2.827.896-10.03-1.654-14.684-.896-1.688-1.758-3.653-2.275-3.757-.551-.104-1.551.999-1.447 1.172.137.275 4.791 6.824 3.826 15.717-.069.724 1.307 2.206 1.55 1.552z' />
        <path
          d='M141.76 116.134c.969-2.737.84-9.756-1.602-14.2-.869-1.631-1.691-3.529-2.199-3.633-.537-.103-1.496.968-1.4 1.134.125.269 4.6 6.595 3.701 15.2-.065.712 1.265 2.133 1.5 1.499z'
          fill='#000202'
        />
        <path
          d='M141.715 115.769c.939-2.648.783-9.481-1.547-13.717-.842-1.572-1.627-3.405-2.125-3.51-.521-.102-1.439.937-1.352 1.095.107.263 4.404 6.366 3.574 14.683-.056.701 1.223 2.061 1.45 1.449z'
          fill='#021010'
        />
        <path
          d='M141.67 115.403c.91-2.56.727-9.207-1.492-13.233-.816-1.515-1.562-3.282-2.051-3.387-.508-.1-1.385.905-1.305 1.057.094.256 4.213 6.137 3.449 14.166-.05.689 1.18 1.988 1.399 1.397z'
          fill='#0b1413'
        />
        <path
          d='M141.623 115.039c.883-2.472.67-8.935-1.436-12.751-.791-1.457-1.498-3.158-1.977-3.263-.492-.099-1.33.872-1.256 1.018.08.249 4.018 5.907 3.32 13.649-.042.678 1.14 1.915 1.349 1.347z'
          fill='#131919'
        />
        <path
          d='M141.578 114.674c.854-2.382.613-8.66-1.383-12.269-.764-1.399-1.434-3.034-1.9-3.139-.479-.098-1.275.841-1.209.979.064.241 3.826 5.678 3.197 13.132-.037.667 1.094 1.844 1.295 1.297z'
          fill='#171d1d'
        />
        <path
          d='M141.533 114.309c.824-2.294.557-8.387-1.328-11.785-.736-1.341-1.367-2.911-1.826-3.015-.463-.098-1.219.809-1.162.939.051.236 3.633 5.449 3.072 12.615-.031.656 1.051 1.771 1.244 1.246z'
          fill='#1e2323'
        />
        <path
          d='M141.488 113.943c.793-2.204.5-8.112-1.273-11.302-.711-1.283-1.303-2.787-1.752-2.892-.449-.097-1.164.777-1.113.901.035.229 3.439 5.221 2.945 12.098-.025.646 1.008 1.699 1.193 1.195z'
          fill='#232828'
        />
        <path
          d='M141.443 113.578c.764-2.116.443-7.839-1.219-10.818-.686-1.227-1.24-2.664-1.678-2.769-.434-.096-1.109.746-1.066.863.021.222 3.248 4.99 2.82 11.581-.017.632.964 1.625 1.143 1.143z'
          fill='#282d2d'
        />
        <path
          d='M141.398 113.213c.734-2.026.385-7.564-1.166-10.336-.656-1.168-1.174-2.54-1.602-2.645-.42-.094-1.055.714-1.018.825.006.215 3.053 4.761 2.691 11.063-.008.622.924 1.554 1.095 1.093z'
          fill='#2b3131'
        />
        <path
          d='M141.352 112.847c.707-1.938.33-7.289-1.109-9.852-.631-1.109-1.109-2.416-1.527-2.521-.404-.093-.998.682-.971.786-.008.208 2.861 4.532 2.566 10.546-.004.61.878 1.48 1.041 1.041z'
          fill='#2f3534'
        />
        <path
          d='M141.307 112.482c.678-1.85.273-7.017-1.057-9.37-.604-1.051-1.043-2.292-1.451-2.396-.391-.092-.943.65-.922.748-.023.201 2.668 4.303 2.439 10.029.002.598.836 1.406.991.989z'
          fill='#343a39'
        />
        <path
          d='M141.262 112.117c.646-1.76.217-6.742-1.002-8.887-.576-.993-.979-2.168-1.377-2.273-.375-.091-.889.618-.875.71-.037.195 2.475 4.073 2.314 9.512.008.586.793 1.334.94.938z'
          fill='#383d3d'
        />
        <path
          d='M141.217 111.752c.617-1.671.16-6.468-.947-8.403-.551-.937-.914-2.045-1.303-2.15-.361-.09-.834.588-.828.671-.051.189 2.283 3.845 2.189 8.995.016.574.75 1.261.889.887z'
          fill='#3c4242'
        />
        <path
          d='M141.172 111.387c.588-1.583.102-6.194-.893-7.921-.525-.878-.85-1.92-1.227-2.025-.348-.089-.779.555-.781.632-.066.182 2.09 3.615 2.062 8.478.022.563.708 1.188.839.836z'
          fill='#414747'
        />
        <path
          d='M141.127 111.021c.559-1.493.047-5.92-.84-7.437-.498-.82-.783-1.798-1.152-1.903-.332-.088-.723.524-.732.594-.08.175 1.896 3.386 1.938 7.961.026.552.663 1.117.786.785z'
          fill='#454a4b'
        />
        <path
          d='M141.08 110.656c.531-1.404-.01-5.646-.783-6.954-.471-.762-.719-1.674-1.078-1.779-.316-.087-.668.492-.684.555-.096.169 1.701 3.157 1.811 7.444.033.541.621 1.044.734.734z'
          fill='#4a4e4e'
        />
        <path
          d='M141.035 110.291c.5-1.315-.066-5.372-.729-6.471-.445-.704-.654-1.55-1.002-1.655-.305-.086-.613.46-.639.516-.109.162 1.51 2.928 1.686 6.927.042.529.579.972.684.683z'
          fill='#4f5455'
        />
        <path
          d='M140.99 109.926c.471-1.228-.123-5.099-.674-5.988-.42-.646-.59-1.426-.928-1.531-.289-.085-.559.429-.592.478-.123.155 1.318 2.698 1.561 6.409.047.518.534.898.633.632z'
          fill='#545859'
        />
        <path
          d='M140.945 109.562c.441-1.139-.18-4.825-.621-5.505-.391-.589-.523-1.304-.852-1.409-.275-.084-.504.397-.543.439-.139.148 1.123 2.469 1.434 5.893.053.505.491.826.582.582z'
          fill='#575c5c'
        />
        <path
          d='M140.9 109.195c.412-1.049-.236-4.55-.566-5.021-.365-.53-.459-1.179-.777-1.285-.26-.082-.449.366-.496.401-.152.142.932 2.24 1.309 5.375.058.495.446.754.53.53z'
          fill='#5c6060'
        />
        <path d='M137.875 98.06c-4.895-6.687-11.236-8.858-10.582-6.756 0 0 4.861 2.757 9.135 7.928.826.998 2.205-.138 1.447-1.172z' />
        <path
          d='M137.66 97.874c-4.752-6.46-10.959-8.574-10.312-6.558.01.005 4.74 2.687 8.91 7.694.803.964 2.137-.137 1.402-1.136z'
          fill='#000202'
        />
        <path
          d='M137.445 97.689c-4.607-6.234-10.684-8.291-10.043-6.361.018.011 4.621 2.618 8.684 7.46.779.93 2.07-.136 1.359-1.099z'
          fill='#021010'
        />
        <path
          d='M137.229 97.504c-4.463-6.007-10.406-8.007-9.77-6.163.025.016 4.5 2.547 8.457 7.225.756.895 2.002-.134 1.313-1.062z'
          fill='#0b1413'
        />
        <path
          d='M137.014 97.318c-4.32-5.781-10.131-7.723-9.502-5.965.035.021 4.383 2.477 8.234 6.99.731.862 1.936-.131 1.268-1.025z'
          fill='#131919'
        />
        <path
          d='M136.797 97.134c-4.176-5.555-9.854-7.44-9.23-5.768.043.026 4.264 2.405 8.01 6.756.706.827 1.866-.131 1.22-.988z'
          fill='#171d1d'
        />
        <path
          d='M136.582 96.948c-4.033-5.328-9.576-7.156-8.959-5.57.051.032 4.141 2.336 7.781 6.522.684.792 1.799-.129 1.178-.952z'
          fill='#1e2323'
        />
        <path
          d='M136.367 96.764c-3.891-5.102-9.301-6.873-8.689-5.373.059.037 4.021 2.266 7.557 6.288.66.757 1.732-.129 1.132-.915z'
          fill='#232828'
        />
        <path
          d='M136.152 96.578c-3.746-4.875-9.025-6.589-8.42-5.175.068.042 3.9 2.195 7.332 6.054.635.722 1.663-.128 1.088-.879z'
          fill='#282d2d'
        />
        <path
          d='M135.936 96.393c-3.602-4.648-8.748-6.305-8.148-4.977.076.047 3.781 2.125 7.105 5.82.613.687 1.597-.128 1.043-.843z'
          fill='#2b3131'
        />
        <path
          d='M135.721 96.208c-3.459-4.423-8.471-6.021-7.879-4.78.084.053 3.662 2.056 6.881 5.586.588.652 1.529-.126.998-.806z'
          fill='#2f3534'
        />
        <path
          d='M135.504 96.022c-3.312-4.196-8.193-5.737-7.607-4.583.094.059 3.543 1.985 6.656 5.352.564.619 1.461-.123.951-.769z'
          fill='#343a39'
        />
        <path
          d='M135.289 95.838c-3.17-3.97-7.918-5.454-7.338-4.386.102.064 3.424 1.916 6.432 5.118.539.583 1.392-.123.906-.732z'
          fill='#383d3d'
        />
        <path
          d='M135.074 95.653c-3.027-3.744-7.643-5.17-7.068-4.188.111.069 3.303 1.845 6.205 4.884.518.548 1.326-.122.863-.696z'
          fill='#3c4242'
        />
        <path
          d='M134.859 95.467c-2.885-3.517-7.365-4.886-6.799-3.99.119.075 3.184 1.774 5.98 4.649.493.515 1.259-.119.819-.659z'
          fill='#414747'
        />
        <path
          d='M134.643 95.283c-2.74-3.291-7.088-4.603-6.525-3.792.125.08 3.062 1.704 5.754 4.415.468.478 1.189-.12.771-.623z'
          fill='#454a4b'
        />
        <path
          d='M134.428 95.098c-2.598-3.064-6.812-4.32-6.258-3.595.137.085 2.945 1.634 5.529 4.181.446.444 1.123-.119.729-.586z'
          fill='#4a4e4e'
        />
        <path
          d='M134.211 94.912c-2.451-2.838-6.535-4.035-5.986-3.397.145.091 2.824 1.564 5.305 3.947.419.41 1.054-.117.681-.55z'
          fill='#4f5455'
        />
        <path
          d='M133.996 94.727c-2.309-2.611-6.258-3.751-5.717-3.2.154.096 2.705 1.494 5.08 3.713.397.375.987-.115.637-.513z'
          fill='#545859'
        />
        <path
          d='M133.781 94.542c-2.166-2.385-5.982-3.468-5.447-3.002.162.101 2.586 1.424 4.855 3.478.373.341.918-.114.592-.476z'
          fill='#575c5c'
        />
        <path
          d='M133.566 94.356c-2.023-2.158-5.707-3.184-5.176-2.804.168.106 2.463 1.353 4.627 3.244.35.307.852-.112.549-.44z'
          fill='#5c6060'
        />
        <path d='M141.805 116.5c.068 2.998-2.758 10.65-3.826 10.374-1.207-.275.24-2.516 1.344-6.308.449-1.585.793-5.479.932-5.618.446-.448 1.55.827 1.55 1.552z' />
        <path
          d='M141.717 116.749c.049 2.912-2.699 10.337-3.732 10.062-1.16-.271.262-2.464 1.348-6.19.43-1.52.764-5.239.902-5.384.437-.443 1.505.731 1.482 1.512z'
          fill='#000807'
        />
        <path
          d='M141.629 116.999c.029 2.825-2.641 10.023-3.639 9.749-1.113-.268.283-2.411 1.35-6.071.412-1.455.734-5.001.877-5.15.426-.441 1.461.634 1.412 1.472z'
          fill='#061212'
        />
        <path
          d='M141.541 117.249c.01 2.738-2.582 9.711-3.545 9.436-1.066-.264.303-2.357 1.354-5.951.395-1.393.703-4.763.848-4.919.413-.437 1.415.538 1.343 1.434z'
          fill='#131919'
        />
        <path
          d='M141.451 117.498c-.01 2.653-2.521 9.397-3.451 9.124-1.018-.26.326-2.307 1.357-5.834.377-1.327.676-4.522.822-4.686.403-.431 1.372.443 1.272 1.396z'
          fill='#1b1f1f'
        />
        <path
          d='M141.363 117.748c-.029 2.565-2.463 9.084-3.357 8.811-.969-.255.346-2.253 1.359-5.716.359-1.262.646-4.282.795-4.451.393-.428 1.326.345 1.203 1.356z'
          fill='#222727'
        />
        <path
          d='M141.275 117.998c-.049 2.479-2.404 8.771-3.264 8.497-.924-.251.367-2.2 1.363-5.597.34-1.198.617-4.043.768-4.218.379-.423 1.282.25 1.133 1.318z'
          fill='#282d2d'
        />
        <path
          d='M141.188 118.247c-.068 2.393-2.346 8.458-3.17 8.186-.877-.247.387-2.148 1.367-5.479.32-1.133.588-3.804.738-3.985.369-.419 1.236.153 1.065 1.278z'
          fill='#2e3333'
        />
        <path
          d='M141.1 118.497c-.088 2.306-2.287 8.145-3.078 7.871-.828-.242.41-2.095 1.371-5.359.305-1.068.559-3.563.713-3.751.357-.415 1.191.056.994 1.239z'
          fill='#323838'
        />
        <path
          d='M141.012 118.747c-.107 2.22-2.229 7.831-2.982 7.559-.783-.238.428-2.042 1.371-5.241.287-1.004.531-3.324.686-3.519.345-.411 1.145-.039.925 1.201z'
          fill='#383d3d'
        />
        <path
          d='M140.924 118.997c-.129 2.133-2.17 7.518-2.891 7.245-.734-.234.451-1.99 1.377-5.123.268-.938.5-3.084.658-3.284.334-.406 1.1-.135.856 1.162z'
          fill='#3d4243'
        />
        <path
          d='M140.836 119.246c-.148 2.046-2.109 7.205-2.797 6.933-.688-.229.471-1.937 1.379-5.004.252-.875.473-2.845.631-3.052.324-.402 1.056-.231.787 1.123z'
          fill='#434849'
        />
        <path
          d='M140.748 119.496c-.168 1.96-2.053 6.892-2.703 6.619-.641-.226.492-1.884 1.383-4.885.232-.81.441-2.604.604-2.819.312-.397 1.009-.327.716 1.085z'
          fill='#4a4e4e'
        />
        <path
          d='M140.66 119.745c-.188 1.874-1.992 6.578-2.609 6.307-.594-.221.514-1.831 1.385-4.767.215-.745.414-2.364.576-2.585.3-.394.967-.424.648 1.045z'
          fill='#505555'
        />
        <path
          d='M140.57 119.995c-.205 1.787-1.932 6.265-2.514 5.994-.547-.217.533-1.779 1.387-4.648.197-.681.385-2.125.549-2.353.291-.389.922-.519.578 1.007z'
          fill='#565a5a'
        />
        <path
          d='M140.482 120.245c-.227 1.7-1.873 5.952-2.422 5.681-.498-.213.557-1.726 1.393-4.529.178-.616.355-1.886.521-2.119.28-.386.878-.617.508.967z'
          fill='#5c6060'
        />
        <path
          d='M140.395 120.495c-.246 1.613-1.816 5.639-2.326 5.367-.453-.208.574-1.673 1.393-4.411.162-.551.326-1.646.494-1.885.267-.381.831-.712.439.929z'
          fill='#616464'
        />
        <path
          d='M140.307 120.744c-.266 1.527-1.756 5.325-2.234 5.056-.402-.204.598-1.621 1.398-4.293.143-.486.297-1.407.467-1.652.255-.377.787-.809.369.889z'
          fill='#676a6a'
        />
        <path
          d='M140.219 120.994c-.285 1.44-1.699 5.012-2.141 4.742-.357-.2.617-1.568 1.4-4.175.125-.422.268-1.166.439-1.419.247-.371.743-.904.302.852z'
          fill='#6a6d6e'
        />
        <path
          d='M140.131 121.243c-.305 1.354-1.639 4.699-2.047 4.43-.311-.195.639-1.516 1.404-4.056.105-.357.238-.928.412-1.186.233-.369.696-1 .231.812z'
          fill='#6f7273'
        />
        <path
          d='M140.043 121.493c-.326 1.268-1.58 4.386-1.953 4.117-.264-.192.658-1.463 1.406-3.938.09-.293.209-.688.385-.952.223-.365.652-1.097.162.773z'
          fill='#767778'
        />
        <path d='M131.291 98.438c-.734.776-1.389 4.783.451 6.173 1.84 1.349 5.068-1.146 5.068-2.576-.04-3.228-4.783-4.415-5.519-3.597z' />
        <path d='M131.357 98.505c-.746.771-1.377 4.703.41 6.064 1.787 1.322 4.955-1.088 4.971-2.527-.021-3.147-4.634-4.348-5.381-3.537z' />
        <path
          d='M131.422 98.573c-.756.766-1.363 4.621.371 5.954 1.734 1.297 4.84-1.031 4.873-2.479-.004-3.065-4.486-4.28-5.244-3.475z'
          fill='#000202'
        />
        <path
          d='M131.488 98.64c-.768.761-1.352 4.541.332 5.846 1.68 1.271 4.725-.975 4.775-2.43.014-2.984-4.339-4.215-5.107-3.416z'
          fill='#000807'
        />
        <path
          d='M131.553 98.708c-.777.755-1.338 4.459.293 5.736 1.627 1.244 4.609-.919 4.676-2.382.035-2.902-4.19-4.147-4.969-3.354z'
          fill='#021010'
        />
        <path
          d='M131.619 98.774c-.789.751-1.324 4.379.254 5.628 1.572 1.218 4.494-.862 4.578-2.333.051-2.82-4.041-4.081-4.832-3.295z'
          fill='#061212'
        />
        <path
          d='M131.686 98.842c-.801.745-1.312 4.298.213 5.519 1.52 1.192 4.379-.805 4.48-2.285.07-2.739-3.893-4.014-4.693-3.234z'
          fill='#0b1413'
        />
        <path
          d='M131.75 98.909c-.811.74-1.299 4.217.176 5.41 1.465 1.165 4.264-.75 4.381-2.236.089-2.657-3.745-3.947-4.557-3.174z'
          fill='#121818'
        />
        <path
          d='M131.816 98.976c-.822.735-1.287 4.137.135 5.302 1.414 1.14 4.15-.692 4.283-2.188.11-2.575-3.595-3.881-4.418-3.114z'
          fill='#131919'
        />
        <path
          d='M131.881 99.043c-.832.73-1.273 4.056.096 5.192 1.361 1.113 4.037-.636 4.186-2.14.126-2.492-3.448-3.814-4.282-3.052z'
          fill='#151a1a'
        />
        <path
          d='M131.947 99.11c-.844.725-1.262 3.975.057 5.084 1.307 1.086 3.92-.58 4.086-2.092.146-2.411-3.299-3.747-4.143-2.992z'
          fill='#171d1d'
        />
        <path
          d='M132.012 99.178c-.854.72-1.248 3.894.018 4.975 1.254 1.061 3.807-.524 3.988-2.043.166-2.33-3.151-3.68-4.006-2.932z'
          fill='#1c2020'
        />
        <path
          d='M132.078 99.245c-.865.714-1.236 3.812-.023 4.865 1.201 1.035 3.691-.467 3.893-1.994.181-2.247-3.005-3.613-3.87-2.871z'
          fill='#1e2323'
        />
        <path
          d='M132.143 99.312c-.875.709-1.221 3.732-.061 4.758 1.148 1.008 3.576-.411 3.793-1.946.201-2.167-2.855-3.548-3.732-2.812z'
          fill='#212626'
        />
        <path
          d='M132.209 99.38c-.887.703-1.209 3.65-.102 4.647 1.096.981 3.461-.354 3.695-1.897.221-2.084-2.706-3.481-3.593-2.75z'
          fill='#232828'
        />
        <path
          d='M132.273 99.446c-.895.699-1.195 3.57-.139 4.539 1.041.956 3.346-.298 3.596-1.85.241-2.001-2.558-3.412-3.457-2.689z'
          fill='#252b2a'
        />
        <path
          d='M132.34 99.514c-.906.693-1.184 3.489-.18 4.43.988.931 3.23-.24 3.498-1.801.258-1.92-2.408-3.347-3.318-2.629z'
          fill='#282d2d'
        />
        <path
          d='M132.406 99.581c-.918.688-1.172 3.408-.219 4.321.934.903 3.115-.185 3.398-1.753.278-1.837-2.259-3.279-3.179-2.568z'
          fill='#292e2e'
        />
        <path
          d='M132.471 99.648c-.928.683-1.156 3.328-.258 4.212.881.877 3.002-.129 3.301-1.704.297-1.757-2.112-3.213-3.043-2.508z'
          fill='#2b3131'
        />
        <path
          d='M132.535 99.716c-.938.677-1.145 3.246-.297 4.104.83.851 2.887-.072 3.203-1.656.315-1.676-1.962-3.146-2.906-2.448z'
          fill='#2e3333'
        />
        <path
          d='M132.602 99.782c-.949.673-1.131 3.167-.336 3.995.775.824 2.771-.016 3.104-1.608.333-1.592-1.815-3.078-2.768-2.387z'
          fill='#2f3534'
        />
        <path d='M131.119 131.39c.725.724 1.346-1.068 2.068-1.654.725-.586 1.896-1.861 4.24-1.861s2.17-.069 2.066-.965c-.068-.862-.758-.793-2.826-.482a6.716 6.716 0 0 0-4.273 2.309c-.792.929-1.687 2.239-1.275 2.653z' />
        <path
          d='M131.279 131.213c.697.694 1.293-1.015 2.012-1.582.719-.568 1.883-1.787 4.137-1.787s2.113-.062 2.014-.929c-.064-.834-.738-.762-2.752-.469a6.543 6.543 0 0 0-4.17 2.226c-.774.898-1.647 2.141-1.241 2.541z'
          fill='#000807'
        />
        <path
          d='M131.438 131.037c.674.665 1.244-.961 1.959-1.511.713-.55 1.867-1.712 4.033-1.713 2.164 0 2.053-.053 1.959-.892-.062-.808-.717-.732-2.678-.456a6.381 6.381 0 0 0-4.066 2.143c-.754.867-1.602 2.043-1.207 2.429z'
          fill='#061212'
        />
        <path
          d='M131.598 130.862c.648.634 1.193-.908 1.902-1.44s1.854-1.638 3.93-1.639c2.074-.001 1.996-.045 1.906-.854-.059-.781-.699-.702-2.604-.442a6.216 6.216 0 0 0-3.963 2.058c-.734.834-1.558 1.945-1.171 2.317z'
          fill='#131919'
        />
        <path
          d='M131.756 130.686c.623.604 1.145-.854 1.848-1.368s1.84-1.562 3.826-1.563c1.986-.002 1.939-.037 1.854-.819-.057-.753-.68-.671-2.529-.428a6.047 6.047 0 0 0-3.859 1.973c-.716.802-1.517 1.847-1.14 2.205z'
          fill='#1b1f1f'
        />
        <path
          d='M131.916 130.509c.598.576 1.094-.8 1.793-1.296.697-.497 1.824-1.488 3.721-1.489 1.898-.002 1.881-.029 1.801-.782-.055-.726-.658-.641-2.455-.414a5.879 5.879 0 0 0-3.756 1.889c-.694.771-1.473 1.749-1.104 2.092z'
          fill='#222727'
        />
        <path
          d='M132.076 130.333c.572.547 1.043-.746 1.736-1.225s1.811-1.414 3.619-1.415c1.807-.001 1.82-.021 1.746-.745-.051-.699-.639-.611-2.381-.401-1.74.183-2.955 1.04-3.652 1.805-.673.74-1.429 1.652-1.068 1.981z'
          fill='#282d2d'
        />
        <path
          d='M132.234 130.157c.547.516.994-.692 1.682-1.153.689-.46 1.797-1.339 3.516-1.341s1.764-.013 1.693-.708c-.049-.673-.619-.581-2.307-.388-1.688.166-2.873.989-3.551 1.721-.652.708-1.382 1.554-1.033 1.869z'
          fill='#2e3333'
        />
        <path
          d='M132.395 129.981c.521.486.943-.639 1.627-1.082.682-.442 1.781-1.264 3.41-1.266 1.629-.003 1.707-.006 1.641-.672-.045-.646-.602-.551-2.232-.375-1.633.151-2.791.939-3.447 1.637-.634.677-1.343 1.456-.999 1.758z'
          fill='#323838'
        />
        <path
          d='M132.555 129.806c.496.457.893-.586 1.57-1.01.678-.426 1.768-1.19 3.307-1.193 1.541-.002 1.648.004 1.588-.634-.043-.619-.58-.521-2.158-.361-1.578.136-2.709.889-3.342 1.553-.616.643-1.299 1.357-.965 1.645z'
          fill='#383d3d'
        />
        <path
          d='M132.713 129.63c.473.427.844-.532 1.516-.939.674-.406 1.754-1.115 3.205-1.117 1.449-.003 1.588.011 1.531-.598-.039-.593-.559-.491-2.084-.348-1.521.119-2.625.838-3.238 1.468-.594.613-1.254 1.259-.93 1.534z'
          fill='#3d4243'
        />
        <path
          d='M132.873 129.453c.445.397.793-.478 1.461-.867.666-.389 1.738-1.04 3.1-1.043s1.531.019 1.479-.562c-.035-.564-.539-.46-2.01-.334-1.469.104-2.543.788-3.135 1.385-.575.581-1.211 1.161-.895 1.421z'
          fill='#434849'
        />
        <path
          d='M133.031 129.277c.422.368.744-.425 1.406-.796s1.725-.966 2.996-.969c1.273-.003 1.475.027 1.426-.524-.033-.538-.52-.43-1.936-.32-1.414.089-2.461.736-3.031 1.3-.554.55-1.167 1.064-.861 1.309z'
          fill='#4a4e4e'
        />
        <path
          d='M133.191 129.101c.396.339.693-.37 1.35-.724.658-.353 1.713-.892 2.895-.895s1.414.036 1.371-.487c-.029-.511-.5-.399-1.861-.308-1.359.073-2.379.688-2.928 1.216-.536.519-1.125.967-.827 1.198z'
          fill='#505555'
        />
        <path
          d='M133.35 128.925c.371.309.645-.316 1.297-.651s1.697-.817 2.789-.821c1.094-.003 1.357.044 1.318-.451-.027-.483-.48-.369-1.787-.293-1.305.058-2.299.636-2.824 1.132-.516.485-1.082.867-.793 1.084z'
          fill='#565a5a'
        />
        <path
          d='M133.51 128.749c.346.279.594-.263 1.24-.58s1.684-.743 2.686-.746c1.004-.004 1.299.052 1.266-.415-.023-.456-.461-.339-1.713-.279-1.25.042-2.217.585-2.721 1.048-.497.453-1.039.769-.758.972z'
          fill='#5c6060'
        />
        <path
          d='M133.67 128.573c.318.249.543-.21 1.184-.509.643-.3 1.67-.668 2.584-.672.914-.005 1.24.06 1.211-.378-.021-.43-.441-.309-1.639-.266-1.195.025-2.135.534-2.617.963-.477.424-.997.673-.723.862z'
          fill='#616464'
        />
        <path
          d='M133.828 128.396c.295.221.492-.155 1.131-.437.637-.282 1.654-.594 2.479-.598s1.182.067 1.158-.341c-.02-.403-.422-.279-1.564-.253-1.143.011-2.053.484-2.514.88-.457.392-.952.575-.69.749z'
          fill='#676a6a'
        />
        <path
          d='M133.988 128.22c.27.191.441-.101 1.074-.364.631-.264 1.641-.519 2.375-.522.736-.006 1.125.074 1.105-.306-.016-.375-.402-.248-1.49-.238-1.088-.005-1.971.433-2.41.795-.437.358-.908.475-.654.635z'
          fill='#6a6d6e'
        />
        <path
          d='M134.146 128.045c.244.161.395-.048 1.02-.294.627-.246 1.627-.444 2.271-.449.646-.005 1.066.084 1.053-.267-.014-.35-.383-.219-1.416-.227-1.033-.021-1.889.384-2.309.712-.415.327-.863.377-.619.525z'
          fill='#6f7273'
        />
        <path
          d='M134.307 127.869c.219.131.342.005.963-.223.623-.228 1.613-.37 2.17-.374.557-.006 1.008.091.998-.231-.01-.321-.363-.188-1.342-.212-.979-.036-1.807.332-2.205.627-.397.296-.821.279-.584.413z'
          fill='#767778'
        />
        <path d='M140.461 126.874c0 1.104 2.24 1.482 4.377 1.793 2.102.31 3.791.724 3.895 2.929.104 2.172-.447 3.724.518 3.551 2.068-.379 2.826-2.619 2.791-3.653 0-1.034-1.516-2.896-4.238-3.93-2-.758-3.447-1.103-5.205-1.172-2.345-.104-2.138.482-2.138.482z' />
        <path
          d='M140.521 126.893c.006 1.071 2.223 1.422 4.332 1.742 2.082.323 3.709.723 3.881 2.873.166 2.114-.408 3.597.545 3.442 1.984-.355 2.723-2.49 2.684-3.511-.006-1.03-1.492-2.829-4.18-3.849-1.977-.748-3.404-1.1-5.143-1.174-2.275-.103-2.124.457-2.119.477z'
          fill='#000202'
        />
        <path
          d='M140.582 126.911c.01 1.04 2.203 1.362 4.285 1.691 2.064.336 3.629.723 3.871 2.817.225 2.056-.369 3.472.57 3.333 1.9-.33 2.619-2.36 2.576-3.367-.014-1.026-1.469-2.764-4.121-3.767-1.955-.739-3.361-1.097-5.08-1.176-2.206-.103-2.111.43-2.101.469z'
          fill='#021010'
        />
        <path
          d='M140.641 126.929c.018 1.01 2.188 1.303 4.24 1.643 2.045.348 3.549.721 3.861 2.76.285 1.998-.33 3.346.596 3.226 1.816-.306 2.516-2.231 2.469-3.225-.021-1.022-1.445-2.697-4.062-3.687-1.932-.729-3.318-1.093-5.016-1.178-2.141-.105-2.1.402-2.088.461z'
          fill='#0b1413'
        />
        <path
          d='M140.701 126.947c.023.979 2.17 1.243 4.195 1.592 2.025.36 3.467.72 3.848 2.704.348 1.939-.291 3.221.623 3.116 1.732-.28 2.412-2.101 2.363-3.08-.029-1.02-1.424-2.632-4.006-3.605-1.91-.72-3.277-1.091-4.953-1.18-2.072-.105-2.085.374-2.07.453z'
          fill='#131919'
        />
        <path
          d='M140.762 126.966c.029.947 2.152 1.182 4.148 1.542 2.008.373 3.387.719 3.836 2.647.41 1.882-.25 3.094.65 3.008 1.648-.257 2.309-1.973 2.254-2.938-.035-1.016-1.396-2.566-3.945-3.524-1.887-.71-3.232-1.088-4.891-1.182-2.003-.105-2.074.348-2.052.447z'
          fill='#171d1d'
        />
        <path
          d='M140.822 126.984c.033.916 2.135 1.121 4.104 1.491 1.986.386 3.305.718 3.824 2.592.469 1.823-.213 2.969.674 2.898 1.566-.231 2.207-1.842 2.15-2.794-.043-1.011-1.375-2.5-3.889-3.442-1.865-.701-3.191-1.086-4.826-1.185-1.937-.106-2.062.322-2.037.44z'
          fill='#1e2323'
        />
        <path
          d='M140.883 127.003c.039.885 2.115 1.062 4.057 1.44 1.969.398 3.225.717 3.814 2.535.529 1.767-.174 2.843.699 2.79 1.482-.207 2.104-1.711 2.043-2.65-.051-1.007-1.352-2.434-3.83-3.362-1.842-.689-3.148-1.081-4.764-1.185-1.869-.107-2.048.293-2.019.432z'
          fill='#232828'
        />
        <path
          d='M140.943 127.021c.045.854 2.098 1.002 4.012 1.391 1.949.412 3.143.717 3.801 2.479.592 1.708-.133 2.717.727 2.682 1.398-.183 2-1.583 1.936-2.507-.057-1.004-1.328-2.369-3.771-3.281-1.82-.681-3.105-1.079-4.701-1.188-1.802-.109-2.039.266-2.004.424z'
          fill='#282d2d'
        />
        <path
          d='M141.004 127.039c.051.822 2.08.942 3.965 1.341 1.93.424 3.061.715 3.791 2.423.65 1.649-.096 2.592.752 2.573 1.314-.158 1.896-1.453 1.828-2.365-.064-.999-1.303-2.302-3.713-3.199-1.797-.671-3.062-1.075-4.639-1.189-1.732-.108-2.023.238-1.984.416z'
          fill='#2b3131'
        />
        <path
          d='M141.064 127.058c.055.791 2.062.882 3.92 1.29 1.91.438 2.98.714 3.777 2.366.713 1.593-.055 2.467.779 2.465 1.23-.133 1.795-1.324 1.721-2.221-.072-.996-1.281-2.236-3.654-3.119-1.775-.66-3.02-1.072-4.574-1.191-1.664-.109-2.012.212-1.969.41z'
          fill='#2f3534'
        />
        <path
          d='M141.123 127.076c.062.76 2.047.821 3.875 1.239 1.891.45 2.898.714 3.768 2.311.773 1.534-.018 2.34.805 2.356 1.148-.108 1.689-1.194 1.613-2.078-.078-.991-1.256-2.17-3.596-3.038-1.752-.651-2.977-1.069-4.512-1.193-1.596-.11-1.998.186-1.953.403z'
          fill='#343a39'
        />
        <path
          d='M141.184 127.095c.068.728 2.029.762 3.83 1.189 1.871.462 2.818.712 3.754 2.253.836 1.478.023 2.216.832 2.248 1.064-.084 1.586-1.064 1.506-1.935-.086-.987-1.232-2.104-3.537-2.957-1.73-.641-2.934-1.066-4.447-1.195-1.53-.109-1.989.159-1.938.397z'
          fill='#383d3d'
        />
        <path
          d='M141.244 127.112c.074.697 2.012.702 3.783 1.14 1.854.476 2.738.711 3.742 2.197.898 1.419.064 2.09.859 2.14.98-.06 1.484-.936 1.398-1.792-.092-.983-1.209-2.038-3.479-2.876-1.707-.631-2.891-1.062-4.385-1.197-1.459-.11-1.973.132-1.918.388z'
          fill='#3c4242'
        />
        <path
          d='M141.305 127.131c.078.666 1.994.642 3.738 1.089 1.832.488 2.654.711 3.73 2.141.957 1.362.102 1.965.885 2.031.896-.034 1.381-.806 1.291-1.647-.1-.98-1.186-1.973-3.42-2.796-1.684-.621-2.85-1.06-4.322-1.198-1.393-.111-1.961.103-1.902.38z'
          fill='#414747'
        />
        <path
          d='M141.365 127.148c.084.636 1.975.583 3.691 1.04 1.814.5 2.574.709 3.721 2.084 1.018 1.303.141 1.838.91 1.923.812-.011 1.275-.676 1.184-1.505-.105-.977-1.162-1.907-3.361-2.714-1.662-.612-2.805-1.058-4.26-1.201-1.324-.111-1.949.079-1.885.373z'
          fill='#454a4b'
        />
        <path
          d='M141.426 127.167c.09.604 1.957.522 3.646.989 1.795.514 2.492.708 3.707 2.028 1.08 1.245.182 1.713.938 1.814.729.014 1.172-.547 1.076-1.363-.113-.971-1.137-1.84-3.305-2.633-1.637-.602-2.762-1.053-4.193-1.202-1.258-.111-1.938.053-1.869.367z'
          fill='#4a4e4e'
        />
        <path
          d='M141.486 127.186c.096.573 1.939.462 3.6.938 1.775.526 2.412.708 3.697 1.972 1.139 1.188.219 1.588.963 1.706.645.039 1.07-.417.969-1.219-.119-.969-1.113-1.774-3.246-2.552-1.615-.593-2.719-1.051-4.131-1.205-1.19-.111-1.924.026-1.852.36z'
          fill='#4f5455'
        />
        <path
          d='M141.547 127.204c.102.541 1.922.401 3.555.889 1.756.538 2.33.706 3.684 1.915 1.201 1.13.26 1.462.99 1.597.561.063.967-.287.861-1.074-.127-.966-1.09-1.71-3.186-2.472-1.594-.582-2.678-1.048-4.07-1.206-1.121-.114-1.91-.004-1.834.351z'
          fill='#545859'
        />
        <path
          d='M141.605 127.223c.107.51 1.904.342 3.51.839 1.738.55 2.25.705 3.672 1.857 1.264 1.072.299 1.337 1.018 1.489.477.088.863-.158.754-.933-.135-.96-1.066-1.643-3.127-2.39-1.572-.572-2.635-1.045-4.006-1.208-1.055-.112-1.901-.029-1.821.346z'
          fill='#575c5c'
        />
        <path
          d='M141.666 127.241c.113.479 1.887.281 3.465.788 1.717.563 2.168.704 3.66 1.802 1.324 1.015.338 1.211 1.041 1.381.395.112.762-.028.648-.789-.141-.957-1.041-1.577-3.068-2.309-1.549-.563-2.592-1.042-3.943-1.212-.987-.112-1.887-.055-1.803.339z'
          fill='#5c6060'
        />
        <path
          d='M148.764 129.55c.787.479.646.985 1.154 1.324.252.168.676.168.422-.479-.338-.76-.619-1.183-2.365-1.887-1.127-.451-.903.028.789 1.042z'
          fill='#5c6060'
        />
        <path
          d='M148.783 129.556c.77.468.633.962 1.127 1.291.248.164.66.164.412-.467-.33-.742-.604-1.154-2.309-1.842-1.097-.438-.878.028.77 1.018z'
          fill='#5f6263'
        />
        <path
          d='M148.805 129.562c.75.455.615.938 1.098 1.259.24.161.643.161.402-.455-.322-.723-.59-1.125-2.25-1.795-1.071-.429-.858.027.75.991z'
          fill='#646768'
        />
        <path
          d='M148.826 129.567c.73.443.6.913 1.07 1.227.232.155.625.155.391-.443-.312-.705-.574-1.097-2.193-1.749-1.043-.417-.834.026.732.965z'
          fill='#676a6a'
        />
        <path
          d='M148.846 129.573c.713.432.584.89 1.041 1.194.229.151.611.151.383-.433-.307-.686-.561-1.066-2.135-1.701-1.016-.406-.813.025.711.94z'
          fill='#6a6d6e'
        />
        <path
          d='M148.867 129.579c.691.42.568.865 1.014 1.161.223.147.594.147.371-.42-.297-.667-.545-1.038-2.076-1.655-.988-.395-.791.024.691.914z'
          fill='#6f7273'
        />
        <path
          d='M148.889 129.585c.672.408.553.84.984 1.129.217.144.576.144.361-.408-.289-.648-.529-1.009-2.02-1.61-.96-.384-.767.024.675.889z'
          fill='#727575'
        />
        <path
          d='M148.908 129.591c.654.396.537.816.957 1.097.211.14.561.14.352-.397-.281-.63-.514-.979-1.961-1.562-.934-.375-.746.022.652.862z'
          fill='#767979'
        />
        <path
          d='M148.93 129.597c.635.385.521.792.928 1.064.205.135.545.135.34-.386-.271-.61-.498-.95-1.902-1.517-.905-.362-.724.022.634.839z'
          fill='#7b7c7d'
        />
        <path
          d='M148.951 129.602c.615.374.504.769.9 1.032.195.132.527.132.328-.373-.264-.593-.482-.922-1.844-1.471-.876-.352-.702.022.616.812z'
          fill='#7e8081'
        />
        <path
          d='M148.971 129.608c.596.361.49.743.873.999.191.127.51.127.318-.361-.256-.574-.469-.894-1.785-1.425-.852-.34-.682.021.594.787z'
          fill='#848586'
        />
        <path
          d='M148.992 129.614c.576.35.473.72.844.966.186.124.494.124.309-.35-.246-.555-.453-.863-1.729-1.378-.822-.329-.658.021.576.762z'
          fill='#888989'
        />
        <path
          d='M149.014 129.619c.557.339.457.696.814.935.18.119.479.119.299-.338-.238-.537-.438-.835-1.67-1.332-.795-.318-.637.02.557.735z'
          fill='#8c8d8d'
        />
        <path
          d='M149.033 129.626c.539.325.443.671.787.901.174.115.461.115.289-.325-.23-.52-.422-.807-1.613-1.286-.767-.308-.613.019.537.71z'
          fill='#8f8f8f'
        />
        <path
          d='M149.055 129.632c.518.314.426.646.76.869.166.11.443.11.277-.314-.223-.5-.408-.777-1.555-1.24-.74-.296-.592.019.518.685z'
          fill='#939394'
        />
        <path
          d='M149.076 129.637c.498.303.41.624.73.837.16.107.428.107.268-.302-.215-.481-.393-.748-1.496-1.194-.713-.285-.57.018.498.659z'
          fill='#98999a'
        />
        <path
          d='M149.098 129.643c.479.291.393.6.701.805.154.103.41.103.258-.291-.207-.462-.377-.719-1.439-1.146-.686-.276-.548.015.48.632z'
          fill='#9c9d9e'
        />
        <path
          d='M149.117 129.649c.461.279.379.574.674.771.148.099.395.099.248-.279-.197-.443-.363-.689-1.381-1.102-.658-.262-.525.019.459.61z'
          fill='#a0a0a0'
        />
        <path
          d='M149.139 129.654c.441.269.361.552.645.74.143.094.379.094.236-.268-.188-.426-.346-.661-1.322-1.055-.63-.251-.503.017.441.583z'
          fill='#a4a4a5'
        />
        <path
          d='M149.16 129.66c.422.256.346.526.617.708.135.09.361.09.225-.256-.18-.406-.33-.633-1.264-1.009-.601-.241-.482.015.422.557z'
          fill='#a6a6a7'
        />
        <path
          d='M149.18 129.666c.402.244.332.503.59.675.129.087.346.087.215-.244-.172-.388-.316-.603-1.205-.962-.575-.231-.462.014.4.531z'
          fill='#adadae'
        />
        <path d='M117.508 59.931c-.354.432.273 4.004 2.59 6.36 2.316 2.316 3.73 2.316 4.672 1.295 1.807-1.923.354-3.729-.746-4.986-1.1-1.256-2.512-.824-3.807-2.081-1.297-1.255-2.199-1.177-2.709-.588z' />
        <path
          d='M117.541 59.963c-.352.429.273 3.974 2.57 6.311 2.299 2.298 3.701 2.298 4.635 1.286 1.793-1.909.352-3.701-.738-4.947-1.092-1.247-2.494-.818-3.779-2.065-1.286-1.247-2.182-1.169-2.688-.585z'
          fill='#000807'
        />
        <path
          d='M117.574 59.996c-.348.425.271 3.942 2.551 6.26 2.279 2.28 3.672 2.28 4.6 1.275 1.777-1.894.348-3.672-.734-4.908s-2.473-.812-3.75-2.048c-1.274-1.237-2.165-1.159-2.667-.579z'
          fill='#061212'
        />
        <path
          d='M117.607 60.027c-.344.422.27 3.911 2.531 6.212 2.262 2.261 3.643 2.261 4.562 1.265 1.764-1.878.346-3.643-.729-4.869-1.072-1.228-2.453-.805-3.719-2.032s-2.145-1.151-2.645-.576z'
          fill='#131919'
        />
        <path
          d='M117.643 60.06c-.344.418.266 3.88 2.51 6.162 2.244 2.244 3.613 2.244 4.527 1.255 1.748-1.865.342-3.614-.725-4.832-1.064-1.217-2.434-.798-3.689-2.016-1.254-1.216-2.129-1.14-2.623-.569z'
          fill='#1b1f1f'
        />
        <path
          d='M117.676 60.091c-.34.416.264 3.849 2.49 6.114 2.227 2.225 3.584 2.225 4.49 1.244 1.734-1.848.34-3.584-.717-4.792s-2.414-.792-3.66-2c-1.246-1.207-2.113-1.132-2.603-.566z'
          fill='#222727'
        />
        <path
          d='M117.709 60.123c-.336.412.262 3.818 2.471 6.063 2.207 2.208 3.557 2.208 4.453 1.235 1.723-1.834.338-3.556-.711-4.753-1.047-1.198-2.395-.786-3.631-1.984-1.234-1.197-2.096-1.122-2.582-.561z'
          fill='#282d2d'
        />
        <path
          d='M117.742 60.155c-.334.409.262 3.787 2.451 6.015 2.189 2.189 3.525 2.189 4.418 1.225 1.707-1.819.334-3.527-.705-4.715-1.041-1.188-2.377-.779-3.602-1.968-1.224-1.188-2.079-1.113-2.562-.557z'
          fill='#2e3333'
        />
        <path
          d='M117.775 60.188c-.33.405.258 3.755 2.432 5.965 2.172 2.172 3.496 2.172 4.381 1.214 1.693-1.804.33-3.498-.699-4.675-1.031-1.179-2.357-.773-3.572-1.952s-2.061-1.104-2.542-.552z'
          fill='#323838'
        />
        <path
          d='M117.811 60.219c-.328.402.256 3.725 2.41 5.916 2.154 2.154 3.469 2.154 4.344 1.205 1.68-1.789.328-3.469-.693-4.637s-2.336-.767-3.543-1.935c-1.204-1.169-2.044-1.096-2.518-.549z'
          fill='#383d3d'
        />
        <path
          d='M117.844 60.251c-.326.398.254 3.693 2.389 5.866 2.139 2.136 3.441 2.136 4.311 1.194 1.664-1.773.324-3.439-.689-4.598-1.014-1.159-2.316-.761-3.512-1.919s-2.029-1.086-2.499-.543z'
          fill='#3d4243'
        />
        <path
          d='M117.877 60.283c-.322.396.252 3.663 2.369 5.816 2.119 2.118 3.412 2.118 4.273 1.185 1.652-1.759.322-3.411-.682-4.559-1.006-1.15-2.299-.754-3.482-1.903-1.185-1.149-2.011-1.077-2.478-.539z'
          fill='#434849'
        />
        <path
          d='M117.91 60.316c-.318.391.25 3.631 2.352 5.766 2.1 2.1 3.381 2.1 4.234 1.175 1.639-1.744.32-3.382-.676-4.521s-2.277-.748-3.453-1.887c-1.174-1.139-1.994-1.068-2.457-.533z'
          fill='#4a4e4e'
        />
        <path
          d='M117.945 60.348c-.318.388.246 3.6 2.328 5.717 2.082 2.082 3.354 2.082 4.201 1.164 1.623-1.729.316-3.352-.672-4.481-.988-1.13-2.258-.742-3.422-1.87-1.165-1.13-1.976-1.06-2.435-.53z'
          fill='#505555'
        />
        <path
          d='M117.979 60.38c-.314.385.244 3.569 2.309 5.668 2.064 2.063 3.324 2.063 4.164 1.154 1.609-1.715.314-3.324-.664-4.443-.98-1.12-2.24-.735-3.395-1.854s-1.959-1.051-2.414-.525z'
          fill='#565a5a'
        />
        <path
          d='M118.012 60.411c-.311.382.244 3.538 2.289 5.62 2.047 2.045 3.295 2.045 4.127 1.144 1.596-1.7.312-3.295-.658-4.405s-2.221-.729-3.365-1.838c-1.143-1.11-1.942-1.04-2.393-.521z'
          fill='#5c6060'
        />
        <path
          d='M118.045 60.443c-.309.378.242 3.507 2.27 5.569 2.027 2.028 3.266 2.028 4.092 1.134 1.58-1.684.309-3.266-.654-4.366-.961-1.101-2.199-.722-3.334-1.822-1.136-1.099-1.927-1.03-2.374-.515z'
          fill='#616464'
        />
        <path
          d='M118.078 60.476c-.305.375.238 3.476 2.25 5.52 2.01 2.01 3.238 2.01 4.055 1.124 1.568-1.67.307-3.237-.646-4.327-.955-1.09-2.182-.716-3.307-1.806-1.123-1.09-1.909-1.022-2.352-.511z'
          fill='#676a6a'
        />
        <path
          d='M118.113 60.507c-.305.372.236 3.444 2.229 5.471 1.992 1.992 3.207 1.992 4.018 1.114 1.555-1.655.305-3.208-.641-4.288-.945-1.081-2.162-.709-3.275-1.79-1.116-1.08-1.893-1.013-2.331-.507z'
          fill='#6a6d6e'
        />
        <path
          d='M118.146 60.54c-.301.368.234 3.414 2.209 5.421 1.975 1.974 3.18 1.974 3.982 1.104 1.539-1.64.301-3.179-.637-4.25-.936-1.071-2.141-.703-3.246-1.773-1.102-1.071-1.872-1.004-2.308-.502z'
          fill='#6f7273'
        />
        <path
          d='M118.18 60.571c-.297.365.232 3.382 2.189 5.372 1.955 1.956 3.15 1.956 3.945 1.094 1.525-1.625.299-3.149-.629-4.21-.93-1.062-2.123-.696-3.217-1.758-1.093-1.061-1.857-.994-2.288-.498z'
          fill='#767778'
        />
        <path
          d='M77.062 60.816c.946.654.972.77 2.369 1.683 2.222 1.804 6.168 2.21 8.101 2.274 4.42.702 12.321-3.428 15.45-5.319 1.576-1.179 4.49-3.614 5.582-3.655.672.711-.316.858-1.719 1.807-1.869 1.331-3.471 2.469-6.305 4.077-3.035 1.587-7.697 4.209-13.667 3.855-3.132-.459-3.144-.49-5.078-1.478-1.147-.83-3.781-2.206-4.733-3.244z'
          fill='#ecd238'
        />
        <path
          d='M77.04 60.808c.922.61 1.037.787 2.433 1.702 2.211 1.787 6.088 2.209 8.041 2.28 4.484.695 12.472-3.492 15.491-5.342 1.607-1.201 4.473-3.589 5.549-3.64.654.704-.348.846-1.766 1.826-1.861 1.317-3.408 2.425-6.209 4.013-3.012 1.576-7.697 4.21-13.661 3.879-3.141-.452-3.279-.513-5.15-1.475-1.182-.84-3.771-2.196-4.728-3.243z'
          fill='#eace38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-2.656 1.39-6.618 3.605-11.61 3.879h-2.051c-3.141-.452-3.279-.513-5.15-1.475-1.181-.84-3.77-2.196-4.728-3.244.922.61 1.037.787 2.433 1.702 2.211 1.787 6.088 2.209 8.041 2.28 4.484.695 12.472-3.492 15.491-5.342 1.607-1.201 4.473-3.589 5.549-3.64.117.126.179.233.199.331z'
          fill='#eace38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-2.656 1.39-6.618 3.605-11.61 3.879h-2.051c-3.141-.452-3.279-.513-5.15-1.475-.975-.694-2.909-1.738-4.093-2.672v-.133c.454.33.795.605 1.798 1.263 2.211 1.787 6.088 2.209 8.041 2.28 4.484.695 12.472-3.492 15.491-5.342 1.607-1.201 4.473-3.589 5.549-3.64.117.126.179.233.199.331z'
          fill='#e9cf38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-2.656 1.39-6.618 3.605-11.61 3.879h-2.051c-3.141-.452-3.279-.513-5.15-1.475-.815-.58-2.302-1.405-3.458-2.206v-.133c.277.201.625.443 1.164.797 2.211 1.787 6.088 2.209 8.041 2.28 4.484.695 12.472-3.492 15.491-5.342 1.607-1.201 4.473-3.589 5.549-3.64.116.126.178.233.198.331z'
          fill='#e9cf38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-2.656 1.39-6.618 3.605-11.61 3.879h-2.051c-3.141-.452-3.279-.513-5.15-1.475-.672-.479-1.8-1.123-2.824-1.784v-.111c.154.105.328.221.529.353 2.211 1.787 6.088 2.209 8.041 2.28 4.484.695 12.472-3.492 15.491-5.342 1.607-1.201 4.473-3.589 5.549-3.64.117.126.179.233.199.331z'
          fill='#e9cf38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-2.656 1.39-6.618 3.605-11.61 3.879h-2.051c-3.141-.452-3.279-.513-5.15-1.475-.535-.381-1.359-.867-2.19-1.383v-.076c2.226 1.718 6.014 2.128 7.936 2.197 4.484.695 12.472-3.492 15.491-5.342 1.607-1.201 4.473-3.589 5.549-3.64.117.126.179.233.199.331z'
          fill='#e9cf38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-2.656 1.39-6.618 3.605-11.61 3.879h-2.051c-3.141-.452-3.279-.513-5.15-1.475-.397-.283-.955-.624-1.556-.993v-.036c2.256 1.357 5.552 1.703 7.301 1.766 4.484.695 12.472-3.492 15.491-5.342 1.607-1.201 4.473-3.589 5.549-3.64.118.127.18.234.2.332z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-2.656 1.39-6.618 3.605-11.61 3.879h-2.051c-3.141-.452-3.279-.513-5.15-1.475-.252-.18-.569-.383-.921-.602v-.083c2.205 1.074 5.075 1.365 6.667 1.422 4.484.695 12.472-3.492 15.491-5.342 1.607-1.201 4.473-3.589 5.549-3.64.117.127.179.234.199.332z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-2.656 1.39-6.618 3.605-11.61 3.879h-2.051c-3.141-.452-3.279-.513-5.15-1.475a9.288 9.288 0 0 0-.287-.196v-.207c2.096.845 4.59 1.089 6.033 1.141 4.484.695 12.472-3.492 15.491-5.342 1.607-1.201 4.473-3.589 5.549-3.64.117.126.179.233.199.331z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-2.656 1.39-6.618 3.605-11.61 3.879h-2.051c-2.935-.423-3.249-.502-4.803-1.296v-.347c1.946.657 4.099.86 5.398.907 4.484.695 12.472-3.492 15.491-5.342 1.607-1.201 4.473-3.589 5.549-3.64.118.125.18.232.2.33z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-2.656 1.39-6.618 3.605-11.61 3.879h-2.051c-2.49-.358-3.093-.471-4.168-.981v-.465c1.765.502 3.604.668 4.764.71 4.484.695 12.472-3.492 15.491-5.342 1.607-1.201 4.473-3.589 5.549-3.64.117.125.179.232.199.33z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-2.656 1.39-6.618 3.605-11.61 3.879h-2.051c-1.97-.284-2.759-.413-3.534-.707v-.574c1.561.375 3.108.507 4.129.544 4.484.695 12.472-3.492 15.491-5.342 1.607-1.201 4.473-3.589 5.549-3.64.118.126.18.233.2.331z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-2.656 1.39-6.618 3.605-11.61 3.879h-2.051c-1.453-.209-2.263-.334-2.9-.504v-.637c1.337.269 2.613.372 3.495.404 4.484.695 12.472-3.492 15.491-5.342 1.607-1.201 4.473-3.589 5.549-3.64.118.126.18.233.2.331z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-2.656 1.39-6.618 3.605-11.61 3.879h-2.051c-1.017-.146-1.717-.251-2.266-.359v-.665c1.1.184 2.12.261 2.861.288 4.484.695 12.472-3.492 15.491-5.342 1.607-1.201 4.473-3.589 5.549-3.64.118.125.18.232.2.33z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-2.656 1.39-6.618 3.605-11.61 3.879h-2.051a85.35 85.35 0 0 1-1.631-.246v-.683c.854.116 1.63.17 2.227.191 4.484.695 12.472-3.492 15.491-5.342 1.607-1.201 4.473-3.589 5.549-3.64.117.127.179.234.199.332z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-2.656 1.39-6.618 3.605-11.61 3.879h-2.051l-.997-.146v-.705c.603.064 1.147.099 1.592.115 4.484.695 12.472-3.492 15.491-5.342 1.607-1.201 4.473-3.589 5.549-3.64.118.125.18.232.2.33z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-2.656 1.39-6.618 3.605-11.61 3.879h-2.051l-.362-.052v-.74c.352.028.675.045.958.055 4.484.695 12.472-3.492 15.491-5.342 1.607-1.201 4.473-3.589 5.549-3.64.117.126.179.233.199.331z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-2.656 1.39-6.618 3.605-11.61 3.879h-1.779v-.75l.323.014c4.484.695 12.472-3.492 15.491-5.342 1.607-1.201 4.473-3.589 5.549-3.64.118.125.18.232.2.33z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-2.656 1.39-6.618 3.605-11.61 3.879h-1.145v-.697c4.533.467 12.229-3.574 15.18-5.381 1.607-1.201 4.473-3.589 5.549-3.64.118.125.18.232.2.33z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-2.656 1.39-6.618 3.605-11.61 3.879h-.51v-.661c4.585.069 11.727-3.691 14.545-5.417 1.607-1.201 4.473-3.589 5.549-3.64.118.125.18.232.2.33z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-2.635 1.378-6.552 3.568-11.485 3.873v-.667c4.585-.252 11.22-3.757 13.911-5.405 1.607-1.201 4.473-3.589 5.549-3.64.117.125.179.232.199.33z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-2.521 1.319-6.215 3.38-10.852 3.823v-.671c4.544-.511 10.709-3.777 13.277-5.351 1.607-1.201 4.473-3.589 5.549-3.64.118.125.18.232.2.33z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-2.404 1.258-5.877 3.193-10.217 3.751v-.687c4.467-.72 10.191-3.762 12.643-5.264 1.607-1.201 4.473-3.589 5.549-3.64.117.126.179.233.199.331z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-2.287 1.196-5.539 3.003-9.583 3.659v-.71c4.361-.885 9.67-3.716 12.008-5.147 1.607-1.201 4.473-3.589 5.549-3.64.118.124.18.231.2.329z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-2.166 1.133-5.198 2.814-8.948 3.546v-.738c4.229-1.014 9.149-3.645 11.374-5.007 1.607-1.201 4.473-3.589 5.549-3.64.117.125.179.232.199.33z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-2.043 1.068-4.855 2.625-8.314 3.412v-.766c4.077-1.109 8.625-3.55 10.74-4.845 1.607-1.201 4.473-3.589 5.549-3.64.117.125.179.232.199.33z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-1.916 1.002-4.51 2.434-7.679 3.257v-.794c3.905-1.176 8.099-3.435 10.105-4.663 1.607-1.201 4.473-3.589 5.549-3.64.117.126.179.233.199.331z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-1.787.935-4.163 2.243-7.045 3.083v-.818c3.717-1.217 7.574-3.302 9.471-4.463 1.607-1.201 4.473-3.589 5.549-3.64.117.124.179.231.199.329z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-1.654.865-3.812 2.05-6.411 2.888v-.84c3.514-1.235 7.047-3.151 8.836-4.248 1.607-1.201 4.473-3.589 5.549-3.64.118.126.18.233.2.331z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-1.518.793-3.459 1.857-5.776 2.673v-.855c3.3-1.233 6.521-2.988 8.202-4.018 1.607-1.201 4.473-3.589 5.549-3.64.117.126.179.233.199.331z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-1.377.72-3.104 1.662-5.142 2.44v-.866c3.072-1.212 5.994-2.81 7.568-3.774 1.607-1.201 4.473-3.589 5.549-3.64.117.126.179.233.199.331z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-1.232.644-2.742 1.466-4.508 2.19v-.871a53.02 53.02 0 0 0 6.934-3.518c1.607-1.201 4.473-3.589 5.549-3.64.117.125.179.232.199.33z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-1.082.566-2.379 1.269-3.874 1.921v-.871a53.281 53.281 0 0 0 6.299-3.249c1.607-1.201 4.473-3.589 5.549-3.64.118.125.18.232.2.33z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-.926.484-2.01 1.068-3.238 1.636v-.866a53.45 53.45 0 0 0 5.664-2.969c1.607-1.201 4.473-3.589 5.549-3.64.117.125.179.232.199.33z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-.764.399-1.633.866-2.604 1.336v-.856a53.093 53.093 0 0 0 5.029-2.679c1.607-1.201 4.473-3.589 5.549-3.64.118.125.18.232.2.33z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-.592.31-1.25.662-1.971 1.023v-.844a52.5 52.5 0 0 0 4.396-2.378c1.607-1.201 4.473-3.589 5.549-3.64.118.125.18.232.2.33z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-.414.216-.859.454-1.336.7v-.832a51.963 51.963 0 0 0 3.762-2.067c1.607-1.201 4.473-3.589 5.549-3.64.117.125.179.232.199.33z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013-.225.117-.459.241-.701.369v-.822a49.73 49.73 0 0 0 3.127-1.746c1.607-1.201 4.473-3.589 5.549-3.64.117.125.179.232.199.33z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.861 1.317-3.408 2.425-6.209 4.013l-.066.035v-.821a48.05 48.05 0 0 0 2.492-1.414c1.607-1.201 4.473-3.589 5.549-3.64.117.126.179.233.199.331z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.73 1.226-3.191 2.27-5.643 3.688v-.802a46.037 46.037 0 0 0 1.859-1.072c1.607-1.201 4.473-3.589 5.549-3.64.118.125.18.232.2.33z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.576 1.116-2.928 2.082-5.008 3.316v-.784c.465-.263.877-.506 1.225-.719 1.607-1.201 4.473-3.589 5.549-3.64.117.126.179.233.199.331z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.41.999-2.641 1.877-4.373 2.935v-.768c.213-.125.41-.243.59-.353 1.607-1.201 4.473-3.589 5.549-3.64.117.125.179.232.199.33z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.232.872-2.326 1.653-3.738 2.542v-.762c1.615-1.211 4.438-3.556 5.504-3.606.117.125.179.232.199.33z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-1.041.737-1.984 1.409-3.104 2.136v-.839c1.639-1.262 3.932-3.079 4.869-3.123.118.125.18.232.2.33z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-.838.594-1.615 1.146-2.471 1.718v-.911c1.551-1.195 3.414-2.595 4.236-2.634.118.126.18.233.2.331z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-.627.444-1.219.865-1.836 1.288v-.966c1.396-1.058 2.891-2.115 3.602-2.148.117.125.179.232.199.33z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-.41.291-.805.571-1.201.848v-1.001c1.193-.879 2.363-1.645 2.967-1.673.117.125.179.232.199.33z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.078.38-.906.645-1.965 1.377-.191.136-.379.27-.566.401v-1.011c.959-.672 1.836-1.193 2.332-1.217.117.126.179.233.199.331z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.076.372-.871.634-1.898 1.331v-.993c.703-.453 1.314-.77 1.699-.788.117.126.179.233.199.331z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.061.291-.559.514-1.264.931v-.976c.432-.24.803-.392 1.064-.404.118.125.18.232.2.33z'
          fill='#e8ce38'
        />
        <path
          d='M108.752 56.138v.119c-.039.192-.271.355-.629.565v-.912c.164-.061.309-.097.43-.103.117.126.179.233.199.331z'
          fill='#e8ce38'
        />
        <path
          d='M77.016 60.799c.898.566 1.101.804 2.498 1.722 2.198 1.771 6.007 2.209 7.98 2.286 4.547.688 12.621-3.557 15.529-5.365 1.641-1.221 4.457-3.564 5.52-3.624.635.697-.381.833-1.814 1.845-1.852 1.305-3.346 2.38-6.113 3.949-2.988 1.564-7.696 4.211-13.655 3.904-3.15-.445-3.416-.535-5.222-1.471-1.215-.853-3.76-2.187-4.723-3.246z'
          fill='#e6cb39'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-2.66 1.393-6.683 3.643-11.738 3.904H86.96c-3.15-.445-3.416-.535-5.222-1.471-1.214-.852-3.759-2.186-4.722-3.246.898.566 1.101.804 2.498 1.722 2.198 1.771 6.007 2.209 7.98 2.286 4.547.688 12.621-3.557 15.529-5.365 1.641-1.221 4.457-3.564 5.52-3.624.107.118.166.22.186.312z'
          fill='#e6cb39'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-2.66 1.393-6.683 3.643-11.738 3.904H86.96c-3.15-.445-3.416-.535-5.222-1.471-1.002-.703-2.91-1.735-4.087-2.669v-.154c.472.332.858.639 1.863 1.299 2.198 1.771 6.007 2.209 7.98 2.286 4.547.688 12.621-3.557 15.529-5.365 1.641-1.221 4.457-3.564 5.52-3.624.107.118.166.22.186.312z'
          fill='#e6cb39'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-2.66 1.393-6.683 3.643-11.738 3.904H86.96c-3.15-.445-3.416-.535-5.222-1.471-.837-.587-2.306-1.403-3.454-2.201v-.166c.299.215.667.474 1.229.843 2.198 1.771 6.007 2.209 7.98 2.286 4.547.688 12.621-3.557 15.529-5.365 1.641-1.221 4.457-3.564 5.52-3.624.108.118.167.22.187.312z'
          fill='#e6cb39'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-2.66 1.393-6.683 3.643-11.738 3.904H86.96c-3.15-.445-3.416-.535-5.222-1.471-.689-.483-1.805-1.122-2.819-1.777v-.145c.172.118.367.249.595.398 2.198 1.771 6.007 2.209 7.98 2.286 4.547.688 12.621-3.557 15.529-5.365 1.641-1.221 4.457-3.564 5.52-3.624.107.118.166.22.186.312z'
          fill='#e6cb39'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-2.66 1.393-6.683 3.643-11.738 3.904H86.96c-3.15-.445-3.416-.535-5.222-1.471-.547-.384-1.364-.865-2.185-1.375v-.117c2.204 1.744 5.979 2.178 7.94 2.255 4.547.688 12.621-3.557 15.529-5.365 1.641-1.221 4.457-3.564 5.52-3.624a.7.7 0 0 1 .187.311z'
          fill='#e6cb39'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-2.66 1.393-6.683 3.643-11.738 3.904H86.96c-3.15-.445-3.416-.535-5.222-1.471a32.698 32.698 0 0 0-1.55-.985v-.066c2.238 1.378 5.522 1.743 7.306 1.813 4.547.688 12.621-3.557 15.529-5.365 1.641-1.221 4.457-3.564 5.52-3.624.107.118.166.22.186.312z'
          fill='#e6cb39'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-2.66 1.393-6.683 3.643-11.738 3.904H86.96c-3.15-.445-3.416-.535-5.222-1.471-.255-.179-.57-.379-.916-.594v-.105c2.188 1.091 5.05 1.398 6.671 1.462 4.547.688 12.621-3.557 15.529-5.365 1.641-1.221 4.457-3.564 5.52-3.624a.7.7 0 0 1 .187.311z'
          fill='#e6cb39'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-2.66 1.393-6.683 3.643-11.738 3.904H86.96c-3.15-.445-3.416-.535-5.222-1.471a12.118 12.118 0 0 0-.281-.191v-.221c2.082.858 4.568 1.116 6.037 1.174 4.547.688 12.621-3.557 15.529-5.365 1.641-1.221 4.457-3.564 5.52-3.624.107.118.166.22.186.312z'
          fill='#e5c939'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-2.66 1.393-6.683 3.643-11.738 3.904H86.96c-2.933-.415-3.365-.521-4.869-1.289v-.354c1.934.668 4.081.882 5.403.934 4.547.688 12.621-3.557 15.529-5.365 1.641-1.221 4.457-3.564 5.52-3.624.107.118.166.22.186.312z'
          fill='#e5c939'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-2.66 1.393-6.683 3.643-11.738 3.904H86.96c-2.479-.351-3.171-.48-4.235-.976v-.465c1.755.511 3.589.687 4.769.732 4.547.688 12.621-3.557 15.529-5.365 1.641-1.221 4.457-3.564 5.52-3.624.107.118.166.22.186.312z'
          fill='#e6cb39'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-2.66 1.393-6.683 3.643-11.738 3.904H86.96c-1.964-.278-2.807-.417-3.601-.709v-.562c1.552.381 3.097.521 4.134.562 4.547.688 12.621-3.557 15.529-5.365 1.641-1.221 4.457-3.564 5.52-3.624.108.118.167.22.187.312z'
          fill='#e6cb39'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-2.66 1.393-6.683 3.643-11.738 3.904H86.96c-1.463-.207-2.304-.337-2.966-.511v-.617c1.33.274 2.604.384 3.5.419 4.547.688 12.621-3.557 15.529-5.365 1.641-1.221 4.457-3.564 5.52-3.624.107.118.166.22.186.312z'
          fill='#e5c939'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-2.66 1.393-6.683 3.643-11.738 3.904H86.96c-1.038-.147-1.762-.254-2.332-.367v-.642c1.094.188 2.113.27 2.865.3 4.547.688 12.621-3.557 15.529-5.365 1.641-1.221 4.457-3.564 5.52-3.624.108.118.167.22.187.312z'
          fill='#e5c939'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-2.66 1.393-6.683 3.643-11.738 3.904H86.96a80.896 80.896 0 0 1-1.697-.253v-.656c.85.12 1.626.177 2.231.201 4.547.688 12.621-3.557 15.529-5.365 1.641-1.221 4.457-3.564 5.52-3.624a.707.707 0 0 1 .186.311z'
          fill='#e5c939'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-2.66 1.393-6.683 3.643-11.738 3.904H86.96c-.397-.056-.749-.107-1.062-.154v-.676c.6.067 1.146.104 1.596.121 4.547.688 12.621-3.557 15.529-5.365 1.641-1.221 4.457-3.564 5.52-3.624.107.118.166.22.186.312z'
          fill='#e5c839'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-2.66 1.393-6.683 3.643-11.738 3.904H86.96l-.428-.061v-.707c.352.029.676.048.962.059 4.547.688 12.621-3.557 15.529-5.365 1.641-1.221 4.457-3.564 5.52-3.624.107.118.166.22.186.312z'
          fill='#e5c939'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-2.66 1.393-6.683 3.643-11.738 3.904h-1.711v-.724c.114.006.224.012.328.016 4.547.688 12.621-3.557 15.529-5.365 1.641-1.221 4.457-3.564 5.52-3.624a.713.713 0 0 1 .185.311z'
          fill='#e5c939'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-2.66 1.393-6.683 3.643-11.738 3.904h-1.077v-.671c4.595.463 12.379-3.634 15.223-5.403 1.641-1.221 4.457-3.564 5.52-3.624a.72.72 0 0 1 .185.312z'
          fill='#e5c839'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-2.66 1.393-6.683 3.643-11.738 3.904h-.442v-.635c4.646.066 11.872-3.749 14.588-5.438 1.641-1.221 4.457-3.564 5.52-3.624a.713.713 0 0 1 .185.311z'
          fill='#e3c739'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-2.627 1.375-6.581 3.586-11.545 3.893v-.638c4.646-.255 11.358-3.811 13.954-5.425 1.641-1.221 4.457-3.564 5.52-3.624.105.118.164.22.184.312z'
          fill='#e3c739'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-2.514 1.315-6.244 3.398-10.911 3.843v-.641c4.602-.515 10.839-3.829 13.319-5.372 1.641-1.221 4.457-3.564 5.52-3.624a.72.72 0 0 1 .185.312z'
          fill='#e3c739'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-2.398 1.255-5.905 3.209-10.277 3.771v-.657c4.525-.725 10.318-3.812 12.685-5.285 1.641-1.221 4.457-3.564 5.52-3.624a.729.729 0 0 1 .185.313z'
          fill='#e3c739'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-2.281 1.194-5.565 3.02-9.642 3.68v-.68c4.418-.892 9.792-3.766 12.05-5.17 1.641-1.221 4.457-3.564 5.52-3.624a.72.72 0 0 1 .185.312z'
          fill='#e3c739'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-2.162 1.131-5.224 2.83-9.008 3.566v-.707c4.287-1.021 9.264-3.692 11.417-5.03 1.641-1.221 4.457-3.564 5.52-3.624a.735.735 0 0 1 .184.313z'
          fill='#e3c739'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-2.039 1.067-4.88 2.64-8.374 3.433v-.733c4.133-1.118 8.735-3.597 10.782-4.869 1.641-1.221 4.457-3.564 5.52-3.624a.713.713 0 0 1 .185.311z'
          fill='#e3c739'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-1.914 1.002-4.535 2.45-7.739 3.279v-.762c3.96-1.186 8.206-3.479 10.147-4.688 1.641-1.221 4.457-3.564 5.52-3.624a.729.729 0 0 1 .185.313z'
          fill='#e3c739'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-1.787.936-4.188 2.258-7.105 3.104v-.785c3.771-1.229 7.675-3.345 9.513-4.489 1.641-1.221 4.457-3.564 5.52-3.624a.72.72 0 0 1 .185.312z'
          fill='#e3c739'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-1.654.867-3.838 2.066-6.47 2.911v-.806c3.568-1.248 7.142-3.195 8.878-4.274 1.641-1.221 4.457-3.564 5.52-3.624a.713.713 0 0 1 .185.311z'
          fill='#e3c739'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-1.52.796-3.486 1.873-5.836 2.697v-.821c3.352-1.248 6.611-3.031 8.244-4.046 1.641-1.221 4.457-3.564 5.52-3.624a.72.72 0 0 1 .185.312z'
          fill='#e3c739'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-1.381.723-3.131 1.678-5.202 2.465v-.833c3.124-1.227 6.081-2.852 7.61-3.803 1.641-1.221 4.457-3.564 5.52-3.624a.729.729 0 0 1 .185.313z'
          fill='#e3c739'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-1.238.648-2.771 1.482-4.567 2.215v-.838c2.884-1.189 5.55-2.66 6.976-3.547 1.641-1.221 4.457-3.564 5.52-3.624.105.118.164.22.184.312z'
          fill='#e3c739'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-1.09.57-2.408 1.285-3.933 1.948v-.838c2.636-1.136 5.021-2.458 6.341-3.28 1.641-1.221 4.457-3.564 5.52-3.624a.72.72 0 0 1 .185.312z'
          fill='#e3c739'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-.936.49-2.039 1.086-3.299 1.664v-.833c2.381-1.067 4.494-2.246 5.707-3 1.641-1.221 4.457-3.564 5.52-3.624a.713.713 0 0 1 .185.311z'
          fill='#e3c739'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-.775.406-1.666.885-2.664 1.365v-.824c2.119-.986 3.969-2.024 5.072-2.711 1.641-1.221 4.457-3.564 5.52-3.624a.72.72 0 0 1 .185.312z'
          fill='#e3c739'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-.605.318-1.285.681-2.029 1.054v-.813a49.969 49.969 0 0 0 4.438-2.41c1.641-1.221 4.457-3.564 5.52-3.624a.719.719 0 0 1 .184.311z'
          fill='#e3c739'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-.43.225-.895.473-1.395.73v-.801a48.435 48.435 0 0 0 3.803-2.1c1.641-1.221 4.457-3.564 5.52-3.624a.729.729 0 0 1 .185.313z'
          fill='#e3c739'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949-.242.127-.496.262-.762.4v-.791a46.566 46.566 0 0 0 3.17-1.778c1.641-1.221 4.457-3.564 5.52-3.624a.713.713 0 0 1 .185.311z'
          fill='#e3c739'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.852 1.305-3.346 2.38-6.113 3.949l-.127.066v-.79a43.578 43.578 0 0 0 2.535-1.447c1.641-1.221 4.457-3.564 5.52-3.624a.729.729 0 0 1 .185.313z'
          fill='#e3c739'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.734 1.223-3.156 2.244-5.605 3.659v-.775a40.937 40.937 0 0 0 1.9-1.104c1.641-1.221 4.457-3.564 5.52-3.624a.713.713 0 0 1 .185.311z'
          fill='#e3c739'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.58 1.113-2.898 2.06-4.971 3.288v-.759a39.02 39.02 0 0 0 1.266-.75c1.641-1.221 4.457-3.564 5.52-3.624a.72.72 0 0 1 .185.312z'
          fill='#e3c739'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.412.995-2.617 1.857-4.336 2.906v-.744c.23-.137.441-.265.631-.383 1.641-1.221 4.457-3.564 5.52-3.624a.72.72 0 0 1 .185.312z'
          fill='#e3c739'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.232.868-2.307 1.635-3.701 2.514v-.736c1.639-1.221 4.453-3.562 5.516-3.622a.713.713 0 0 1 .185.311z'
          fill='#e3c739'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-1.039.732-1.965 1.392-3.068 2.108v-.811c1.654-1.269 3.947-3.089 4.883-3.142a.72.72 0 0 1 .185.312z'
          fill='#e3c739'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-.834.587-1.596 1.129-2.434 1.689v-.88c1.562-1.201 3.428-2.607 4.248-2.654.107.118.166.22.186.312z'
          fill='#e3c739'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-.619.436-1.199.847-1.799 1.259v-.935c1.404-1.065 2.902-2.129 3.613-2.169.107.118.166.22.186.312z'
          fill='#e3c739'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-.398.281-.781.552-1.164.819v-.97c1.201-.886 2.373-1.66 2.979-1.694a.72.72 0 0 1 .185.312z'
          fill='#e3c739'
        />
        <path
          d='M108.729 56.129v.129c-.086.38-.926.646-2 1.404-.18.126-.355.251-.529.374v-.983c.965-.679 1.846-1.208 2.344-1.236a.72.72 0 0 1 .185.312z'
          fill='#e3c739'
        />
        <path
          d='M108.729 56.129v.129c-.082.368-.871.629-1.896 1.333v-.968c.709-.46 1.326-.783 1.711-.805a.713.713 0 0 1 .185.311z'
          fill='#e3c739'
        />
        <path
          d='M108.729 56.129v.129c-.064.287-.561.51-1.262.928v-.951c.438-.246.811-.403 1.076-.417a.707.707 0 0 1 .186.311z'
          fill='#e3c739'
        />
        <path
          d='M108.729 56.129v.129c-.043.189-.273.351-.627.559v-.889c.168-.064.316-.104.441-.11a.707.707 0 0 1 .186.311z'
          fill='#e3c739'
        />
        <path
          d='M76.993 60.79c.874.523 1.165.821 2.562 1.741 2.188 1.754 5.926 2.209 7.92 2.292 4.61.681 12.771-3.62 15.57-5.387 1.672-1.242 4.439-3.539 5.488-3.609.615.69-.412.823-1.863 1.864-1.844 1.292-3.283 2.337-6.016 3.886-2.967 1.552-7.698 4.211-13.65 3.927-3.16-.438-3.551-.557-5.295-1.468-1.247-.863-3.747-2.176-4.716-3.246z'
          fill='#e5c839'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-2.668 1.395-6.759 3.685-11.884 3.927h-1.767c-3.16-.438-3.551-.557-5.295-1.468-1.247-.863-3.747-2.176-4.716-3.246.874.523 1.165.821 2.562 1.741 2.188 1.754 5.926 2.209 7.92 2.292 4.61.681 12.771-3.62 15.57-5.387 1.672-1.242 4.439-3.539 5.488-3.609a.722.722 0 0 1 .173.286z'
          fill='#e5c839'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-2.668 1.395-6.759 3.685-11.884 3.927h-1.767c-3.16-.438-3.551-.557-5.295-1.468-1.028-.712-2.91-1.73-4.081-2.666v-.174c.49.333.922.673 1.927 1.335 2.188 1.754 5.926 2.209 7.92 2.292 4.61.681 12.771-3.62 15.57-5.387 1.672-1.242 4.439-3.539 5.488-3.609a.722.722 0 0 1 .173.286z'
          fill='#e5c839'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-2.668 1.395-6.759 3.685-11.884 3.927h-1.767c-3.16-.438-3.551-.557-5.295-1.468-.858-.594-2.309-1.4-3.447-2.195v-.197c.32.229.71.504 1.293.887 2.188 1.754 5.926 2.209 7.92 2.292 4.61.681 12.771-3.62 15.57-5.387 1.672-1.242 4.439-3.539 5.488-3.609a.722.722 0 0 1 .173.286z'
          fill='#e5c839'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-2.668 1.395-6.759 3.685-11.884 3.927h-1.767c-3.16-.438-3.551-.557-5.295-1.468-.705-.487-1.81-1.119-2.812-1.77v-.178c.19.13.406.276.658.442 2.188 1.754 5.926 2.209 7.92 2.292 4.61.681 12.771-3.62 15.57-5.387 1.672-1.242 4.439-3.539 5.488-3.609a.731.731 0 0 1 .173.287z'
          fill='#e3c739'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-2.668 1.395-6.759 3.685-11.884 3.927h-1.767c-3.16-.438-3.551-.557-5.295-1.468-.558-.386-1.367-.862-2.178-1.367v-.154l.024.016c2.188 1.754 5.926 2.209 7.92 2.292 4.61.681 12.771-3.62 15.57-5.387 1.672-1.242 4.439-3.539 5.488-3.609a.722.722 0 0 1 .173.286z'
          fill='#e3c739'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-2.668 1.395-6.759 3.685-11.884 3.927h-1.767c-3.16-.438-3.551-.557-5.295-1.468-.412-.285-.959-.618-1.543-.976v-.097c2.22 1.399 5.491 1.784 7.31 1.859 4.61.681 12.771-3.62 15.57-5.387 1.672-1.242 4.439-3.539 5.488-3.609a.74.74 0 0 1 .172.287z'
          fill='#e3c739'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-2.668 1.395-6.759 3.685-11.884 3.927h-1.767c-3.16-.438-3.551-.557-5.295-1.468a23.274 23.274 0 0 0-.909-.586v-.127c2.173 1.107 5.023 1.431 6.675 1.5 4.61.681 12.771-3.62 15.57-5.387 1.672-1.242 4.439-3.539 5.488-3.609a.722.722 0 0 1 .173.286z'
          fill='#e3c739'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-2.668 1.395-6.759 3.685-11.884 3.927h-1.767c-3.16-.438-3.551-.557-5.295-1.468-.086-.06-.178-.121-.275-.184v-.235c2.068.872 4.546 1.144 6.041 1.206 4.61.681 12.771-3.62 15.57-5.387 1.672-1.242 4.439-3.539 5.488-3.609a.722.722 0 0 1 .173.286z'
          fill='#e2c539'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-2.668 1.395-6.759 3.685-11.884 3.927h-1.767c-2.931-.407-3.479-.538-4.935-1.281v-.36c1.921.68 4.061.904 5.406.96 4.61.681 12.771-3.62 15.57-5.387 1.672-1.242 4.439-3.539 5.488-3.609a.722.722 0 0 1 .173.286z'
          fill='#e2c539'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-2.668 1.395-6.759 3.685-11.884 3.927h-1.767c-2.468-.343-3.247-.49-4.301-.972v-.464c1.744.521 3.573.705 4.772.754 4.61.681 12.771-3.62 15.57-5.387 1.672-1.242 4.439-3.539 5.488-3.609a.731.731 0 0 1 .173.287z'
          fill='#e2c539'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-2.668 1.395-6.759 3.685-11.884 3.927h-1.767c-1.959-.271-2.854-.421-3.667-.712v-.549c1.542.389 3.084.536 4.138.58 4.61.681 12.771-3.62 15.57-5.387 1.672-1.242 4.439-3.539 5.488-3.609a.722.722 0 0 1 .173.286z'
          fill='#e2c539'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-2.668 1.395-6.759 3.685-11.884 3.927h-1.767c-1.474-.205-2.345-.339-3.032-.517v-.596c1.323.28 2.594.396 3.503.433 4.61.681 12.771-3.62 15.57-5.387 1.672-1.242 4.439-3.539 5.488-3.609.095.107.152.2.173.285z'
          fill='#e2c539'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-2.668 1.395-6.759 3.685-11.884 3.927h-1.767c-1.058-.146-1.805-.257-2.397-.374v-.617c1.089.192 2.106.278 2.869.31 4.61.681 12.771-3.62 15.57-5.387 1.672-1.242 4.439-3.539 5.488-3.609a.73.73 0 0 1 .172.286z'
          fill='#e2c339'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-2.668 1.395-6.759 3.685-11.884 3.927h-1.767a72.292 72.292 0 0 1-1.763-.26v-.629c.846.123 1.623.183 2.234.208 4.61.681 12.771-3.62 15.57-5.387 1.672-1.242 4.439-3.539 5.488-3.609a.722.722 0 0 1 .173.286z'
          fill='#e2c339'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-2.668 1.395-6.759 3.685-11.884 3.927h-1.767a113.27 113.27 0 0 1-1.128-.161v-.646c.598.069 1.144.107 1.6.126 4.61.681 12.771-3.62 15.57-5.387 1.672-1.242 4.439-3.539 5.488-3.609a.73.73 0 0 1 .172.286z'
          fill='#e0c33a'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-2.668 1.395-6.759 3.685-11.884 3.927h-1.767l-.494-.069v-.674c.352.031.676.05.965.062 4.61.681 12.771-3.62 15.57-5.387 1.672-1.242 4.439-3.539 5.488-3.609a.722.722 0 0 1 .173.286z'
          fill='#e2c339'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-2.668 1.395-6.759 3.685-11.884 3.927h-1.626v-.697c.115.007.226.012.331.016 4.61.681 12.771-3.62 15.57-5.387 1.672-1.242 4.439-3.539 5.488-3.609a.73.73 0 0 1 .172.286z'
          fill='#e0c33a'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-2.668 1.395-6.759 3.685-11.884 3.927h-.992v-.645c4.657.46 12.528-3.694 15.267-5.424 1.672-1.242 4.439-3.539 5.488-3.609a.74.74 0 0 1 .172.287z'
          fill='#e0c33a'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-2.668 1.395-6.759 3.685-11.884 3.927h-.358v-.609c4.707.063 12.015-3.807 14.632-5.459 1.672-1.242 4.439-3.539 5.488-3.609a.722.722 0 0 1 .173.286z'
          fill='#e0c33a'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-2.619 1.37-6.612 3.603-11.607 3.912v-.608c4.706-.258 11.494-3.865 13.998-5.445 1.672-1.242 4.439-3.539 5.488-3.609a.73.73 0 0 1 .172.286z'
          fill='#e0c33a'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-2.508 1.311-6.273 3.414-10.972 3.863v-.612c4.662-.52 10.97-3.881 13.363-5.392 1.672-1.242 4.439-3.539 5.488-3.609a.73.73 0 0 1 .172.286z'
          fill='#dfc139'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-2.393 1.251-5.934 3.224-10.338 3.792v-.627c4.583-.73 10.443-3.863 12.729-5.306 1.672-1.242 4.439-3.539 5.488-3.609a.73.73 0 0 1 .172.286z'
          fill='#dfc139'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-2.277 1.191-5.593 3.035-9.704 3.7v-.649c4.476-.898 9.913-3.814 12.094-5.191 1.672-1.242 4.439-3.539 5.488-3.609.095.107.152.2.173.285z'
          fill='#dfc139'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-2.158 1.129-5.251 2.845-9.069 3.586v-.675c4.343-1.028 9.38-3.739 11.46-5.052 1.672-1.242 4.439-3.539 5.488-3.609a.73.73 0 0 1 .172.286z'
          fill='#dfc139'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-2.039 1.066-4.908 2.655-8.435 3.453v-.703c4.189-1.126 8.847-3.643 10.825-4.892 1.672-1.242 4.439-3.539 5.488-3.609a.731.731 0 0 1 .173.287z'
          fill='#dfc139'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-1.914 1.001-4.563 2.464-7.8 3.299v-.729c4.016-1.196 8.312-3.525 10.191-4.712 1.672-1.242 4.439-3.539 5.488-3.609a.74.74 0 0 1 .172.287z'
          fill='#dfc139'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-1.787.935-4.216 2.272-7.166 3.125v-.751c3.826-1.24 7.775-3.39 9.556-4.514 1.672-1.242 4.439-3.539 5.488-3.609.095.107.152.2.173.285z'
          fill='#dfc139'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-1.658.867-3.867 2.081-6.531 2.932v-.772c3.621-1.261 7.24-3.238 8.922-4.301 1.672-1.242 4.439-3.539 5.488-3.609a.73.73 0 0 1 .172.286z'
          fill='#dfc139'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-1.525.797-3.514 1.888-5.897 2.72v-.789c3.403-1.261 6.704-3.073 8.288-4.073 1.672-1.242 4.439-3.539 5.488-3.609a.74.74 0 0 1 .172.287z'
          fill='#dfc139'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-1.389.726-3.16 1.694-5.263 2.489v-.799c3.173-1.241 6.169-2.894 7.653-3.831 1.672-1.242 4.439-3.539 5.488-3.609a.722.722 0 0 1 .173.286z'
          fill='#dfc139'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-1.246.651-2.801 1.498-4.628 2.24v-.805c2.933-1.205 5.634-2.701 7.019-3.576 1.672-1.242 4.439-3.539 5.488-3.609a.73.73 0 0 1 .172.286z'
          fill='#dfc139'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-1.1.575-2.439 1.301-3.994 1.974v-.805c2.683-1.152 5.099-2.498 6.384-3.31 1.672-1.242 4.439-3.539 5.488-3.609a.722.722 0 0 1 .173.286z'
          fill='#dfc139'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-.947.495-2.072 1.102-3.359 1.691v-.8c2.426-1.085 4.566-2.286 5.75-3.032 1.672-1.242 4.439-3.539 5.488-3.609a.73.73 0 0 1 .172.286z'
          fill='#dfc139'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-.787.412-1.699.902-2.725 1.394v-.793c2.162-1.004 4.037-2.062 5.115-2.742 1.672-1.242 4.439-3.539 5.488-3.609a.722.722 0 0 1 .173.286z'
          fill='#dfc139'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-.621.325-1.32.698-2.09 1.083v-.781c1.891-.91 3.51-1.829 4.48-2.442 1.672-1.242 4.439-3.539 5.488-3.609.095.107.152.2.173.285z'
          fill='#dfc139'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886-.447.233-.932.492-1.455.761v-.771c1.615-.805 2.984-1.588 3.846-2.132 1.672-1.242 4.439-3.539 5.488-3.609a.74.74 0 0 1 .172.287z'
          fill='#dfc139'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886l-.82.431v-.761a44.178 44.178 0 0 0 3.211-1.811c1.672-1.242 4.439-3.539 5.488-3.609a.73.73 0 0 1 .172.286z'
          fill='#dfc139'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.844 1.292-3.283 2.337-6.016 3.886l-.188.097v-.759a41.798 41.798 0 0 0 2.578-1.479c1.672-1.242 4.439-3.539 5.488-3.609a.722.722 0 0 1 .173.286z'
          fill='#dfc139'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.74 1.219-3.121 2.219-5.568 3.63v-.749c.783-.43 1.447-.823 1.943-1.136 1.672-1.242 4.439-3.539 5.488-3.609a.73.73 0 0 1 .172.286z'
          fill='#dfc139'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.584 1.109-2.869 2.038-4.934 3.26v-.733c.51-.291.953-.557 1.309-.781 1.672-1.242 4.439-3.539 5.488-3.609a.72.72 0 0 1 .172.285z'
          fill='#dfc139'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.414.991-2.592 1.838-4.299 2.878v-.721c.248-.148.475-.287.674-.412 1.672-1.242 4.439-3.539 5.488-3.609a.73.73 0 0 1 .172.286z'
          fill='#dfc139'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.232.863-2.285 1.618-3.664 2.485v-.715l.039-.025c1.672-1.242 4.439-3.539 5.488-3.609a.73.73 0 0 1 .172.286z'
          fill='#dfc139'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-1.037.727-1.947 1.375-3.031 2.08v-.784c1.672-1.276 3.961-3.098 4.895-3.16.093.108.15.201.171.286z'
          fill='#dfc139'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-.828.581-1.576 1.112-2.396 1.661V58.5c1.574-1.207 3.441-2.619 4.26-2.673.093.107.15.2.171.285z'
          fill='#dfc139'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425-.609.428-1.178.829-1.762 1.23v-.905c1.414-1.071 2.914-2.142 3.625-2.189a.73.73 0 0 1 .172.286z'
          fill='#dfc139'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425l-1.127.791v-.941c1.209-.892 2.385-1.673 2.99-1.713a.72.72 0 0 1 .172.285z'
          fill='#dfc139'
        />
        <path
          d='M108.705 56.112v.153c-.102.375-.949.646-2.035 1.425l-.492.346v-.956c.971-.686 1.857-1.221 2.355-1.254a.73.73 0 0 1 .172.286z'
          fill='#dfc139'
        />
        <path
          d='M108.705 56.112v.153c-.096.359-.877.622-1.893 1.325v-.942c.713-.467 1.334-.796 1.721-.822a.73.73 0 0 1 .172.286z'
          fill='#dfc139'
        />
        <path
          d='M108.705 56.112v.153c-.074.279-.566.501-1.26.917v-.925c.443-.251.82-.414 1.088-.431a.73.73 0 0 1 .172.286z'
          fill='#dfc139'
        />
        <path
          d='M108.705 56.112v.153c-.049.184-.277.342-.625.547v-.868c.172-.068.326-.11.453-.118a.73.73 0 0 1 .172.286z'
          fill='#dfc139'
        />
        <path
          d='M76.97 60.781c.849.479 1.229.838 2.626 1.76 2.177 1.738 5.847 2.209 7.86 2.299 4.674.673 12.921-3.685 15.61-5.411 1.703-1.263 4.42-3.513 5.455-3.592.598.682-.443.81-1.91 1.882-1.834 1.28-3.221 2.293-5.92 3.824-2.943 1.54-7.698 4.212-13.645 3.95-3.168-.431-3.687-.579-5.367-1.464-1.278-.875-3.734-2.167-4.709-3.248z'
          fill='#e2c339'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-2.67 1.396-6.826 3.723-12.011 3.95h-1.634c-3.168-.431-3.687-.579-5.367-1.464-1.279-.874-3.735-2.166-4.71-3.247.849.479 1.229.838 2.626 1.76 2.177 1.738 5.847 2.209 7.86 2.299 4.674.673 12.921-3.685 15.61-5.411 1.703-1.263 4.42-3.513 5.455-3.592a.69.69 0 0 1 .161.265z'
          fill='#e2c339'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-2.67 1.396-6.826 3.723-12.011 3.95h-1.634c-3.168-.431-3.687-.579-5.367-1.464-1.055-.721-2.911-1.727-4.076-2.663v-.196c.507.335.986.707 1.992 1.371 2.177 1.738 5.847 2.209 7.86 2.299 4.674.673 12.921-3.685 15.61-5.411 1.703-1.263 4.42-3.513 5.455-3.592a.7.7 0 0 1 .161.266z'
          fill='#e2c339'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-2.67 1.396-6.826 3.723-12.011 3.95h-1.634c-3.168-.431-3.687-.579-5.367-1.464-.879-.601-2.313-1.398-3.441-2.189v-.23c.343.243.756.535 1.357.932 2.177 1.738 5.847 2.209 7.86 2.299 4.674.673 12.921-3.685 15.61-5.411 1.703-1.263 4.42-3.513 5.455-3.592a.69.69 0 0 1 .161.265z'
          fill='#e2c339'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-2.67 1.396-6.826 3.723-12.011 3.95h-1.634c-3.168-.431-3.687-.579-5.367-1.464-.72-.492-1.814-1.117-2.807-1.762v-.212c.209.144.446.305.723.487 2.177 1.738 5.847 2.209 7.86 2.299 4.674.673 12.921-3.685 15.61-5.411 1.703-1.263 4.42-3.513 5.455-3.592a.69.69 0 0 1 .161.265z'
          fill='#e2c339'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-2.67 1.396-6.826 3.723-12.011 3.95h-1.634c-3.168-.431-3.687-.579-5.367-1.464-.569-.389-1.371-.86-2.172-1.359v-.187l.088.059c2.177 1.738 5.847 2.209 7.86 2.299 4.674.673 12.921-3.685 15.61-5.411 1.703-1.263 4.42-3.513 5.455-3.592a.69.69 0 0 1 .161.265z'
          fill='#e1c239'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-2.67 1.396-6.826 3.723-12.011 3.95h-1.634c-3.168-.431-3.687-.579-5.367-1.464-.417-.286-.961-.615-1.538-.968v-.128c2.204 1.42 5.46 1.825 7.314 1.908 4.674.673 12.921-3.685 15.61-5.411 1.703-1.263 4.42-3.513 5.455-3.592a.69.69 0 0 1 .161.265z'
          fill='#e0c33a'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-2.67 1.396-6.826 3.723-12.011 3.95h-1.634c-3.168-.431-3.687-.579-5.367-1.464-.26-.178-.568-.372-.903-.579v-.15c2.158 1.125 4.997 1.466 6.679 1.541 4.674.673 12.921-3.685 15.61-5.411 1.703-1.263 4.42-3.513 5.455-3.592a.69.69 0 0 1 .161.265z'
          fill='#dfc139'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-2.67 1.396-6.826 3.723-12.011 3.95h-1.634c-3.168-.431-3.687-.579-5.367-1.464a12.584 12.584 0 0 0-.269-.179v-.249c2.055.886 4.523 1.171 6.045 1.239 4.674.673 12.921-3.685 15.61-5.411 1.703-1.263 4.42-3.513 5.455-3.592a.7.7 0 0 1 .161.266z'
          fill='#dfc139'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-2.67 1.396-6.826 3.723-12.011 3.95h-1.634c-2.927-.398-3.592-.554-5.001-1.273v-.368c1.91.691 4.042.928 5.411.989 4.674.673 12.921-3.685 15.61-5.411 1.703-1.263 4.42-3.513 5.455-3.592a.7.7 0 0 1 .16.265z'
          fill='#dfbf39'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-2.67 1.396-6.826 3.723-12.011 3.95h-1.634c-2.456-.334-3.319-.498-4.366-.967v-.463c1.733.529 3.558.723 4.776.777 4.674.673 12.921-3.685 15.61-5.411 1.703-1.263 4.42-3.513 5.455-3.592a.71.71 0 0 1 .16.266z'
          fill='#dfbf39'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-2.67 1.396-6.826 3.723-12.011 3.95h-1.634c-1.954-.266-2.899-.424-3.731-.713v-.538c1.533.396 3.071.551 4.141.599 4.674.673 12.921-3.685 15.61-5.411 1.703-1.263 4.42-3.513 5.455-3.592a.7.7 0 0 1 .16.265z'
          fill='#dfbf39'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-2.67 1.396-6.826 3.723-12.011 3.95h-1.634c-1.482-.202-2.385-.341-3.097-.523v-.577c1.315.286 2.584.407 3.507.448 4.674.673 12.921-3.685 15.61-5.411 1.703-1.263 4.42-3.513 5.455-3.592a.7.7 0 0 1 .16.265z'
          fill='#dfbf39'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-2.67 1.396-6.826 3.723-12.011 3.95h-1.634c-1.078-.146-1.848-.26-2.463-.381v-.593c1.083.197 2.1.287 2.873.322 4.674.673 12.921-3.685 15.61-5.411 1.703-1.263 4.42-3.513 5.455-3.592a.7.7 0 0 1 .16.265z'
          fill='#dfbf39'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-2.67 1.396-6.826 3.723-12.011 3.95h-1.634a63.81 63.81 0 0 1-1.829-.268v-.602c.842.125 1.619.189 2.238.217 4.674.673 12.921-3.685 15.61-5.411 1.703-1.263 4.42-3.513 5.455-3.592a.7.7 0 0 1 .161.266z'
          fill='#debd39'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-2.67 1.396-6.826 3.723-12.011 3.95h-1.634a93.323 93.323 0 0 1-1.194-.168v-.616c.597.071 1.144.111 1.604.132 4.674.673 12.921-3.685 15.61-5.411 1.703-1.263 4.42-3.513 5.455-3.592a.7.7 0 0 1 .16.265z'
          fill='#ddbe3a'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-2.67 1.396-6.826 3.723-12.011 3.95h-1.634a72.848 72.848 0 0 1-.56-.077v-.641c.352.032.678.052.969.065 4.674.673 12.921-3.685 15.61-5.411 1.703-1.263 4.42-3.513 5.455-3.592a.7.7 0 0 1 .161.266z'
          fill='#debd39'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-2.67 1.396-6.826 3.723-12.011 3.95h-1.559v-.67l.334.018c4.674.673 12.921-3.685 15.61-5.411 1.703-1.263 4.42-3.513 5.455-3.592a.69.69 0 0 1 .161.265z'
          fill='#dcbd3a'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-2.67 1.396-6.826 3.723-12.011 3.95h-.924v-.617c4.719.456 12.677-3.756 15.31-5.446 1.703-1.263 4.42-3.513 5.455-3.592a.7.7 0 0 1 .16.265z'
          fill='#dbbb3a'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-2.67 1.396-6.826 3.723-12.011 3.95h-.29v-.583c4.768.06 12.158-3.864 14.676-5.48 1.703-1.263 4.42-3.513 5.455-3.592a.7.7 0 0 1 .16.265z'
          fill='#dbbb3a'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-2.609 1.365-6.642 3.62-11.667 3.932v-.58c4.765-.262 11.631-3.919 14.042-5.466 1.703-1.263 4.42-3.513 5.455-3.592a.71.71 0 0 1 .16.266z'
          fill='#dbbb3a'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-2.5 1.307-6.302 3.43-11.032 3.882v-.582c4.721-.524 11.102-3.934 13.407-5.413 1.703-1.263 4.42-3.513 5.455-3.592a.7.7 0 0 1 .16.265z'
          fill='#dbbb3a'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-2.387 1.248-5.961 3.24-10.397 3.811v-.598c4.642-.735 10.569-3.913 12.772-5.327 1.703-1.263 4.42-3.513 5.455-3.592a.71.71 0 0 1 .16.266z'
          fill='#dbbb3a'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-2.271 1.188-5.62 3.05-9.763 3.719v-.618c4.534-.904 10.035-3.864 12.138-5.214 1.703-1.263 4.42-3.513 5.455-3.592a.7.7 0 0 1 .16.265z'
          fill='#dbbb3a'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-2.154 1.126-5.278 2.86-9.128 3.606v-.645c4.4-1.036 9.498-3.787 11.503-5.075 1.703-1.263 4.42-3.513 5.455-3.592a.71.71 0 0 1 .16.266z'
          fill='#dbbb3a'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-2.035 1.064-4.934 2.669-8.494 3.473v-.671c4.246-1.136 8.959-3.689 10.869-4.916 1.703-1.263 4.42-3.513 5.455-3.592a.71.71 0 0 1 .16.266z'
          fill='#dbbb3a'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-1.912 1-4.589 2.479-7.86 3.32v-.697c4.072-1.206 8.418-3.571 10.235-4.736 1.703-1.263 4.42-3.513 5.455-3.592a.7.7 0 0 1 .16.265z'
          fill='#dbbb3a'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-1.787.935-4.242 2.287-7.225 3.147v-.72c3.881-1.252 7.879-3.436 9.6-4.541 1.703-1.263 4.42-3.513 5.455-3.592a.71.71 0 0 1 .16.266z'
          fill='#dbbb3a'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-1.66.867-3.893 2.095-6.59 2.954v-.74c3.674-1.274 7.336-3.283 8.965-4.328 1.703-1.263 4.42-3.513 5.455-3.592a.71.71 0 0 1 .16.266z'
          fill='#dbbb3a'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-1.527.799-3.541 1.902-5.956 2.743v-.755c3.456-1.275 6.797-3.116 8.331-4.101 1.703-1.263 4.42-3.513 5.455-3.592a.7.7 0 0 1 .16.265z'
          fill='#dbbb3a'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-1.393.728-3.188 1.708-5.322 2.513v-.766c3.224-1.257 6.257-2.936 7.697-3.86 1.703-1.263 4.42-3.513 5.455-3.592a.7.7 0 0 1 .16.265z'
          fill='#dbbb3a'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-1.252.654-2.83 1.514-4.687 2.265v-.772c2.982-1.221 5.716-2.743 7.062-3.606 1.703-1.263 4.42-3.513 5.455-3.592a.7.7 0 0 1 .16.265z'
          fill='#dbbb3a'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-1.105.578-2.469 1.317-4.053 2v-.772c2.731-1.169 5.18-2.539 6.428-3.34 1.703-1.263 4.42-3.513 5.455-3.592a.69.69 0 0 1 .16.264z'
          fill='#dbbb3a'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-.955.5-2.102 1.119-3.418 1.718v-.769c2.471-1.102 4.641-2.323 5.793-3.062 1.703-1.263 4.42-3.513 5.455-3.592a.7.7 0 0 1 .16.265z'
          fill='#dbbb3a'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-.799.417-1.73.919-2.783 1.422v-.761c2.205-1.021 4.107-2.1 5.158-2.774 1.703-1.263 4.42-3.513 5.455-3.592a.7.7 0 0 1 .16.265z'
          fill='#dbbb3a'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-.635.332-1.354.716-2.148 1.112v-.75c1.932-.928 3.574-1.866 4.523-2.475 1.703-1.263 4.42-3.513 5.455-3.592a.7.7 0 0 1 .16.265z'
          fill='#dbbb3a'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824-.461.241-.967.51-1.516.791v-.739c1.656-.823 3.047-1.624 3.891-2.165 1.703-1.263 4.42-3.513 5.455-3.592a.7.7 0 0 1 .16.265z'
          fill='#dbbb3a'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824l-.881.462v-.731a41.617 41.617 0 0 0 3.256-1.844c1.703-1.263 4.42-3.513 5.455-3.592a.7.7 0 0 1 .16.265z'
          fill='#dbbb3a'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.834 1.28-3.221 2.293-5.92 3.824l-.246.128v-.729a38.475 38.475 0 0 0 2.621-1.512c1.703-1.263 4.42-3.513 5.455-3.592a.7.7 0 0 1 .16.265z'
          fill='#dbbb3a'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.744 1.216-3.084 2.193-5.531 3.601v-.722a35.243 35.243 0 0 0 1.986-1.169c1.703-1.263 4.42-3.513 5.455-3.592a.71.71 0 0 1 .16.266z'
          fill='#dbbb3a'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.586 1.107-2.84 2.016-4.896 3.231v-.708c.535-.306.992-.583 1.352-.813 1.703-1.263 4.42-3.513 5.455-3.592a.719.719 0 0 1 .159.266z'
          fill='#dbbb3a'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.416.989-2.568 1.819-4.262 2.851v-.698c.27-.161.51-.31.717-.443 1.703-1.263 4.42-3.513 5.455-3.592a.71.71 0 0 1 .16.266z'
          fill='#dbbb3a'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.232.86-2.264 1.601-3.627 2.458v-.695l.082-.053c1.703-1.263 4.42-3.513 5.455-3.592a.71.71 0 0 1 .16.266z'
          fill='#dbbb3a'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-1.035.722-1.928 1.36-2.994 2.053v-.757c1.691-1.285 3.977-3.106 4.904-3.177a.7.7 0 0 1 .16.265z'
          fill='#dbbb3a'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-.822.575-1.557 1.096-2.359 1.633v-.823c1.588-1.214 3.453-2.629 4.27-2.692a.719.719 0 0 1 .159.266z'
          fill='#dbbb3a'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-.602.42-1.156.812-1.725 1.202v-.875c1.424-1.078 2.926-2.154 3.635-2.208a.7.7 0 0 1 .16.265z'
          fill='#dbbb3a'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-.377.263-.734.516-1.09.763v-.912c1.215-.898 2.396-1.687 3-1.733a.71.71 0 0 1 .16.266z'
          fill='#dbbb3a'
        />
        <path
          d='M108.682 56.103v.166c-.111.374-.971.646-2.07 1.45-.154.108-.307.214-.455.319v-.928c.979-.693 1.867-1.235 2.365-1.273a.71.71 0 0 1 .16.266z'
          fill='#dbbb3a'
        />
        <path
          d='M108.682 56.103v.166c-.104.354-.879.617-1.891 1.323v-.917c.719-.474 1.344-.809 1.73-.838a.7.7 0 0 1 .161.266z'
          fill='#dbbb3a'
        />
        <path
          d='M108.682 56.103v.166c-.082.275-.568.496-1.256.912v-.9c.447-.257.828-.424 1.096-.444a.71.71 0 0 1 .16.266z'
          fill='#dbbb3a'
        />
        <path
          d='M108.682 56.103v.166c-.053.18-.279.336-.621.539v-.846c.176-.071.332-.116.461-.125a.71.71 0 0 1 .16.266z'
          fill='#dbbb3a'
        />
        <path
          d='M76.947 60.772c.825.435 1.294.855 2.691 1.779 2.165 1.722 5.765 2.209 7.799 2.305 4.738.666 13.07-3.749 15.65-5.434 1.734-1.283 4.404-3.488 5.424-3.576.578.675-.477.798-1.959 1.9-1.826 1.266-3.158 2.25-5.824 3.759-2.92 1.528-7.697 4.214-13.639 3.976-3.178-.425-3.823-.602-5.439-1.461-1.311-.884-3.723-2.156-4.703-3.248z'
          fill='#dfbf39'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-2.674 1.399-6.902 3.768-12.156 3.976H87.09c-3.178-.425-3.823-.602-5.439-1.461-1.312-.885-3.724-2.157-4.704-3.248.825.435 1.294.855 2.691 1.779 2.165 1.722 5.765 2.209 7.799 2.305 4.738.666 13.07-3.749 15.65-5.434 1.734-1.283 4.404-3.488 5.424-3.576a.708.708 0 0 1 .147.24z'
          fill='#dfbf39'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-2.674 1.399-6.902 3.768-12.156 3.976H87.09c-3.178-.425-3.823-.602-5.439-1.461-1.082-.729-2.911-1.722-4.069-2.66v-.217c.524.337 1.048.742 2.057 1.408 2.165 1.722 5.765 2.209 7.799 2.305 4.738.666 13.07-3.749 15.65-5.434 1.734-1.283 4.404-3.488 5.424-3.576a.72.72 0 0 1 .146.24z'
          fill='#dfbf39'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-2.674 1.399-6.902 3.768-12.156 3.976H87.09c-3.178-.425-3.823-.602-5.439-1.461-.899-.607-2.317-1.396-3.435-2.184v-.262c.367.257.803.567 1.422.976 2.165 1.722 5.765 2.209 7.799 2.305 4.738.666 13.07-3.749 15.65-5.434 1.734-1.283 4.404-3.488 5.424-3.576a.72.72 0 0 1 .147.241z'
          fill='#debd39'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-2.674 1.399-6.902 3.768-12.156 3.976H87.09c-3.178-.425-3.823-.602-5.439-1.461-.736-.497-1.819-1.115-2.8-1.755v-.247c.229.158.486.333.788.533 2.165 1.722 5.765 2.209 7.799 2.305 4.738.666 13.07-3.749 15.65-5.434 1.734-1.283 4.404-3.488 5.424-3.576a.72.72 0 0 1 .146.24z'
          fill='#debd39'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-2.674 1.399-6.902 3.768-12.156 3.976H87.09c-3.178-.425-3.823-.602-5.439-1.461-.58-.392-1.375-.857-2.166-1.352v-.22l.153.102c2.165 1.722 5.765 2.209 7.799 2.305 4.738.666 13.07-3.749 15.65-5.434 1.734-1.283 4.404-3.488 5.424-3.576a.72.72 0 0 1 .147.241z'
          fill='#debd39'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-2.674 1.399-6.902 3.768-12.156 3.976H87.09c-3.178-.425-3.823-.602-5.439-1.461-.423-.286-.962-.612-1.531-.96v-.162c2.187 1.441 5.428 1.868 7.318 1.958 4.738.666 13.07-3.749 15.65-5.434 1.734-1.283 4.404-3.488 5.424-3.576a.72.72 0 0 1 .146.24z'
          fill='#debd39'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-2.674 1.399-6.902 3.768-12.156 3.976H87.09c-3.178-.425-3.823-.602-5.439-1.461a25.154 25.154 0 0 0-.896-.571v-.174c2.143 1.141 4.969 1.5 6.684 1.581 4.738.666 13.07-3.749 15.65-5.434 1.734-1.283 4.404-3.488 5.424-3.576a.732.732 0 0 1 .145.24z'
          fill='#ddbc39'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-2.674 1.399-6.902 3.768-12.156 3.976H87.09c-3.178-.425-3.823-.602-5.439-1.461a17.245 17.245 0 0 0-.262-.173v-.265c2.041.9 4.499 1.201 6.049 1.274 4.738.666 13.07-3.749 15.65-5.434 1.734-1.283 4.404-3.488 5.424-3.576a.72.72 0 0 1 .146.24z'
          fill='#ddbc39'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-2.674 1.399-6.902 3.768-12.156 3.976H87.09c-2.923-.391-3.703-.571-5.067-1.267v-.375c1.897.702 4.022.951 5.415 1.017 4.738.666 13.07-3.749 15.65-5.434 1.734-1.283 4.404-3.488 5.424-3.576a.72.72 0 0 1 .146.24z'
          fill='#dbbb3a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-2.674 1.399-6.902 3.768-12.156 3.976H87.09c-2.444-.327-3.39-.507-4.432-.964v-.462c1.722.539 3.541.742 4.78.801 4.738.666 13.07-3.749 15.65-5.434 1.734-1.283 4.404-3.488 5.424-3.576a.72.72 0 0 1 .146.24z'
          fill='#dbb93a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-2.674 1.399-6.902 3.768-12.156 3.976H87.09c-1.95-.261-2.946-.428-3.798-.717v-.526c1.524.404 3.059.566 4.146.618 4.738.666 13.07-3.749 15.65-5.434 1.734-1.283 4.404-3.488 5.424-3.576a.72.72 0 0 1 .146.24z'
          fill='#dbbb3a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-2.674 1.399-6.902 3.768-12.156 3.976H87.09c-1.492-.199-2.425-.344-3.164-.53v-.558c1.307.292 2.575.419 3.511.463 4.738.666 13.07-3.749 15.65-5.434 1.734-1.283 4.404-3.488 5.424-3.576a.708.708 0 0 1 .147.24z'
          fill='#dbb93a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-2.674 1.399-6.902 3.768-12.156 3.976H87.09c-1.097-.146-1.892-.264-2.529-.389v-.569c1.077.202 2.093.296 2.876.333 4.738.666 13.07-3.749 15.65-5.434 1.734-1.283 4.404-3.488 5.424-3.576a.708.708 0 0 1 .147.24z'
          fill='#dbb93a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-2.674 1.399-6.902 3.768-12.156 3.976H87.09a60.283 60.283 0 0 1-1.894-.275v-.575a21.7 21.7 0 0 0 2.242.225c4.738.666 13.07-3.749 15.65-5.434 1.734-1.283 4.404-3.488 5.424-3.576a.72.72 0 0 1 .146.24z'
          fill='#d9b93a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-2.674 1.399-6.902 3.768-12.156 3.976H87.09c-.472-.063-.888-.12-1.26-.176v-.586c.594.073 1.142.115 1.607.137 4.738.666 13.07-3.749 15.65-5.434 1.734-1.283 4.404-3.488 5.424-3.576a.708.708 0 0 1 .147.24z'
          fill='#d9b93a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-2.674 1.399-6.902 3.768-12.156 3.976H87.09l-.625-.084v-.609c.352.033.679.055.973.069 4.738.666 13.07-3.749 15.65-5.434 1.734-1.283 4.404-3.488 5.424-3.576a.707.707 0 0 1 .146.239z'
          fill='#d9b73a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-2.674 1.399-6.902 3.768-12.156 3.976h-1.474v-.644l.338.019c4.738.666 13.07-3.749 15.65-5.434 1.734-1.283 4.404-3.488 5.424-3.576a.708.708 0 0 1 .147.24z'
          fill='#d9b73a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-2.674 1.399-6.902 3.768-12.156 3.976h-.839v-.591c4.78.452 12.827-3.817 15.354-5.468 1.734-1.283 4.404-3.488 5.424-3.576a.72.72 0 0 1 .146.24z'
          fill='#d8b53a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-2.674 1.399-6.902 3.768-12.156 3.976h-.205v-.558c4.828.057 12.302-3.921 14.72-5.501 1.734-1.283 4.404-3.488 5.424-3.576a.72.72 0 0 1 .146.24z'
          fill='#d8b53a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-2.6 1.361-6.671 3.639-11.726 3.954v-.551c4.825-.265 11.771-3.974 14.085-5.486 1.734-1.283 4.404-3.488 5.424-3.576a.72.72 0 0 1 .146.24z'
          fill='#d6b43a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-2.49 1.303-6.331 3.447-11.091 3.903v-.553c4.78-.528 11.236-3.987 13.451-5.434 1.734-1.283 4.404-3.488 5.424-3.576.075.09.122.169.145.241z'
          fill='#d6b43a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-2.379 1.245-5.989 3.257-10.457 3.832v-.567c4.7-.74 10.697-3.964 12.816-5.348 1.734-1.283 4.404-3.488 5.424-3.576a.72.72 0 0 1 .146.24z'
          fill='#d6b43a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-2.266 1.186-5.646 3.066-9.822 3.74v-.588c4.592-.91 10.156-3.913 12.182-5.235 1.734-1.283 4.404-3.488 5.424-3.576a.732.732 0 0 1 .145.24z'
          fill='#d6b43a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-2.148 1.125-5.304 2.876-9.188 3.628v-.613c4.458-1.044 9.616-3.836 11.547-5.098 1.734-1.283 4.404-3.488 5.424-3.576a.72.72 0 0 1 .146.24z'
          fill='#d6b43a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-2.031 1.063-4.959 2.685-8.553 3.495v-.639c4.303-1.145 9.071-3.737 10.913-4.939 1.734-1.283 4.404-3.488 5.424-3.576a.732.732 0 0 1 .145.24z'
          fill='#d6b43a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-1.91 1-4.614 2.494-7.919 3.342v-.665c4.127-1.217 8.528-3.618 10.278-4.761 1.734-1.283 4.404-3.488 5.424-3.576.076.09.123.169.146.241z'
          fill='#d6b43a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-1.787.935-4.267 2.303-7.284 3.17v-.688c3.937-1.264 7.981-3.48 9.644-4.566 1.734-1.283 4.404-3.488 5.424-3.576.075.09.122.169.145.241z'
          fill='#d6b43a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-1.66.868-3.918 2.11-6.65 2.978v-.707c3.73-1.287 7.437-3.327 9.009-4.354 1.734-1.283 4.404-3.488 5.424-3.576a.72.72 0 0 1 .146.24z'
          fill='#d6b43a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-1.529.8-3.566 1.918-6.015 2.767v-.722c3.509-1.289 6.892-3.159 8.375-4.128 1.734-1.283 4.404-3.488 5.424-3.576a.732.732 0 0 1 .145.24z'
          fill='#d6b43a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-1.395.73-3.213 1.724-5.381 2.538v-.733c3.275-1.272 6.348-2.978 7.74-3.888 1.734-1.283 4.404-3.488 5.424-3.576a.72.72 0 0 1 .146.24z'
          fill='#d6b43a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-1.256.658-2.857 1.53-4.746 2.291v-.739c3.033-1.237 5.803-2.784 7.105-3.635 1.734-1.283 4.404-3.488 5.424-3.576a.72.72 0 0 1 .146.24z'
          fill='#d6b43a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-1.113.583-2.496 1.333-4.112 2.027v-.74c2.78-1.186 5.26-2.579 6.471-3.371 1.734-1.283 4.404-3.488 5.424-3.576.076.09.123.169.146.241z'
          fill='#d6b43a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-.965.505-2.133 1.136-3.477 1.747v-.736c2.518-1.12 4.717-2.363 5.836-3.094 1.734-1.283 4.404-3.488 5.424-3.576a.72.72 0 0 1 .146.24z'
          fill='#d6b43a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-.811.424-1.762.937-2.842 1.451v-.728c2.248-1.04 4.178-2.138 5.201-2.807 1.734-1.283 4.404-3.488 5.424-3.576.076.09.123.169.146.241z'
          fill='#d6b43a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-.646.339-1.387.734-2.207 1.143v-.718c1.975-.947 3.641-1.903 4.566-2.508 1.734-1.283 4.404-3.488 5.424-3.576a.72.72 0 0 1 .146.24z'
          fill='#d6b43a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-.477.25-1.002.529-1.574.822v-.707c1.695-.841 3.109-1.659 3.934-2.198 1.734-1.283 4.404-3.488 5.424-3.576a.732.732 0 0 1 .145.24z'
          fill='#d6b43a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759-.295.154-.607.32-.939.493V61.3c1.412-.726 2.58-1.408 3.299-1.878 1.734-1.283 4.404-3.488 5.424-3.576a.732.732 0 0 1 .145.24z'
          fill='#d6b43a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.826 1.266-3.158 2.25-5.824 3.759l-.305.16v-.697a36.43 36.43 0 0 0 2.664-1.546c1.734-1.283 4.404-3.488 5.424-3.576a.72.72 0 0 1 .146.24z'
          fill='#d6b43a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.748 1.213-3.045 2.167-5.494 3.571v-.693a32.36 32.36 0 0 0 2.029-1.203c1.734-1.283 4.404-3.488 5.424-3.576.076.09.123.169.146.241z'
          fill='#d6b43a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.592 1.104-2.809 1.993-4.859 3.202v-.68c.561-.321 1.033-.61 1.395-.846 1.734-1.283 4.404-3.488 5.424-3.576a.732.732 0 0 1 .145.24z'
          fill='#d6b43a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.42.985-2.543 1.799-4.225 2.823v-.673c.289-.174.545-.333.76-.474 1.734-1.283 4.404-3.488 5.424-3.576a.72.72 0 0 1 .146.24z'
          fill='#d6b43a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.234.856-2.242 1.583-3.59 2.43v-.673l.125-.082c1.734-1.283 4.404-3.488 5.424-3.576.076.09.123.169.146.241z'
          fill='#d6b43a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-1.033.716-1.908 1.343-2.955 2.024v-.73c1.709-1.296 3.99-3.115 4.914-3.195.076.09.123.169.146.241z'
          fill='#d6b43a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-.818.567-1.537 1.079-2.322 1.605v-.794c1.602-1.222 3.467-2.641 4.281-2.711a.72.72 0 0 1 .146.24z'
          fill='#d6b43a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-.594.412-1.135.794-1.688 1.174v-.845c1.434-1.085 2.939-2.167 3.646-2.229a.708.708 0 0 1 .147.24z'
          fill='#d6b43a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471-.365.254-.711.497-1.053.734v-.88c1.225-.906 2.408-1.701 3.012-1.753a.707.707 0 0 1 .146.239z'
          fill='#d6b43a'
        />
        <path
          d='M108.658 56.086v.189c-.125.37-.996.646-2.105 1.471l-.418.292v-.899c.986-.7 1.879-1.249 2.377-1.292a.707.707 0 0 1 .146.239z'
          fill='#d6b43a'
        />
        <path
          d='M108.658 56.086v.189c-.117.346-.885.61-1.889 1.315v-.889c.725-.48 1.354-.822 1.742-.856a.72.72 0 0 1 .147.241z'
          fill='#d6b43a'
        />
        <path
          d='M108.658 56.086v.189c-.092.268-.574.488-1.254.902v-.873c.453-.263.838-.436 1.107-.458a.708.708 0 0 1 .147.24z'
          fill='#d6b43a'
        />
        <path
          d='M108.658 56.086v.189c-.061.174-.283.328-.619.527v-.822c.182-.076.342-.123.473-.134a.72.72 0 0 1 .146.24z'
          fill='#d6b43a'
        />
        <path
          d='M76.923 60.763c.801.391 1.358.874 2.756 1.798 2.154 1.706 5.685 2.209 7.739 2.311 4.801.66 13.22-3.812 15.691-5.456 1.768-1.304 4.387-3.462 5.393-3.562.559.669-.508.787-2.008 1.92-1.816 1.253-3.096 2.206-5.729 3.696-2.896 1.516-7.697 4.213-13.633 4-3.187-.418-3.959-.624-5.511-1.458-1.344-.895-3.711-2.146-4.698-3.249z'
          fill='#ddbc39'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-2.676 1.401-6.975 3.809-12.294 4h-1.339c-3.187-.418-3.959-.624-5.511-1.458-1.345-.896-3.712-2.146-4.699-3.249.801.391 1.358.874 2.756 1.798 2.154 1.706 5.685 2.209 7.739 2.311 4.801.66 13.22-3.812 15.691-5.456 1.768-1.304 4.387-3.462 5.393-3.562.067.08.11.152.134.219z'
          fill='#ddbc39'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-2.676 1.401-6.975 3.809-12.294 4h-1.339c-3.187-.418-3.959-.624-5.511-1.458-1.108-.738-2.913-1.717-4.064-2.657v-.237c.541.339 1.112.775 2.121 1.443 2.154 1.706 5.685 2.209 7.739 2.311 4.801.66 13.22-3.812 15.691-5.456 1.768-1.304 4.387-3.462 5.393-3.562.067.08.11.152.134.219z'
          fill='#ddbc39'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-2.676 1.401-6.975 3.809-12.294 4h-1.339c-3.187-.418-3.959-.624-5.511-1.458-.921-.613-2.321-1.393-3.429-2.178v-.292c.39.27.851.599 1.486 1.02 2.154 1.706 5.685 2.209 7.739 2.311 4.801.66 13.22-3.812 15.691-5.456 1.768-1.304 4.387-3.462 5.393-3.562.067.079.11.151.134.218z'
          fill='#ddba39'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-2.676 1.401-6.975 3.809-12.294 4h-1.339c-3.187-.418-3.959-.624-5.511-1.458-.752-.5-1.824-1.112-2.795-1.748v-.281c.248.172.527.363.853.578 2.154 1.706 5.685 2.209 7.739 2.311 4.801.66 13.22-3.812 15.691-5.456 1.768-1.304 4.387-3.462 5.393-3.562a.75.75 0 0 1 .133.219z'
          fill='#dbb93a'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-2.676 1.401-6.975 3.809-12.294 4h-1.339c-3.187-.418-3.959-.624-5.511-1.458-.59-.393-1.378-.855-2.161-1.342v-.253l.218.145c2.154 1.706 5.685 2.209 7.739 2.311 4.801.66 13.22-3.812 15.691-5.456 1.768-1.304 4.387-3.462 5.393-3.562.067.079.11.151.134.218z'
          fill='#dbb93a'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-2.676 1.401-6.975 3.809-12.294 4h-1.339c-3.187-.418-3.959-.624-5.511-1.458-.43-.287-.965-.609-1.526-.952v-.194c2.17 1.462 5.395 1.911 7.322 2.006 4.801.66 13.22-3.812 15.691-5.456 1.768-1.304 4.387-3.462 5.393-3.562.067.08.11.152.134.219z'
          fill='#dbb93a'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-2.676 1.401-6.975 3.809-12.294 4h-1.339c-3.187-.418-3.959-.624-5.511-1.458a29.228 29.228 0 0 0-.891-.564v-.199c2.127 1.16 4.94 1.536 6.688 1.623 4.801.66 13.22-3.812 15.691-5.456 1.768-1.304 4.387-3.462 5.393-3.562a.75.75 0 0 1 .133.219z'
          fill='#dab739'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-2.676 1.401-6.975 3.809-12.294 4h-1.339c-3.187-.418-3.959-.624-5.511-1.458-.082-.054-.167-.11-.257-.167v-.28c2.027.915 4.475 1.229 6.053 1.308 4.801.66 13.22-3.812 15.691-5.456 1.768-1.304 4.387-3.462 5.393-3.562.067.079.11.151.134.218z'
          fill='#d9b73a'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-2.676 1.401-6.975 3.809-12.294 4h-1.339c-2.918-.383-3.811-.587-5.133-1.26v-.384c1.884.714 4.002.976 5.418 1.045 4.801.66 13.22-3.812 15.691-5.456 1.768-1.304 4.387-3.462 5.393-3.562a.75.75 0 0 1 .134.22z'
          fill='#d8b53a'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-2.676 1.401-6.975 3.809-12.294 4h-1.339c-2.433-.319-3.458-.515-4.498-.96v-.461c1.711.549 3.524.762 4.784.824 4.801.66 13.22-3.812 15.691-5.456 1.768-1.304 4.387-3.462 5.393-3.562a.733.733 0 0 1 .133.218z'
          fill='#d8b53a'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-2.676 1.401-6.975 3.809-12.294 4h-1.339c-1.946-.255-2.991-.431-3.864-.72v-.515c1.515.411 3.045.582 4.149.636 4.801.66 13.22-3.812 15.691-5.456 1.768-1.304 4.387-3.462 5.393-3.562a.75.75 0 0 1 .134.22z'
          fill='#d8b53a'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-2.676 1.401-6.975 3.809-12.294 4h-1.339c-1.5-.197-2.465-.347-3.229-.537v-.539c1.299.298 2.564.431 3.515.478 4.801.66 13.22-3.812 15.691-5.456 1.768-1.304 4.387-3.462 5.393-3.562a.75.75 0 0 1 .133.219z'
          fill='#d6b43a'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-2.676 1.401-6.975 3.809-12.294 4h-1.339c-1.115-.146-1.934-.267-2.595-.396v-.546c1.071.206 2.086.305 2.88.344 4.801.66 13.22-3.812 15.691-5.456 1.768-1.304 4.387-3.462 5.393-3.562.067.08.11.152.134.219z'
          fill='#d6b33a'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-2.676 1.401-6.975 3.809-12.294 4h-1.339a61.612 61.612 0 0 1-1.96-.282v-.549c.833.132 1.61.201 2.246.233 4.801.66 13.22-3.812 15.691-5.456 1.768-1.304 4.387-3.462 5.393-3.562a.75.75 0 0 1 .133.219z'
          fill='#d5b13a'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-2.676 1.401-6.975 3.809-12.294 4h-1.339a78.523 78.523 0 0 1-1.325-.183v-.558c.591.075 1.14.119 1.611.143 4.801.66 13.22-3.812 15.691-5.456 1.768-1.304 4.387-3.462 5.393-3.562a.75.75 0 0 1 .133.219z'
          fill='#d3b03a'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-2.676 1.401-6.975 3.809-12.294 4h-1.339c-.244-.032-.473-.062-.69-.093v-.577c.35.034.678.057.976.072 4.801.66 13.22-3.812 15.691-5.456 1.768-1.304 4.387-3.462 5.393-3.562a.75.75 0 0 1 .133.219z'
          fill='#d3b03a'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-2.676 1.401-6.975 3.809-12.294 4h-1.339l-.056-.007v-.61c.118.008.232.014.342.02 4.801.66 13.22-3.812 15.691-5.456 1.768-1.304 4.387-3.462 5.393-3.562a.733.733 0 0 1 .133.218z'
          fill='#d3b03a'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-2.676 1.401-6.975 3.809-12.294 4h-.76v-.565c4.843.448 12.976-3.877 15.398-5.489 1.768-1.304 4.387-3.462 5.393-3.562a.75.75 0 0 1 .133.219z'
          fill='#d3af3a'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-2.676 1.401-6.975 3.809-12.294 4h-.125v-.533c4.889.054 12.445-3.979 14.763-5.521 1.768-1.304 4.387-3.462 5.393-3.562a.75.75 0 0 1 .133.219z'
          fill='#d3af3a'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-2.59 1.355-6.701 3.655-11.785 3.974v-.522c4.885-.269 11.908-4.028 14.129-5.506 1.768-1.304 4.387-3.462 5.393-3.562a.75.75 0 0 1 .133.219z'
          fill='#d0ac3b'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-2.482 1.299-6.359 3.464-11.151 3.924v-.524c4.84-.532 11.368-4.039 13.495-5.454 1.768-1.304 4.387-3.462 5.393-3.562a.75.75 0 0 1 .133.219z'
          fill='#d0ac3b'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-2.371 1.241-6.017 3.273-10.516 3.853v-.539c4.76-.745 10.825-4.015 12.86-5.369 1.768-1.304 4.387-3.462 5.393-3.562a.766.766 0 0 1 .133.22z'
          fill='#d0ac3b'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-2.26 1.182-5.673 3.082-9.881 3.761v-.559c4.65-.917 10.28-3.962 12.225-5.256 1.768-1.304 4.387-3.462 5.393-3.562a.75.75 0 0 1 .133.219z'
          fill='#d0ac3b'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-2.145 1.122-5.33 2.891-9.247 3.648v-.583c4.516-1.051 9.733-3.884 11.59-5.12 1.768-1.304 4.387-3.462 5.393-3.562a.75.75 0 0 1 .134.22z'
          fill='#d0ac3b'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-2.027 1.061-4.985 2.7-8.612 3.516v-.608c4.359-1.153 9.185-3.784 10.956-4.962 1.768-1.304 4.387-3.462 5.393-3.562a.75.75 0 0 1 .133.219z'
          fill='#d0ac3b'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-1.908.999-4.64 2.509-7.978 3.363v-.632c4.185-1.227 8.636-3.664 10.322-4.785 1.768-1.304 4.387-3.462 5.393-3.562a.75.75 0 0 1 .133.219z'
          fill='#d0ac3b'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-1.785.934-4.292 2.317-7.343 3.191v-.655c3.992-1.274 8.085-3.525 9.687-4.59 1.768-1.304 4.387-3.462 5.393-3.562a.75.75 0 0 1 .133.219z'
          fill='#d0ac3b'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-1.66.869-3.944 2.125-6.708 3v-.674c3.783-1.3 7.537-3.372 9.052-4.38 1.768-1.304 4.387-3.462 5.393-3.562a.75.75 0 0 1 .133.219z'
          fill='#d0ac3b'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-1.531.801-3.594 1.933-6.074 2.79v-.689c3.562-1.303 6.986-3.203 8.417-4.155 1.768-1.304 4.387-3.462 5.393-3.562.067.08.11.152.134.219z'
          fill='#d0ac3b'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-1.398.732-3.24 1.74-5.439 2.562v-.7c3.328-1.287 6.438-3.021 7.783-3.917 1.768-1.304 4.387-3.462 5.393-3.562a.766.766 0 0 1 .133.22z'
          fill='#d0ac3b'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-1.262.661-2.885 1.545-4.805 2.315v-.706c3.084-1.253 5.889-2.826 7.148-3.664 1.768-1.304 4.387-3.462 5.393-3.562a.75.75 0 0 1 .134.22z'
          fill='#d0ac3b'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-1.121.586-2.525 1.35-4.17 2.052v-.707c2.829-1.203 5.34-2.62 6.514-3.4 1.768-1.304 4.387-3.462 5.393-3.562a.766.766 0 0 1 .133.22z'
          fill='#d0ac3b'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-.973.509-2.162 1.153-3.535 1.773v-.704c2.564-1.138 4.795-2.403 5.879-3.125 1.768-1.304 4.387-3.462 5.393-3.562a.782.782 0 0 1 .133.221z'
          fill='#d0ac3b'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-.82.429-1.793.954-2.9 1.479v-.696c2.295-1.058 4.25-2.177 5.244-2.837 1.768-1.304 4.387-3.462 5.393-3.562a.75.75 0 0 1 .133.219z'
          fill='#d0ac3b'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-.66.345-1.418.752-2.266 1.171v-.687c2.018-.965 3.709-1.94 4.609-2.54 1.768-1.304 4.387-3.462 5.393-3.562.067.082.11.154.134.221z'
          fill='#d0ac3b'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696-.49.257-1.035.547-1.631.852v-.676c1.734-.86 3.17-1.696 3.975-2.231 1.768-1.304 4.387-3.462 5.393-3.562a.766.766 0 0 1 .133.22z'
          fill='#d0ac3b'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696l-.998.524v-.668c1.449-.744 2.637-1.442 3.342-1.911 1.768-1.304 4.387-3.462 5.393-3.562a.766.766 0 0 1 .133.22z'
          fill='#d0ac3b'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.816 1.253-3.096 2.206-5.729 3.696l-.361.19v-.667c1.158-.616 2.107-1.181 2.705-1.579 1.768-1.304 4.387-3.462 5.393-3.562a.782.782 0 0 1 .133.221z'
          fill='#d0ac3b'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.752 1.209-3.006 2.139-5.457 3.542v-.665c.871-.48 1.586-.913 2.072-1.235 1.768-1.304 4.387-3.462 5.393-3.562a.75.75 0 0 1 .133.219z'
          fill='#d0ac3b'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.594 1.101-2.777 1.969-4.822 3.173v-.653a26.888 26.888 0 0 0 1.438-.878c1.768-1.304 4.387-3.462 5.393-3.562.065.08.108.152.132.219z'
          fill='#d0ac3b'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.422.981-2.516 1.779-4.188 2.794v-.648c.311-.187.58-.357.803-.504 1.768-1.304 4.387-3.462 5.393-3.562a.75.75 0 0 1 .133.219z'
          fill='#d0ac3b'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.234.852-2.221 1.565-3.553 2.402v-.651l.168-.109c1.768-1.304 4.387-3.462 5.393-3.562a.75.75 0 0 1 .133.219z'
          fill='#d0ac3b'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-1.029.711-1.889 1.326-2.918 1.996v-.704c1.734-1.308 4.006-3.122 4.926-3.212a.75.75 0 0 1 .133.219z'
          fill='#d0ac3b'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-.812.561-1.518 1.061-2.283 1.576v-.765c1.617-1.23 3.479-2.651 4.291-2.731a.75.75 0 0 1 .133.219z'
          fill='#d0ac3b'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-.584.403-1.113.775-1.648 1.144v-.814c1.445-1.093 2.949-2.18 3.656-2.25a.75.75 0 0 1 .133.219z'
          fill='#d0ac3b'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-.354.244-.688.477-1.014.705v-.851c1.232-.913 2.418-1.714 3.021-1.773.067.079.11.151.134.218z'
          fill='#d0ac3b'
        />
        <path
          d='M108.635 56.074v.205c-.137.368-1.018.647-2.141 1.496-.129.089-.256.177-.379.264v-.872c.99-.707 1.887-1.263 2.387-1.312a.75.75 0 0 1 .133.219z'
          fill='#d0ac3b'
        />
        <path
          d='M108.635 56.074v.205c-.125.34-.887.604-1.885 1.31v-.862c.73-.486 1.361-.834 1.752-.873a.766.766 0 0 1 .133.22z'
          fill='#d0ac3b'
        />
        <path
          d='M108.635 56.074v.205c-.098.263-.576.481-1.252.894v-.846c.459-.269.848-.445 1.119-.472a.75.75 0 0 1 .133.219z'
          fill='#d0ac3b'
        />
        <path
          d='M108.635 56.074v.205c-.062.17-.285.321-.617.518v-.799c.188-.08.35-.13.484-.143a.75.75 0 0 1 .133.219z'
          fill='#d0ac3b'
        />
        <path
          d='M76.9 60.754c.776.347 1.423.891 2.82 1.818 2.143 1.689 5.604 2.209 7.679 2.317 4.865.653 13.371-3.875 15.73-5.479 1.799-1.325 4.371-3.437 5.361-3.545.541.662-.539.775-2.055 1.939-1.809 1.24-3.033 2.162-5.633 3.633-2.875 1.504-7.696 4.214-13.627 4.022-3.196-.411-4.095-.646-5.583-1.455-1.377-.906-3.7-2.136-4.692-3.25z'
          fill='#dab73b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-2.68 1.402-7.05 3.851-12.434 4.022h-1.193c-3.196-.411-4.095-.646-5.583-1.455-1.377-.907-3.701-2.136-4.692-3.25.776.347 1.423.891 2.82 1.818 2.143 1.689 5.604 2.209 7.679 2.317 4.865.653 13.371-3.875 15.73-5.479 1.799-1.325 4.371-3.437 5.361-3.545a.642.642 0 0 1 .119.196z'
          fill='#dab73b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-2.68 1.402-7.05 3.851-12.434 4.022h-1.193c-3.196-.411-4.095-.646-5.583-1.455-1.135-.748-2.913-1.714-4.058-2.654v-.258c.559.34 1.176.81 2.186 1.479 2.143 1.689 5.604 2.209 7.679 2.317 4.865.653 13.371-3.875 15.73-5.479 1.799-1.325 4.371-3.437 5.361-3.545a.657.657 0 0 1 .119.197z'
          fill='#dab63b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-2.68 1.402-7.05 3.851-12.434 4.022h-1.193c-3.196-.411-4.095-.646-5.583-1.455-.942-.62-2.325-1.391-3.423-2.172v-.323c.414.284.901.632 1.551 1.063 2.143 1.689 5.604 2.209 7.679 2.317 4.865.653 13.371-3.875 15.73-5.479 1.799-1.325 4.371-3.437 5.361-3.545a.642.642 0 0 1 .119.196z'
          fill='#d8b53a'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-2.68 1.402-7.05 3.851-12.434 4.022h-1.193c-3.196-.411-4.095-.646-5.583-1.455-.767-.505-1.828-1.11-2.789-1.74v-.315c.268.186.569.393.916.623 2.143 1.689 5.604 2.209 7.679 2.317 4.865.653 13.371-3.875 15.73-5.479 1.799-1.325 4.371-3.437 5.361-3.545.06.071.099.135.12.196z'
          fill='#d8b53a'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-2.68 1.402-7.05 3.851-12.434 4.022h-1.193c-3.196-.411-4.095-.646-5.583-1.455-.601-.396-1.382-.853-2.154-1.335v-.286c.09.062.184.124.281.188 2.143 1.689 5.604 2.209 7.679 2.317 4.865.653 13.371-3.875 15.73-5.479 1.799-1.325 4.371-3.437 5.361-3.545.06.072.099.136.12.197z'
          fill='#d7b33a'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-2.68 1.402-7.05 3.851-12.434 4.022h-1.193c-3.196-.411-4.095-.646-5.583-1.455-.436-.287-.966-.606-1.52-.943v-.229c2.154 1.483 5.362 1.956 7.326 2.057 4.865.653 13.371-3.875 15.73-5.479 1.799-1.325 4.371-3.437 5.361-3.545.06.071.099.135.12.196z'
          fill='#d7b33a'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-2.68 1.402-7.05 3.851-12.434 4.022h-1.193c-3.196-.411-4.095-.646-5.583-1.455a31.578 31.578 0 0 0-.885-.557v-.223c2.112 1.176 4.912 1.572 6.691 1.664 4.865.653 13.371-3.875 15.73-5.479 1.799-1.325 4.371-3.437 5.361-3.545.06.072.099.136.12.197z'
          fill='#d7b23a'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-2.68 1.402-7.05 3.851-12.434 4.022h-1.193c-3.196-.411-4.095-.646-5.583-1.455a28.422 28.422 0 0 0-.25-.162v-.296c2.013.929 4.45 1.259 6.056 1.342 4.865.653 13.371-3.875 15.73-5.479 1.799-1.325 4.371-3.437 5.361-3.545.06.072.099.136.12.197z'
          fill='#d5b13a'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-2.68 1.402-7.05 3.851-12.434 4.022h-1.193c-2.912-.375-3.917-.603-5.198-1.252v-.392c1.872.726 3.981.999 5.421 1.074 4.865.653 13.371-3.875 15.73-5.479 1.799-1.325 4.371-3.437 5.361-3.545.06.071.099.135.12.196z'
          fill='#d4af3a'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-2.68 1.402-7.05 3.851-12.434 4.022h-1.193c-2.421-.312-3.524-.521-4.564-.957v-.46c1.7.558 3.508.781 4.787.847 4.865.653 13.371-3.875 15.73-5.479 1.799-1.325 4.371-3.437 5.361-3.545.06.071.099.135.12.196z'
          fill='#d3af3a'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-2.68 1.402-7.05 3.851-12.434 4.022h-1.193c-1.942-.25-3.036-.434-3.929-.722v-.502c1.505.418 3.031.597 4.152.655 4.865.653 13.371-3.875 15.73-5.479 1.799-1.325 4.371-3.437 5.361-3.545.06.07.099.134.12.195z'
          fill='#d2ad3a'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-2.68 1.402-7.05 3.851-12.434 4.022h-1.193c-1.509-.194-2.505-.349-3.294-.543v-.519c1.291.303 2.554.442 3.518.492 4.865.653 13.371-3.875 15.73-5.479 1.799-1.325 4.371-3.437 5.361-3.545a.642.642 0 0 1 .119.196z'
          fill='#d2ad3a'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-2.68 1.402-7.05 3.851-12.434 4.022h-1.193c-1.132-.146-1.976-.269-2.66-.404v-.521c1.064.21 2.078.314 2.883.355 4.865.653 13.371-3.875 15.73-5.479 1.799-1.325 4.371-3.437 5.361-3.545.06.071.099.135.12.196z'
          fill='#d2ab3a'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-2.68 1.402-7.05 3.851-12.434 4.022h-1.193a55.407 55.407 0 0 1-2.025-.29v-.521a20.81 20.81 0 0 0 2.248.241c4.865.653 13.371-3.875 15.73-5.479 1.799-1.325 4.371-3.437 5.361-3.545.06.071.099.135.12.196z'
          fill='#d0aa3b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-2.68 1.402-7.05 3.851-12.434 4.022h-1.193a73.03 73.03 0 0 1-1.39-.19v-.528c.589.077 1.138.123 1.613.148 4.865.653 13.371-3.875 15.73-5.479 1.799-1.325 4.371-3.437 5.361-3.545.06.071.099.135.12.196z'
          fill='#cfa93a'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-2.68 1.402-7.05 3.851-12.434 4.022h-1.193c-.268-.035-.519-.067-.755-.1v-.545c.35.035.679.059.979.075 4.865.653 13.371-3.875 15.73-5.479 1.799-1.325 4.371-3.437 5.361-3.545a.642.642 0 0 1 .119.196z'
          fill='#cfa93a'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-2.68 1.402-7.05 3.851-12.434 4.022h-1.193l-.121-.016v-.575l.344.021c4.865.653 13.371-3.875 15.73-5.479 1.799-1.325 4.371-3.437 5.361-3.545.06.071.099.135.12.196z'
          fill='#cea83b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-2.68 1.402-7.05 3.851-12.434 4.022h-.68v-.538c4.906.444 13.125-3.939 15.44-5.512 1.799-1.325 4.371-3.437 5.361-3.545.06.072.099.136.12.197z'
          fill='#cea83b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-2.68 1.402-7.05 3.851-12.434 4.022h-.045v-.506c4.951.05 12.588-4.037 14.805-5.543 1.799-1.325 4.371-3.437 5.361-3.545.06.071.099.135.12.196z'
          fill='#cca53a'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-2.58 1.35-6.731 3.672-11.844 3.994v-.493c4.945-.272 12.047-4.084 14.17-5.528 1.799-1.325 4.371-3.437 5.361-3.545.06.071.099.135.12.196z'
          fill='#cba63b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-2.473 1.294-6.387 3.481-11.209 3.944v-.496c4.899-.536 11.5-4.092 13.536-5.475 1.799-1.325 4.371-3.437 5.361-3.545a.642.642 0 0 1 .119.196z'
          fill='#cba43b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-2.363 1.236-6.044 3.289-10.575 3.873v-.51c4.818-.75 10.954-4.067 12.901-5.391 1.799-1.325 4.371-3.437 5.361-3.545.06.072.099.136.12.197z'
          fill='#cba43b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-2.252 1.179-5.7 3.098-9.94 3.781v-.529c4.708-.923 10.403-4.012 12.266-5.279 1.799-1.325 4.371-3.437 5.361-3.545.06.071.099.135.12.196z'
          fill='#cba43b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-2.139 1.119-5.356 2.906-9.305 3.668v-.552c4.573-1.059 9.852-3.933 11.631-5.143 1.799-1.325 4.371-3.437 5.361-3.545.06.071.099.135.12.196z'
          fill='#cba43b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-2.023 1.059-5.011 2.715-8.671 3.536v-.577c4.417-1.162 9.3-3.832 10.997-4.986 1.799-1.325 4.371-3.437 5.361-3.545.06.071.099.135.12.196z'
          fill='#cba43b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-1.904.997-4.665 2.523-8.036 3.384v-.601c4.241-1.237 8.745-3.71 10.362-4.81 1.799-1.325 4.371-3.437 5.361-3.545.06.071.099.135.12.196z'
          fill='#cba43b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-1.783.933-4.318 2.332-7.402 3.212v-.623c4.048-1.287 8.191-3.572 9.728-4.616 1.799-1.325 4.371-3.437 5.361-3.545.06.071.099.135.12.196z'
          fill='#cba43b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-1.66.869-3.969 2.14-6.767 3.022v-.642c3.839-1.312 7.636-3.417 9.093-4.407 1.799-1.325 4.371-3.437 5.361-3.545.06.071.099.135.12.196z'
          fill='#cba43b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-1.533.802-3.619 1.947-6.132 2.812v-.656c3.617-1.317 7.083-3.247 8.458-4.183 1.799-1.325 4.371-3.437 5.361-3.545.06.071.099.135.12.196z'
          fill='#cba43b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-1.402.733-3.268 1.754-5.498 2.585v-.667c3.38-1.302 6.529-3.064 7.824-3.945 1.799-1.325 4.371-3.437 5.361-3.545.06.071.099.135.12.196z'
          fill='#cba43b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-1.268.663-2.912 1.56-4.863 2.34v-.672c3.134-1.27 5.976-2.869 7.189-3.694 1.799-1.325 4.371-3.437 5.361-3.545.06.07.099.134.12.195z'
          fill='#cba43b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-1.127.589-2.553 1.365-4.228 2.078v-.674c2.878-1.221 5.423-2.662 6.554-3.431 1.799-1.325 4.371-3.437 5.361-3.545.06.071.099.135.12.196z'
          fill='#cba43b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-.982.514-2.191 1.168-3.594 1.8v-.67c2.613-1.156 4.873-2.444 5.92-3.156 1.799-1.325 4.371-3.437 5.361-3.545.06.07.099.134.12.195z'
          fill='#cba43b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-.83.435-1.824.97-2.959 1.507v-.664c2.342-1.077 4.324-2.216 5.285-2.87 1.799-1.325 4.371-3.437 5.361-3.545.06.071.099.135.12.196z'
          fill='#cba43b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-.672.352-1.451.769-2.324 1.2v-.654c2.061-.984 3.777-1.979 4.65-2.573 1.799-1.325 4.371-3.437 5.361-3.545.06.071.099.135.12.196z'
          fill='#cba43b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633-.504.264-1.07.565-1.689.882v-.645c1.775-.879 3.234-1.732 4.016-2.264 1.799-1.325 4.371-3.437 5.361-3.545a.642.642 0 0 1 .119.196z'
          fill='#cba43b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633l-1.055.554v-.636c1.486-.762 2.695-1.477 3.381-1.944 1.799-1.325 4.371-3.437 5.361-3.545.06.07.099.134.12.195z'
          fill='#cba43b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.809 1.24-3.033 2.162-5.633 3.633l-.42.221v-.635c1.195-.635 2.16-1.214 2.746-1.613 1.799-1.325 4.371-3.437 5.361-3.545.06.071.099.135.12.196z'
          fill='#cba43b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.758 1.205-2.965 2.11-5.418 3.511v-.635a27.893 27.893 0 0 0 2.111-1.27c1.799-1.325 4.371-3.437 5.361-3.545.06.071.099.135.12.196z'
          fill='#cba43b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.6 1.097-2.744 1.944-4.783 3.144v-.626a24.275 24.275 0 0 0 1.477-.912c1.799-1.325 4.371-3.437 5.361-3.545a.642.642 0 0 1 .119.196z'
          fill='#cba43b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.426.978-2.49 1.757-4.148 2.765v-.622c.332-.201.615-.383.842-.537 1.799-1.325 4.371-3.437 5.361-3.545a.642.642 0 0 1 .119.196z'
          fill='#cba43b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.234.847-2.199 1.546-3.514 2.373v-.627l.207-.139c1.799-1.325 4.371-3.437 5.361-3.545.06.07.099.134.12.195z'
          fill='#cba43b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-1.027.705-1.869 1.308-2.881 1.966v-.677c1.758-1.32 4.021-3.128 4.936-3.229a.657.657 0 0 1 .119.197z'
          fill='#cba43b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-.807.553-1.498 1.042-2.246 1.546v-.737c1.635-1.24 3.492-2.66 4.301-2.749a.657.657 0 0 1 .119.197z'
          fill='#cba43b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-.574.394-1.09.756-1.611 1.114v-.785c1.457-1.1 2.961-2.19 3.666-2.268a.642.642 0 0 1 .119.196z'
          fill='#cba43b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519-.342.234-.662.457-.977.675v-.821c1.242-.92 2.43-1.727 3.031-1.793.06.071.099.135.12.196z'
          fill='#cba43b'
        />
        <path
          d='M108.609 56.061v.224c-.148.365-1.039.647-2.174 1.519l-.342.235v-.844c1-.713 1.898-1.275 2.396-1.33.06.071.099.135.12.196z'
          fill='#cba43b'
        />
        <path
          d='M108.609 56.061v.224c-.135.333-.889.597-1.881 1.302v-.832c.736-.494 1.373-.848 1.762-.891a.657.657 0 0 1 .119.197z'
          fill='#cba43b'
        />
        <path
          d='M108.609 56.061v.224c-.104.257-.578.474-1.246.884v-.818c.463-.275.855-.457 1.127-.487a.657.657 0 0 1 .119.197z'
          fill='#cba43b'
        />
        <path
          d='M108.609 56.061v.224c-.066.166-.287.314-.611.508v-.776c.189-.083.357-.137.492-.152a.642.642 0 0 1 .119.196z'
          fill='#cba43b'
        />
        <path
          d='M76.877 60.746c.752.303 1.487.908 2.885 1.837 2.131 1.673 5.523 2.208 7.618 2.323 4.928.645 13.52-3.94 15.77-5.503 1.832-1.346 4.354-3.411 5.33-3.529.521.654-.572.764-2.104 1.958-1.799 1.227-2.973 2.118-5.537 3.569-2.852 1.492-7.696 4.215-13.621 4.046-3.206-.404-4.23-.668-5.656-1.452-1.408-.916-3.687-2.125-4.685-3.249z'
          fill='#d7b23b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-2.68 1.403-7.123 3.893-12.568 4.046h-1.053c-3.206-.404-4.23-.668-5.656-1.452-1.41-.917-3.689-2.126-4.687-3.25.752.303 1.487.908 2.885 1.837 2.131 1.673 5.523 2.208 7.618 2.323 4.928.645 13.52-3.94 15.77-5.503 1.832-1.346 4.354-3.411 5.33-3.529a.637.637 0 0 1 .107.176z'
          fill='#d7b23b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-2.68 1.403-7.123 3.893-12.568 4.046h-1.053c-3.206-.404-4.23-.668-5.656-1.452-1.162-.756-2.913-1.709-4.052-2.65v-.279c.575.341 1.239.843 2.25 1.516 2.131 1.673 5.523 2.208 7.618 2.323 4.928.645 13.52-3.94 15.77-5.503 1.832-1.346 4.354-3.411 5.33-3.529a.637.637 0 0 1 .107.176z'
          fill='#d7b23b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-2.68 1.403-7.123 3.893-12.568 4.046h-1.053c-3.206-.404-4.23-.668-5.656-1.452-.962-.626-2.329-1.388-3.417-2.166v-.354c.438.297.952.666 1.616 1.107 2.131 1.673 5.523 2.208 7.618 2.323 4.928.645 13.52-3.94 15.77-5.503 1.832-1.346 4.354-3.411 5.33-3.529a.656.656 0 0 1 .106.176z'
          fill='#d7b23b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-2.68 1.403-7.123 3.893-12.568 4.046h-1.053c-3.206-.404-4.23-.668-5.656-1.452-.782-.509-1.832-1.107-2.783-1.732v-.35c.29.201.612.423.981.669 2.131 1.673 5.523 2.208 7.618 2.323 4.928.645 13.52-3.94 15.77-5.503 1.832-1.346 4.354-3.411 5.33-3.529a.637.637 0 0 1 .107.176z'
          fill='#d4af3a'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-2.68 1.403-7.123 3.893-12.568 4.046h-1.053c-3.206-.404-4.23-.668-5.656-1.452-.611-.397-1.385-.85-2.148-1.326v-.32c.111.076.226.153.347.233 2.131 1.673 5.523 2.208 7.618 2.323 4.928.645 13.52-3.94 15.77-5.503 1.832-1.346 4.354-3.411 5.33-3.529a.656.656 0 0 1 .106.176z'
          fill='#d4ad3b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-2.68 1.403-7.123 3.893-12.568 4.046h-1.053c-3.206-.404-4.23-.668-5.656-1.452-.441-.287-.967-.603-1.513-.935v-.264c2.139 1.505 5.327 2 7.33 2.109 4.928.645 13.52-3.94 15.77-5.503 1.832-1.346 4.354-3.411 5.33-3.529a.656.656 0 0 1 .106.176z'
          fill='#d2ad3c'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-2.68 1.403-7.123 3.893-12.568 4.046h-1.053c-3.206-.404-4.23-.668-5.656-1.452a38.225 38.225 0 0 0-.878-.549v-.249c2.098 1.194 4.882 1.609 6.695 1.708 4.928.645 13.52-3.94 15.77-5.503 1.832-1.346 4.354-3.411 5.33-3.529a.656.656 0 0 1 .106.176z'
          fill='#d2ab3a'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-2.68 1.403-7.123 3.893-12.568 4.046h-1.053c-3.206-.404-4.23-.668-5.656-1.452-.079-.051-.16-.104-.244-.156v-.312c1.999.943 4.425 1.29 6.061 1.379 4.928.645 13.52-3.94 15.77-5.503 1.832-1.346 4.354-3.411 5.33-3.529a.638.638 0 0 1 .106.175z'
          fill='#d2ab3a'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-2.68 1.403-7.123 3.893-12.568 4.046h-1.053c-2.906-.367-4.02-.618-5.265-1.245v-.4c1.859.738 3.96 1.024 5.426 1.104 4.928.645 13.52-3.94 15.77-5.503 1.832-1.346 4.354-3.411 5.33-3.529a.638.638 0 0 1 .106.175z'
          fill='#cfa93a'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-2.68 1.403-7.123 3.893-12.568 4.046h-1.053c-2.411-.304-3.588-.529-4.63-.955v-.459c1.688.567 3.49.801 4.791.872 4.928.645 13.52-3.94 15.77-5.503 1.832-1.346 4.354-3.411 5.33-3.529a.656.656 0 0 1 .106.176z'
          fill='#cfa93a'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-2.68 1.403-7.123 3.893-12.568 4.046h-1.053c-1.94-.245-3.081-.438-3.996-.726v-.491c1.495.426 3.018.613 4.157.675 4.928.645 13.52-3.94 15.77-5.503 1.832-1.346 4.354-3.411 5.33-3.529a.656.656 0 0 1 .106.176z'
          fill='#cea83b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-2.68 1.403-7.123 3.893-12.568 4.046h-1.053c-1.518-.191-2.546-.351-3.36-.55v-.5a19.96 19.96 0 0 0 3.521.509c4.928.645 13.52-3.94 15.77-5.503 1.832-1.346 4.354-3.411 5.33-3.529a.638.638 0 0 1 .106.175z'
          fill='#cda63b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-2.68 1.403-7.123 3.893-12.568 4.046h-1.053c-1.149-.145-2.019-.272-2.726-.411v-.499c1.058.215 2.071.323 2.887.368 4.928.645 13.52-3.94 15.77-5.503 1.832-1.346 4.354-3.411 5.33-3.529a.656.656 0 0 1 .106.176z'
          fill='#cca53a'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-2.68 1.403-7.123 3.893-12.568 4.046h-1.053c-.828-.104-1.51-.2-2.091-.296v-.496c.825.139 1.602.215 2.252.25 4.928.645 13.52-3.94 15.77-5.503 1.832-1.346 4.354-3.411 5.33-3.529a.656.656 0 0 1 .106.176z'
          fill='#cba43b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-2.68 1.403-7.123 3.893-12.568 4.046h-1.053a70.342 70.342 0 0 1-1.456-.197v-.499c.587.079 1.136.127 1.617.154 4.928.645 13.52-3.94 15.77-5.503 1.832-1.346 4.354-3.411 5.33-3.529a.656.656 0 0 1 .106.176z'
          fill='#cba43b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-2.68 1.403-7.123 3.893-12.568 4.046h-1.053a95.128 95.128 0 0 1-.822-.107v-.513c.35.036.68.062.983.078 4.928.645 13.52-3.94 15.77-5.503 1.832-1.346 4.354-3.411 5.33-3.529a.656.656 0 0 1 .106.176z'
          fill='#caa23b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-2.68 1.403-7.123 3.893-12.568 4.046h-1.053l-.187-.024v-.54c.12.009.236.016.348.022 4.928.645 13.52-3.94 15.77-5.503 1.832-1.346 4.354-3.411 5.33-3.529a.656.656 0 0 1 .106.176z'
          fill='#caa13b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-2.68 1.403-7.123 3.893-12.568 4.046h-.605v-.511c4.967.441 13.276-4 15.483-5.533 1.832-1.346 4.354-3.411 5.33-3.529a.638.638 0 0 1 .106.175z'
          fill='#c8a03b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-2.676 1.4-7.107 3.884-12.538 4.046v-.48c5.011.046 12.733-4.094 14.849-5.564 1.832-1.346 4.354-3.411 5.33-3.529.05.063.085.121.105.175z'
          fill='#c89f3b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-2.57 1.345-6.762 3.69-11.903 4.016v-.465c5.005-.276 12.184-4.14 14.213-5.549 1.832-1.346 4.354-3.411 5.33-3.529a.638.638 0 0 1 .106.175z'
          fill='#c89f3b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-2.465 1.29-6.417 3.498-11.269 3.965v-.467c4.958-.54 11.636-4.146 13.579-5.496 1.832-1.346 4.354-3.411 5.33-3.529a.638.638 0 0 1 .106.175z'
          fill='#c69e3b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-2.355 1.232-6.072 3.306-10.634 3.894v-.479c4.878-.755 11.082-4.119 12.945-5.413 1.832-1.346 4.354-3.411 5.33-3.529.05.063.085.121.105.175z'
          fill='#c69e3b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-2.246 1.175-5.727 3.113-10 3.802v-.499c4.768-.929 10.527-4.063 12.31-5.301 1.832-1.346 4.354-3.411 5.33-3.529a.638.638 0 0 1 .106.175z'
          fill='#c69e3b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-2.133 1.116-5.382 2.921-9.365 3.689v-.522c4.632-1.066 9.972-3.982 11.675-5.166 1.832-1.346 4.354-3.411 5.33-3.529a.656.656 0 0 1 .106.176z'
          fill='#c69e3b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-2.02 1.057-5.037 2.73-8.73 3.557v-.545c4.475-1.171 9.414-3.88 11.041-5.01 1.832-1.346 4.354-3.411 5.33-3.529.05.063.085.121.105.175z'
          fill='#c69e3b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-1.902.995-4.69 2.538-8.095 3.405v-.569c4.298-1.247 8.855-3.758 10.406-4.834 1.832-1.346 4.354-3.411 5.33-3.529.05.063.085.121.105.175z'
          fill='#c69e3b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-1.783.933-4.343 2.347-7.46 3.234v-.59c4.103-1.298 8.296-3.618 9.771-4.642 1.832-1.346 4.354-3.411 5.33-3.529.05.063.085.121.105.175z'
          fill='#c69e3b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-1.66.869-3.995 2.154-6.826 3.044v-.609c3.894-1.325 7.738-3.462 9.136-4.433 1.832-1.346 4.354-3.411 5.33-3.529a.638.638 0 0 1 .106.175z'
          fill='#c69e3b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-1.533.803-3.645 1.962-6.191 2.835v-.624c3.669-1.331 7.179-3.292 8.501-4.21 1.832-1.346 4.354-3.411 5.33-3.529a.656.656 0 0 1 .106.176z'
          fill='#c69e3b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-1.404.735-3.293 1.769-5.556 2.608v-.633c3.433-1.317 6.623-3.108 7.867-3.973 1.832-1.346 4.354-3.411 5.33-3.529.05.063.085.121.105.175z'
          fill='#c69e3b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-1.271.665-2.939 1.575-4.921 2.364v-.639c3.187-1.286 6.064-2.912 7.232-3.723 1.832-1.346 4.354-3.411 5.33-3.529.05.063.085.121.105.175z'
          fill='#c69e3b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-1.133.593-2.582 1.38-4.287 2.104v-.64c2.929-1.237 5.507-2.705 6.597-3.461 1.832-1.346 4.354-3.411 5.33-3.529a.62.62 0 0 1 .106.174z'
          fill='#c69e3b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-.99.518-2.221 1.185-3.652 1.826v-.637c2.662-1.173 4.953-2.485 5.963-3.187 1.832-1.346 4.354-3.411 5.33-3.529.05.063.085.121.105.175z'
          fill='#c69e3b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-.84.439-1.854.987-3.018 1.535v-.631c2.387-1.095 4.398-2.256 5.328-2.901 1.832-1.346 4.354-3.411 5.33-3.529a.62.62 0 0 1 .106.174z'
          fill='#c69e3b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-.684.358-1.482.786-2.383 1.229v-.623c2.105-1.003 3.848-2.017 4.693-2.605 1.832-1.346 4.354-3.411 5.33-3.529a.656.656 0 0 1 .106.176z'
          fill='#c69e3b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-.518.271-1.104.583-1.748.911V61.7c1.818-.898 3.301-1.77 4.059-2.297 1.832-1.346 4.354-3.411 5.33-3.529.05.063.085.121.105.175z'
          fill='#c69e3b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569-.342.18-.715.377-1.113.585v-.605c1.525-.782 2.756-1.513 3.424-1.978 1.832-1.346 4.354-3.411 5.33-3.529.05.063.085.121.105.175z'
          fill='#c69e3b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.799 1.227-2.973 2.118-5.537 3.569l-.479.252v-.603c1.23-.654 2.217-1.248 2.789-1.646 1.832-1.346 4.354-3.411 5.33-3.529a.62.62 0 0 1 .106.174z'
          fill='#c69e3b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.762 1.202-2.924 2.081-5.381 3.481v-.607c.934-.516 1.684-.976 2.154-1.303 1.832-1.346 4.354-3.411 5.33-3.529a.638.638 0 0 1 .106.175z'
          fill='#c69e3b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.604 1.094-2.709 1.92-4.746 3.115v-.598c.641-.369 1.16-.696 1.52-.945 1.832-1.346 4.354-3.411 5.33-3.529.05.062.085.12.105.174z'
          fill='#c69e3b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.428.974-2.463 1.737-4.111 2.736v-.595c.354-.215.654-.409.885-.57 1.832-1.346 4.354-3.411 5.33-3.529.05.063.085.121.105.175z'
          fill='#c69e3b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.236.843-2.176 1.528-3.477 2.345v-.604c.09-.06.174-.116.25-.169 1.832-1.346 4.354-3.411 5.33-3.529a.62.62 0 0 1 .106.174z'
          fill='#c69e3b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-1.025.7-1.85 1.291-2.842 1.938v-.652c1.783-1.334 4.035-3.134 4.945-3.245a.656.656 0 0 1 .106.176z'
          fill='#c69e3b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-.801.546-1.477 1.025-2.207 1.518v-.709c1.652-1.25 3.506-2.669 4.311-2.767.05.063.085.121.105.175z'
          fill='#c69e3b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-.564.385-1.068.738-1.572 1.085v-.756c1.469-1.108 2.973-2.202 3.676-2.287.05.063.085.121.105.175z'
          fill='#c69e3b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-.33.225-.639.438-.938.646v-.793c1.252-.927 2.439-1.739 3.041-1.812a.656.656 0 0 1 .106.176z'
          fill='#c69e3b'
        />
        <path
          d='M108.586 56.049v.241c-.16.362-1.061.647-2.209 1.542-.104.07-.203.14-.303.208v-.817c1.006-.72 1.908-1.288 2.406-1.349a.638.638 0 0 1 .106.175z'
          fill='#c69e3b'
        />
        <path
          d='M108.586 56.049v.241c-.145.326-.891.591-1.877 1.295v-.805c.742-.5 1.381-.859 1.771-.906a.638.638 0 0 1 .106.175z'
          fill='#c69e3b'
        />
        <path
          d='M108.586 56.049v.241c-.111.251-.58.467-1.242.875v-.791c.467-.28.863-.467 1.137-.5.05.063.085.121.105.175z'
          fill='#c69e3b'
        />
        <path
          d='M108.586 56.049v.241c-.07.161-.287.307-.607.498v-.753c.193-.087.363-.144.502-.16.05.062.085.12.105.174z'
          fill='#c69e3b'
        />
        <path
          d='M76.854 60.737c.728.259 1.551.925 2.949 1.856 2.12 1.656 5.442 2.209 7.558 2.33 4.992.638 13.67-4.004 15.811-5.525 1.863-1.367 4.336-3.386 5.299-3.514.502.647-.604.751-2.152 1.976-1.791 1.214-2.91 2.074-5.439 3.506-2.83 1.48-7.697 4.215-13.617 4.07-3.215-.397-4.366-.69-5.728-1.448-1.443-.928-3.678-2.116-4.681-3.251z'
          fill='#d4ad3b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-2.684 1.404-7.2 3.937-12.708 4.07h-.908c-3.215-.397-4.366-.69-5.728-1.448-1.442-.929-3.677-2.116-4.68-3.251.728.259 1.551.925 2.949 1.856 2.12 1.656 5.442 2.209 7.558 2.33 4.992.638 13.67-4.004 15.811-5.525 1.863-1.367 4.336-3.386 5.299-3.514a.694.694 0 0 1 .092.154z'
          fill='#d4ad3b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-2.684 1.404-7.2 3.937-12.708 4.07h-.908c-3.215-.397-4.366-.69-5.728-1.448-1.188-.765-2.914-1.705-4.045-2.647v-.299c.592.342 1.303.877 2.314 1.551 2.12 1.656 5.442 2.209 7.558 2.33 4.992.638 13.67-4.004 15.811-5.525 1.863-1.367 4.336-3.386 5.299-3.514a.694.694 0 0 1 .092.154z'
          fill='#d2ad3c'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-2.684 1.404-7.2 3.937-12.708 4.07h-.908c-3.215-.397-4.366-.69-5.728-1.448-.982-.633-2.333-1.385-3.411-2.16v-.384c.462.31 1.003.698 1.68 1.149 2.12 1.656 5.442 2.209 7.558 2.33 4.992.638 13.67-4.004 15.811-5.525 1.863-1.367 4.336-3.386 5.299-3.514a.694.694 0 0 1 .092.154z'
          fill='#d2ab3b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-2.684 1.404-7.2 3.937-12.708 4.07h-.908c-3.215-.397-4.366-.69-5.728-1.448-.797-.513-1.836-1.105-2.776-1.725v-.383c.311.215.655.454 1.045.713 2.12 1.656 5.442 2.209 7.558 2.33 4.992.638 13.67-4.004 15.811-5.525 1.863-1.367 4.336-3.386 5.299-3.514a.694.694 0 0 1 .092.154z'
          fill='#d1a93b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-2.684 1.404-7.2 3.937-12.708 4.07h-.908c-3.215-.397-4.366-.69-5.728-1.448-.621-.399-1.388-.847-2.141-1.318v-.353l.41.276c2.12 1.656 5.442 2.209 7.558 2.33 4.992.638 13.67-4.004 15.811-5.525 1.863-1.367 4.336-3.386 5.299-3.514a.694.694 0 0 1 .092.154z'
          fill='#d1a93b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-2.684 1.404-7.2 3.937-12.708 4.07h-.908c-3.215-.397-4.366-.69-5.728-1.448-.446-.287-.968-.599-1.506-.927v-.299c2.124 1.525 5.291 2.045 7.333 2.161 4.992.638 13.67-4.004 15.811-5.525 1.863-1.367 4.336-3.386 5.299-3.514a.694.694 0 0 1 .092.154z'
          fill='#cfa73b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-2.684 1.404-7.2 3.937-12.708 4.07h-.908c-3.215-.397-4.366-.69-5.728-1.448a40.9 40.9 0 0 0-.872-.542v-.275c2.084 1.211 4.852 1.646 6.699 1.751 4.992.638 13.67-4.004 15.811-5.525 1.863-1.367 4.336-3.386 5.299-3.514a.722.722 0 0 1 .092.155z'
          fill='#cea53a'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-2.684 1.404-7.2 3.937-12.708 4.07h-.908c-3.215-.397-4.366-.69-5.728-1.448-.077-.05-.156-.1-.237-.15v-.33c1.986.958 4.399 1.32 6.064 1.415 4.992.638 13.67-4.004 15.811-5.525 1.863-1.367 4.336-3.386 5.299-3.514a.694.694 0 0 1 .092.154z'
          fill='#cca53a'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-2.684 1.404-7.2 3.937-12.708 4.07h-.908c-2.898-.358-4.12-.632-5.33-1.237v-.41c1.847.75 3.938 1.049 5.429 1.134 4.992.638 13.67-4.004 15.811-5.525 1.863-1.367 4.336-3.386 5.299-3.514a.694.694 0 0 1 .092.154z'
          fill='#cca33a'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-2.684 1.404-7.2 3.937-12.708 4.07h-.908c-2.399-.297-3.649-.535-4.695-.952v-.458c1.677.577 3.472.821 4.794.896 4.992.638 13.67-4.004 15.811-5.525 1.863-1.367 4.336-3.386 5.299-3.514a.722.722 0 0 1 .092.155z'
          fill='#cca33a'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-2.684 1.404-7.2 3.937-12.708 4.07h-.908c-1.937-.239-3.124-.44-4.061-.729v-.48c1.485.434 3.003.629 4.16.695 4.992.638 13.67-4.004 15.811-5.525 1.863-1.367 4.336-3.386 5.299-3.514a.722.722 0 0 1 .092.155z'
          fill='#caa23b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-2.684 1.404-7.2 3.937-12.708 4.07h-.908c-1.526-.188-2.586-.354-3.426-.556v-.482c1.275.316 2.533.467 3.525.524 4.992.638 13.67-4.004 15.811-5.525 1.863-1.367 4.336-3.386 5.299-3.514a.722.722 0 0 1 .092.155z'
          fill='#c99f3a'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-2.684 1.404-7.2 3.937-12.708 4.07h-.908c-1.166-.145-2.06-.274-2.791-.418v-.475c1.052.219 2.063.332 2.89.379 4.992.638 13.67-4.004 15.811-5.525 1.863-1.367 4.336-3.386 5.299-3.514a.722.722 0 0 1 .092.155z'
          fill='#c89f3b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-2.684 1.404-7.2 3.937-12.708 4.07h-.908a51.827 51.827 0 0 1-2.156-.303v-.469a19.94 19.94 0 0 0 2.255.258c4.992.638 13.67-4.004 15.811-5.525 1.863-1.367 4.336-3.386 5.299-3.514a.722.722 0 0 1 .092.155z'
          fill='#c89f3b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-2.684 1.404-7.2 3.937-12.708 4.07h-.908c-.567-.07-1.07-.137-1.521-.204v-.47c.585.082 1.134.132 1.621.16 4.992.638 13.67-4.004 15.811-5.525 1.863-1.367 4.336-3.386 5.299-3.514.041.055.07.106.091.155z'
          fill='#c59c3b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-2.684 1.404-7.2 3.937-12.708 4.07h-.908a92.725 92.725 0 0 1-.886-.114v-.48c.349.038.68.064.985.081 4.992.638 13.67-4.004 15.811-5.525 1.863-1.367 4.336-3.386 5.299-3.514a.694.694 0 0 1 .092.154z'
          fill='#c59c3b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-2.684 1.404-7.2 3.937-12.708 4.07h-.908l-.251-.031V64.9c.12.009.237.017.351.023 4.992.638 13.67-4.004 15.811-5.525 1.863-1.367 4.336-3.386 5.299-3.514.041.054.07.105.091.154z'
          fill='#c59b3b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-2.684 1.404-7.2 3.937-12.708 4.07h-.525v-.484c5.03.436 13.425-4.062 15.527-5.555 1.863-1.367 4.336-3.386 5.299-3.514a.722.722 0 0 1 .092.155z'
          fill='#c3993b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-2.666 1.395-7.14 3.902-12.599 4.067v-.451c5.073.042 12.876-4.153 14.892-5.584 1.863-1.367 4.336-3.386 5.299-3.514a.664.664 0 0 1 .093.154z'
          fill='#c3993b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-2.562 1.34-6.793 3.709-11.964 4.038v-.437c5.066-.28 12.323-4.196 14.257-5.569 1.863-1.367 4.336-3.386 5.299-3.514a.664.664 0 0 1 .093.154z'
          fill='#c1963b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-2.457 1.285-6.448 3.515-11.33 3.986v-.438c5.019-.545 11.769-4.2 13.623-5.517 1.863-1.367 4.336-3.386 5.299-3.514a.69.69 0 0 1 .093.155z'
          fill='#c0953b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-2.35 1.229-6.102 3.322-10.694 3.915v-.45c4.937-.762 11.212-4.171 12.987-5.434 1.863-1.367 4.336-3.386 5.299-3.514a.69.69 0 0 1 .093.155z'
          fill='#c1963b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-2.24 1.172-5.756 3.129-10.06 3.823v-.469c4.826-.936 10.651-4.114 12.353-5.322 1.863-1.367 4.336-3.386 5.299-3.514a.664.664 0 0 1 .093.154z'
          fill='#c1963b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-2.129 1.113-5.41 2.937-9.425 3.71v-.491c4.691-1.074 10.091-4.032 11.718-5.188 1.863-1.367 4.336-3.386 5.299-3.514a.69.69 0 0 1 .093.155z'
          fill='#c1963b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-2.016 1.054-5.064 2.745-8.79 3.578v-.514c4.532-1.18 9.528-3.928 11.083-5.032 1.863-1.367 4.336-3.386 5.299-3.514a.664.664 0 0 1 .093.154z'
          fill='#c1963b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-1.9.994-4.717 2.553-8.155 3.427v-.538c4.354-1.258 8.966-3.805 10.448-4.857 1.863-1.367 4.336-3.386 5.299-3.514a.664.664 0 0 1 .093.154z'
          fill='#c1963b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-1.781.932-4.37 2.361-7.521 3.256v-.559c4.159-1.31 8.403-3.664 9.813-4.666 1.863-1.367 4.336-3.386 5.299-3.514a.661.661 0 0 1 .094.155z'
          fill='#c1963b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-1.66.869-4.022 2.169-6.886 3.066v-.576c3.95-1.338 7.841-3.508 9.179-4.459 1.863-1.367 4.336-3.386 5.299-3.514a.69.69 0 0 1 .093.155z'
          fill='#c1963b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-1.537.804-3.672 1.977-6.25 2.858v-.591c3.725-1.345 7.278-3.337 8.543-4.236 1.863-1.367 4.336-3.386 5.299-3.514a.69.69 0 0 1 .093.155z'
          fill='#c1963b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-1.408.736-3.32 1.784-5.616 2.632v-.601c3.487-1.333 6.716-3.152 7.909-4 1.863-1.367 4.336-3.386 5.299-3.514a.69.69 0 0 1 .093.155z'
          fill='#c1963b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-1.277.667-2.967 1.591-4.981 2.39v-.607c3.239-1.302 6.153-2.954 7.274-3.751 1.863-1.367 4.336-3.386 5.299-3.514a.664.664 0 0 1 .093.154z'
          fill='#c1963b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-1.141.596-2.611 1.396-4.346 2.129v-.608c2.979-1.254 5.592-2.746 6.639-3.49 1.863-1.367 4.336-3.386 5.299-3.514a.69.69 0 0 1 .093.155z'
          fill='#c1963b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-.998.522-2.25 1.201-3.711 1.853v-.604c2.711-1.191 5.031-2.526 6.004-3.217 1.863-1.367 4.336-3.386 5.299-3.514a.664.664 0 0 1 .093.154z'
          fill='#c1963b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-.852.445-1.885 1.003-3.076 1.562v-.598c2.434-1.114 4.473-2.296 5.369-2.933 1.863-1.367 4.336-3.386 5.299-3.514a.69.69 0 0 1 .093.155z'
          fill='#c1963b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-.697.364-1.516.804-2.441 1.258v-.59c2.15-1.022 3.918-2.056 4.734-2.636 1.863-1.367 4.336-3.386 5.299-3.514a.664.664 0 0 1 .093.154z'
          fill='#c1963b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-.533.278-1.137.602-1.807.941v-.581c1.859-.917 3.365-1.807 4.1-2.329 1.863-1.367 4.336-3.386 5.299-3.514a.69.69 0 0 1 .093.155z'
          fill='#c1963b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-.359.187-.75.396-1.172.615v-.573c1.564-.801 2.816-1.549 3.465-2.01 1.863-1.367 4.336-3.386 5.299-3.514a.664.664 0 0 1 .093.154z'
          fill='#c1963b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.791 1.214-2.91 2.074-5.439 3.506-.172.089-.352.184-.537.282v-.571c1.268-.673 2.271-1.283 2.83-1.68 1.863-1.367 4.336-3.386 5.299-3.514a.69.69 0 0 1 .093.155z'
          fill='#c1963b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.768 1.199-2.881 2.052-5.342 3.451v-.577c.967-.534 1.734-1.008 2.195-1.336 1.863-1.367 4.336-3.386 5.299-3.514a.638.638 0 0 1 .094.154z'
          fill='#c1963b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.607 1.09-2.674 1.895-4.707 3.084v-.569a20.977 20.977 0 0 0 1.561-.978c1.863-1.367 4.336-3.386 5.299-3.514a.69.69 0 0 1 .093.155z'
          fill='#c1963b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.432.971-2.434 1.716-4.072 2.707v-.568c.377-.23.691-.435.926-.602 1.863-1.367 4.336-3.386 5.299-3.514a.69.69 0 0 1 .093.155z'
          fill='#c1963b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.236.839-2.154 1.51-3.438 2.316v-.579c.105-.071.205-.138.291-.2 1.863-1.367 4.336-3.386 5.299-3.514a.661.661 0 0 1 .094.155z'
          fill='#c1963b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-1.023.694-1.828 1.273-2.803 1.91v-.625c1.812-1.35 4.049-3.14 4.955-3.26a.615.615 0 0 1 .094.153z'
          fill='#c1963b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-.793.539-1.457 1.008-2.168 1.488v-.68c1.67-1.26 3.518-2.678 4.32-2.784a.638.638 0 0 1 .094.154z'
          fill='#c1963b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-.555.376-1.045.719-1.533 1.055v-.727c1.482-1.116 2.984-2.212 3.686-2.305a.69.69 0 0 1 .093.155z'
          fill='#c1963b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565-.316.215-.613.419-.898.617v-.763c1.262-.935 2.449-1.75 3.051-1.83a.664.664 0 0 1 .093.154z'
          fill='#c1963b'
        />
        <path
          d='M108.564 56.038v.257c-.174.359-1.086.647-2.246 1.565l-.264.18v-.79c1.014-.727 1.918-1.301 2.416-1.367a.661.661 0 0 1 .094.155z'
          fill='#c1963b'
        />
        <path
          d='M108.564 56.038v.257c-.154.32-.895.584-1.875 1.287v-.774c.748-.506 1.389-.872 1.781-.923a.615.615 0 0 1 .094.153z'
          fill='#c1963b'
        />
        <path
          d='M108.564 56.038v.257c-.119.246-.586.459-1.24.865v-.762c.473-.286.871-.478 1.146-.514a.638.638 0 0 1 .094.154z'
          fill='#c1963b'
        />
        <path
          d='M108.564 56.038v.257c-.076.156-.291.299-.605.487v-.729c.197-.091.371-.15.512-.169a.664.664 0 0 1 .093.154z'
          fill='#c1963b'
        />
        <path
          d='M76.831 60.728c.704.216 1.615.942 3.014 1.876 2.108 1.64 5.362 2.208 7.498 2.335 5.055.631 13.82-4.068 15.851-5.548 1.895-1.388 4.318-3.361 5.268-3.499.484.64-.637.74-2.201 1.995-1.781 1.202-2.848 2.031-5.344 3.443-2.807 1.469-7.697 4.216-13.611 4.095-3.224-.391-4.502-.713-5.8-1.446-1.476-.938-3.667-2.105-4.675-3.251z'
          fill='#d0a83b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-2.686 1.405-7.281 3.982-12.854 4.095h-.757c-3.224-.391-4.502-.713-5.8-1.446-1.475-.939-3.666-2.106-4.675-3.252.704.216 1.615.942 3.014 1.876 2.108 1.64 5.362 2.208 7.498 2.335 5.055.631 13.82-4.068 15.851-5.548 1.895-1.388 4.318-3.361 5.268-3.499.035.048.06.092.08.133z'
          fill='#d0a83b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-2.686 1.405-7.281 3.982-12.854 4.095h-.757c-3.224-.391-4.502-.713-5.8-1.446-1.215-.774-2.914-1.701-4.04-2.644v-.319c.608.344 1.366.91 2.379 1.587 2.108 1.64 5.362 2.208 7.498 2.335 5.055.631 13.82-4.068 15.851-5.548 1.895-1.388 4.318-3.361 5.268-3.499.035.048.06.092.08.133z'
          fill='#d0a83b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-2.686 1.405-7.281 3.982-12.854 4.095h-.757c-3.224-.391-4.502-.713-5.8-1.446-1.003-.639-2.337-1.382-3.405-2.153v-.415c.486.323 1.056.732 1.745 1.192 2.108 1.64 5.362 2.208 7.498 2.335 5.055.631 13.82-4.068 15.851-5.548 1.895-1.388 4.318-3.361 5.268-3.499a.94.94 0 0 1 .079.133z'
          fill='#cea53b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-2.686 1.405-7.281 3.982-12.854 4.095h-.757c-3.224-.391-4.502-.713-5.8-1.446-.812-.517-1.841-1.103-2.771-1.716v-.418c.333.23.7.485 1.11.759 2.108 1.64 5.362 2.208 7.498 2.335 5.055.631 13.82-4.068 15.851-5.548 1.895-1.388 4.318-3.361 5.268-3.499.035.047.06.091.08.132z'
          fill='#cea53a'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-2.686 1.405-7.281 3.982-12.854 4.095h-.757c-3.224-.391-4.502-.713-5.8-1.446-.631-.402-1.392-.844-2.136-1.31v-.388c.152.104.311.211.475.321 2.108 1.64 5.362 2.208 7.498 2.335 5.055.631 13.82-4.068 15.851-5.548 1.895-1.388 4.318-3.361 5.268-3.499.035.049.06.093.08.134z'
          fill='#cca33a'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-2.686 1.405-7.281 3.982-12.854 4.095h-.757c-3.224-.391-4.502-.713-5.8-1.446-.452-.288-.97-.596-1.5-.919v-.336c2.11 1.547 5.255 2.091 7.337 2.215 5.055.631 13.82-4.068 15.851-5.548 1.895-1.388 4.318-3.361 5.268-3.499.035.047.06.091.08.132z'
          fill='#cba13a'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-2.686 1.405-7.281 3.982-12.854 4.095h-.757c-3.224-.391-4.502-.713-5.8-1.446-.269-.171-.562-.349-.866-.534v-.303c2.069 1.229 4.82 1.684 6.703 1.796 5.055.631 13.82-4.068 15.851-5.548 1.895-1.388 4.318-3.361 5.268-3.499.035.048.06.092.08.133z'
          fill='#caa13b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-2.686 1.405-7.281 3.982-12.854 4.095h-.757c-3.224-.391-4.502-.713-5.8-1.446l-.23-.145v-.348c1.972.973 4.372 1.351 6.067 1.452 5.055.631 13.82-4.068 15.851-5.548 1.895-1.388 4.318-3.361 5.268-3.499.035.048.06.092.08.133z'
          fill='#c99f3a'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-2.686 1.405-7.281 3.982-12.854 4.095h-.757c-2.89-.351-4.217-.646-5.396-1.23v-.421c1.834.762 3.916 1.075 5.433 1.165 5.055.631 13.82-4.068 15.851-5.548 1.895-1.388 4.318-3.361 5.268-3.499.035.047.06.091.08.132z'
          fill='#c99d3a'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-2.686 1.405-7.281 3.982-12.854 4.095h-.757c-2.389-.29-3.709-.542-4.761-.95v-.458c1.666.587 3.453.842 4.798.922 5.055.631 13.82-4.068 15.851-5.548 1.895-1.388 4.318-3.361 5.268-3.499.035.047.06.091.08.132z'
          fill='#c79d3b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-2.686 1.405-7.281 3.982-12.854 4.095h-.757c-1.935-.235-3.168-.444-4.126-.733v-.469c1.475.442 2.988.645 4.163.715 5.055.631 13.82-4.068 15.851-5.548 1.895-1.388 4.318-3.361 5.268-3.499.035.048.06.092.08.133z'
          fill='#c59c3b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-2.686 1.405-7.281 3.982-12.854 4.095h-.757c-1.534-.187-2.626-.356-3.491-.562V64.4c1.267.322 2.521.479 3.528.54 5.055.631 13.82-4.068 15.851-5.548 1.895-1.388 4.318-3.361 5.268-3.499a.77.77 0 0 1 .08.131z'
          fill='#c59b3b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-2.686 1.405-7.281 3.982-12.854 4.095h-.757c-1.181-.144-2.101-.277-2.856-.425v-.453c1.046.224 2.056.342 2.894.392 5.055.631 13.82-4.068 15.851-5.548 1.895-1.388 4.318-3.361 5.268-3.499a.882.882 0 0 1 .079.132z'
          fill='#c4993b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-2.686 1.405-7.281 3.982-12.854 4.095h-.757a49.027 49.027 0 0 1-2.221-.311v-.443c.816.145 1.592.227 2.258.267 5.055.631 13.82-4.068 15.851-5.548 1.895-1.388 4.318-3.361 5.268-3.499.035.048.06.092.08.133z'
          fill='#c2973b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-2.686 1.405-7.281 3.982-12.854 4.095h-.757a67.259 67.259 0 0 1-1.586-.21v-.441c.582.083 1.132.136 1.624.166 5.055.631 13.82-4.068 15.851-5.548 1.895-1.388 4.318-3.361 5.268-3.499.034.046.059.09.079.131z'
          fill='#c2973b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-2.686 1.405-7.281 3.982-12.854 4.095h-.757c-.338-.042-.654-.081-.952-.121v-.449c.348.039.68.066.989.084 5.055.631 13.82-4.068 15.851-5.548 1.895-1.388 4.318-3.361 5.268-3.499.035.047.06.091.08.132z'
          fill='#c0953b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-2.686 1.405-7.281 3.982-12.854 4.095h-.757a55.169 55.169 0 0 1-.316-.039v-.472c.121.01.239.018.354.024 5.055.631 13.82-4.068 15.851-5.548 1.895-1.388 4.318-3.361 5.268-3.499a.94.94 0 0 1 .079.133z'
          fill='#c0933b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-2.686 1.405-7.281 3.982-12.854 4.095h-.439v-.458c5.092.431 13.576-4.124 15.57-5.577 1.895-1.388 4.318-3.361 5.268-3.499.035.048.06.092.08.133z'
          fill='#be933b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-2.654 1.389-7.173 3.921-12.658 4.089v-.424c5.134.039 13.021-4.211 14.935-5.605 1.895-1.388 4.318-3.361 5.268-3.499.035.048.06.092.08.133z'
          fill='#bc913b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-2.553 1.335-6.825 3.727-12.023 4.06v-.409c5.126-.284 12.462-4.252 14.3-5.59 1.895-1.388 4.318-3.361 5.268-3.499.035.047.06.091.08.132z'
          fill='#bc8f3b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-2.447 1.28-6.477 3.532-11.388 4.008v-.411c5.078-.549 11.904-4.253 13.666-5.537 1.895-1.388 4.318-3.361 5.268-3.499a.94.94 0 0 1 .079.133z'
          fill='#ba8f3b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-2.342 1.225-6.13 3.338-10.753 3.937v-.422c4.996-.767 11.341-4.224 13.031-5.454 1.895-1.388 4.318-3.361 5.268-3.499a.882.882 0 0 1 .079.132z'
          fill='#ba8f3b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-2.232 1.168-5.783 3.146-10.119 3.845v-.44c4.885-.942 10.777-4.165 12.396-5.344 1.895-1.388 4.318-3.361 5.268-3.499.035.047.06.091.08.132z'
          fill='#ba8f3b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-2.123 1.111-5.437 2.952-9.483 3.731V64.6c4.749-1.082 10.212-4.081 11.761-5.209 1.895-1.388 4.318-3.361 5.268-3.499a.882.882 0 0 1 .079.132z'
          fill='#ba8f3b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-2.012 1.052-5.09 2.76-8.849 3.6v-.484c4.59-1.189 9.646-3.977 11.126-5.055 1.895-1.388 4.318-3.361 5.268-3.499.035.047.06.091.08.132z'
          fill='#ba8f3b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-1.896.992-4.743 2.568-8.214 3.448v-.506c4.411-1.268 9.079-3.853 10.491-4.881 1.895-1.388 4.318-3.361 5.268-3.499.035.047.06.091.08.132z'
          fill='#ba8f3b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-1.779.931-4.396 2.375-7.579 3.278v-.527c4.215-1.321 8.51-3.711 9.856-4.69 1.895-1.388 4.318-3.361 5.268-3.499.035.047.06.091.08.132z'
          fill='#ba8f3b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-1.66.868-4.047 2.184-6.944 3.089v-.544c4.004-1.351 7.944-3.553 9.221-4.484 1.895-1.388 4.318-3.361 5.268-3.499.035.047.06.091.08.132z'
          fill='#ba8f3b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-1.537.804-3.697 1.991-6.309 2.881v-.558c3.78-1.359 7.375-3.382 8.586-4.263 1.895-1.388 4.318-3.361 5.268-3.499.035.048.06.092.08.133z'
          fill='#ba8f3b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-1.41.738-3.346 1.799-5.674 2.656v-.567c3.542-1.348 6.809-3.196 7.952-4.028 1.895-1.388 4.318-3.361 5.268-3.499a.882.882 0 0 1 .079.132z'
          fill='#ba8f3b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-1.281.67-2.994 1.605-5.039 2.414v-.574c3.291-1.318 6.242-2.998 7.316-3.78 1.895-1.388 4.318-3.361 5.268-3.499.035.048.06.092.08.133z'
          fill='#ba8f3b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-1.146.6-2.637 1.412-4.404 2.155v-.575c3.031-1.272 5.678-2.789 6.682-3.52 1.895-1.388 4.318-3.361 5.268-3.499a.94.94 0 0 1 .079.133z'
          fill='#ba8f3b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-1.006.526-2.279 1.216-3.77 1.88v-.572c2.76-1.209 5.113-2.568 6.047-3.247 1.895-1.388 4.318-3.361 5.268-3.499.035.047.06.091.08.132z'
          fill='#ba8f3b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-.859.45-1.916 1.02-3.135 1.59v-.565c2.482-1.132 4.551-2.337 5.412-2.964 1.895-1.388 4.318-3.361 5.268-3.499.035.047.06.091.08.132z'
          fill='#ba8f3b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-.707.37-1.547.821-2.5 1.286v-.556c2.197-1.041 3.99-2.096 4.777-2.668 1.895-1.388 4.318-3.361 5.268-3.499a.77.77 0 0 1 .08.131z'
          fill='#ba8f3b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-.547.285-1.17.619-1.865.97v-.548c1.904-.937 3.434-1.845 4.143-2.361 1.895-1.388 4.318-3.361 5.268-3.499a.882.882 0 0 1 .079.132z'
          fill='#ba8f3b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443-.375.195-.785.414-1.23.645v-.542c1.607-.82 2.879-1.586 3.508-2.043 1.895-1.388 4.318-3.361 5.268-3.499a.94.94 0 0 1 .079.133z'
          fill='#ba8f3b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.781 1.202-2.848 2.031-5.344 3.443l-.594.312v-.539c1.305-.692 2.328-1.317 2.871-1.713 1.895-1.388 4.318-3.361 5.268-3.499.035.048.06.092.08.133z'
          fill='#ba8f3b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.771 1.196-2.836 2.022-5.305 3.42v-.546c1.002-.553 1.787-1.042 2.238-1.37 1.895-1.388 4.318-3.361 5.268-3.499.035.047.06.091.08.132z'
          fill='#ba8f3b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.611 1.088-2.639 1.87-4.67 3.056v-.541a19.888 19.888 0 0 0 1.604-1.011c1.895-1.388 4.318-3.361 5.268-3.499a.882.882 0 0 1 .079.132z'
          fill='#ba8f3b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.436.969-2.406 1.695-4.033 2.678v-.54c.4-.246.73-.462.967-.634 1.895-1.388 4.318-3.361 5.268-3.499a.882.882 0 0 1 .079.132z'
          fill='#ba8f3b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.238.836-2.131 1.491-3.398 2.288v-.554c.125-.083.236-.161.332-.23 1.895-1.388 4.318-3.361 5.268-3.499a.882.882 0 0 1 .079.132z'
          fill='#ba8f3b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-1.021.689-1.809 1.257-2.764 1.881v-.6c1.842-1.367 4.064-3.145 4.965-3.275a.77.77 0 0 1 .08.131z'
          fill='#ba8f3b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-.787.532-1.436.991-2.129 1.459v-.653c1.691-1.271 3.531-2.685 4.33-2.8a.77.77 0 0 1 .08.131z'
          fill='#ba8f3b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-.545.368-1.021.7-1.494 1.025v-.697c1.498-1.125 2.996-2.222 3.695-2.323.035.047.06.091.08.132z'
          fill='#ba8f3b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588-.303.206-.588.399-.859.588v-.733c1.271-.942 2.461-1.762 3.061-1.849.034.046.059.09.079.131z'
          fill='#ba8f3b'
        />
        <path
          d='M108.541 56.024v.275c-.186.356-1.109.648-2.281 1.588l-.225.153v-.763c1.021-.734 1.926-1.312 2.426-1.385.035.047.06.091.08.132z'
          fill='#ba8f3b'
        />
        <path
          d='M108.541 56.024v.275c-.164.313-.898.578-1.871 1.278v-.744c.754-.513 1.398-.883 1.791-.94a.77.77 0 0 1 .08.131z'
          fill='#ba8f3b'
        />
        <path
          d='M108.541 56.024v.275c-.125.241-.588.453-1.236.854v-.732c.477-.292.879-.488 1.156-.528a.77.77 0 0 1 .08.131z'
          fill='#ba8f3b'
        />
        <path
          d='M108.541 56.024v.275c-.08.152-.293.292-.602.476v-.704a1.97 1.97 0 0 1 .521-.178.72.72 0 0 1 .081.131z'
          fill='#ba8f3b'
        />
        <path
          d='M76.808 60.719c.679.172 1.68.96 3.079 1.896 2.097 1.624 5.281 2.208 7.438 2.342 5.118.624 13.969-4.132 15.891-5.571 1.926-1.409 4.301-3.335 5.234-3.482.467.633-.668.728-2.248 2.013-1.773 1.189-2.785 1.987-5.248 3.38-2.783 1.457-7.697 4.217-13.604 4.119-3.233-.384-4.639-.735-5.873-1.443-1.509-.952-3.655-2.097-4.669-3.254z'
          fill='#cea43a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-2.688 1.406-7.358 4.026-12.992 4.119h-.612c-3.233-.384-4.639-.735-5.873-1.443-1.508-.95-3.654-2.096-4.668-3.253.679.172 1.68.96 3.079 1.896 2.097 1.624 5.281 2.208 7.438 2.342 5.118.624 13.969-4.132 15.891-5.571 1.926-1.409 4.301-3.335 5.234-3.482a.73.73 0 0 1 .067.109z'
          fill='#cea43a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-2.688 1.406-7.358 4.026-12.992 4.119h-.612c-3.233-.384-4.639-.735-5.873-1.443-1.241-.782-2.915-1.696-4.034-2.641v-.34c.625.345 1.429.945 2.444 1.624 2.097 1.624 5.281 2.208 7.438 2.342 5.118.624 13.969-4.132 15.891-5.571 1.926-1.409 4.301-3.335 5.234-3.482.029.037.05.074.068.109z'
          fill='#cca33a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-2.688 1.406-7.358 4.026-12.992 4.119h-.612c-3.233-.384-4.639-.735-5.873-1.443-1.023-.645-2.34-1.379-3.398-2.146v-.446c.51.335 1.109.766 1.809 1.234 2.097 1.624 5.281 2.208 7.438 2.342 5.118.624 13.969-4.132 15.891-5.571 1.926-1.409 4.301-3.335 5.234-3.482a.792.792 0 0 1 .067.11z'
          fill='#cba03a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-2.688 1.406-7.358 4.026-12.992 4.119h-.612c-3.233-.384-4.639-.735-5.873-1.443-.827-.521-1.845-1.1-2.764-1.708v-.453c.355.245.746.518 1.174.804 2.097 1.624 5.281 2.208 7.438 2.342 5.118.624 13.969-4.132 15.891-5.571 1.926-1.409 4.301-3.335 5.234-3.482.029.037.05.074.068.109z'
          fill='#c99f3a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-2.688 1.406-7.358 4.026-12.992 4.119h-.612c-3.233-.384-4.639-.735-5.873-1.443-.64-.403-1.394-.841-2.129-1.301v-.422c.173.119.353.241.539.366 2.097 1.624 5.281 2.208 7.438 2.342 5.118.624 13.969-4.132 15.891-5.571 1.926-1.409 4.301-3.335 5.234-3.482.029.037.05.074.068.109z'
          fill='#c99d3a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-2.688 1.406-7.358 4.026-12.992 4.119h-.612c-3.233-.384-4.639-.735-5.873-1.443-.456-.287-.97-.592-1.494-.91v-.374c2.097 1.568 5.217 2.137 7.341 2.269 5.118.624 13.969-4.132 15.891-5.571 1.926-1.409 4.301-3.335 5.234-3.482.03.037.051.074.069.109z'
          fill='#c79d3b'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-2.688 1.406-7.358 4.026-12.992 4.119h-.612c-3.233-.384-4.639-.735-5.873-1.443-.27-.169-.559-.345-.859-.526v-.331c2.056 1.247 4.788 1.723 6.707 1.841 5.118.624 13.969-4.132 15.891-5.571 1.926-1.409 4.301-3.335 5.234-3.482.029.038.05.075.068.11z'
          fill='#c79b3a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-2.688 1.406-7.358 4.026-12.992 4.119h-.612c-3.233-.384-4.639-.735-5.873-1.443l-.224-.139v-.367c1.958.987 4.344 1.383 6.071 1.49 5.118.624 13.969-4.132 15.891-5.571 1.926-1.409 4.301-3.335 5.234-3.482.03.038.051.075.069.11z'
          fill='#c59b3b'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-2.688 1.406-7.358 4.026-12.992 4.119h-.612c-2.881-.342-4.311-.658-5.461-1.224v-.431c1.821.774 3.893 1.101 5.437 1.196 5.118.624 13.969-4.132 15.891-5.571 1.926-1.409 4.301-3.335 5.234-3.482a.792.792 0 0 1 .067.11z'
          fill='#c4973a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-2.688 1.406-7.358 4.026-12.992 4.119h-.612c-2.378-.283-3.767-.547-4.827-.949v-.457c1.654.597 3.435.863 4.802.947 5.118.624 13.969-4.132 15.891-5.571 1.926-1.409 4.301-3.335 5.234-3.482.029.038.05.075.068.11z'
          fill='#c2973b'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-2.688 1.406-7.358 4.026-12.992 4.119h-.612c-1.932-.23-3.211-.447-4.191-.736v-.459c1.464.45 2.973.662 4.167.736 5.118.624 13.969-4.132 15.891-5.571 1.926-1.409 4.301-3.335 5.234-3.482a.792.792 0 0 1 .067.11z'
          fill='#c2953b'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-2.688 1.406-7.358 4.026-12.992 4.119h-.612c-1.542-.183-2.667-.359-3.557-.569V64.4c1.258.328 2.51.492 3.532.556 5.118.624 13.969-4.132 15.891-5.571 1.926-1.409 4.301-3.335 5.234-3.482.029.038.05.075.068.11z'
          fill='#c0933b'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-2.688 1.406-7.358 4.026-12.992 4.119h-.612a40.683 40.683 0 0 1-2.922-.432v-.43c1.039.229 2.047.351 2.897.403 5.118.624 13.969-4.132 15.891-5.571 1.926-1.409 4.301-3.335 5.234-3.482.029.038.05.075.068.11z'
          fill='#bf923b'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-2.688 1.406-7.358 4.026-12.992 4.119h-.612a48.989 48.989 0 0 1-2.287-.317v-.417c.812.148 1.586.233 2.262.275 5.118.624 13.969-4.132 15.891-5.571 1.926-1.409 4.301-3.335 5.234-3.482.029.038.05.075.068.11z'
          fill='#be913b'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-2.688 1.406-7.358 4.026-12.992 4.119h-.612a59.045 59.045 0 0 1-1.652-.217v-.412c.58.086 1.13.14 1.627.171 5.118.624 13.969-4.132 15.891-5.571 1.926-1.409 4.301-3.335 5.234-3.482.029.037.05.074.068.109z'
          fill='#bc8f3b'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-2.688 1.406-7.358 4.026-12.992 4.119h-.612a65.16 65.16 0 0 1-1.017-.128v-.418c.347.04.68.068.992.087 5.118.624 13.969-4.132 15.891-5.571 1.926-1.409 4.301-3.335 5.234-3.482.029.038.05.075.068.11z'
          fill='#bb8e3b'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-2.688 1.406-7.358 4.026-12.992 4.119h-.612l-.382-.046v-.438c.122.01.241.018.357.025 5.118.624 13.969-4.132 15.891-5.571 1.926-1.409 4.301-3.335 5.234-3.482.029.038.05.075.068.11z'
          fill='#b98c3a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-2.688 1.406-7.358 4.026-12.992 4.119h-.359v-.431c5.155.427 13.725-4.186 15.613-5.599 1.926-1.409 4.301-3.335 5.234-3.482.029.038.05.075.068.11z'
          fill='#b98c3a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-2.643 1.383-7.206 3.941-12.716 4.112v-.397c5.195.035 13.166-4.27 14.978-5.626 1.926-1.409 4.301-3.335 5.234-3.482.029.038.05.075.068.11z'
          fill='#b78a3a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-2.541 1.33-6.856 3.745-12.082 4.082v-.381c5.187-.288 12.603-4.309 14.343-5.611 1.926-1.409 4.301-3.335 5.234-3.482.03.037.051.074.069.109z'
          fill='#b5883b'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-2.438 1.276-6.507 3.55-11.447 4.03v-.383c5.138-.554 12.039-4.308 13.708-5.558 1.926-1.409 4.301-3.335 5.234-3.482.03.038.051.075.069.11z'
          fill='#b5863a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-2.332 1.221-6.158 3.355-10.812 3.958v-.394c5.056-.772 11.47-4.275 13.073-5.475 1.926-1.409 4.301-3.335 5.234-3.482.03.038.051.075.069.11z'
          fill='#b5863a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-2.225 1.165-5.811 3.162-10.177 3.866v-.411c4.944-.949 10.901-4.216 12.438-5.366 1.926-1.409 4.301-3.335 5.234-3.482.03.038.051.075.069.11z'
          fill='#b5863a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-2.117 1.107-5.463 2.968-9.542 3.753v-.432c4.807-1.089 10.333-4.132 11.803-5.233 1.926-1.409 4.301-3.335 5.234-3.482.03.039.051.076.069.111z'
          fill='#b5863a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-2.006 1.049-5.116 2.775-8.907 3.622v-.454c4.648-1.198 9.762-4.026 11.168-5.079 1.926-1.409 4.301-3.335 5.234-3.482.03.038.051.075.069.11z'
          fill='#b5863a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-1.893.99-4.769 2.583-8.272 3.47v-.475c4.469-1.278 9.19-3.901 10.534-4.906 1.926-1.409 4.301-3.335 5.234-3.482.029.038.05.075.068.11z'
          fill='#b5863a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-1.777.93-4.42 2.391-7.637 3.3V64.1c4.273-1.332 8.619-3.758 9.898-4.716 1.926-1.409 4.301-3.335 5.234-3.482.03.039.051.076.069.111z'
          fill='#b5863a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-1.658.868-4.073 2.198-7.002 3.111v-.512c4.061-1.363 8.047-3.6 9.264-4.51 1.926-1.409 4.301-3.335 5.234-3.482.029.038.05.075.068.11z'
          fill='#b5863a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-1.537.805-3.723 2.006-6.367 2.904v-.525c3.835-1.373 7.476-3.427 8.628-4.29 1.926-1.409 4.301-3.335 5.234-3.482.03.038.051.075.069.11z'
          fill='#b5863a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-1.412.739-3.373 1.813-5.732 2.68v-.536c3.595-1.362 6.904-3.241 7.994-4.056 1.926-1.409 4.301-3.335 5.234-3.482.029.039.05.076.068.111z'
          fill='#b5863a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-1.283.672-3.02 1.621-5.097 2.438v-.541c3.345-1.334 6.333-3.042 7.359-3.809 1.926-1.409 4.301-3.335 5.234-3.482.029.039.05.076.068.111z'
          fill='#b5863a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-1.15.602-2.664 1.427-4.462 2.18v-.542c3.083-1.289 5.765-2.832 6.724-3.549 1.926-1.409 4.301-3.335 5.234-3.482.029.038.05.075.068.11z'
          fill='#b5863a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-1.012.53-2.307 1.232-3.826 1.906v-.54c2.811-1.228 5.195-2.61 6.088-3.278 1.926-1.409 4.301-3.335 5.234-3.482.029.039.05.076.068.111z'
          fill='#b5863a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-.869.455-1.943 1.036-3.191 1.618v-.534c2.529-1.151 4.629-2.378 5.453-2.995 1.926-1.409 4.301-3.335 5.234-3.482.029.038.05.075.068.11z'
          fill='#b5863a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-.717.375-1.576.837-2.557 1.315v-.525c2.242-1.061 4.064-2.136 4.818-2.701 1.926-1.409 4.301-3.335 5.234-3.482.03.038.051.075.069.11z'
          fill='#b5863a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-.559.292-1.203.637-1.922 1v-.517c1.947-.957 3.502-1.884 4.184-2.395 1.926-1.409 4.301-3.335 5.234-3.482.029.039.05.076.068.111z'
          fill='#b5863a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38-.389.203-.818.433-1.287.675v-.509c1.648-.84 2.941-1.624 3.549-2.077 1.926-1.409 4.301-3.335 5.234-3.482.029.038.05.075.068.11z'
          fill='#b5863a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38l-.652.343v-.507c1.344-.712 2.389-1.354 2.914-1.747 1.926-1.409 4.301-3.335 5.234-3.482.029.038.05.075.068.11z'
          fill='#b5863a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.773 1.189-2.785 1.987-5.248 3.38l-.018.009v-.516c1.037-.572 1.84-1.075 2.279-1.404 1.926-1.409 4.301-3.335 5.234-3.482.03.038.051.075.069.11z'
          fill='#b5863a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.617 1.084-2.602 1.844-4.631 3.025v-.511c.73-.421 1.299-.788 1.645-1.045 1.926-1.409 4.301-3.335 5.234-3.482.029.038.05.075.068.11z'
          fill='#b5863a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.439.965-2.377 1.673-3.996 2.649v-.513a14.42 14.42 0 0 0 1.01-.668c1.926-1.409 4.301-3.335 5.234-3.482.029.039.05.076.068.111z'
          fill='#b5863a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.24.832-2.107 1.473-3.361 2.259v-.528c.145-.096.27-.185.375-.263 1.926-1.409 4.301-3.335 5.234-3.482.029.039.05.076.068.111z'
          fill='#b5863a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-1.02.684-1.789 1.239-2.727 1.852v-.576c1.875-1.385 4.082-3.149 4.975-3.29.029.039.05.076.068.111z'
          fill='#b5863a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-.781.524-1.416.973-2.09 1.429v-.626c1.713-1.284 3.545-2.692 4.338-2.816.029.038.05.075.068.11z'
          fill='#b5863a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-.533.358-1 .682-1.455.996v-.668c1.512-1.135 3.008-2.231 3.703-2.34.029.037.05.074.068.109z'
          fill='#b5863a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612-.291.195-.561.38-.82.559v-.705c1.281-.949 2.471-1.772 3.068-1.867.029.038.05.075.068.11z'
          fill='#b5863a'
        />
        <path
          d='M108.518 56.013v.291c-.197.354-1.131.648-2.316 1.612l-.186.125v-.736c1.029-.741 1.936-1.325 2.434-1.403.029.039.05.076.068.111z'
          fill='#b5863a'
        />
        <path
          d='M108.518 56.013v.291c-.17.308-.9.571-1.867 1.269v-.713c.76-.519 1.406-.895 1.799-.957.029.038.05.075.068.11z'
          fill='#b5863a'
        />
        <path
          d='M108.518 56.013v.291c-.131.236-.59.445-1.232.844v-.704c.482-.297.887-.498 1.164-.542.029.039.05.076.068.111z'
          fill='#b5863a'
        />
        <path
          d='M108.518 56.013v.291c-.082.148-.293.285-.598.466v-.68c.207-.099.385-.164.529-.187.03.038.051.075.069.11z'
          fill='#b5863a'
        />
        <path
          d='M76.784 60.71c.655.128 1.744.977 3.143 1.915 2.086 1.607 5.201 2.208 7.377 2.348 5.183.616 14.118-4.196 15.93-5.594 1.959-1.429 4.285-3.31 5.205-3.466.447.626-.699.715-2.297 2.032-1.764 1.176-2.723 1.943-5.152 3.316-2.76 1.445-7.696 4.217-13.599 4.143-3.242-.377-4.774-.758-5.945-1.438-1.539-.963-3.641-2.088-4.662-3.256z'
          fill='#cba03a'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-2.689 1.408-7.446 4.077-13.149 4.143h-.45c-3.242-.377-4.774-.758-5.945-1.438-1.54-.962-3.642-2.086-4.663-3.255.655.128 1.744.977 3.143 1.915 2.086 1.607 5.201 2.208 7.377 2.348 5.183.616 14.118-4.196 15.93-5.594 1.959-1.429 4.285-3.31 5.205-3.466a.434.434 0 0 1 .054.086z'
          fill='#cba03a'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-2.689 1.408-7.446 4.077-13.149 4.143h-.45c-3.242-.377-4.774-.758-5.945-1.438-1.268-.792-2.915-1.693-4.027-2.639v-.36c.642.346 1.493.979 2.508 1.659 2.086 1.607 5.201 2.208 7.377 2.348 5.183.616 14.118-4.196 15.93-5.594 1.959-1.429 4.285-3.31 5.205-3.466.022.029.04.058.053.086z'
          fill='#c99d3a'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-2.689 1.408-7.446 4.077-13.149 4.143h-.45c-3.242-.377-4.774-.758-5.945-1.438-1.043-.652-2.344-1.378-3.392-2.142v-.474c.534.348 1.163.8 1.873 1.276 2.086 1.607 5.201 2.208 7.377 2.348 5.183.616 14.118-4.196 15.93-5.594 1.959-1.429 4.285-3.31 5.205-3.466.022.029.04.058.053.086z'
          fill='#c89c3a'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-2.689 1.408-7.446 4.077-13.149 4.143h-.45c-3.242-.377-4.774-.758-5.945-1.438-.841-.525-1.849-1.099-2.757-1.702v-.486c.378.26.792.549 1.237.848 2.086 1.607 5.201 2.208 7.377 2.348 5.183.616 14.118-4.196 15.93-5.594 1.959-1.429 4.285-3.31 5.205-3.466a.434.434 0 0 1 .054.086z'
          fill='#c69a3a'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-2.689 1.408-7.446 4.077-13.149 4.143h-.45c-3.242-.377-4.774-.758-5.945-1.438-.649-.406-1.396-.839-2.122-1.293v-.457c.194.133.395.271.603.41 2.086 1.607 5.201 2.208 7.377 2.348 5.183.616 14.118-4.196 15.93-5.594 1.959-1.429 4.285-3.31 5.205-3.466.022.029.04.058.053.086z'
          fill='#c4993b'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-2.689 1.408-7.446 4.077-13.149 4.143h-.45c-3.242-.377-4.774-.758-5.945-1.438-.46-.288-.97-.589-1.487-.903v-.413c2.086 1.589 5.18 2.185 7.345 2.323 5.183.616 14.118-4.196 15.93-5.594 1.959-1.429 4.285-3.31 5.205-3.466a.52.52 0 0 1 .053.087z'
          fill='#c4973a'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-2.689 1.408-7.446 4.077-13.149 4.143h-.45c-3.242-.377-4.774-.758-5.945-1.438-.27-.169-.557-.342-.852-.52v-.36c2.043 1.265 4.755 1.763 6.709 1.888 5.183.616 14.118-4.196 15.93-5.594 1.959-1.429 4.285-3.31 5.205-3.466a.434.434 0 0 1 .054.086z'
          fill='#c4963a'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-2.689 1.408-7.446 4.077-13.149 4.143h-.45c-3.242-.377-4.774-.758-5.945-1.438l-.217-.135v-.386c1.945 1.002 4.316 1.416 6.074 1.529 5.183.616 14.118-4.196 15.93-5.594 1.959-1.429 4.285-3.31 5.205-3.466a.434.434 0 0 1 .054.086z'
          fill='#c1943a'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-2.689 1.408-7.446 4.077-13.149 4.143h-.45c-2.871-.334-4.401-.671-5.527-1.216v-.442c1.809.786 3.869 1.127 5.439 1.228 5.183.616 14.118-4.196 15.93-5.594 1.959-1.429 4.285-3.31 5.205-3.466a.434.434 0 0 1 .054.086z'
          fill='#bf923b'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-2.689 1.408-7.446 4.077-13.149 4.143h-.45c-2.367-.275-3.823-.552-4.892-.947V64c1.644.607 3.415.884 4.805.973 5.183.616 14.118-4.196 15.93-5.594 1.959-1.429 4.285-3.31 5.205-3.466a.52.52 0 0 1 .053.087z'
          fill='#bf923b'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-2.689 1.408-7.446 4.077-13.149 4.143h-.45c-1.93-.225-3.254-.45-4.257-.74v-.447c1.455.458 2.958.679 4.169.756 5.183.616 14.118-4.196 15.93-5.594 1.959-1.429 4.285-3.31 5.205-3.466a.47.47 0 0 1 .054.087z'
          fill='#bd903b'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-2.689 1.408-7.446 4.077-13.149 4.143h-.45c-1.548-.18-2.707-.361-3.622-.575v-.427c1.25.334 2.498.505 3.534.572 5.183.616 14.118-4.196 15.93-5.594 1.959-1.429 4.285-3.31 5.205-3.466a.434.434 0 0 1 .054.086z'
          fill='#bb8e3b'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-2.689 1.408-7.446 4.077-13.149 4.143h-.45a40.278 40.278 0 0 1-2.987-.439v-.406a18.81 18.81 0 0 0 2.899.415c5.183.616 14.118-4.196 15.93-5.594 1.959-1.429 4.285-3.31 5.205-3.466a.434.434 0 0 1 .054.086z'
          fill='#bb8c3a'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-2.689 1.408-7.446 4.077-13.149 4.143h-.45a47.418 47.418 0 0 1-2.352-.324v-.391c.807.151 1.582.241 2.264.284 5.183.616 14.118-4.196 15.93-5.594 1.959-1.429 4.285-3.31 5.205-3.466a.47.47 0 0 1 .054.087z'
          fill='#b98a3a'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-2.689 1.408-7.446 4.077-13.149 4.143h-.45a53.425 53.425 0 0 1-1.716-.224v-.383c.577.088 1.128.144 1.629.176 5.183.616 14.118-4.196 15.93-5.594 1.959-1.429 4.285-3.31 5.205-3.466a.52.52 0 0 1 .053.087z'
          fill='#b7883a'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-2.689 1.408-7.446 4.077-13.149 4.143h-.45a60.325 60.325 0 0 1-1.082-.135v-.387c.347.042.681.071.994.091 5.183.616 14.118-4.196 15.93-5.594 1.959-1.429 4.285-3.31 5.205-3.466a.47.47 0 0 1 .054.087z'
          fill='#b6863a'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-2.689 1.408-7.446 4.077-13.149 4.143h-.45l-.447-.053v-.404c.123.01.242.019.359.026 5.183.616 14.118-4.196 15.93-5.594 1.959-1.429 4.285-3.31 5.205-3.466a.47.47 0 0 1 .054.087z'
          fill='#b4853a'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-2.689 1.408-7.446 4.077-13.149 4.143h-.261V65c5.217.421 13.875-4.248 15.654-5.622 1.959-1.429 4.285-3.31 5.205-3.466a.57.57 0 0 1 .053.088z'
          fill='#b3843a'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-2.631 1.377-7.238 3.961-12.775 4.135v-.369c5.257.03 13.31-4.329 15.019-5.648 1.959-1.429 4.285-3.31 5.205-3.466a.52.52 0 0 1 .053.087z'
          fill='#b1823b'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-2.529 1.324-6.886 3.764-12.14 4.104v-.354c5.248-.293 12.741-4.365 14.384-5.632 1.959-1.429 4.285-3.31 5.205-3.466a.52.52 0 0 1 .053.087z'
          fill='#b07f3a'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-2.428 1.271-6.536 3.567-11.505 4.052v-.355c5.198-.559 12.173-4.362 13.749-5.579 1.959-1.429 4.285-3.31 5.205-3.466a.52.52 0 0 1 .053.087z'
          fill='#ad7f3b'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-2.324 1.216-6.187 3.372-10.87 3.98v-.366c5.115-.778 11.6-4.329 13.114-5.497 1.959-1.429 4.285-3.31 5.205-3.466a.57.57 0 0 1 .053.088z'
          fill='#ac7d3b'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-2.217 1.161-5.837 3.178-10.235 3.888v-.383c5.003-.955 11.028-4.267 12.479-5.387 1.959-1.429 4.285-3.31 5.205-3.466a.52.52 0 0 1 .053.087z'
          fill='#ac7d3b'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-2.109 1.104-5.489 2.983-9.6 3.775v-.402c4.866-1.098 10.453-4.182 11.844-5.255 1.959-1.429 4.285-3.31 5.205-3.466a.52.52 0 0 1 .053.087z'
          fill='#ac7d3b'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-2 1.047-5.141 2.79-8.964 3.643v-.424c4.706-1.207 9.878-4.075 11.208-5.102 1.959-1.429 4.285-3.31 5.205-3.466a.57.57 0 0 1 .053.088z'
          fill='#ac7d3b'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-1.889.988-4.793 2.598-8.33 3.492v-.444c4.527-1.289 9.302-3.949 10.574-4.93 1.959-1.429 4.285-3.31 5.205-3.466a.52.52 0 0 1 .053.087z'
          fill='#ac7d3b'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-1.773.929-4.445 2.405-7.695 3.322v-.464c4.332-1.344 8.728-3.806 9.939-4.741 1.959-1.429 4.285-3.31 5.205-3.466a.57.57 0 0 1 .053.088z'
          fill='#ac7d3b'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-1.656.867-4.097 2.212-7.06 3.134v-.48c4.118-1.376 8.151-3.646 9.304-4.536 1.959-1.429 4.285-3.31 5.205-3.466a.52.52 0 0 1 .053.087z'
          fill='#ac7d3b'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-1.537.804-3.748 2.021-6.424 2.927v-.493c3.891-1.387 7.577-3.473 8.668-4.316 1.959-1.429 4.285-3.31 5.205-3.466a.52.52 0 0 1 .053.087z'
          fill='#ac7d3b'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-1.414.74-3.396 1.828-5.789 2.703v-.502c3.65-1.378 7-3.286 8.033-4.083 1.959-1.429 4.285-3.31 5.205-3.466a.52.52 0 0 1 .053.087z'
          fill='#ac7d3b'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-1.287.673-3.045 1.635-5.154 2.462v-.507c3.398-1.351 6.426-3.086 7.398-3.837 1.959-1.429 4.285-3.31 5.205-3.466a.52.52 0 0 1 .053.087z'
          fill='#ac7d3b'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-1.156.604-2.691 1.442-4.519 2.206v-.509c3.134-1.306 5.851-2.875 6.763-3.579 1.959-1.429 4.285-3.31 5.205-3.466a.52.52 0 0 1 .053.087z'
          fill='#ac7d3b'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-1.02.533-2.334 1.247-3.885 1.932v-.506c2.863-1.245 5.279-2.652 6.129-3.308 1.959-1.429 4.285-3.31 5.205-3.466a.52.52 0 0 1 .053.087z'
          fill='#ac7d3b'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-.877.458-1.973 1.052-3.25 1.644v-.5c2.58-1.17 4.709-2.419 5.494-3.026 1.959-1.429 4.285-3.31 5.205-3.466a.52.52 0 0 1 .053.087z'
          fill='#ac7d3b'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-.729.38-1.605.854-2.613 1.343v-.494c2.289-1.08 4.137-2.176 4.857-2.732 1.959-1.429 4.285-3.31 5.205-3.466a.57.57 0 0 1 .053.088z'
          fill='#ac7d3b'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-.57.298-1.234.654-1.979 1.029v-.484c1.992-.977 3.57-1.924 4.223-2.427 1.959-1.429 4.285-3.31 5.205-3.466a.52.52 0 0 1 .053.087z'
          fill='#ac7d3b'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-.402.21-.852.45-1.344.704v-.477c1.689-.86 3.008-1.661 3.588-2.11 1.959-1.429 4.285-3.31 5.205-3.466a.57.57 0 0 1 .053.088z'
          fill='#ac7d3b'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316-.223.116-.459.241-.709.373v-.475c1.383-.731 2.447-1.389 2.953-1.78 1.959-1.429 4.285-3.31 5.205-3.466a.52.52 0 0 1 .053.087z'
          fill='#ac7d3b'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.764 1.176-2.723 1.943-5.152 3.316l-.074.039v-.483c1.072-.591 1.893-1.109 2.318-1.438 1.959-1.429 4.285-3.31 5.205-3.466a.52.52 0 0 1 .053.087z'
          fill='#ac7d3b'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.621 1.082-2.562 1.817-4.592 2.995v-.481c.762-.44 1.348-.819 1.684-1.08 1.959-1.429 4.285-3.31 5.205-3.466a.52.52 0 0 1 .053.087z'
          fill='#ac7d3b'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.441.962-2.346 1.65-3.957 2.62v-.485c.455-.278.812-.52 1.049-.702 1.959-1.429 4.285-3.31 5.205-3.466a.57.57 0 0 1 .053.088z'
          fill='#ac7d3b'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.242.828-2.084 1.453-3.32 2.229v-.5c.162-.11.301-.208.412-.295 1.959-1.429 4.285-3.31 5.205-3.466a.52.52 0 0 1 .053.087z'
          fill='#ac7d3b'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-1.018.679-1.768 1.221-2.686 1.822v-.551c1.908-1.405 4.096-3.153 4.982-3.303a.47.47 0 0 1 .054.087z'
          fill='#ac7d3b'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-.775.517-1.395.955-2.051 1.399v-.599c1.738-1.297 3.559-2.698 4.348-2.832a.52.52 0 0 1 .053.087z'
          fill='#ac7d3b'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-.523.349-.975.662-1.416.965v-.64c1.529-1.144 3.02-2.239 3.713-2.357a.52.52 0 0 1 .053.087z'
          fill='#ac7d3b'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635-.277.185-.535.36-.781.529v-.676c1.295-.957 2.48-1.783 3.078-1.884.022.029.04.058.053.086z'
          fill='#ac7d3b'
        />
        <path
          d='M108.492 56v.31c-.209.351-1.152.648-2.35 1.635l-.146.098v-.709c1.039-.748 1.945-1.335 2.443-1.42.022.029.04.058.053.086z'
          fill='#ac7d3b'
        />
        <path
          d='M108.492 56v.31c-.18.302-.902.564-1.861 1.257v-.682c.768-.525 1.416-.906 1.809-.973a.65.65 0 0 1 .052.088z'
          fill='#ac7d3b'
        />
        <path
          d='M108.492 56v.31c-.137.23-.592.438-1.225.833v-.674c.486-.303.893-.508 1.172-.555.022.029.04.058.053.086z'
          fill='#ac7d3b'
        />
        <path
          d='M108.492 56v.31c-.084.144-.293.278-.59.454v-.655c.209-.103.391-.171.537-.196a.52.52 0 0 1 .053.087z'
          fill='#ac7d3b'
        />
        <path
          d='M76.761 60.701c.631.084 1.809.994 3.208 1.934 2.074 1.59 5.119 2.208 7.316 2.354 5.245.61 14.269-4.26 15.97-5.617 1.99-1.45 4.266-3.284 5.174-3.45.428.619-.732.704-2.346 2.051-1.756 1.164-2.66 1.9-5.057 3.253-2.738 1.434-7.696 4.218-13.593 4.167-3.251-.37-4.909-.78-6.017-1.436-1.571-.973-3.629-2.076-4.655-3.256z'
          fill='#c89a3a'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-2.689 1.408-7.519 4.119-13.276 4.167h-.317c-3.251-.37-4.909-.78-6.017-1.436-1.573-.972-3.631-2.075-4.656-3.255.631.084 1.809.994 3.208 1.934 2.074 1.59 5.119 2.208 7.316 2.354 5.245.61 14.269-4.26 15.97-5.617 1.99-1.45 4.266-3.284 5.174-3.45.016.022.03.044.042.065z'
          fill='#c89a3a'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-2.689 1.408-7.519 4.119-13.276 4.167h-.317c-3.251-.37-4.909-.78-6.017-1.436-1.294-.8-2.916-1.688-4.021-2.635v-.382c.658.346 1.557 1.012 2.573 1.695 2.074 1.59 5.119 2.208 7.316 2.354 5.245.61 14.269-4.26 15.97-5.617 1.99-1.45 4.266-3.284 5.174-3.45.016.023.03.045.042.066z'
          fill='#c6983a'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-2.689 1.408-7.519 4.119-13.276 4.167h-.317c-3.251-.37-4.909-.78-6.017-1.436-1.063-.657-2.348-1.374-3.386-2.135v-.503c.558.359 1.218.834 1.938 1.317 2.074 1.59 5.119 2.208 7.316 2.354 5.245.61 14.269-4.26 15.97-5.617 1.99-1.45 4.266-3.284 5.174-3.45.016.022.03.044.042.065z'
          fill='#c4973a'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-2.689 1.408-7.519 4.119-13.276 4.167h-.317c-3.251-.37-4.909-.78-6.017-1.436-.855-.528-1.853-1.095-2.751-1.692v-.521c.402.275.84.582 1.303.893 2.074 1.59 5.119 2.208 7.316 2.354 5.245.61 14.269-4.26 15.97-5.617 1.99-1.45 4.266-3.284 5.174-3.45.016.021.03.043.042.064z'
          fill='#c3943a'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-2.689 1.408-7.519 4.119-13.276 4.167h-.317c-3.251-.37-4.909-.78-6.017-1.436-.658-.406-1.399-.835-2.116-1.284v-.493c.216.149.438.301.667.455 2.074 1.59 5.119 2.208 7.316 2.354 5.245.61 14.269-4.26 15.97-5.617 1.99-1.45 4.266-3.284 5.174-3.45a.667.667 0 0 1 .043.066z'
          fill='#c1943a'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-2.689 1.408-7.519 4.119-13.276 4.167h-.317c-3.251-.37-4.909-.78-6.017-1.436-.464-.287-.971-.585-1.48-.894v-.449l.032.021c2.074 1.59 5.119 2.208 7.316 2.354 5.245.61 14.269-4.26 15.97-5.617 1.99-1.45 4.266-3.284 5.174-3.45.016.023.03.045.042.066z'
          fill='#bf923b'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-2.689 1.408-7.519 4.119-13.276 4.167h-.317c-3.251-.37-4.909-.78-6.017-1.436-.27-.167-.554-.337-.846-.512v-.39c2.031 1.282 4.721 1.802 6.714 1.935 5.245.61 14.269-4.26 15.97-5.617 1.99-1.45 4.266-3.284 5.174-3.45.016.022.03.044.042.065z'
          fill='#bf903a'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-2.689 1.408-7.519 4.119-13.276 4.167h-.317c-3.251-.37-4.909-.78-6.017-1.436a30.8 30.8 0 0 1-.21-.129v-.408c1.933 1.018 4.288 1.45 6.079 1.569 5.245.61 14.269-4.26 15.97-5.617 1.99-1.45 4.266-3.284 5.174-3.45l.041.066z'
          fill='#bd8e3b'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-2.689 1.408-7.519 4.119-13.276 4.167h-.317c-2.861-.326-4.488-.682-5.592-1.209v-.455c1.796.799 3.845 1.155 5.443 1.261 5.245.61 14.269-4.26 15.97-5.617 1.99-1.45 4.266-3.284 5.174-3.45.016.022.03.044.042.065z'
          fill='#bb8c3b'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-2.689 1.408-7.519 4.119-13.276 4.167h-.317c-2.357-.269-3.877-.557-4.957-.946v-.457c1.631.618 3.395.907 4.808 1 5.245.61 14.269-4.26 15.97-5.617 1.99-1.45 4.266-3.284 5.174-3.45.016.022.03.044.042.065z'
          fill='#ba8a3a'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-2.689 1.408-7.519 4.119-13.276 4.167h-.317c-1.929-.22-3.296-.453-4.322-.744v-.437c1.444.466 2.942.696 4.173.778 5.245.61 14.269-4.26 15.97-5.617 1.99-1.45 4.266-3.284 5.174-3.45.016.022.03.044.042.065z'
          fill='#b98a3a'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-2.689 1.408-7.519 4.119-13.276 4.167h-.317c-1.556-.177-2.746-.363-3.687-.581v-.411c1.241.341 2.486.519 3.538.588 5.245.61 14.269-4.26 15.97-5.617 1.99-1.45 4.266-3.284 5.174-3.45.016.023.03.045.042.066z'
          fill='#b6863a'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-2.689 1.408-7.519 4.119-13.276 4.167h-.317a39.749 39.749 0 0 1-3.051-.446v-.385c1.026.238 2.031.37 2.903.428 5.245.61 14.269-4.26 15.97-5.617 1.99-1.45 4.266-3.284 5.174-3.45a.982.982 0 0 1 .041.065z'
          fill='#b4853a'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-2.689 1.408-7.519 4.119-13.276 4.167h-.317a45.049 45.049 0 0 1-2.416-.331v-.365c.802.155 1.577.247 2.268.293 5.245.61 14.269-4.26 15.97-5.617 1.99-1.45 4.266-3.284 5.174-3.45a.982.982 0 0 1 .041.065z'
          fill='#b4833a'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-2.689 1.408-7.519 4.119-13.276 4.167h-.317a52.422 52.422 0 0 1-1.781-.23v-.355c.574.09 1.125.148 1.632.182 5.245.61 14.269-4.26 15.97-5.617 1.99-1.45 4.266-3.284 5.174-3.45.016.022.03.044.042.065z'
          fill='#b2813a'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-2.689 1.408-7.519 4.119-13.276 4.167h-.317a57.48 57.48 0 0 1-1.146-.141v-.356c.346.042.68.073.997.094 5.245.61 14.269-4.26 15.97-5.617 1.99-1.45 4.266-3.284 5.174-3.45.016.022.03.044.042.065z'
          fill='#b07f3a'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-2.689 1.408-7.519 4.119-13.276 4.167h-.317l-.511-.06v-.371c.123.011.244.02.362.028 5.245.61 14.269-4.26 15.97-5.617 1.99-1.45 4.266-3.284 5.174-3.45.016.022.03.044.042.065z'
          fill='#ae7e3a'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-2.689 1.408-7.519 4.119-13.276 4.167h-.192v-.376c5.279.417 14.023-4.31 15.697-5.644 1.99-1.45 4.266-3.284 5.174-3.45a.982.982 0 0 1 .041.065z'
          fill='#ac7a3a'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-2.619 1.372-7.271 3.979-12.833 4.157v-.342c5.318.027 13.455-4.387 15.062-5.669 1.99-1.45 4.266-3.284 5.174-3.45l.041.066z'
          fill='#a8783a'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-2.52 1.319-6.918 3.782-12.198 4.127v-.328c5.308-.296 12.882-4.421 14.427-5.653 1.99-1.45 4.266-3.284 5.174-3.45l.041.066z'
          fill='#a6763a'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-2.418 1.266-6.566 3.585-11.563 4.075v-.328c5.259-.564 12.308-4.417 13.792-5.601 1.99-1.45 4.266-3.284 5.174-3.45l.041.066z'
          fill='#a3733a'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-2.314 1.212-6.215 3.389-10.928 4.003v-.339c5.175-.783 11.731-4.381 13.157-5.518 1.99-1.45 4.266-3.284 5.174-3.45l.041.066z'
          fill='#a37239'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-2.209 1.157-5.865 3.193-10.293 3.91v-.354c5.063-.962 11.154-4.319 12.521-5.409 1.99-1.45 4.266-3.284 5.174-3.45.016.022.03.044.042.065z'
          fill='#a37239'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-2.104 1.101-5.516 2.999-9.658 3.797v-.373c4.925-1.105 10.576-4.232 11.886-5.277 1.99-1.45 4.266-3.284 5.174-3.45.016.022.03.044.042.065z'
          fill='#a37239'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-1.994 1.044-5.167 2.805-9.023 3.665v-.394c4.766-1.216 9.998-4.125 11.251-5.125 1.99-1.45 4.266-3.284 5.174-3.45.016.023.03.045.042.066z'
          fill='#a37239'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-1.885.986-4.819 2.613-8.387 3.514v-.414c4.584-1.299 9.417-3.998 10.616-4.954 1.99-1.45 4.266-3.284 5.174-3.45l.041.066z'
          fill='#a37239'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-1.771.927-4.471 2.42-7.752 3.345v-.432c4.387-1.356 8.836-3.854 9.981-4.766 1.99-1.45 4.266-3.284 5.174-3.45a.982.982 0 0 1 .041.065z'
          fill='#a37239'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-1.654.867-4.122 2.228-7.117 3.156v-.448c4.174-1.389 8.258-3.694 9.346-4.562 1.99-1.45 4.266-3.284 5.174-3.45l.041.066z'
          fill='#a37239'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-1.537.805-3.773 2.035-6.482 2.951v-.461c3.947-1.401 7.677-3.52 8.71-4.343 1.99-1.45 4.266-3.284 5.174-3.45.016.022.03.044.042.065z'
          fill='#a37239'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-1.414.741-3.422 1.843-5.847 2.728v-.47c3.705-1.394 7.099-3.332 8.076-4.111 1.99-1.45 4.266-3.284 5.174-3.45a.982.982 0 0 1 .041.065z'
          fill='#a37239'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-1.289.675-3.07 1.65-5.211 2.487v-.475c3.452-1.367 6.518-3.131 7.44-3.866 1.99-1.45 4.266-3.284 5.174-3.45l.041.066z'
          fill='#a37239'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-1.16.607-2.717 1.457-4.577 2.231v-.477c3.188-1.324 5.94-2.918 6.805-3.608 1.99-1.45 4.266-3.284 5.174-3.45.016.023.03.045.042.066z'
          fill='#a37239'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-1.025.537-2.361 1.263-3.941 1.959v-.475c2.912-1.264 5.363-2.695 6.17-3.338 1.99-1.45 4.266-3.284 5.174-3.45l.041.066z'
          fill='#a37239'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-.885.463-2.002 1.068-3.307 1.672v-.469c2.631-1.188 4.787-2.461 5.535-3.057 1.99-1.45 4.266-3.284 5.174-3.45.016.023.03.045.042.066z'
          fill='#a37239'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-.736.386-1.637.871-2.672 1.371v-.46c2.338-1.1 4.215-2.217 4.9-2.764 1.99-1.45 4.266-3.284 5.174-3.45.016.022.03.044.042.065z'
          fill='#a37239'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-.582.305-1.266.671-2.035 1.058v-.452c2.037-.996 3.641-1.963 4.264-2.459 1.99-1.45 4.266-3.284 5.174-3.45a.982.982 0 0 1 .041.065z'
          fill='#a37239'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253-.416.218-.885.468-1.4.734v-.444c1.732-.881 3.072-1.7 3.629-2.144 1.99-1.45 4.266-3.284 5.174-3.45l.041.066z'
          fill='#a37239'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253l-.766.403v-.442c1.424-.752 2.508-1.427 2.994-1.814 1.99-1.45 4.266-3.284 5.174-3.45.016.022.03.044.042.065z'
          fill='#a37239'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.756 1.164-2.66 1.9-5.057 3.253l-.131.069v-.451c1.109-.611 1.949-1.144 2.359-1.472 1.99-1.45 4.266-3.284 5.174-3.45.016.023.03.045.042.066z'
          fill='#a37239'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.627 1.078-2.523 1.79-4.553 2.965v-.452c.795-.459 1.396-.852 1.725-1.114 1.99-1.45 4.266-3.284 5.174-3.45l.041.066z'
          fill='#a37239'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.447.959-2.316 1.627-3.916 2.59v-.455c.48-.296.854-.549 1.088-.736 1.99-1.45 4.266-3.284 5.174-3.45l.041.066z'
          fill='#a37239'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.242.824-2.061 1.434-3.281 2.2V59.7c.184-.124.336-.235.453-.328 1.99-1.45 4.266-3.284 5.174-3.45l.041.066z'
          fill='#a37239'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-1.016.673-1.746 1.204-2.646 1.793v-.526c1.945-1.427 4.113-3.156 4.992-3.317a.982.982 0 0 1 .041.065z'
          fill='#a37239'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-.768.509-1.373.937-2.012 1.369v-.572c1.764-1.312 3.572-2.704 4.357-2.848.016.023.03.045.042.066z'
          fill='#a37239'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-.512.339-.951.642-1.377.935v-.611c1.547-1.154 3.031-2.248 3.723-2.374a.982.982 0 0 1 .041.065z'
          fill='#a37239'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658-.264.175-.508.34-.74.499v-.648c1.307-.965 2.49-1.793 3.086-1.902l.041.066z'
          fill='#a37239'
        />
        <path
          d='M108.471 55.988v.327c-.223.348-1.178.648-2.387 1.658l-.105.07v-.684c1.047-.754 1.953-1.347 2.451-1.438l.041.067z'
          fill='#a37239'
        />
        <path
          d='M108.471 55.988v.327c-.189.295-.906.557-1.857 1.247v-.65c.773-.531 1.424-.917 1.816-.989a.982.982 0 0 1 .041.065z'
          fill='#a37239'
        />
        <path
          d='M108.471 55.988v.327c-.145.225-.596.431-1.223.821v-.645c.492-.308.902-.518 1.182-.569l.041.066z'
          fill='#a37239'
        />
        <path
          d='M108.471 55.988v.327c-.09.139-.295.271-.586.442v-.63c.213-.107.396-.178.545-.206l.041.067z'
          fill='#a37239'
        />
        <path
          d='M76.738 60.692c.607.041 1.873 1.012 3.272 1.954 2.062 1.574 5.039 2.208 7.256 2.36 5.309.603 14.419-4.324 16.011-5.64 2.021-1.471 4.248-3.258 5.141-3.434.41.612-.764.692-2.393 2.07-1.746 1.15-2.6 1.855-4.961 3.189-2.715 1.421-7.696 4.218-13.586 4.189-3.261-.363-5.046-.802-6.09-1.432-1.605-.983-3.618-2.066-4.65-3.256z'
          fill='#c3943a'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-2.715 1.421-7.696 4.218-13.586 4.189-3.261-.363-5.046-.802-6.09-1.432-1.605-.983-3.619-2.066-4.65-3.256.607.041 1.873 1.012 3.272 1.954 2.062 1.574 5.039 2.208 7.256 2.36 5.309.603 14.419-4.324 16.011-5.64 2.021-1.471 4.248-3.258 5.141-3.434.012.015.02.03.027.044z'
          fill='#c3943a'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-2.715 1.421-7.696 4.218-13.586 4.189-3.261-.363-5.046-.802-6.09-1.432-1.32-.809-2.916-1.684-4.015-2.631v-.402c.674.348 1.62 1.046 2.637 1.731 2.062 1.574 5.039 2.208 7.256 2.36 5.309.603 14.419-4.324 16.011-5.64 2.021-1.471 4.248-3.258 5.141-3.434.012.015.02.03.027.044z'
          fill='#c3943a'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-2.715 1.421-7.696 4.218-13.586 4.189-3.261-.363-5.046-.802-6.09-1.432-1.083-.664-2.352-1.372-3.38-2.128v-.532c.582.371 1.274.868 2.002 1.358 2.062 1.574 5.039 2.208 7.256 2.36 5.309.603 14.419-4.324 16.011-5.64 2.021-1.471 4.248-3.258 5.141-3.434.012.015.02.03.027.044z'
          fill='#c1923a'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-2.715 1.421-7.696 4.218-13.586 4.189-3.261-.363-5.046-.802-6.09-1.432-.869-.532-1.857-1.093-2.744-1.685v-.555c.426.29.888.615 1.366.937 2.062 1.574 5.039 2.208 7.256 2.36 5.309.603 14.419-4.324 16.011-5.64 2.021-1.471 4.248-3.258 5.141-3.434.012.016.02.031.027.045z'
          fill='#bf903a'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-2.715 1.421-7.696 4.218-13.586 4.189-3.261-.363-5.046-.802-6.09-1.432-.666-.408-1.402-.833-2.109-1.276v-.526c.238.164.482.332.731.5 2.062 1.574 5.039 2.208 7.256 2.36 5.309.603 14.419-4.324 16.011-5.64 2.021-1.471 4.248-3.258 5.141-3.434.012.015.02.03.027.044z'
          fill='#be8e3a'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-2.715 1.421-7.696 4.218-13.586 4.189-3.261-.363-5.046-.802-6.09-1.432-.468-.287-.971-.582-1.474-.886v-.481l.096.065c2.062 1.574 5.039 2.208 7.256 2.36 5.309.603 14.419-4.324 16.011-5.64 2.021-1.471 4.248-3.258 5.141-3.434.012.015.02.03.027.044z'
          fill='#bc8c3a'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-2.715 1.421-7.696 4.218-13.586 4.189-3.261-.363-5.046-.802-6.09-1.432-.271-.166-.552-.333-.839-.505v-.42c2.02 1.3 4.687 1.843 6.717 1.982 5.309.603 14.419-4.324 16.011-5.64 2.021-1.471 4.248-3.258 5.141-3.434.012.016.02.031.027.045z'
          fill='#ba8a3b'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-2.715 1.421-7.696 4.218-13.586 4.189-3.261-.363-5.046-.802-6.09-1.432l-.203-.124v-.427c1.92 1.032 4.257 1.483 6.082 1.608 5.309.603 14.419-4.324 16.011-5.64 2.021-1.471 4.248-3.258 5.141-3.434a.365.365 0 0 1 .026.045z'
          fill='#b8873a'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-2.715 1.421-7.696 4.218-13.586 4.189-2.85-.317-4.572-.692-5.658-1.203v-.466c1.784.812 3.82 1.183 5.447 1.294 5.309.603 14.419-4.324 16.011-5.64 2.021-1.471 4.248-3.258 5.141-3.434a.365.365 0 0 1 .026.045z'
          fill='#b6863a'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-2.715 1.421-7.696 4.218-13.586 4.189-2.348-.261-3.931-.562-5.022-.945v-.457c1.619.628 3.375.929 4.811 1.027 5.309.603 14.419-4.324 16.011-5.64 2.021-1.471 4.248-3.258 5.141-3.434a.365.365 0 0 1 .026.045z'
          fill='#b6833a'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-2.715 1.421-7.696 4.218-13.586 4.189-1.928-.214-3.339-.456-4.388-.747v-.427c1.434.475 2.926.713 4.176.799 5.309.603 14.419-4.324 16.011-5.64 2.021-1.471 4.248-3.258 5.141-3.434.012.016.02.031.027.045z'
          fill='#b4833a'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-2.715 1.421-7.696 4.218-13.586 4.189-1.563-.174-2.787-.365-3.752-.586v-.393c1.231.347 2.474.531 3.541.604 5.309.603 14.419-4.324 16.011-5.64 2.021-1.471 4.248-3.258 5.141-3.434a.365.365 0 0 1 .026.045z'
          fill='#b2803a'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-2.715 1.421-7.696 4.218-13.586 4.189a38.967 38.967 0 0 1-3.117-.453v-.362c1.019.243 2.022.379 2.906.44 5.309.603 14.419-4.324 16.011-5.64 2.021-1.471 4.248-3.258 5.141-3.434a.365.365 0 0 1 .026.045z'
          fill='#b07e3a'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-2.715 1.421-7.696 4.218-13.586 4.189a44.362 44.362 0 0 1-2.482-.337v-.339c.797.158 1.572.254 2.271.302 5.309.603 14.419-4.324 16.011-5.64 2.021-1.471 4.248-3.258 5.141-3.434.011.015.019.03.026.044z'
          fill='#ae7c3a'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-2.715 1.421-7.696 4.218-13.586 4.189a49.424 49.424 0 0 1-1.847-.237v-.325c.572.092 1.124.152 1.635.188 5.309.603 14.419-4.324 16.011-5.64 2.021-1.471 4.248-3.258 5.141-3.434.012.015.02.03.027.044z'
          fill='#ac7a3a'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-2.715 1.421-7.696 4.218-13.586 4.189a49.58 49.58 0 0 1-1.211-.147v-.324c.346.043.681.075 1 .097 5.309.603 14.419-4.324 16.011-5.64 2.021-1.471 4.248-3.258 5.141-3.434.011.015.019.03.026.044z'
          fill='#a77539'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-2.715 1.421-7.696 4.218-13.586 4.189a89.458 89.458 0 0 1-.576-.066v-.336c.124.011.245.021.365.028 5.309.603 14.419-4.324 16.011-5.64 2.021-1.471 4.248-3.258 5.141-3.434.011.015.019.03.026.044z'
          fill='#a57339'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-2.705 1.417-7.662 4.199-13.527 4.189v-.349c5.342.412 14.174-4.372 15.74-5.666 2.021-1.471 4.248-3.258 5.141-3.434.012.016.02.031.027.045z'
          fill='#a37239'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-2.607 1.365-7.305 3.999-12.892 4.18v-.314c5.38.022 13.599-4.446 15.105-5.69 2.021-1.471 4.248-3.258 5.141-3.434.012.014.02.029.027.043z'
          fill='#a1703a'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-2.508 1.313-6.95 3.8-12.257 4.149v-.3c5.369-.301 13.022-4.478 14.47-5.674 2.021-1.471 4.248-3.258 5.141-3.434.012.015.02.03.027.044z'
          fill='#9e6e3a'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-2.406 1.26-6.596 3.602-11.622 4.097v-.3c5.319-.568 12.444-4.472 13.834-5.622 2.021-1.471 4.248-3.258 5.141-3.434a.2.2 0 0 1 .028.044z'
          fill='#9c6b39'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-2.305 1.207-6.244 3.405-10.986 4.024v-.31c5.235-.789 11.863-4.436 13.199-5.54 2.021-1.471 4.248-3.258 5.141-3.434.012.016.02.031.027.045z'
          fill='#9a6b39'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-2.201 1.152-5.893 3.209-10.351 3.931v-.325c5.122-.969 11.281-4.371 12.564-5.431 2.021-1.471 4.248-3.258 5.141-3.434.012.015.02.03.027.044z'
          fill='#9a6b39'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-2.096 1.097-5.542 3.015-9.716 3.818v-.344c4.984-1.113 10.698-4.283 11.929-5.3 2.021-1.471 4.248-3.258 5.141-3.434.012.016.02.031.027.045z'
          fill='#9a6b39'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-1.988 1.041-5.193 2.82-9.08 3.687v-.364c4.824-1.225 10.115-4.174 11.293-5.148 2.021-1.471 4.248-3.258 5.141-3.434.012.015.02.03.027.044z'
          fill='#9a6b39'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-1.879.984-4.844 2.627-8.445 3.536v-.383c4.645-1.309 9.531-4.047 10.658-4.978 2.021-1.471 4.248-3.258 5.141-3.434.012.015.02.03.027.044z'
          fill='#9a6b39'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-1.768.925-4.496 2.434-7.81 3.366v-.4c4.445-1.367 8.947-3.902 10.023-4.791 2.021-1.471 4.248-3.258 5.141-3.434.012.015.02.03.027.044z'
          fill='#9a6b39'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-1.652.866-4.147 2.242-7.175 3.179v-.416c4.231-1.402 8.364-3.742 9.388-4.588 2.021-1.471 4.248-3.258 5.141-3.434.012.015.02.03.027.044z'
          fill='#9a6b39'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-1.535.804-3.797 2.049-6.54 2.973v-.428c4.002-1.416 7.78-3.567 8.752-4.371 2.021-1.471 4.248-3.258 5.141-3.434a.232.232 0 0 1 .028.045z'
          fill='#9a6b39'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-1.416.741-3.447 1.856-5.904 2.75v-.436c3.762-1.408 7.195-3.378 8.117-4.139 2.021-1.471 4.248-3.258 5.141-3.434.012.015.02.03.027.044z'
          fill='#9a6b39'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-1.291.676-3.098 1.664-5.269 2.511v-.442c3.505-1.383 6.613-3.177 7.482-3.895 2.021-1.471 4.248-3.258 5.141-3.434.012.016.02.031.027.045z'
          fill='#9a6b39'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-1.164.609-2.744 1.471-4.634 2.255v-.443c3.241-1.341 6.03-2.963 6.847-3.638 2.021-1.471 4.248-3.258 5.141-3.434.012.016.02.031.027.045z'
          fill='#9a6b39'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-1.031.54-2.389 1.278-3.998 1.984v-.44c2.965-1.282 5.449-2.739 6.211-3.369 2.021-1.471 4.248-3.258 5.141-3.434.012.015.02.03.027.044z'
          fill='#9a6b39'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-.893.467-2.029 1.083-3.363 1.698v-.435c2.68-1.208 4.869-2.504 5.576-3.088 2.021-1.471 4.248-3.258 5.141-3.434.012.015.02.03.027.044z'
          fill='#9a6b39'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-.746.391-1.666.886-2.729 1.398v-.427c2.387-1.119 4.291-2.259 4.941-2.796 2.021-1.471 4.248-3.258 5.141-3.434a.2.2 0 0 1 .028.044z'
          fill='#9a6b39'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-.594.311-1.297.688-2.092 1.086v-.419c2.084-1.017 3.713-2.004 4.305-2.493 2.021-1.471 4.248-3.258 5.141-3.434.012.016.02.031.027.045z'
          fill='#9a6b39'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189-.43.225-.918.486-1.457.764v-.412c1.777-.901 3.141-1.739 3.67-2.177 2.021-1.471 4.248-3.258 5.141-3.434.012.015.02.03.027.044z'
          fill='#9a6b39'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189l-.822.433v-.41c1.465-.772 2.57-1.464 3.035-1.848 2.021-1.471 4.248-3.258 5.141-3.434.012.015.02.03.027.044z'
          fill='#9a6b39'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.746 1.15-2.6 1.855-4.961 3.189l-.188.099v-.417c1.146-.631 2.006-1.18 2.4-1.506 2.021-1.471 4.248-3.258 5.141-3.434a.175.175 0 0 1 .028.043z'
          fill='#9a6b39'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.631 1.074-2.482 1.76-4.514 2.933v-.421c.828-.478 1.447-.885 1.766-1.148 2.021-1.471 4.248-3.258 5.141-3.434.012.015.02.03.027.044z'
          fill='#9a6b39'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.449.955-2.285 1.604-3.877 2.56v-.425c.51-.313.898-.58 1.129-.77 2.021-1.471 4.248-3.258 5.141-3.434.012.014.02.029.027.043z'
          fill='#9a6b39'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.244.819-2.035 1.414-3.242 2.17v-.443c.205-.139.371-.261.494-.362 2.021-1.471 4.248-3.258 5.141-3.434.012.014.02.029.027.043z'
          fill='#9a6b39'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-1.014.667-1.727 1.185-2.607 1.762v-.501c1.984-1.45 4.129-3.159 5-3.331.012.015.02.03.027.044z'
          fill='#9a6b39'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-.76.5-1.352.917-1.971 1.338v-.545c1.789-1.327 3.584-2.709 4.363-2.862a.175.175 0 0 1 .028.043z'
          fill='#9a6b39'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-.5.329-.928.622-1.336.903v-.583c1.564-1.166 3.043-2.256 3.729-2.391.012.016.02.031.027.045z'
          fill='#9a6b39'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-.25.164-.48.32-.701.469v-.62c1.32-.974 2.502-1.802 3.094-1.919.012.015.02.03.027.044z'
          fill='#9a6b39'
        />
        <path
          d='M108.445 55.976v.345c-.234.345-1.199.648-2.42 1.681-.021.014-.045.028-.066.043v-.658c1.057-.762 1.965-1.357 2.459-1.455.012.015.02.03.027.044z'
          fill='#9a6b39'
        />
        <path
          d='M108.445 55.976v.345c-.195.289-.906.549-1.85 1.234v-.619c.777-.538 1.43-.928 1.822-1.005a.232.232 0 0 1 .028.045z'
          fill='#9a6b39'
        />
        <path
          d='M108.445 55.976v.345c-.148.219-.596.423-1.215.808v-.614c.496-.313.908-.528 1.188-.583.012.015.02.03.027.044z'
          fill='#9a6b39'
        />
        <path
          d='M108.445 55.976v.345c-.092.134-.295.263-.58.431v-.605c.217-.11.404-.186.553-.215.012.015.02.03.027.044z'
          fill='#9a6b39'
        />
        <path
          d='M76.714 60.684c.583-.003 1.938 1.028 3.337 1.973 2.052 1.559 4.958 2.208 7.196 2.366 5.372.596 14.567-4.388 16.051-5.663 2.053-1.492 4.232-3.233 5.109-3.418.391.605-.795.68-2.441 2.088-1.738 1.137-2.537 1.812-4.865 3.126-2.691 1.409-7.695 4.219-13.581 4.213-3.27-.356-5.182-.823-6.162-1.429-1.636-.994-3.606-2.055-4.644-3.256z'
          fill='#c1903a'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-2.691 1.409-7.695 4.219-13.581 4.213-3.27-.356-5.182-.823-6.162-1.429-1.638-.994-3.607-2.055-4.645-3.256.583-.003 1.938 1.028 3.337 1.973 2.052 1.559 4.958 2.208 7.196 2.366 5.372.596 14.567-4.388 16.051-5.663 2.053-1.492 4.232-3.233 5.109-3.418.007.008.011.016.015.023z'
          fill='#c1903a'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-2.691 1.409-7.695 4.219-13.581 4.213-3.27-.356-5.182-.823-6.162-1.429-1.346-.818-2.917-1.679-4.009-2.628v-.421c.69.347 1.684 1.08 2.701 1.766 2.052 1.559 4.958 2.208 7.196 2.366 5.372.596 14.567-4.388 16.051-5.663 2.053-1.492 4.232-3.233 5.109-3.418.007.008.011.016.015.023z'
          fill='#be8e3a'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-2.691 1.409-7.695 4.219-13.581 4.213-3.27-.356-5.182-.823-6.162-1.429-1.103-.669-2.356-1.369-3.374-2.122v-.561c.606.382 1.33.902 2.066 1.399 2.052 1.559 4.958 2.208 7.196 2.366 5.372.596 14.567-4.388 16.051-5.663 2.053-1.492 4.232-3.233 5.109-3.418.007.009.011.017.015.024z'
          fill='#be8b3a'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-2.691 1.409-7.695 4.219-13.581 4.213-3.27-.356-5.182-.823-6.162-1.429-.883-.536-1.861-1.091-2.739-1.677v-.588c.45.306.938.648 1.431.981 2.052 1.559 4.958 2.208 7.196 2.366 5.372.596 14.567-4.388 16.051-5.663 2.053-1.492 4.232-3.233 5.109-3.418.007.009.011.017.015.024z'
          fill='#bc893a'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-2.691 1.409-7.695 4.219-13.581 4.213-3.27-.356-5.182-.823-6.162-1.429-.674-.41-1.405-.83-2.103-1.267v-.562c.26.18.526.364.795.545 2.052 1.559 4.958 2.208 7.196 2.366 5.372.596 14.567-4.388 16.051-5.663 2.053-1.492 4.232-3.233 5.109-3.418.007.009.011.017.015.024z'
          fill='#ba8739'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-2.691 1.409-7.695 4.219-13.581 4.213-3.27-.356-5.182-.823-6.162-1.429-.472-.287-.971-.579-1.468-.877v-.515l.16.108c2.052 1.559 4.958 2.208 7.196 2.366 5.372.596 14.567-4.388 16.051-5.663 2.053-1.492 4.232-3.233 5.109-3.418.007.009.011.017.015.024z'
          fill='#b88539'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-2.691 1.409-7.695 4.219-13.581 4.213-3.27-.356-5.182-.823-6.162-1.429-.271-.165-.549-.33-.833-.498v-.45c2.008 1.317 4.651 1.884 6.721 2.03 5.372.596 14.567-4.388 16.051-5.663 2.053-1.492 4.232-3.233 5.109-3.418.007.009.011.017.015.024z'
          fill='#b6833a'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-2.691 1.409-7.695 4.219-13.581 4.213-3.27-.356-5.182-.823-6.162-1.429l-.197-.119v-.448c1.908 1.048 4.227 1.518 6.085 1.649 5.372.596 14.567-4.388 16.051-5.663 2.053-1.492 4.232-3.233 5.109-3.418.007.009.011.017.015.024z'
          fill='#b4823a'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-2.691 1.409-7.695 4.219-13.581 4.213-2.838-.309-4.653-.702-5.724-1.196v-.479c1.771.825 3.794 1.211 5.45 1.328 5.372.596 14.567-4.388 16.051-5.663 2.053-1.492 4.232-3.233 5.109-3.418.007.009.011.017.015.024z'
          fill='#b2803a'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-2.691 1.409-7.695 4.219-13.581 4.213-2.339-.255-3.983-.566-5.088-.944v-.457c1.607.639 3.353.951 4.814 1.055 5.372.596 14.567-4.388 16.051-5.663 2.053-1.492 4.232-3.233 5.109-3.418.007.008.011.016.015.023z'
          fill='#b07e3a'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-2.691 1.409-7.695 4.219-13.581 4.213-1.927-.21-3.382-.458-4.453-.751V64.2c1.423.483 2.909.731 4.18.821 5.372.596 14.567-4.388 16.051-5.663 2.053-1.492 4.232-3.233 5.109-3.418l.014.024z'
          fill='#ad7b3a'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-2.691 1.409-7.695 4.219-13.581 4.213-1.57-.171-2.827-.367-3.817-.593V64.4c1.223.354 2.461.544 3.544.621 5.372.596 14.567-4.388 16.051-5.663 2.053-1.492 4.232-3.233 5.109-3.418l.014.024z'
          fill='#ab793a'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-2.691 1.409-7.695 4.219-13.581 4.213a39.242 39.242 0 0 1-3.182-.459v-.34c1.012.247 2.014.389 2.909.452 5.372.596 14.567-4.388 16.051-5.663 2.053-1.492 4.232-3.233 5.109-3.418l.014.024z'
          fill='#a77539'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-2.691 1.409-7.695 4.219-13.581 4.213a44.934 44.934 0 0 1-2.547-.344v-.312c.792.161 1.566.26 2.273.31 5.372.596 14.567-4.388 16.051-5.663 2.053-1.492 4.232-3.233 5.109-3.418.007.008.011.016.015.023z'
          fill='#a57239'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-2.691 1.409-7.695 4.219-13.581 4.213a47.392 47.392 0 0 1-1.911-.243v-.297c.569.094 1.121.157 1.638.193 5.372.596 14.567-4.388 16.051-5.663 2.053-1.492 4.232-3.233 5.109-3.418l.014.024z'
          fill='#a37039'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-2.691 1.409-7.695 4.219-13.581 4.213a55.43 55.43 0 0 1-1.276-.153v-.293c.345.044.68.077 1.002.1 5.372.596 14.567-4.388 16.051-5.663 2.053-1.492 4.232-3.233 5.109-3.418.007.008.011.016.015.023z'
          fill='#a16f39'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-2.691 1.409-7.695 4.219-13.581 4.213a58.99 58.99 0 0 1-.641-.073v-.303c.124.011.247.021.367.029 5.372.596 14.567-4.388 16.051-5.663 2.053-1.492 4.232-3.233 5.109-3.418.007.009.011.017.015.024z'
          fill='#9f6d39'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-2.691 1.409-7.695 4.219-13.581 4.213h-.005v-.321c5.405.408 14.324-4.434 15.783-5.688 2.053-1.492 4.232-3.233 5.109-3.418l.014.023z'
          fill='#9a6a39'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-2.596 1.358-7.339 4.019-12.95 4.204v-.289c5.441.018 13.743-4.505 15.147-5.711 2.053-1.492 4.232-3.233 5.109-3.418l.014.023z'
          fill='#9a6838'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-2.496 1.307-6.982 3.819-12.315 4.172v-.274c5.43-.305 13.163-4.534 14.512-5.694 2.053-1.492 4.232-3.233 5.109-3.418l.014.023z'
          fill='#986738'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-2.396 1.254-6.626 3.621-11.68 4.12v-.273c5.379-.574 12.578-4.527 13.877-5.643 2.053-1.492 4.232-3.233 5.109-3.418l.014.023z'
          fill='#966538'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-2.295 1.202-6.273 3.422-11.044 4.046v-.281c5.295-.794 11.994-4.488 13.242-5.561 2.053-1.492 4.232-3.233 5.109-3.418l.013.023z'
          fill='#956539'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-2.193 1.148-5.92 3.226-10.409 3.954v-.297c5.182-.976 11.409-4.423 12.606-5.453 2.053-1.492 4.232-3.233 5.109-3.418l.014.023z'
          fill='#956539'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-2.088 1.093-5.569 3.03-9.773 3.84v-.314c5.043-1.121 10.82-4.334 11.971-5.322 2.053-1.492 4.232-3.233 5.109-3.418l.013.023z'
          fill='#956539'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-1.982 1.038-5.219 2.835-9.138 3.708v-.333c4.883-1.234 10.234-4.224 11.335-5.171 2.053-1.492 4.232-3.233 5.109-3.418l.014.023z'
          fill='#956539'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-1.873.981-4.87 2.642-8.503 3.558v-.352c4.702-1.32 9.646-4.096 10.7-5.002 2.053-1.492 4.232-3.233 5.109-3.418l.014.023z'
          fill='#956539'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-1.764.923-4.52 2.449-7.868 3.388v-.369c4.504-1.379 9.059-3.95 10.065-4.815 2.053-1.492 4.232-3.233 5.109-3.418l.014.023z'
          fill='#956539'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-1.65.864-4.171 2.256-7.232 3.201v-.384c4.289-1.415 8.471-3.789 9.43-4.613 2.053-1.492 4.232-3.233 5.109-3.418l.013.023z'
          fill='#956539'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-1.535.804-3.822 2.063-6.597 2.996v-.396c4.06-1.429 7.882-3.613 8.794-4.396 2.053-1.492 4.232-3.233 5.109-3.418l.014.023z'
          fill='#956539'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-1.416.742-3.473 1.871-5.961 2.774v-.404c3.817-1.424 7.295-3.424 8.159-4.167 2.053-1.492 4.232-3.233 5.109-3.418l.013.024z'
          fill='#956539'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-1.293.677-3.121 1.679-5.326 2.535v-.409c3.56-1.4 6.707-3.222 7.523-3.923 2.053-1.492 4.232-3.233 5.109-3.418l.014.024z'
          fill='#956539'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-1.166.611-2.77 1.486-4.691 2.28v-.41c3.294-1.358 6.121-3.008 6.888-3.667 2.053-1.492 4.232-3.233 5.109-3.418l.014.024z'
          fill='#956539'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-1.035.542-2.416 1.293-4.055 2.01v-.407c3.016-1.3 5.535-2.783 6.252-3.399 2.053-1.492 4.232-3.233 5.109-3.418l.014.023z'
          fill='#956539'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-.898.471-2.057 1.098-3.42 1.725v-.402c2.73-1.227 4.951-2.546 5.617-3.119 2.053-1.492 4.232-3.233 5.109-3.418l.014.023z'
          fill='#956539'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-.756.396-1.695.902-2.785 1.426v-.395c2.436-1.139 4.369-2.301 4.982-2.828 2.053-1.492 4.232-3.233 5.109-3.418l.014.024z'
          fill='#956539'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-.605.316-1.326.705-2.15 1.115v-.386c2.133-1.037 3.789-2.045 4.348-2.525 2.053-1.492 4.232-3.233 5.109-3.418l.013.023z'
          fill='#956539'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-.443.232-.951.503-1.514.792v-.379c1.822-.921 3.209-1.778 3.711-2.21 2.053-1.492 4.232-3.233 5.109-3.418l.014.024z'
          fill='#956539'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126-.27.141-.562.297-.879.462v-.376c1.506-.792 2.635-1.502 3.076-1.882 2.053-1.492 4.232-3.233 5.109-3.418l.014.023z'
          fill='#956539'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.738 1.137-2.537 1.812-4.865 3.126l-.244.128V60.9c1.186-.651 2.064-1.216 2.441-1.541 2.053-1.492 4.232-3.233 5.109-3.418l.014.023z'
          fill='#956539'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.637 1.071-2.439 1.732-4.473 2.903v-.39c.861-.498 1.498-.919 1.805-1.183 2.053-1.492 4.232-3.233 5.109-3.418l.014.023z'
          fill='#956539'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.455.952-2.252 1.58-3.838 2.53v-.395c.541-.332.945-.611 1.17-.805 2.053-1.492 4.232-3.233 5.109-3.418l.014.023z'
          fill='#956539'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.246.816-2.01 1.394-3.201 2.141v-.414c.227-.155.408-.29.533-.397 2.053-1.492 4.232-3.233 5.109-3.418l.014.023z'
          fill='#956539'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-1.012.662-1.705 1.168-2.566 1.733v-.477c2.023-1.475 4.145-3.163 5.008-3.345l.013.024z'
          fill='#956539'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-.754.493-1.33.899-1.932 1.308v-.518c1.818-1.344 3.6-2.715 4.373-2.878l.014.023z'
          fill='#956539'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-.488.319-.902.602-1.297.873v-.554c1.586-1.177 3.055-2.262 3.738-2.407l.014.023z'
          fill='#956539'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-.234.154-.453.3-.66.439v-.591c1.332-.983 2.512-1.812 3.102-1.937l.013.024z'
          fill='#956539'
        />
        <path
          d='M108.422 55.964v.361c-.246.342-1.221.648-2.455 1.704-.008.005-.018.011-.025.017v-.633c1.064-.769 1.973-1.368 2.467-1.472l.013.023z'
          fill='#956539'
        />
        <path
          d='M108.422 55.964v.361c-.203.283-.908.542-1.846 1.223v-.585c.785-.544 1.439-.938 1.832-1.022l.014.023z'
          fill='#956539'
        />
        <path
          d='M108.422 55.964v.361c-.154.215-.598.416-1.209.797v-.583c.5-.319.914-.538 1.195-.598l.014.023z'
          fill='#956539'
        />
        <path
          d='M108.422 55.964v.361c-.094.13-.295.256-.574.42v-.579c.221-.115.41-.193.561-.225l.013.023z'
          fill='#956539'
        />
        <path
          d='M76.692 60.674c.558-.047 2.001 1.046 3.401 1.993 2.041 1.541 4.877 2.208 7.136 2.372 5.436.588 14.716-4.451 16.091-5.686 2.086-1.512 4.215-3.208 5.078-3.403.371.598-.828.669-2.49 2.107-1.729 1.124-2.475 1.769-4.77 3.062-2.668 1.397-7.695 4.22-13.575 4.238-3.278-.35-5.317-.847-6.233-1.426-1.67-1.004-3.595-2.044-4.638-3.257z'
          fill='#be8b3a'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-2.668 1.397-7.695 4.22-13.575 4.238-3.278-.35-5.317-.847-6.233-1.426-1.67-1.005-3.595-2.045-4.638-3.258.558-.047 2.001 1.046 3.401 1.993 2.041 1.541 4.877 2.208 7.136 2.372 5.436.588 14.716-4.451 16.091-5.686 2.086-1.512 4.215-3.208 5.078-3.403v.002z'
          fill='#be8b3a'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-2.668 1.397-7.695 4.22-13.575 4.238-3.278-.35-5.317-.847-6.233-1.426-1.373-.826-2.917-1.675-4.003-2.625v-.443c.707.349 1.748 1.114 2.766 1.803 2.041 1.541 4.877 2.208 7.136 2.372 5.436.588 14.716-4.451 16.091-5.686 2.086-1.512 4.215-3.208 5.078-3.403v.002z'
          fill='#bc893a'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-2.668 1.397-7.695 4.22-13.575 4.238-3.278-.35-5.317-.847-6.233-1.426-1.123-.675-2.36-1.367-3.368-2.116v-.59c.629.393 1.386.937 2.13 1.441 2.041 1.541 4.877 2.208 7.136 2.372 5.436.588 14.716-4.451 16.091-5.686 2.086-1.512 4.215-3.208 5.078-3.403l.001.002z'
          fill='#ba873a'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-2.668 1.397-7.695 4.22-13.575 4.238-3.278-.35-5.317-.847-6.233-1.426-.896-.539-1.865-1.088-2.732-1.668v-.622c.475.32.987.682 1.495 1.025 2.041 1.541 4.877 2.208 7.136 2.372 5.436.588 14.716-4.451 16.091-5.686 2.086-1.512 4.215-3.208 5.078-3.403v.002z'
          fill='#b78439'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-2.668 1.397-7.695 4.22-13.575 4.238-3.278-.35-5.317-.847-6.233-1.426-.682-.411-1.406-.827-2.096-1.258v-.597c.283.195.571.396.859.59 2.041 1.541 4.877 2.208 7.136 2.372 5.436.588 14.716-4.451 16.091-5.686 2.086-1.512 4.215-3.208 5.078-3.403v.002z'
          fill='#b58239'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-2.668 1.397-7.695 4.22-13.575 4.238-3.278-.35-5.317-.847-6.233-1.426-.475-.286-.971-.575-1.461-.869v-.548l.224.152c2.041 1.541 4.877 2.208 7.136 2.372 5.436.588 14.716-4.451 16.091-5.686 2.086-1.512 4.215-3.208 5.078-3.403v.002z'
          fill='#b3803a'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-2.668 1.397-7.695 4.22-13.575 4.238-3.278-.35-5.317-.847-6.233-1.426-.27-.163-.546-.326-.826-.491v-.482c1.999 1.334 4.614 1.926 6.725 2.08 5.436.588 14.716-4.451 16.091-5.686 2.086-1.512 4.215-3.208 5.078-3.403v.002z'
          fill='#b17e3a'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-2.668 1.397-7.695 4.22-13.575 4.238-3.278-.35-5.317-.847-6.233-1.426a31.808 31.808 0 0 0-.19-.114v-.471c1.896 1.063 4.196 1.554 6.089 1.691 5.436.588 14.716-4.451 16.091-5.686 2.086-1.512 4.215-3.208 5.078-3.403v.003z'
          fill='#af7b3a'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-2.668 1.397-7.695 4.22-13.575 4.238-2.825-.301-4.729-.712-5.788-1.19v-.492c1.759.837 3.768 1.24 5.454 1.363 5.436.588 14.716-4.451 16.091-5.686 2.086-1.512 4.215-3.208 5.078-3.403v.002z'
          fill='#ad793a'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-2.668 1.397-7.695 4.22-13.575 4.238-2.33-.249-4.033-.571-5.153-.945v-.457c1.596.649 3.332.975 4.818 1.083 5.436.588 14.716-4.451 16.091-5.686 2.086-1.512 4.215-3.208 5.078-3.403l.001.002z'
          fill='#a87639'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-2.668 1.397-7.695 4.22-13.575 4.238-1.926-.206-3.423-.461-4.517-.755v-.407a16.62 16.62 0 0 0 4.183.843c5.436.588 14.716-4.451 16.091-5.686 2.086-1.512 4.215-3.208 5.078-3.403v.002z'
          fill='#a57239'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-2.668 1.397-7.695 4.22-13.575 4.238-1.576-.168-2.865-.37-3.881-.599V64.4c1.213.36 2.449.559 3.547.639 5.436.588 14.716-4.451 16.091-5.686 2.086-1.512 4.215-3.208 5.078-3.403v.002z'
          fill='#a37039'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-2.668 1.397-7.695 4.22-13.575 4.238a39.566 39.566 0 0 1-3.246-.466v-.318c1.004.252 2.004.399 2.912.465 5.436.588 14.716-4.451 16.091-5.686 2.086-1.512 4.215-3.208 5.078-3.403v.002z'
          fill='#a16d39'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-2.668 1.397-7.695 4.22-13.575 4.238a42.837 42.837 0 0 1-2.611-.351v-.287c.788.165 1.561.268 2.276.319 5.436.588 14.716-4.451 16.091-5.686 2.086-1.512 4.215-3.208 5.078-3.403l.001.002z'
          fill='#9f6b39'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-2.668 1.397-7.695 4.22-13.575 4.238a47.797 47.797 0 0 1-1.975-.25v-.268c.566.097 1.119.161 1.641.199 5.436.588 14.716-4.451 16.091-5.686 2.086-1.512 4.215-3.208 5.078-3.403v.002z'
          fill='#9b6938'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-2.668 1.397-7.695 4.22-13.575 4.238a55.692 55.692 0 0 1-1.34-.16v-.262c.344.045.681.08 1.005.103 5.436.588 14.716-4.451 16.091-5.686 2.086-1.512 4.215-3.208 5.078-3.403l.001.002z'
          fill='#996738'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-2.668 1.397-7.695 4.22-13.575 4.238a48.59 48.59 0 0 1-.705-.08v-.271c.125.012.248.022.37.031 5.436.588 14.716-4.451 16.091-5.686 2.086-1.512 4.215-3.208 5.078-3.403l.001.003z'
          fill='#986538'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-2.668 1.397-7.695 4.22-13.575 4.238l-.069-.007v-.288c5.467.402 14.474-4.496 15.826-5.71 2.086-1.512 4.215-3.208 5.078-3.403v.002z'
          fill='#966338'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-2.582 1.353-7.373 4.04-13.008 4.228v-.262c5.502.014 13.889-4.564 15.19-5.733 2.086-1.512 4.215-3.208 5.078-3.403v.002z'
          fill='#946238'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-2.484 1.301-7.014 3.838-12.373 4.196v-.248c5.491-.31 13.303-4.591 14.555-5.715 2.086-1.512 4.215-3.208 5.078-3.403v.002z'
          fill='#915e37'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-2.387 1.25-6.657 3.639-11.737 4.143v-.247c5.439-.578 12.714-4.582 13.919-5.664 2.086-1.512 4.215-3.208 5.078-3.403v.003z'
          fill='#8e5d38'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-2.285 1.197-6.301 3.44-11.102 4.07v-.255c5.355-.801 12.125-4.542 13.284-5.583 2.086-1.512 4.215-3.208 5.078-3.403v.003z'
          fill='#8e5c38'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-2.184 1.144-5.948 3.242-10.466 3.977v-.269c5.241-.982 11.535-4.475 12.648-5.475 2.086-1.512 4.215-3.208 5.078-3.403v.002z'
          fill='#8e5c38'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-2.08 1.09-5.596 3.046-9.831 3.864v-.286c5.103-1.128 10.944-4.385 12.013-5.345 2.086-1.512 4.215-3.208 5.078-3.403v.002z'
          fill='#8e5c38'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-1.977 1.035-5.245 2.851-9.196 3.731v-.304c4.941-1.243 10.354-4.275 11.377-5.194 2.086-1.512 4.215-3.208 5.078-3.403l.001.002z'
          fill='#8e5c38'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-1.869.979-4.895 2.657-8.56 3.581v-.322c4.761-1.33 9.761-4.146 10.742-5.026 2.086-1.512 4.215-3.208 5.078-3.403v.002z'
          fill='#8e5c38'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-1.76.922-4.545 2.463-7.924 3.412v-.339c4.561-1.39 9.168-3.999 10.106-4.84 2.086-1.512 4.215-3.208 5.078-3.403v.002z'
          fill='#8e5c38'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-1.648.863-4.196 2.271-7.289 3.224v-.353c4.346-1.427 8.578-3.836 9.471-4.639 2.086-1.512 4.215-3.208 5.078-3.403v.003z'
          fill='#8e5c38'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-1.533.804-3.848 2.078-6.654 3.02v-.364c4.117-1.443 7.986-3.66 8.835-4.423 2.086-1.512 4.215-3.208 5.078-3.403l.001.002z'
          fill='#8e5c38'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-1.416.742-3.498 1.886-6.018 2.798v-.372c3.872-1.438 7.395-3.47 8.2-4.193 2.086-1.512 4.215-3.208 5.078-3.403v.002z'
          fill='#8e5c38'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-1.295.679-3.146 1.693-5.383 2.56v-.376c3.615-1.416 6.803-3.267 7.564-3.951 2.086-1.512 4.215-3.208 5.078-3.403l.001.002z'
          fill='#8e5c38'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-1.17.613-2.795 1.501-4.747 2.306v-.377c3.349-1.375 6.212-3.052 6.929-3.696 2.086-1.512 4.215-3.208 5.078-3.403v.002z'
          fill='#8e5c38'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-1.041.545-2.441 1.308-4.111 2.036v-.375c3.07-1.318 5.623-2.826 6.293-3.429 2.086-1.512 4.215-3.208 5.078-3.403v.003z'
          fill='#8e5c38'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-.906.475-2.084 1.114-3.477 1.752v-.369c2.781-1.246 5.035-2.59 5.658-3.15 2.086-1.512 4.215-3.208 5.078-3.403l.001.002z'
          fill='#8e5c38'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-.764.4-1.723.919-2.842 1.454v-.361c2.486-1.159 4.447-2.343 5.023-2.86 2.086-1.512 4.215-3.208 5.078-3.403l.001.002z'
          fill='#8e5c38'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-.615.322-1.357.721-2.205 1.144v-.354c2.18-1.057 3.861-2.085 4.387-2.557 2.086-1.512 4.215-3.208 5.078-3.403v.002z'
          fill='#8e5c38'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062-.455.239-.982.521-1.57.822v-.346c1.869-.942 3.279-1.818 3.752-2.244 2.086-1.512 4.215-3.208 5.078-3.403v.003z'
          fill='#8e5c38'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062l-.934.492v-.342c1.549-.813 2.697-1.541 3.115-1.917 2.086-1.512 4.215-3.208 5.078-3.403l.001.002z'
          fill='#8e5c38'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.729 1.124-2.475 1.769-4.77 3.062l-.299.158v-.35c1.225-.672 2.121-1.253 2.48-1.575 2.086-1.512 4.215-3.208 5.078-3.403l.001.002z'
          fill='#8e5c38'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.643 1.067-2.396 1.703-4.434 2.872v-.358c.898-.518 1.553-.955 1.846-1.218 2.086-1.512 4.215-3.208 5.078-3.403v.001z'
          fill='#8e5c38'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.459.949-2.217 1.556-3.797 2.5v-.364c.57-.351.99-.644 1.209-.84 2.086-1.512 4.215-3.208 5.078-3.403v.001z'
          fill='#8e5c38'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.248.812-1.984 1.374-3.162 2.111v-.384a5.7 5.7 0 0 0 .574-.432c2.086-1.512 4.215-3.208 5.078-3.403v.002z'
          fill='#8e5c38'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-1.01.656-1.684 1.149-2.525 1.703v-.452c2.064-1.501 4.16-3.165 5.016-3.358l-.001.001z'
          fill='#8e5c38'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-.746.485-1.309.88-1.891 1.277v-.491c1.848-1.362 3.613-2.72 4.381-2.894v.002z'
          fill='#8e5c38'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-.475.309-.877.582-1.256.842v-.526c1.607-1.189 3.066-2.27 3.746-2.423v.001z'
          fill='#8e5c38'
        />
        <path
          d='M108.398 55.952v.378c-.258.339-1.244.649-2.49 1.728-.221.144-.426.279-.619.41v-.564c1.346-.992 2.521-1.82 3.109-1.953v.001z'
          fill='#8e5c38'
        />
        <path
          d='M108.398 55.952v.378c-.258.338-1.236.646-2.475 1.714v-.604c1.074-.776 1.98-1.378 2.475-1.489v.001z'
          fill='#8e5c38'
        />
        <path
          d='M108.398 55.952v.378c-.211.278-.91.536-1.84 1.21v-.553c.793-.55 1.447-.949 1.84-1.038v.003z'
          fill='#8e5c38'
        />
        <path
          d='M108.398 55.952v.378c-.16.21-.598.409-1.203.785v-.553c.504-.324.922-.548 1.203-.611v.001z'
          fill='#8e5c38'
        />
        <path
          d='M108.398 55.952v.378c-.096.126-.293.249-.568.409v-.554c.223-.119.416-.2.568-.234v.001z'
          fill='#8e5c38'
        />
        <path
          d='M76.668 60.666c.533-.091 2.066 1.063 3.466 2.011 2.029 1.525 4.797 2.208 7.075 2.379 5.5.581 14.867-4.516 16.132-5.709 2.117-1.533 4.197-3.182 5.047-3.387.352.591-.859.657-2.539 2.126-1.721 1.112-2.412 1.724-4.672 3-2.646 1.385-7.697 4.22-13.571 4.261-3.288-.342-5.454-.868-6.306-1.422-1.702-1.017-3.583-2.036-4.632-3.259z'
          fill='#ba873a'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-2.646 1.385-7.697 4.22-13.571 4.261-3.288-.342-5.454-.868-6.306-1.422-1.703-1.016-3.584-2.036-4.633-3.259.533-.091 2.066 1.063 3.466 2.011 2.029 1.525 4.797 2.208 7.075 2.379 5.5.581 14.867-4.516 16.132-5.709 2.118-1.534 4.198-3.182 5.048-3.387z'
          fill='#ba873a'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-2.646 1.385-7.697 4.22-13.571 4.261-3.288-.342-5.454-.868-6.306-1.422-1.399-.835-2.918-1.671-3.998-2.622v-.463c.723.349 1.812 1.148 2.831 1.837 2.029 1.525 4.797 2.208 7.075 2.379 5.5.581 14.867-4.516 16.132-5.709 2.118-1.534 4.198-3.182 5.048-3.387z'
          fill='#b98439'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-2.646 1.385-7.697 4.22-13.571 4.261-3.288-.342-5.454-.868-6.306-1.422-1.143-.682-2.364-1.364-3.362-2.109v-.619c.653.404 1.443.972 2.195 1.481 2.029 1.525 4.797 2.208 7.075 2.379 5.5.581 14.867-4.516 16.132-5.709 2.118-1.535 4.198-3.183 5.048-3.388z'
          fill='#b58039'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-2.646 1.385-7.697 4.22-13.571 4.261-3.288-.342-5.454-.868-6.306-1.422-.91-.542-1.869-1.086-2.727-1.661v-.656c.5.335 1.039.716 1.56 1.069 2.029 1.525 4.797 2.208 7.075 2.379 5.5.581 14.867-4.516 16.132-5.709 2.118-1.534 4.198-3.182 5.048-3.387z'
          fill='#b37f39'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-2.646 1.385-7.697 4.22-13.571 4.261-3.288-.342-5.454-.868-6.306-1.422-.69-.412-1.409-.824-2.091-1.25v-.632c.307.211.618.428.924.634 2.029 1.525 4.797 2.208 7.075 2.379 5.5.581 14.867-4.516 16.132-5.709 2.118-1.534 4.198-3.182 5.048-3.387z'
          fill='#b07b39'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-2.646 1.385-7.697 4.22-13.571 4.261-3.288-.342-5.454-.868-6.306-1.422-.479-.286-.971-.571-1.455-.861v-.582l.288.196c2.029 1.525 4.797 2.208 7.075 2.379 5.5.581 14.867-4.516 16.132-5.709 2.118-1.535 4.198-3.183 5.048-3.388z'
          fill='#ae7939'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-2.646 1.385-7.697 4.22-13.571 4.261-3.288-.342-5.454-.868-6.306-1.422-.271-.162-.544-.322-.819-.484v-.515c1.99 1.352 4.577 1.97 6.728 2.131 5.5.581 14.867-4.516 16.132-5.709 2.117-1.535 4.197-3.183 5.047-3.388z'
          fill='#ad783a'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-2.646 1.385-7.697 4.22-13.571 4.261-3.288-.342-5.454-.868-6.306-1.422l-.184-.109v-.493c1.885 1.079 4.164 1.589 6.092 1.734 5.5.581 14.867-4.516 16.132-5.709 2.118-1.535 4.198-3.183 5.048-3.388z'
          fill='#a87439'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-2.646 1.385-7.697 4.22-13.571 4.261-2.812-.293-4.804-.72-5.854-1.184v-.505c1.747.851 3.741 1.27 5.457 1.398 5.5.581 14.867-4.516 16.132-5.709 2.117-1.534 4.197-3.182 5.047-3.387z'
          fill='#a47139'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-2.646 1.385-7.697 4.22-13.571 4.261-2.321-.242-4.083-.575-5.218-.944v-.458c1.583.66 3.31.998 4.821 1.111 5.5.581 14.867-4.516 16.132-5.709 2.117-1.534 4.197-3.182 5.047-3.387z'
          fill='#a26f39'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-2.646 1.385-7.697 4.22-13.571 4.261-1.926-.2-3.465-.463-4.583-.759v-.398c1.401.5 2.874.768 4.186.866 5.5.581 14.867-4.516 16.132-5.709 2.117-1.534 4.197-3.182 5.047-3.387z'
          fill='#a16d39'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-2.646 1.385-7.697 4.22-13.571 4.261-1.583-.165-2.905-.372-3.947-.604V64.4c1.205.367 2.436.573 3.549.656 5.5.581 14.867-4.516 16.132-5.709 2.118-1.534 4.198-3.182 5.048-3.387z'
          fill='#9f6a38'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-2.646 1.385-7.697 4.22-13.571 4.261a38.653 38.653 0 0 1-3.312-.473v-.296c.997.257 1.995.409 2.914.478 5.5.581 14.867-4.516 16.132-5.709 2.118-1.534 4.198-3.182 5.048-3.387z'
          fill='#9b6738'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-2.646 1.385-7.697 4.22-13.571 4.261a42.658 42.658 0 0 1-2.676-.357v-.261a16.94 16.94 0 0 0 2.278.328c5.5.581 14.867-4.516 16.132-5.709 2.118-1.535 4.198-3.183 5.048-3.388z'
          fill='#996538'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-2.646 1.385-7.697 4.22-13.571 4.261a45.111 45.111 0 0 1-2.04-.256v-.239c.564.098 1.116.165 1.643.204 5.5.581 14.867-4.516 16.132-5.709 2.117-1.534 4.197-3.182 5.047-3.387z'
          fill='#976238'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-2.646 1.385-7.697 4.22-13.571 4.261a47.388 47.388 0 0 1-1.404-.166v-.231c.343.046.68.082 1.007.106 5.5.581 14.867-4.516 16.132-5.709 2.117-1.534 4.197-3.182 5.047-3.387z'
          fill='#966238'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-2.646 1.385-7.697 4.22-13.571 4.261-.264-.027-.52-.056-.769-.085v-.237c.125.012.25.022.372.031 5.5.581 14.867-4.516 16.132-5.709 2.117-1.534 4.197-3.182 5.047-3.387z'
          fill='#915e37'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-2.646 1.385-7.697 4.22-13.571 4.261l-.133-.014v-.254c5.53.397 14.622-4.558 15.868-5.732 2.117-1.534 4.197-3.182 5.047-3.387z'
          fill='#8f5c37'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-2.572 1.346-7.41 4.059-13.068 4.251v-.235c5.564.009 14.033-4.624 15.232-5.754 2.117-1.535 4.197-3.183 5.047-3.388z'
          fill='#8c5a37'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-2.475 1.295-7.048 3.857-12.433 4.219v-.222c5.552-.314 13.443-4.648 14.597-5.736 2.117-1.534 4.197-3.182 5.047-3.387z'
          fill='#8b5838'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-2.377 1.244-6.689 3.656-11.797 4.166v-.22c5.5-.584 12.85-4.637 13.961-5.685 2.117-1.534 4.197-3.182 5.047-3.387z'
          fill='#885437'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-2.277 1.192-6.333 3.457-11.162 4.092v-.228c5.415-.806 12.257-4.596 13.326-5.604 2.117-1.533 4.197-3.181 5.047-3.386z'
          fill='#865437'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3C99 62.225 95.2 64.344 90.652 65.084v-.24c5.301-.989 11.663-4.528 12.69-5.497 2.117-1.534 4.197-3.182 5.047-3.387z'
          fill='#865437'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-2.074 1.086-5.625 3.062-9.891 3.886v-.257c5.162-1.137 11.068-4.438 12.055-5.367 2.117-1.535 4.197-3.183 5.047-3.388z'
          fill='#865437'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-1.971 1.032-5.272 2.866-9.255 3.753v-.275c5-1.252 10.474-4.325 11.419-5.217 2.117-1.534 4.197-3.182 5.047-3.387z'
          fill='#865437'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-1.865.976-4.921 2.671-8.62 3.603v-.292c4.819-1.34 9.877-4.195 10.784-5.05 2.117-1.534 4.197-3.182 5.047-3.387z'
          fill='#865437'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-1.758.919-4.572 2.478-7.984 3.434v-.308c4.621-1.401 9.283-4.047 10.148-4.864 2.117-1.535 4.197-3.183 5.047-3.388z'
          fill='#865437'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-1.646.862-4.222 2.285-7.348 3.247v-.321c4.403-1.44 8.686-3.885 9.512-4.664 2.117-1.535 4.197-3.183 5.047-3.388z'
          fill='#865437'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-1.533.802-3.873 2.092-6.712 3.042v-.333c4.173-1.457 8.089-3.707 8.876-4.449 2.117-1.533 4.197-3.181 5.047-3.386z'
          fill='#865437'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-1.418.742-3.523 1.9-6.077 2.821v-.339c3.928-1.454 7.495-3.517 8.241-4.221 2.117-1.534 4.197-3.182 5.047-3.387z'
          fill='#865437'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-1.299.679-3.174 1.708-5.441 2.583v-.343c3.672-1.432 6.9-3.313 7.605-3.979 2.117-1.534 4.197-3.182 5.047-3.387z'
          fill='#865437'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-1.176.615-2.822 1.515-4.806 2.33v-.344c3.403-1.393 6.304-3.098 6.97-3.725 2.117-1.534 4.197-3.182 5.047-3.387z'
          fill='#865437'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-1.047.548-2.469 1.322-4.17 2.061v-.342c3.123-1.336 5.711-2.871 6.334-3.458 2.117-1.534 4.197-3.182 5.047-3.387z'
          fill='#865437'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-.914.478-2.113 1.129-3.535 1.778v-.336c2.834-1.265 5.119-2.633 5.699-3.181 2.117-1.534 4.197-3.182 5.047-3.387z'
          fill='#865437'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-.773.405-1.754.934-2.898 1.481v-.329c2.535-1.178 4.525-2.385 5.062-2.891 2.117-1.534 4.197-3.182 5.047-3.387z'
          fill='#865437'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-.627.328-1.389.737-2.264 1.171v-.321c2.229-1.078 3.938-2.127 4.428-2.589 2.117-1.534 4.197-3.182 5.047-3.387z'
          fill='#865437'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3-.471.246-1.016.538-1.627.851v-.313c1.912-.963 3.348-1.858 3.791-2.276 2.117-1.535 4.197-3.183 5.047-3.388z'
          fill='#865437'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3l-.992.521v-.311c1.592-.834 2.764-1.58 3.156-1.95 2.117-1.533 4.197-3.181 5.047-3.386z'
          fill='#865437'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.721 1.112-2.412 1.724-4.672 3l-.355.187v-.316c1.266-.693 2.182-1.291 2.52-1.609 2.116-1.535 4.196-3.183 5.046-3.388z'
          fill='#865437'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.646 1.064-2.352 1.671-4.393 2.84v-.327c.936-.538 1.605-.99 1.885-1.252 2.117-1.534 4.197-3.182 5.047-3.387z'
          fill='#865437'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.463.946-2.184 1.53-3.756 2.469v-.333c.602-.37 1.037-.677 1.248-.875 2.117-1.534 4.197-3.182 5.047-3.387z'
          fill='#865437'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.252.809-1.959 1.354-3.121 2.081v-.354c.277-.188.486-.347.613-.467 2.117-1.533 4.197-3.181 5.047-3.386z'
          fill='#865437'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-1.008.651-1.662 1.131-2.486 1.673v-.429c2.111-1.529 4.177-3.166 5.025-3.37z'
          fill='#865437'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-.738.477-1.287.862-1.85 1.246v-.466c1.879-1.38 3.627-2.722 4.389-2.906z'
          fill='#865437'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-.463.298-.852.562-1.215.811v-.499c1.629-1.203 3.078-2.275 3.754-2.438z'
          fill='#865437'
        />
        <path
          d='M108.389 55.96v.358c-.252.343-1.26.648-2.539 1.768-.207.133-.398.259-.578.38v-.537c1.361-1.002 2.531-1.828 3.117-1.969z'
          fill='#865437'
        />
        <path
          d='M108.389 55.96v.358c-.248.338-1.23.639-2.482 1.718v-.571c1.085-.783 1.989-1.386 2.482-1.505z'
          fill='#865437'
        />
        <path
          d='M108.389 55.96v.358c-.205.278-.906.532-1.848 1.214v-.519c.801-.556 1.455-.958 1.848-1.053z'
          fill='#865437'
        />
        <path
          d='M108.389 55.96v.358c-.156.211-.598.408-1.211.789v-.522c.51-.329.927-.557 1.211-.625z'
          fill='#865437'
        />
        <path
          d='M108.389 55.96v.358c-.096.129-.295.252-.576.414v-.528c.228-.122.421-.207.576-.244z'
          fill='#865437'
        />
        <path
          d='M76.645 60.656c.51-.134 2.13 1.081 3.53 2.031 2.019 1.509 4.717 2.208 7.015 2.385 5.563.573 15.019-4.58 16.173-5.732 2.148-1.554 4.178-3.156 5.014-3.371.334.583-.891.645-2.586 2.145-1.711 1.099-2.35 1.681-4.576 2.936-2.625 1.374-7.697 4.221-13.565 4.286-3.297-.336-5.589-.891-6.378-1.42-1.736-1.027-3.572-2.025-4.627-3.26z'
          fill='#b68139'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-2.625 1.374-7.697 4.221-13.565 4.286-3.297-.336-5.589-.891-6.378-1.42-1.736-1.027-3.572-2.025-4.627-3.26.51-.134 2.13 1.081 3.53 2.031 2.019 1.509 4.717 2.208 7.015 2.385 5.563.573 15.019-4.58 16.173-5.732 2.149-1.555 4.178-3.157 5.014-3.371z'
          fill='#b68139'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-2.625 1.374-7.697 4.221-13.565 4.286-3.297-.336-5.589-.891-6.378-1.42-1.426-.844-2.919-1.667-3.991-2.619v-.483c.739.35 1.875 1.182 2.895 1.874 2.019 1.509 4.717 2.208 7.015 2.385 5.563.573 15.019-4.58 16.173-5.732 2.148-1.556 4.177-3.158 5.013-3.372z'
          fill='#b47f39'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-2.625 1.374-7.697 4.221-13.565 4.286-3.297-.336-5.589-.891-6.378-1.42-1.162-.688-2.368-1.361-3.355-2.103v-.647c.677.414 1.5 1.006 2.259 1.521 2.019 1.509 4.717 2.208 7.015 2.385 5.563.573 15.019-4.58 16.173-5.732 2.148-1.555 4.177-3.157 5.013-3.371z'
          fill='#b27c39'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-2.625 1.374-7.697 4.221-13.565 4.286-3.297-.336-5.589-.891-6.378-1.42-.922-.546-1.873-1.083-2.719-1.652v-.689c.524.35 1.089.75 1.623 1.112 2.019 1.509 4.717 2.208 7.015 2.385 5.563.573 15.019-4.58 16.173-5.732 2.148-1.555 4.177-3.157 5.013-3.371z'
          fill='#ae7939'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-2.625 1.374-7.697 4.221-13.565 4.286-3.297-.336-5.589-.891-6.378-1.42-.698-.413-1.411-.82-2.084-1.241v-.667c.33.228.664.461.987.68 2.019 1.509 4.717 2.208 7.015 2.385 5.563.573 15.019-4.58 16.173-5.732 2.149-1.556 4.178-3.158 5.014-3.372z'
          fill='#ac7639'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-2.625 1.374-7.697 4.221-13.565 4.286-3.297-.336-5.589-.891-6.378-1.42-.481-.285-.97-.567-1.448-.853v-.616c.118.082.235.162.352.24 2.019 1.509 4.717 2.208 7.015 2.385 5.563.573 15.019-4.58 16.173-5.732 2.148-1.555 4.177-3.157 5.013-3.371z'
          fill='#a87339'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-2.625 1.374-7.697 4.221-13.565 4.286-3.297-.336-5.589-.891-6.378-1.42-.27-.16-.542-.318-.812-.477v-.547c1.983 1.369 4.539 2.012 6.731 2.181 5.563.573 15.019-4.58 16.173-5.732 2.148-1.556 4.177-3.158 5.013-3.372z'
          fill='#a46f39'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-2.625 1.374-7.697 4.221-13.565 4.286-3.297-.336-5.589-.891-6.378-1.42l-.177-.104v-.517c1.875 1.093 4.131 1.626 6.095 1.777 5.563.573 15.019-4.58 16.173-5.732 2.149-1.555 4.178-3.157 5.014-3.371z'
          fill='#a26d39'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-2.625 1.374-7.697 4.221-13.565 4.286-2.8-.285-4.873-.728-5.919-1.179v-.519c1.735.864 3.712 1.3 5.459 1.435 5.563.573 15.019-4.58 16.173-5.732 2.149-1.556 4.178-3.158 5.014-3.372z'
          fill='#a06a38'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-2.625 1.374-7.697 4.221-13.565 4.286-2.313-.236-4.131-.579-5.283-.946v-.458c1.572.671 3.287 1.022 4.824 1.141 5.563.573 15.019-4.58 16.173-5.732 2.148-1.556 4.177-3.158 5.013-3.372z'
          fill='#9d6a39'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-2.625 1.374-7.697 4.221-13.565 4.286-1.925-.196-3.507-.467-4.647-.764v-.389c1.391.509 2.856.786 4.188.889 5.563.573 15.019-4.58 16.173-5.732 2.148-1.555 4.177-3.157 5.013-3.371z'
          fill='#9b6638'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-2.625 1.374-7.697 4.221-13.565 4.286-1.589-.162-2.945-.375-4.012-.611V64.4c1.195.374 2.422.586 3.552.674 5.563.573 15.019-4.58 16.173-5.732 2.149-1.556 4.178-3.158 5.014-3.372z'
          fill='#996438'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-2.625 1.374-7.697 4.221-13.565 4.286a39.428 39.428 0 0 1-3.376-.479v-.274c.99.262 1.986.419 2.917.491 5.563.573 15.019-4.58 16.173-5.732 2.148-1.557 4.177-3.159 5.013-3.373z'
          fill='#956038'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-2.625 1.374-7.697 4.221-13.565 4.286a42.539 42.539 0 0 1-2.74-.364v-.236c.778.171 1.549.28 2.281.337 5.563.573 15.019-4.58 16.173-5.732 2.148-1.556 4.177-3.158 5.013-3.372z'
          fill='#945f38'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-2.625 1.374-7.697 4.221-13.565 4.286a44.198 44.198 0 0 1-2.104-.263v-.21c.561.1 1.114.169 1.645.21 5.563.573 15.019-4.58 16.173-5.732 2.148-1.556 4.177-3.158 5.013-3.372z'
          fill='#915c37'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-2.625 1.374-7.697 4.221-13.565 4.286a49.01 49.01 0 0 1-1.469-.172v-.2c.343.047.68.083 1.009.109 5.563.573 15.019-4.58 16.173-5.732 2.149-1.556 4.178-3.158 5.014-3.372z'
          fill='#8d5a38'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-2.625 1.374-7.697 4.221-13.565 4.286-.285-.029-.562-.06-.833-.092v-.205c.125.013.25.024.374.033 5.563.573 15.019-4.58 16.173-5.732 2.148-1.555 4.177-3.157 5.013-3.371z'
          fill='#8b5837'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-2.625 1.374-7.697 4.221-13.565 4.286l-.197-.021v-.22c5.593.392 14.774-4.621 15.911-5.755 2.148-1.555 4.177-3.157 5.013-3.371z'
          fill='#895537'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-2.559 1.339-7.444 4.08-13.126 4.275v-.209c5.626.005 14.179-4.683 15.275-5.776 2.148-1.555 4.177-3.157 5.013-3.371z'
          fill='#865237'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-2.463 1.289-7.081 3.876-12.491 4.243v-.195c5.613-.319 13.584-4.706 14.639-5.758 2.149-1.555 4.178-3.157 5.014-3.371z'
          fill='#845137'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-2.365 1.238-6.72 3.674-11.855 4.189v-.193c5.561-.589 12.988-4.693 14.003-5.706 2.149-1.555 4.178-3.157 5.014-3.371z'
          fill='#824d36'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-2.268 1.187-6.362 3.474-11.219 4.116v-.201c5.476-.812 12.391-4.65 13.368-5.625 2.148-1.555 4.177-3.157 5.013-3.371z'
          fill='#814d35'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-2.168 1.135-6.006 3.275-10.583 4.022v-.213c5.361-.997 11.792-4.582 12.732-5.519 2.148-1.555 4.177-3.157 5.013-3.371z'
          fill='#814d35'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-2.066 1.082-5.651 3.078-9.948 3.908v-.229c5.222-1.145 11.194-4.489 12.096-5.39 2.149-1.554 4.178-3.156 5.014-3.37z'
          fill='#814d35'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-1.965 1.028-5.299 2.881-9.312 3.776v-.246c5.06-1.262 10.593-4.376 11.46-5.241 2.149-1.554 4.178-3.156 5.014-3.37z'
          fill='#814d35'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-1.859.973-4.947 2.687-8.676 3.625v-.262c4.877-1.351 9.995-4.245 10.825-5.074 2.148-1.554 4.177-3.156 5.013-3.37z'
          fill='#814d35'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-1.752.917-4.596 2.492-8.041 3.457v-.278c4.679-1.414 9.394-4.097 10.189-4.89 2.149-1.554 4.178-3.156 5.014-3.37z'
          fill='#814d35'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-1.643.86-4.247 2.298-7.405 3.27v-.29c4.461-1.453 8.795-3.934 9.553-4.69 2.149-1.555 4.178-3.157 5.014-3.371z'
          fill='#814d35'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-1.531.802-3.898 2.106-6.769 3.066v-.301c4.23-1.471 8.195-3.754 8.917-4.475 2.149-1.555 4.178-3.157 5.014-3.371z'
          fill='#814d35'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-1.418.742-3.549 1.914-6.133 2.845v-.307c3.985-1.469 7.596-3.563 8.282-4.248 2.148-1.555 4.177-3.157 5.013-3.371z'
          fill='#814d35'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-1.299.68-3.199 1.721-5.498 2.607v-.31c3.728-1.448 6.996-3.359 7.646-4.007 2.149-1.555 4.178-3.157 5.014-3.371z'
          fill='#814d35'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-1.178.616-2.848 1.53-4.862 2.355v-.312c3.458-1.41 6.397-3.143 7.01-3.753 2.149-1.555 4.178-3.157 5.014-3.371z'
          fill='#814d35'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-1.051.55-2.496 1.337-4.226 2.087v-.309c3.177-1.354 5.8-2.915 6.375-3.488 2.148-1.555 4.177-3.157 5.013-3.371z'
          fill='#814d35'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-.92.481-2.141 1.144-3.59 1.804v-.303c2.885-1.284 5.203-2.677 5.738-3.211 2.149-1.555 4.178-3.157 5.014-3.371z'
          fill='#814d35'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-.781.409-1.781.95-2.955 1.508v-.296c2.586-1.198 4.607-2.428 5.104-2.922 2.148-1.555 4.177-3.157 5.013-3.371z'
          fill='#814d35'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-.637.333-1.418.753-2.318 1.199v-.288c2.277-1.098 4.012-2.168 4.467-2.622 2.148-1.554 4.177-3.156 5.013-3.37z'
          fill='#814d35'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936-.482.252-1.047.554-1.684.879v-.28c1.961-.984 3.42-1.899 3.832-2.309 2.149-1.555 4.178-3.157 5.014-3.371z'
          fill='#814d35'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936l-1.047.551v-.277c1.637-.855 2.83-1.62 3.195-1.983 2.149-1.556 4.178-3.158 5.014-3.372z'
          fill='#814d35'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.711 1.099-2.35 1.681-4.576 2.936l-.412.217v-.283c1.307-.714 2.244-1.329 2.561-1.644 2.148-1.555 4.177-3.157 5.013-3.371z'
          fill='#814d35'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.652 1.061-2.305 1.64-4.352 2.809v-.295c.973-.559 1.662-1.026 1.924-1.288 2.149-1.555 4.178-3.157 5.014-3.371z'
          fill='#814d35'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.469.942-2.146 1.505-3.717 2.438v-.302c.637-.39 1.088-.71 1.289-.911 2.149-1.554 4.178-3.156 5.014-3.37z'
          fill='#814d35'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.254.805-1.932 1.333-3.08 2.051v-.322c.303-.207.525-.379.652-.503 2.149-1.555 4.178-3.157 5.014-3.371z'
          fill='#814d35'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-1.006.646-1.641 1.113-2.445 1.643v-.402l.018-.015c2.148-1.555 4.177-3.157 5.013-3.371z'
          fill='#814d35'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-.729.468-1.264.843-1.809 1.215v-.44c1.911-1.401 3.641-2.727 4.395-2.92z'
          fill='#814d35'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807a50.44 50.44 0 0 0-1.174.779v-.471c1.653-1.217 3.09-2.281 3.76-2.453z'
          fill='#814d35'
        />
        <path
          d='M108.377 55.97v.338c-.244.347-1.273.646-2.586 1.807-.191.123-.369.239-.537.351v-.511c1.379-1.012 2.541-1.836 3.123-1.985z'
          fill='#814d35'
        />
        <path
          d='M108.377 55.97v.338c-.236.338-1.223.631-2.488 1.721v-.537c1.095-.792 1.998-1.397 2.488-1.522z'
          fill='#814d35'
        />
        <path
          d='M108.377 55.97v.338c-.195.279-.9.527-1.852 1.218v-.487c.805-.563 1.461-.969 1.852-1.069z'
          fill='#814d35'
        />
        <path
          d='M108.377 55.97v.338c-.148.212-.594.407-1.217.792v-.491c.516-.335.934-.567 1.217-.639z'
          fill='#814d35'
        />
        <path
          d='M108.377 55.97v.338c-.092.13-.293.254-.58.418v-.503c.23-.126.426-.214.58-.253z'
          fill='#814d35'
        />
        <path
          d='M76.622 60.647c.485-.178 2.195 1.098 3.596 2.051 2.006 1.492 4.636 2.208 6.954 2.391 5.627.567 15.167-4.644 16.211-5.755 2.182-1.575 4.164-3.131 4.984-3.355.314.576-.924.633-2.635 2.163-1.703 1.086-2.287 1.637-4.48 2.873-2.602 1.362-7.696 4.222-13.559 4.31-3.307-.329-5.725-.913-6.45-1.417-1.769-1.038-3.561-2.015-4.621-3.261z'
          fill='#b27c39'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-2.602 1.362-7.696 4.222-13.559 4.31-3.307-.329-5.725-.913-6.45-1.417-1.769-1.038-3.561-2.015-4.621-3.261.485-.178 2.195 1.098 3.596 2.051 2.006 1.492 4.636 2.208 6.954 2.391 5.627.567 15.167-4.644 16.211-5.755 2.181-1.575 4.164-3.131 4.984-3.355z'
          fill='#b27c39'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-2.602 1.362-7.696 4.222-13.559 4.31-3.307-.329-5.725-.913-6.45-1.417-1.452-.853-2.919-1.663-3.985-2.616v-.503c.755.35 1.939 1.215 2.96 1.909 2.006 1.492 4.636 2.208 6.954 2.391 5.627.567 15.167-4.644 16.211-5.755 2.181-1.575 4.164-3.131 4.984-3.355z'
          fill='#b07839'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-2.602 1.362-7.696 4.222-13.559 4.31-3.307-.329-5.725-.913-6.45-1.417-1.181-.693-2.372-1.359-3.349-2.096v-.676c.7.423 1.558 1.041 2.324 1.562 2.006 1.492 4.636 2.208 6.954 2.391 5.627.567 15.167-4.644 16.211-5.755 2.181-1.575 4.164-3.131 4.984-3.355z'
          fill='#ae7639'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-2.602 1.362-7.696 4.222-13.559 4.31-3.307-.329-5.725-.913-6.45-1.417-.935-.549-1.876-1.08-2.713-1.644v-.722c.549.364 1.142.785 1.688 1.156 2.006 1.492 4.636 2.208 6.954 2.391 5.627.567 15.167-4.644 16.211-5.755 2.181-1.575 4.164-3.131 4.984-3.355z'
          fill='#a77138'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-2.602 1.362-7.696 4.222-13.559 4.31-3.307-.329-5.725-.913-6.45-1.417-.705-.413-1.413-.817-2.077-1.232v-.702c.354.243.712.494 1.052.725 2.006 1.492 4.636 2.208 6.954 2.391 5.627.567 15.167-4.644 16.211-5.755 2.181-1.576 4.164-3.132 4.984-3.356z'
          fill='#a46f39'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-2.602 1.362-7.696 4.222-13.559 4.31-3.307-.329-5.725-.913-6.45-1.417-.483-.284-.969-.563-1.441-.845v-.65l.416.285c2.006 1.492 4.636 2.208 6.954 2.391 5.627.567 15.167-4.644 16.211-5.755 2.181-1.575 4.164-3.131 4.984-3.355z'
          fill='#a26d39'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-2.602 1.362-7.696 4.222-13.559 4.31-3.307-.329-5.725-.913-6.45-1.417l-.806-.47v-.581c1.976 1.384 4.5 2.055 6.734 2.232 5.627.567 15.167-4.644 16.211-5.755 2.182-1.575 4.165-3.131 4.985-3.355z'
          fill='#a06a38'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-2.602 1.362-7.696 4.222-13.559 4.31-3.307-.329-5.725-.913-6.45-1.417l-.17-.099v-.542c1.864 1.108 4.097 1.663 6.099 1.821 5.627.567 15.167-4.644 16.211-5.755 2.181-1.574 4.164-3.13 4.984-3.354z'
          fill='#9e6838'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-2.602 1.362-7.696 4.222-13.559 4.31-2.786-.277-4.94-.735-5.984-1.173v-.533c1.723.877 3.684 1.33 5.462 1.471 5.627.567 15.167-4.644 16.211-5.755 2.182-1.576 4.165-3.132 4.985-3.356z'
          fill='#9a6437'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-2.602 1.362-7.696 4.222-13.559 4.31-2.306-.23-4.18-.583-5.349-.946v-.46c1.56.683 3.264 1.047 4.827 1.171 5.627.567 15.167-4.644 16.211-5.755 2.182-1.576 4.165-3.132 4.985-3.356z'
          fill='#996237'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-2.602 1.362-7.696 4.222-13.559 4.31-1.925-.192-3.548-.469-4.712-.768v-.381c1.379.518 2.838.806 4.191.913 5.627.567 15.167-4.644 16.211-5.755 2.181-1.575 4.164-3.131 4.984-3.355z'
          fill='#955f38'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-2.602 1.362-7.696 4.222-13.559 4.31-1.596-.159-2.985-.377-4.077-.617v-.311c1.186.38 2.409.601 3.555.692 5.627.567 15.167-4.644 16.211-5.755 2.182-1.575 4.165-3.131 4.985-3.355z'
          fill='#925f38'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-2.602 1.362-7.696 4.222-13.559 4.31a38.57 38.57 0 0 1-3.44-.486v-.253a15.99 15.99 0 0 0 2.919.503c5.627.567 15.167-4.644 16.211-5.755 2.181-1.575 4.164-3.131 4.984-3.355z'
          fill='#8f5a37'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-2.602 1.362-7.696 4.222-13.559 4.31a41.369 41.369 0 0 1-2.805-.371v-.21c.773.175 1.544.287 2.283.346 5.627.567 15.167-4.644 16.211-5.755 2.182-1.576 4.165-3.132 4.985-3.356z'
          fill='#8c5837'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-2.602 1.362-7.696 4.222-13.559 4.31a43.004 43.004 0 0 1-2.169-.269v-.182c.559.103 1.111.173 1.647.215 5.627.567 15.167-4.644 16.211-5.755 2.182-1.575 4.165-3.131 4.985-3.355z'
          fill='#8a5537'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-2.602 1.362-7.696 4.222-13.559 4.31a47.868 47.868 0 0 1-1.533-.178v-.169c.342.049.68.085 1.012.112 5.627.567 15.167-4.644 16.211-5.755 2.181-1.576 4.164-3.132 4.984-3.356z'
          fill='#875337'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-2.602 1.362-7.696 4.222-13.559 4.31a48.412 48.412 0 0 1-.897-.098v-.172c.126.013.252.024.376.034 5.627.567 15.167-4.644 16.211-5.755 2.181-1.575 4.164-3.131 4.984-3.355z'
          fill='#865137'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-2.602 1.362-7.696 4.222-13.559 4.31l-.261-.027v-.187c5.655.387 14.922-4.683 15.951-5.777 2.181-1.575 4.164-3.131 4.984-3.355z'
          fill='#834d36'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-2.545 1.333-7.479 4.1-13.184 4.299v-.183c5.688 0 14.325-4.743 15.315-5.797 2.181-1.575 4.164-3.131 4.984-3.355z'
          fill='#804b35'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-2.451 1.283-7.114 3.895-12.548 4.267v-.17c5.674-.323 13.724-4.763 14.679-5.778 2.181-1.575 4.164-3.131 4.984-3.355z'
          fill='#7c4935'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-2.355 1.233-6.751 3.693-11.913 4.213v-.168c5.621-.594 13.124-4.748 14.043-5.727 2.182-1.574 4.165-3.13 4.985-3.354z'
          fill='#7a4535'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-2.258 1.182-6.392 3.491-11.277 4.139v-.174c5.536-.818 12.523-4.704 13.408-5.646 2.181-1.575 4.164-3.131 4.984-3.355z'
          fill='#794434'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-2.158 1.13-6.034 3.292-10.641 4.045v-.186c5.421-1.003 11.92-4.634 12.772-5.54 2.181-1.575 4.164-3.131 4.984-3.355z'
          fill='#794434'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-2.059 1.078-5.678 3.093-10.005 3.931v-.201c5.281-1.152 11.317-4.541 12.136-5.412 2.181-1.574 4.164-3.13 4.984-3.354z'
          fill='#794434'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-1.957 1.024-5.325 2.897-9.369 3.798v-.216c5.119-1.271 10.715-4.427 11.5-5.263 2.181-1.575 4.164-3.131 4.984-3.355z'
          fill='#794434'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-1.854.97-4.972 2.701-8.733 3.648v-.232c4.936-1.36 10.11-4.295 10.864-5.097 2.181-1.575 4.164-3.131 4.984-3.355z'
          fill='#794434'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-1.748.915-4.622 2.507-8.097 3.479v-.247c4.736-1.425 9.507-4.146 10.228-4.913 2.181-1.575 4.164-3.131 4.984-3.355z'
          fill='#794434'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-1.641.858-4.271 2.313-7.461 3.292v-.258c4.52-1.466 8.903-3.981 9.592-4.715 2.181-1.575 4.164-3.131 4.984-3.355z'
          fill='#794434'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-1.529.801-3.922 2.12-6.826 3.088v-.269c4.289-1.485 8.3-3.802 8.957-4.501 2.181-1.574 4.164-3.13 4.984-3.354z'
          fill='#794434'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-1.416.742-3.572 1.928-6.19 2.869v-.275c4.042-1.484 7.698-3.61 8.321-4.274 2.181-1.576 4.164-3.132 4.984-3.356z'
          fill='#794434'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-1.301.681-3.223 1.736-5.554 2.631v-.278c3.782-1.464 7.093-3.405 7.685-4.034 2.181-1.575 4.164-3.131 4.984-3.355z'
          fill='#794434'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-1.18.618-2.873 1.544-4.918 2.38v-.279c3.512-1.427 6.492-3.188 7.049-3.782 2.181-1.575 4.164-3.131 4.984-3.355z'
          fill='#794434'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-1.055.552-2.521 1.352-4.282 2.112v-.276c3.231-1.373 5.889-2.96 6.413-3.518 2.181-1.574 4.164-3.13 4.984-3.354z'
          fill='#794434'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-.926.484-2.166 1.159-3.646 1.831v-.27c2.939-1.303 5.289-2.722 5.777-3.242 2.181-1.575 4.164-3.131 4.984-3.355z'
          fill='#794434'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-.789.413-1.809.965-3.01 1.535v-.263c2.637-1.218 4.688-2.472 5.141-2.954 2.181-1.574 4.164-3.13 4.984-3.354z'
          fill='#794434'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-.646.338-1.447.77-2.375 1.227v-.254c2.326-1.119 4.09-2.211 4.506-2.654 2.181-1.575 4.164-3.131 4.984-3.355z'
          fill='#794434'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-.494.258-1.076.571-1.738.908v-.247c2.008-1.005 3.492-1.94 3.869-2.342 2.181-1.575 4.164-3.131 4.984-3.355z'
          fill='#794434'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873-.328.172-.697.368-1.102.58v-.244c1.68-.877 2.896-1.66 3.232-2.017 2.182-1.575 4.165-3.131 4.985-3.355z'
          fill='#794434'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.703 1.086-2.287 1.637-4.48 2.873l-.467.246v-.249c1.35-.735 2.307-1.367 2.598-1.678 2.181-1.575 4.164-3.131 4.984-3.355z'
          fill='#794434'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.658 1.058-2.256 1.608-4.311 2.777v-.263c1.01-.58 1.717-1.062 1.961-1.322 2.182-1.575 4.165-3.131 4.985-3.355z'
          fill='#794434'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.473.94-2.109 1.479-3.676 2.408v-.27c.67-.41 1.139-.745 1.326-.946 2.182-1.575 4.165-3.131 4.985-3.355z'
          fill='#794434'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.256.802-1.904 1.312-3.039 2.021v-.29c.33-.225.568-.41.689-.539 2.182-1.575 4.165-3.131 4.985-3.355z'
          fill='#794434'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-1.004.64-1.619 1.094-2.404 1.613v-.367a.952.952 0 0 0 .055-.054c2.181-1.575 4.164-3.131 4.984-3.355z'
          fill='#794434'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-.721.459-1.24.824-1.768 1.184v-.415c1.946-1.42 3.655-2.728 4.403-2.932z'
          fill='#794434'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-.436.278-.797.521-1.133.749v-.445c1.678-1.231 3.104-2.286 3.768-2.467z'
          fill='#794434'
        />
        <path
          d='M108.367 55.979v.316c-.232.351-1.287.642-2.635 1.847-.176.113-.34.219-.496.322v-.484c1.397-1.023 2.553-1.843 3.131-2.001z'
          fill='#794434'
        />
        <path
          d='M108.367 55.979v.316c-.225.338-1.215.622-2.494 1.724v-.503c1.106-.799 2.006-1.404 2.494-1.537z'
          fill='#794434'
        />
        <path
          d='M108.367 55.979v.316c-.186.28-.895.522-1.859 1.221v-.453c.814-.568 1.469-.977 1.859-1.084z'
          fill='#794434'
        />
        <path
          d='M108.367 55.979v.316c-.143.214-.59.406-1.223.796v-.46c.52-.339.94-.575 1.223-.652z'
          fill='#794434'
        />
        <path
          d='M108.367 55.979v.316c-.088.132-.293.256-.588.424v-.478c.235-.129.432-.22.588-.262z'
          fill='#794434'
        />
        <path
          d='M76.599 60.639c.461-.222 2.259 1.115 3.66 2.07 1.995 1.476 4.556 2.207 6.895 2.396 5.689.56 15.316-4.708 16.251-5.777 2.213-1.596 4.146-3.106 4.951-3.34.297.569-.953.622-2.682 2.182-1.693 1.073-2.225 1.593-4.385 2.81-2.578 1.35-7.695 4.222-13.553 4.333-3.316-.322-5.861-.936-6.522-1.413-1.801-1.049-3.549-2.005-4.615-3.261z'
          fill='#ae7639'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-2.578 1.35-7.695 4.222-13.553 4.333-3.316-.322-5.861-.936-6.522-1.413-1.801-1.05-3.549-2.005-4.615-3.262.461-.222 2.259 1.115 3.66 2.07 1.995 1.476 4.556 2.207 6.895 2.396 5.689.56 15.316-4.708 16.251-5.777 2.213-1.595 4.147-3.105 4.951-3.339z'
          fill='#ae7639'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-2.578 1.35-7.695 4.222-13.553 4.333-3.316-.322-5.861-.936-6.522-1.413-1.479-.861-2.92-1.659-3.979-2.612v-.524c.771.351 2.003 1.25 3.023 1.945 1.995 1.476 4.556 2.207 6.895 2.396 5.689.56 15.316-4.708 16.251-5.777 2.214-1.596 4.148-3.106 4.952-3.34z'
          fill='#ab7339'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-2.578 1.35-7.695 4.222-13.553 4.333-3.316-.322-5.861-.936-6.522-1.413-1.2-.699-2.376-1.356-3.343-2.089v-.704c.724.434 1.616 1.076 2.388 1.602 1.995 1.476 4.556 2.207 6.895 2.396 5.689.56 15.316-4.708 16.251-5.777 2.213-1.596 4.147-3.106 4.951-3.34z'
          fill='#a77038'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-2.578 1.35-7.695 4.222-13.553 4.333-3.316-.322-5.861-.936-6.522-1.413-.948-.552-1.88-1.078-2.707-1.636v-.754c.574.379 1.194.819 1.751 1.199 1.995 1.476 4.556 2.207 6.895 2.396 5.689.56 15.316-4.708 16.251-5.777 2.214-1.596 4.148-3.106 4.952-3.34z'
          fill='#a36c38'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-2.578 1.35-7.695 4.222-13.553 4.333-3.316-.322-5.861-.936-6.522-1.413-.712-.415-1.415-.814-2.071-1.225v-.736c.379.26.76.527 1.116.77 1.995 1.476 4.556 2.207 6.895 2.396 5.689.56 15.316-4.708 16.251-5.777 2.213-1.596 4.147-3.106 4.951-3.34z'
          fill='#a06a38'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-2.578 1.35-7.695 4.222-13.553 4.333-3.316-.322-5.861-.936-6.522-1.413-.487-.284-.969-.56-1.435-.837v-.684l.479.33c1.995 1.476 4.556 2.207 6.895 2.396 5.689.56 15.316-4.708 16.251-5.777 2.214-1.596 4.148-3.106 4.952-3.34z'
          fill='#9e6838'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-2.578 1.35-7.695 4.222-13.553 4.333-3.316-.322-5.861-.936-6.522-1.413l-.799-.464v-.614c1.971 1.4 4.459 2.099 6.738 2.283 5.689.56 15.316-4.708 16.251-5.777 2.214-1.596 4.148-3.106 4.952-3.34z'
          fill='#9a6437'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-2.578 1.35-7.695 4.222-13.553 4.333-3.316-.322-5.861-.936-6.522-1.413l-.163-.095v-.565c1.854 1.124 4.062 1.701 6.102 1.866 5.689.56 15.316-4.708 16.251-5.777 2.214-1.597 4.148-3.107 4.952-3.341z'
          fill='#996237'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-2.578 1.35-7.695 4.222-13.553 4.333-2.772-.269-5.004-.741-6.049-1.169v-.548c1.711.891 3.654 1.362 5.466 1.509 5.689.56 15.316-4.708 16.251-5.777 2.214-1.596 4.148-3.106 4.952-3.34z'
          fill='#955f38'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-2.578 1.35-7.695 4.222-13.553 4.333-2.298-.223-4.226-.586-5.413-.947v-.462c1.547.693 3.239 1.072 4.83 1.201 5.689.56 15.316-4.708 16.251-5.777 2.214-1.596 4.148-3.106 4.952-3.34z'
          fill='#935d38'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-2.578 1.35-7.695 4.222-13.553 4.333-1.925-.187-3.589-.472-4.777-.771v-.373c1.368.527 2.819.825 4.194.937 5.689.56 15.316-4.708 16.251-5.777 2.214-1.597 4.148-3.107 4.952-3.341z'
          fill='#905937'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-2.578 1.35-7.695 4.222-13.553 4.333-1.603-.156-3.025-.379-4.142-.623v-.295c1.176.387 2.394.616 3.558.71 5.689.56 15.316-4.708 16.251-5.777 2.215-1.596 4.149-3.106 4.953-3.34z'
          fill='#8c5737'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-2.578 1.35-7.695 4.222-13.553 4.333a38.723 38.723 0 0 1-3.505-.492v-.232c.976.272 1.967.439 2.922.516 5.689.56 15.316-4.708 16.251-5.777 2.214-1.596 4.148-3.106 4.952-3.34z'
          fill='#8a5337'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-2.578 1.35-7.695 4.222-13.553 4.333a41.01 41.01 0 0 1-2.87-.377v-.185c.768.178 1.538.294 2.286.354 5.689.56 15.316-4.708 16.251-5.777 2.215-1.596 4.149-3.106 4.953-3.34z'
          fill='#875136'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-2.578 1.35-7.695 4.222-13.553 4.333a42.666 42.666 0 0 1-2.233-.275v-.153c.555.104 1.108.177 1.649.221 5.689.56 15.316-4.708 16.251-5.777 2.215-1.597 4.149-3.107 4.953-3.341z'
          fill='#854e36'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-2.578 1.35-7.695 4.222-13.553 4.333a44.12 44.12 0 0 1-1.597-.184v-.138c.341.05.679.087 1.014.114 5.689.56 15.316-4.708 16.251-5.777 2.214-1.596 4.148-3.106 4.952-3.34z'
          fill='#814c36'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-2.578 1.35-7.695 4.222-13.553 4.333a39.954 39.954 0 0 1-.961-.104v-.14c.126.014.252.025.378.035 5.689.56 15.316-4.708 16.251-5.777 2.214-1.595 4.148-3.105 4.952-3.339z'
          fill='#7f4835'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-2.578 1.35-7.695 4.222-13.553 4.333-.109-.011-.218-.021-.325-.033v-.154c5.718.381 15.071-4.745 15.993-5.799 2.214-1.595 4.148-3.105 4.952-3.339z'
          fill='#7c4634'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-2.531 1.326-7.515 4.121-13.242 4.325v-.158c5.75-.005 14.47-4.803 15.357-5.819 2.214-1.596 4.148-3.106 4.952-3.34z'
          fill='#794434'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-2.438 1.277-7.147 3.915-12.606 4.292v-.144c5.735-.329 13.866-4.82 14.721-5.799 2.214-1.597 4.148-3.107 4.952-3.341z'
          fill='#753f33'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-2.342 1.227-6.783 3.711-11.97 4.237v-.142c5.683-.599 13.261-4.804 14.085-5.747 2.214-1.596 4.148-3.106 4.952-3.34z'
          fill='#733e33'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-2.246 1.177-6.421 3.509-11.334 4.163v-.147c5.596-.824 12.656-4.759 13.449-5.667 2.214-1.597 4.148-3.107 4.952-3.341z'
          fill='#723c32'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-2.148 1.125-6.062 3.309-10.698 4.068v-.158c5.481-1.01 12.049-4.688 12.813-5.562 2.214-1.596 4.148-3.106 4.952-3.34z'
          fill='#723c32'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-2.049 1.074-5.705 3.11-10.062 3.955v-.173c5.341-1.161 11.442-4.592 12.177-5.433 2.214-1.597 4.148-3.107 4.952-3.341z'
          fill='#723c32'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-1.949 1.021-5.351 2.912-9.425 3.822v-.188c5.179-1.28 10.835-4.478 11.541-5.286 2.213-1.596 4.147-3.106 4.951-3.34z'
          fill='#723c32'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-1.846.967-4.998 2.716-8.79 3.671v-.203c4.997-1.371 10.227-4.345 10.905-5.12 2.214-1.596 4.148-3.106 4.952-3.34z'
          fill='#723c32'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-1.742.913-4.645 2.521-8.154 3.502v-.217c4.796-1.437 9.621-4.195 10.269-4.937 2.214-1.596 4.148-3.106 4.952-3.34z'
          fill='#723c32'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-1.637.857-4.295 2.327-7.518 3.315v-.228c4.578-1.479 9.014-4.03 9.633-4.739 2.214-1.596 4.148-3.106 4.952-3.34z'
          fill='#723c32'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-1.527.8-3.945 2.134-6.881 3.112v-.237c4.344-1.499 8.407-3.851 8.997-4.526 2.213-1.597 4.147-3.107 4.951-3.341z'
          fill='#723c32'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-1.416.742-3.596 1.942-6.246 2.892v-.243c4.099-1.499 7.798-3.658 8.361-4.301 2.214-1.596 4.148-3.106 4.952-3.34z'
          fill='#723c32'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-1.301.681-3.248 1.75-5.61 2.656v-.246c3.838-1.48 7.192-3.452 7.725-4.062 2.214-1.596 4.148-3.106 4.952-3.34z'
          fill='#723c32'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-1.182.619-2.896 1.559-4.974 2.405v-.247c3.567-1.444 6.587-3.235 7.089-3.81 2.214-1.596 4.148-3.106 4.952-3.34z'
          fill='#723c32'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-1.059.555-2.547 1.366-4.337 2.138v-.243c3.285-1.391 5.98-3.005 6.453-3.546 2.213-1.597 4.147-3.107 4.951-3.341z'
          fill='#723c32'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-.93.488-2.193 1.174-3.701 1.857V62.6c2.99-1.323 5.375-2.766 5.816-3.271 2.214-1.597 4.148-3.107 4.952-3.341z'
          fill='#723c32'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-.797.417-1.836.98-3.066 1.562v-.229c2.689-1.238 4.771-2.515 5.182-2.984 2.213-1.597 4.147-3.107 4.951-3.341z'
          fill='#723c32'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-.654.343-1.475.786-2.43 1.255v-.222c2.377-1.14 4.168-2.254 4.545-2.686 2.214-1.595 4.148-3.105 4.952-3.339z'
          fill='#723c32'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-.504.264-1.107.588-1.793.937v-.215c2.055-1.026 3.566-1.982 3.908-2.374 2.214-1.596 4.148-3.106 4.952-3.34z'
          fill='#723c32'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81-.342.179-.73.386-1.158.609v-.211c1.729-.898 2.967-1.699 3.273-2.05 2.214-1.596 4.148-3.106 4.952-3.34z'
          fill='#723c32'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.693 1.073-2.225 1.593-4.385 2.81l-.521.275v-.215c1.393-.757 2.369-1.406 2.637-1.712 2.213-1.596 4.147-3.106 4.951-3.34z'
          fill='#723c32'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.664 1.054-2.207 1.575-4.27 2.745v-.23c1.051-.602 1.775-1.1 2-1.357 2.214-1.596 4.148-3.106 4.952-3.34z'
          fill='#723c32'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.479.937-2.072 1.452-3.635 2.377v-.237c.707-.431 1.189-.78 1.365-.981 2.214-1.597 4.148-3.107 4.952-3.341z'
          fill='#723c32'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.26.798-1.877 1.291-2.998 1.991v-.258c.361-.244.611-.442.729-.575 2.213-1.596 4.147-3.106 4.951-3.34z'
          fill='#723c32'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-1.002.634-1.596 1.076-2.361 1.582v-.331c.035-.033.066-.065.092-.093 2.213-1.596 4.147-3.106 4.951-3.34z'
          fill='#723c32'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-.711.451-1.219.804-1.727 1.152v-.389c1.984-1.442 3.671-2.73 4.409-2.945z'
          fill='#723c32'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885-.42.267-.77.5-1.09.717v-.417c1.702-1.247 3.114-2.29 3.772-2.482z'
          fill='#723c32'
        />
        <path
          d='M108.355 55.988v.297c-.223.354-1.301.637-2.682 1.885a25.37 25.37 0 0 0-.453.292v-.458c1.413-1.033 2.561-1.849 3.135-2.016z'
          fill='#723c32'
        />
        <path
          d='M108.355 55.988v.297c-.213.338-1.207.612-2.5 1.725v-.469c1.12-.807 2.016-1.412 2.5-1.553z'
          fill='#723c32'
        />
        <path
          d='M108.355 55.988v.297c-.176.28-.889.517-1.863 1.222v-.419c.82-.575 1.475-.987 1.863-1.1z'
          fill='#723c32'
        />
        <path
          d='M108.355 55.988v.297c-.135.215-.586.404-1.229.798v-.429c.526-.344.948-.584 1.229-.666z'
          fill='#723c32'
        />
        <path
          d='M108.355 55.988v.297c-.084.134-.291.258-.592.428v-.452a3.1 3.1 0 0 1 .592-.273z'
          fill='#723c32'
        />
      </g>
    </svg>
  )
}

export default LinuxIcon
