import { RouterProvider } from '@tanstack/react-router'
import { router } from './routes'
import './App.css'
import '@fontsource-variable/oswald'

function App() {
  return (
    <div className='App'>
      <RouterProvider router={router} setLight />
    </div>
  )
}

export default App
