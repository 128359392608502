import { useState } from 'react'
import { Outlet } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import { Container, CssBaseline } from '@mui/material'
import { ThemeProvider } from '@emotion/react'
import { darkTheme, theme } from '../Theme'
import AppBar from '../components/AppBar'

const RenderDevtools = () => {
  return (
    <>
      {process.env.NODE_ENV === 'development' && (
        <>
          <TanStackRouterDevtools />
        </>
      )}
    </>
  )
}

const Layout = () => {
  const [light, setLight] = useState(window?.matchMedia("(prefers-color-scheme: light)")?.matches)

  return (
    <ThemeProvider theme={light ? theme : darkTheme}>
      <CssBaseline />
      <AppBar light={light} setLight={setLight} />
      <Container>
        <Outlet />
      </Container>
      <RenderDevtools />
    </ThemeProvider>
  )
}

export default Layout
