import {
  createRootRoute,
  createRoute,
  createRouter
} from '@tanstack/react-router'
import Layout from '../screens/Layout'
import Home from '../screens/Home'
import About from '../screens/About'
import Experience from '../screens/Experience'
import ContactMe from '../screens/Contact'

// ROUTE
const rootRoute = createRootRoute({
  component: Layout
})

const homeRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/',
  component: Home
})

const abouteRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/about',
  component: About
})

const experienceRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/experience',
  component: Experience
})

const contactMeRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/contact',
  component: ContactMe
})

// ROUTE TREE
const routeTree = rootRoute.addChildren([
  homeRoute,
  abouteRoute,
  experienceRoute,
  contactMeRoute
])

const router = createRouter({ routeTree })

export { router }
