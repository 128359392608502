import { useId, useState } from 'react'
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  useTheme
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import GitHubIcon from '@mui/icons-material/GitHub'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import LightModeIcon from '@mui/icons-material/LightMode'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import { colorTheme } from '../../Theme'
import Link from '../Link'
import IconButtonTooltip from '../IconButton/IconButtonTooltip'
import IconButtonLink from '../IconButton/IconButtonLink'

const AppBar = ({ light, setLight }) => {
  const [navOpen, setNavOpen] = useState(false)
  const theme = useTheme()

  const handleDrawerToggle = () => {
    setNavOpen((prevState) => !prevState)
  }

  const container =
    window !== undefined ? () => window.document.body : undefined

  const navItems = [
    {
      title: 'Home',
      link: '/',
      key: useId()
    },
    {
      title: 'About',
      link: '/about',
      key: useId()
    },
    {
      title: 'Experience',
      link: '/experience',
      key: useId()
    }
  ]

  return (
    <>
      <CssBaseline />
      <MuiAppBar position='static' component='nav' elevation={0}>
        <Container>
          <Toolbar disableGutters>
            {/* -------- MENU ICON -------- */}
            <IconButtonTooltip
              title='Menu'
              color='inherit'
              aria-label='open drawer'
              edge='start'
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}>
              <MenuIcon />
            </IconButtonTooltip>

            {/* -------- TITLE WEB -------- */}
            <Link to='/'>
              <Typography
                variant='h4'
                fontWeight='fontWeightBold'
                sx={{ cursor: 'pointer' }}>
                Fauzan
                <Typography
                  variant='h4'
                  fontWeight='fontWeightBold'
                  color={theme.palette.primary.main}
                  component={'span'}>
                  Akmal
                </Typography>
              </Typography>
            </Link>
            {/* -------- NAV MENU -------- */}
            <Box
              flexGrow={1}
              textAlign={'center'}
              sx={{ display: { xs: 'none', sm: 'block' } }}>
              {navItems.map((item) => (
                <Link to={item.link} key={item.key}>
                  <Button>{item.title}</Button>
                </Link>
              ))}
            </Box>

            {/* -------- ACTION ICON -------- */}
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <IconButtonLink to='https://github.com/nazudis' title='Github'>
                <GitHubIcon />
              </IconButtonLink>

              <IconButtonLink
                to='https://linkedin.com/in/nazudis'
                title='Linkedin'>
                <LinkedInIcon />
              </IconButtonLink>

              {light ? (
                <IconButtonTooltip
                  onClick={() => setLight(false)}
                  title='Dark Mode'>
                  <DarkModeIcon />
                </IconButtonTooltip>
              ) : (
                <IconButtonTooltip
                  onClick={() => setLight(true)}
                  title='Light Mode'>
                  <LightModeIcon />
                </IconButtonTooltip>
              )}
            </Box>
          </Toolbar>
        </Container>
      </MuiAppBar>

      {/* -------- NAV DRAWER -------- */}
      <nav
        animate={navOpen ? 'open' : 'closed'}
        variants={{
          open: { opacity: 1, x: 0 },
          closed: { opacity: 0, x: '-100%' }
        }}>
        <Drawer
          container={container}
          variant='temporary'
          open={navOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: 240
            }
          }}>
          <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Link to='/'>
              <Typography
                variant='h4'
                fontWeight='fontWeightBold'
                sx={{ cursor: 'pointer' }}>
                F
                <Typography
                  variant='h4'
                  fontWeight='fontWeightBold'
                  color={light ? colorTheme.red : colorTheme.yellow}
                  component={'span'}>
                  A
                </Typography>
              </Typography>
            </Link>
            <Divider />
            <List>
              {navItems.map((item) => (
                <Link to={item.link} key={item.key}>
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemText primary={item.title} />
                    </ListItemButton>
                  </ListItem>
                </Link>
              ))}
            </List>
            <Divider />
            <Box>
              <IconButtonLink to='https://github.com/nazudis' title='Github'>
                <GitHubIcon />
              </IconButtonLink>

              <IconButtonLink
                to='https://linkedin.com/in/nazudis'
                title='Linkedin'>
                <LinkedInIcon />
              </IconButtonLink>

              {light ? (
                <IconButtonTooltip
                  onClick={() => setLight(false)}
                  title='Dark Mode'>
                  <DarkModeIcon />
                </IconButtonTooltip>
              ) : (
                <IconButtonTooltip
                  onClick={() => setLight(true)}
                  title='Light Mode'>
                  <LightModeIcon />
                </IconButtonTooltip>
              )}
            </Box>
          </Box>
        </Drawer>
      </nav>
    </>
  )
}

export default AppBar
