const NginxIcon = () => {
  return (
    <svg
      width='60'
      height='60'
      viewBox='0 -202 512 512'
      xmlns='http://www.w3.org/2000/svg'
      preserveAspectRatio='xMinYMin meet'
      fill='#000000'>
      <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
      <g
        id='SVGRepo_tracerCarrier'
        strokeLinecap='round'
        strokeLinejoin='round'></g>
      <g id='SVGRepo_iconCarrier'>
        {' '}
        <g fill='#090'>
          {' '}
          <path d='M96.174 107.15c-2.89 0-5.734-1.124-7.86-3.254L22.544 38.12v57.917c0 6.14-4.975 11.114-11.114 11.114-6.135 0-11.114-4.975-11.114-11.114v-84.75c0-4.499 2.712-8.549 6.864-10.269a11.096 11.096 0 0 1 12.11 2.41L85.06 69.204V11.287c0-6.14 4.975-11.114 11.114-11.114 6.14 0 11.114 4.975 11.114 11.114v84.75a11.12 11.12 0 0 1-11.114 11.114M147.64.28l-3.054 5.7-23.619 42.925-3.054 5.42 3.054 5.414 23.62 41.54 3.191 5.694h61.824l3.054-6.25L230.3 65.157l7.918-16.115h-17.92l-45.149.138c-5.872-.08-11.274 5.241-11.274 11.114 0 5.872 5.402 11.198 11.274 11.114l27.229-.138-6.668 13.474H160.7l-17.369-30.563 17.507-31.674h37.24l8.682 17.817h22.37L215.03 6.535 211.971.28h-64.33M260.232.142c-5.824.071-11.051 5.433-10.976 11.252v28.963h22.228V11.394C271.554 5.48 266.14.067 260.232.142M295.859 107.364a11.117 11.117 0 0 1-11.11-11.114V11.5c0-6.139 4.975-11.113 11.114-11.113 6.14 0 11.114 4.974 11.114 11.114v57.916L372.749 3.64a11.102 11.102 0 0 1 12.11-2.41 11.117 11.117 0 0 1 6.859 10.27v84.75c0 6.138-4.975 11.113-11.114 11.113-6.14 0-11.114-4.975-11.114-11.114V38.334l-65.772 65.776a11.1 11.1 0 0 1-7.86 3.254M474.044 53.79l34.573-34.447c4.348-4.33 4.361-11.368.027-15.72-4.33-4.348-11.363-4.357-15.715-.027l-34.626 34.507-34.627-34.507c-4.352-4.334-11.385-4.32-15.714.027-4.335 4.352-4.322 11.39.022 15.72l34.573 34.448-34.471 34.35c-4.352 4.335-4.361 11.368-.027 15.715a11.059 11.059 0 0 0 7.873 3.272c2.837 0 5.673-1.08 7.842-3.245l34.529-34.404 34.524 34.404a11.075 11.075 0 0 0 7.846 3.245c2.854 0 5.7-1.089 7.873-3.272 4.33-4.347 4.321-11.38-.027-15.714L474.044 53.79M260.232 107.048c-5.824-.07-11.051-5.432-10.976-11.251V49.274h22.228v46.523c.07 5.908-5.344 11.322-11.252 11.251'></path>{' '}
        </g>{' '}
      </g>
    </svg>
  )
}

export default NginxIcon
