import React from 'react'
import IconButtonTooltip from './IconButtonTooltip'
import { Link } from '@tanstack/react-router'

const IconButtonLink = (props) => {
  return (
    <Link to={props.to}>
      <IconButtonTooltip title={props.title}>
        {props.children}
      </IconButtonTooltip>
    </Link>
  )
}

export default IconButtonLink
