import { Box, Button, Typography, useTheme } from '@mui/material'
import MotionBox from '../../components/Motion/MotionBox'
import Link from '../../components/Link'
import Cv from '../../assets/cv.pdf'

const Home = () => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 'calc(100vh - 128px)'
      }}>
      <MotionBox
        initial={{ opacity: 0, scale: 0.1 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8, type: 'spring' }}
        textAlign='center'>
        <Typography variant='h2' fontWeight='fontWeightBold'>
          Fauzan Akmal
        </Typography>
      </MotionBox>
      <MotionBox
        initial={{ opacity: 0, scale: 0.1 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8, type: 'spring', delay: 0.4 }}
        textAlign='center'>
        <Typography
          variant='h2'
          fontWeight='fontWeightBold'
          color={theme.palette.primary.main}>
          Software Engineer
        </Typography>
        <Typography variant='h6' fontWeight='fontWeightLight'>
          Frontend, Backend, Full-Stack, DevOps.
        </Typography>
      </MotionBox>

      <MotionBox
        mt={5}
        display='flex'
        flexDirection='row'
        gap={5}
        initial={{ opacity: 0, scale: 0.1 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8, type: 'spring', delay: 0.8 }}
        textAlign='center'>
        <Link to='/contact'>
          <Button variant='contained' fullWidth size='large'>
            Contact Me
          </Button>
        </Link>
        <a href={Cv} download='cv_fauzan_akmal.pdf'>
          <Button variant='contained' fullWidth size='large'>
            Resume
          </Button>
        </a>
      </MotionBox>
    </Box>
  )
}

export default Home
