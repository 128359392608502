const PhpIcon = () => {
  return (
    <svg
      width='60'
      height='60'
      viewBox='0 -61 256 256'
      xmlns='http://www.w3.org/2000/svg'
      preserveAspectRatio='xMinYMin meet'>
      <g fillRule='evenodd'>
        <ellipse fill='#8993BE' cx='128' cy='66.63' rx='128' ry='66.63' />

        <path
          d='M35.945 106.082l14.028-71.014H82.41c14.027.877 21.041 7.89 21.041 20.165 0 21.041-16.657 33.315-31.562 32.438H56.11l-3.507 18.411H35.945zm23.671-31.561L64 48.219h11.397c6.137 0 10.52 2.63 10.52 7.89-.876 14.905-7.89 17.535-15.78 18.412h-10.52zM100.192 87.671l14.027-71.013h16.658l-3.507 18.41h15.78c14.028.877 19.288 7.89 17.535 16.658l-6.137 35.945h-17.534l6.137-32.438c.876-4.384.876-7.014-5.26-7.014H124.74l-7.89 39.452h-16.658zM153.425 106.082l14.027-71.014h32.438c14.028.877 21.042 7.89 21.042 20.165 0 21.041-16.658 33.315-31.562 32.438h-15.781l-3.507 18.411h-16.657zm23.67-31.561l4.384-26.302h11.398c6.137 0 10.52 2.63 10.52 7.89-.876 14.905-7.89 17.535-15.78 18.412h-10.521z'
          fill='#232531'
        />
      </g>
    </svg>
  )
}

export default PhpIcon
