import { Grid2 as Grid, Paper, Typography, useTheme } from '@mui/material'
import MotionBox from '../../components/Motion/MotionBox'
import MotionText from '../../components/Motion/MotionText'
import AwsIcon from '../../components/svg/aws'
import CssIcon from '../../components/svg/css'
import DockerIcon from '../../components/svg/docker'
import FirebaseIcon from '../../components/svg/firebase'
import GitIcon from '../../components/svg/git'
import GolangIcon from '../../components/svg/golang'
import GoogleCloudIcon from '../../components/svg/googlecloud'
import HtmlIcon from '../../components/svg/html'
import LinuxIcon from '../../components/svg/linux'
import MongoDBIcon from '../../components/svg/mongodb'
import MySQLIcon from '../../components/svg/mysql'
import NextJSIcon from '../../components/svg/nextjs'
import NginxIcon from '../../components/svg/nginx'
import NodeJSIcon from '../../components/svg/nodejs'
import PhpIcon from '../../components/svg/php'
import PostgreIcon from '../../components/svg/postgre'
import RabbitMQIcon from '../../components/svg/rabbitmq'
import ReactIcon from '../../components/svg/react'
import ReactNativeIcon from '../../components/svg/reactnative'
import RedisIcon from '../../components/svg/redis'
import TailwindIcon from '../../components/svg/tailwind_icon'
import { useId } from 'react'

const splitTextIntoArray = (text, color) => {
  const chars = []
  const regex = /[\s\S]/gu

  let match
  while ((match = regex.exec(text)) !== null) {
    chars.push({
      char: match[0],
      color
    })
  }

  return chars
}

const About = () => {
  const theme = useTheme()

  const textOne = [
    ...splitTextIntoArray(`Hello, I'm`),
    ...splitTextIntoArray(' Fauzan', theme.palette.primary.main)
  ]
  const textTwo = [
    ...splitTextIntoArray(`I'm a`),
    ...splitTextIntoArray(' FullStack Engineer', theme.palette.primary.main),
    ...splitTextIntoArray(' with focus on'),
    ...splitTextIntoArray(' Backend and DevOps.', theme.palette.primary.main)
  ]

  const textThree = splitTextIntoArray(
    `In my free time, I love to learn new technologies and keep up-to-date with full stack developement. Outside of programming, I love to read books, blog or anything about conspiracy 😁, also I watch animes. I learnt alot things from anime.`
  )

  const skills = [
    {
      id: useId(),
      title: 'Backend',
      data: [
        {
          id: useId(),
          title: 'Golang',
          icon: <GolangIcon />
        },
        {
          id: useId(),
          title: 'NodeJS',
          icon: <NodeJSIcon />
        },
        {
          id: useId(),
          title: 'MySQL',
          icon: <MySQLIcon />
        },
        {
          id: useId(),
          title: 'Postgre',
          icon: <PostgreIcon />
        },
        {
          id: useId(),
          title: 'MongoDB',
          icon: <MongoDBIcon />
        },
        {
          id: useId(),
          title: 'RabbitMQ',
          icon: <RabbitMQIcon />
        },
        {
          id: useId(),
          title: 'Redis',
          icon: <RedisIcon />
        }
      ]
    },
    {
      id: useId(),
      title: 'Frontend',
      data: [
        {
          id: useId(),
          title: 'ReactJS',
          icon: <ReactIcon />
        },
        {
          id: useId(),
          title: 'NextJS',
          icon: <NextJSIcon />
        },
        {
          id: useId(),
          title: 'Tailwind CSS',
          icon: <TailwindIcon />
        },
        {
          id: useId(),
          title: 'Firebase',
          icon: <FirebaseIcon />
        },
        {
          id: useId(),
          title: 'HTML',
          icon: <HtmlIcon />
        },
        {
          id: useId(),
          title: 'CSS',
          icon: <CssIcon />
        }
      ]
    },
    {
      id: useId(),
      title: 'DevOps',
      data: [
        {
          id: useId(),
          title: 'Docker',
          icon: <DockerIcon />
        },
        {
          id: useId(),
          title: 'Nginx',
          icon: <NginxIcon />
        },
        {
          id: useId(),
          title: 'AWS',
          icon: <AwsIcon />
        },
        {
          id: useId(),
          title: 'Google Cloud',
          icon: <GoogleCloudIcon />
        },
        {
          id: useId(),
          title: 'Linux',
          icon: <LinuxIcon />
        }
      ]
    },
    {
      id: useId(),
      title: 'Other',
      data: [
        {
          id: useId(),
          title: 'React Native',
          icon: <ReactNativeIcon />
        },
        {
          id: useId(),
          title: 'Git',
          icon: <GitIcon />
        },
        {
          id: useId(),
          title: 'Php',
          icon: <PhpIcon />
        }
      ]
    }
  ]

  const variants = {
    hidden: { opacity: 0 },
    reveal: { opacity: 1 }
  }

  return (
    <Grid
      container
      mt={10}
      mx='auto'
      spacing={5}
      px={{ xs: 5, sm: 10, md: 20 }}>
      <Grid size={{ xs: 12, md: 8 }}>
        <MotionBox
          initial='hidden'
          whileInView='reveal'
          transition={{ staggerChildren: 0.02 }}>
          {textOne?.map((t, idx) => (
            <MotionText
              variant='h4'
              fontWeight='fontWeightBold'
              key={t.char + idx}
              component='span'
              color={t.color}
              transition={{ duration: 0.5 }}
              variants={variants}>
              {t.char}
            </MotionText>
          ))}
          <br />
          {textTwo?.map((t, idx) => (
            <MotionText
              variant='h4'
              fontWeight='fontWeightBold'
              key={t.char + idx}
              component='span'
              color={t.color}
              transition={{ duration: 0.5 }}
              variants={variants}>
              {t.char}
            </MotionText>
          ))}
        </MotionBox>
      </Grid>

      <Grid size={{ xs: 12, md: 4 }}>
        <MotionBox
          initial='hidden'
          whileInView='reveal'
          transition={{ staggerChildren: 0.01 }}>
          {textThree?.map((t, idx) => (
            <MotionText
              variant='h6'
              fontWeight='fontWeightRegular'
              key={t.char + idx}
              component='span'
              color={t.color}
              transition={{ duration: 0.5 }}
              variants={variants}>
              {t.char}
            </MotionText>
          ))}
        </MotionBox>
      </Grid>

      <Grid size={12}>
        <MotionText
          variant='h4'
          color={theme.palette.primary.main}
          textAlign='center'
          initial={{ opacity: 0, scale: 0.1 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1, type: 'spring', delay: 2 }}>
          Skills & Tech Stack
        </MotionText>
      </Grid>

      <Grid size={12} mb={5}>
        <MotionBox
          initial={{ opacity: 0, scale: 0.1 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1, type: 'spring', delay: 2 }}>
          {skills.map((skill) => (
            <Paper elevation={0} key={skill.id} sx={{ mb: 2 }}>
              <Grid container spacing={2} p={4}>
                <Grid alignContent='center'>
                  <Typography color={theme.palette.primary.main} variant='h5'>
                    {skill.title}
                  </Typography>
                </Grid>
                {skill.data.map((d) => (
                  <Grid
                    key={d.id}
                    display='flex'
                    flexDirection='column'
                    justifyContent='space-between'
                    alignItems='center'>
                    {d.icon}
                    <Typography color='#151515'>{d.title}</Typography>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          ))}
        </MotionBox>

        <Typography variant='body2' fontWeight='fontWeightLight'>
          * My skills are not limited to those shown above, I like to learn new
          things.
        </Typography>
      </Grid>
    </Grid>
  )
}

export default About
