import { IconButton, Tooltip } from '@mui/material'
import React from 'react'

/**
 *
 * @param {import('@mui/material').IconButtonProps} props
 * @returns
 */
const IconButtonTooltip = (props) => {
  const { title, ...cleanProps } = props
  return (
    <Tooltip title={title}>
      <IconButton {...cleanProps}>{props.children}</IconButton>
    </Tooltip>
  )
}

export default IconButtonTooltip
